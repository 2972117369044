import dayjs from "dayjs";
import React from "react";
import { Highlight } from "react-instantsearch";
import {
  RiMailLine,
} from "react-icons/ri";
import { showToast, useErrorDispatch } from "../../../contexts/ErrorContext";

const STATUS_COLOR = {
  PENDING_REVIEW: "border-blue-400/40 border-2",
  CALL_REQUESTED: "border-yellow-400/40 border-2",
  APPROVED: "border-green-400/40 border-2",
  REJECTED: "border-rose-400/40 border-2",
};

export default function CreatorApplicantHit({ hit, onSelected }) {
  const alertDispatch = useErrorDispatch();

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    showToast(alertDispatch, "Copied!");
  };
  return (
    <div
      className="w-full h-full space-y-4 hover:cursor-pointer"
      onClick={(e) => onSelected(hit)}
    >
      <div className="flex justify-between">
        <div>
          <div className="flex items-center pb-2 space-x-4">
            {hit.firstName && hit.lastName && (
              <div className="text-lg first:capitalize">
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="firstName"
                  hit={hit}
                />{" "}
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="lastName"
                  hit={hit}
                />
              </div>
            )}
            {hit.pronouns && (
              <div className="text-xs text-slate-500">
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="pronouns"
                  hit={hit}
                />
              </div>
            )}
          </div>
          <div className="gap-4 lg:flex lg:items-center">
            <div className="text-sm text-slate-500">
              {hit.email ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyToClipboard(hit.email);
                  }}
                  className="flex items-center space-x-2 hover:opacity-30"
                >
                  <RiMailLine />
                  <Highlight
                    classNames={{
                      highlighted: "px-0",
                    }}
                    attribute="email"
                    hit={hit}
                  />
                </div>
              ) : (
                "Incomplete"
              )}
            </div>
            <div className="hidden w-1 h-1 rounded-full lg:block bg-slate-400"></div>
            <div className="text-sm text-slate-500">
              {hit.dob} {!hit.dob && "Incomplete"}
            </div>
            <div className="hidden w-1 h-1 rounded-full lg:block bg-slate-400"></div>

            <div className="text-sm text-slate-500">
              {hit.location ? (
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="location"
                  hit={hit}
                />
              ) : (
                "Incomplete"
              )}
            </div>
          </div>
        </div>
        <div>
          <div
            className={`px-4 py-1 text-sm border rounded-full text-slate-500 ${
              STATUS_COLOR[hit.status]
            }`}
          >
            {hit.status ? (
              <Highlight
                classNames={{
                  highlighted: "px-0",
                }}
                attribute="status"
                hit={hit}
              />
            ) : (
              "Incomplete"
            )}
          </div>
        </div>
      </div>

      <div className="px-3 py-2 overflow-y-auto text-sm whitespace-pre-wrap bg-white border rounded-md">
        {hit.notes ? (
          <Highlight
            classNames={{
              highlighted: "px-0",
            }}
            attribute="notes"
            hit={hit}
          />
        ) : (
          <div className="text-slate-300">No notes</div>
        )}
      </div>

      <div className="flex justify-between space-x-4">
        <Highlight
          classNames={{
            highlighted: "text-slate-200 px-0",
            nonHighlighted: "text-slate-400",
          }}
          attribute="industries"
          hit={hit}
        />
        <Highlight
          classNames={{
            root: "text-end",
            highlighted: "text-slate-200 px-0",
            nonHighlighted: "text-slate-400",
          }}
          attribute="contentType"
          hit={hit}
        />
      </div>

      <div className="flex justify-between">
        {hit.updatedAt && (
          <div className="text-xs text-slate-400">
            Updated on {dayjs(hit.updatedAt).format("MMMM D, YYYY")}
          </div>
        )}
        {hit.createdAt && (
          <div className="text-xs text-slate-400">
            Applied on {dayjs(hit.createdAt).format("MMMM D, YYYY")}
          </div>
        )}
      </div>
    </div>
  );
}
