import React from "react";
import ProfileImage from "../../../../../Basic/ProfileImage";

export default function CollabItem({ collab }) {
  const STATUS_COLOR = {
    REQUESTED: "text-slate-700 bg-slate-200",
    PENDING: "text-yellow-700 bg-yellow-200",
	PAUSED: "text-green-700 bg-green-200",
    ACTIVE: "text-green-700 bg-green-200",
    COMPLETED: "text-blue-700 bg-blue-200",
    REJECTED: "text-red-700 bg-red-200",
  };

  return (
    <div
      className="p-4 px-6 space-y-2 overflow-hidden rounded-lg bg-gray-50 min-w-min"
      key={collab.id}
    >
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="pr-4">{collab?.deliverableCount} asset</div>
          <div className="w-1 h-1 bg-black rounded-full"></div>
          <div className="flex pl-4 divide-x">
            <div className="px-2 text-gray-400">
              ${Math.round(collab?.price * 1.25) / 100} client
            </div>
            <div className="px-2">${Number(collab?.price / 100)} creator</div>
            <div className="px-2 text-green-500">
              ${Math.round(collab?.price * 0.25) / 100} fee
            </div>
          </div>
        </div>
        <div
          className={`px-3 py-1 m-0 text-sm ${
            STATUS_COLOR[collab?.status]
          } rounded-full `}
        >
          {collab?.status}
        </div>
      </div>
      <div className="text-xl font-medium first-letter:capitalize">
        {collab?.name}
      </div>
      <div className="flex justify-between">
        <div className="flex items-center space-x-4">
          <ProfileImage profileImage={collab.client?.image} size="xs" />
          <div className="text-sm truncate w-52 first-letter:capitalize max-h-24 text-slate-500">
            {collab?.client?.brand}
          </div>
        </div>
        <div>{collab?.isOngoing ? "Ongoing" : "Demo"}</div>
      </div>
    </div>
  );
}
