import { AmpAPI } from "@thatsclutch/shared";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { FiArrowRight, FiLoader } from "react-icons/fi";

export default function Matches() {
  const [matches, setMatches] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getStripeSummary();
  }, []);

  const getStripeSummary = async () => {
    setLoading(true);
    try {
      const data = await AmpAPI.get("CampusAPI", "/match", {});
      console.log(data);
      setMatches(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleMatchClick = (match) => {
    if (match.status === "DRAFT") {
      navigate("/match/?matchID=" + match.id);
    }
  };

  return (
    <div className="p-6 overflow-hidden bg-white shadow-2xl sm:space-x-4 sm:flex md:p-6 shadow-slate-200/50 rounded-2xl">
      <div className="flex-1">
        <div className="flex items-center justify-start space-x-4">
          <div className="text-lg font-semibold">Opportunities</div>
          {loading && (
            <div className="flex items-center h-6 right-4">
              <FiLoader className="w-4 h-4 text-slate-500 animate-spin" />
            </div>
          )}
        </div>
        <div className="text-sm text-gray-600 whitespace-pre-line">
          {matches.length === 0
            ? "No opportunities available"
            : "If you get matched with a brand, you'll see it here. Click on NEW opportunities to view more details and apply with a custom message."}
        </div>
        <div className="pt-4 space-y-2 divide-y">
          {matches.map((match) => (
            <div
              className={`flex items-center justify-between p-4 ${match.status ===
                "DRAFT" && "hover:bg-slate-50 cursor-pointer"}`}
              onClick={() => handleMatchClick(match)}
            >
              <div>
                <div className="flex items-center space-x-2">
                  <div className="text-lg capitalize">{match.client.brand}</div>
                  {match.status === "DRAFT" && (
                    <div>
                      <FiArrowRight className="w-4 h-4 text-green-400" />
                    </div>
                  )}
                  <div></div>
                </div>
                <div className="text-sm text-slate-500">
                  {match.status === "DRAFT"
                    ? "NEW"
                    : match.status === "ACTIVE"
                    ? "In review"
                    : match.status === "REQUESTED"
                    ? "Completed"
                    : "Closed"}
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="font-bold text-slate-700">${match.price}</div>
                <div className="text-slate-700">
                  {match.deliverableAmount} asset
                </div>
              </div>
            </div>
          ))}
          {/* empty */}
          {!loading && matches.length === 0 && (
            <div className="flex items-center justify-center p-4 text-sm text-gray-400 bg-white border rounded-md">
              None yet
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
