import React from "react";
import PillItem from "../../../../../Admin/Info/PillItem";
import dayjs from "dayjs";

export default function ListItem({
  client,
  onOpen,
  onSelect,
  showSelect,
  selected,
}) {
  const STATUS_COLOR = {
    PENDING_REVIEW: "bg-slate-400",
    PENDING_PAYMENT: "bg-yellow-400",
    INVITED: "bg-purple-400",
    APPROVED: "bg-green-400",
    REJECTED: "bg-red-400",
  };
  const STATUS_NAMES = {
    PENDING_REVIEW: "Pending Review",
    PENDING_PAYMENT: "Pending Payment",
    APPROVED: "Approved",
    INVITED: "Invited",
    REJECTED: "Rejected",
  };

  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const dateStatus = (date) => {
    // today
    if (date === dayjs().format("YYYY-MM-DD")) return "Today";

    // yesterday
    if (dayjs().diff(date, "day") == 1) return "Yesterday";

    // # days ago (less than 7 days)
    if (dayjs().diff(date, "day") > 1 && dayjs().diff(date, "day") < 7)
      return dayjs().diff(date, "day") + " days ago";

    // 1 week ago
    if (dayjs().diff(date, "week") === 1) return "1 week ago";

    // # weeks ago | greater than a week and less than a month
    if (dayjs().diff(date, "day") > 7 && dayjs().diff(date, "day") < 30)
      return dayjs().diff(date, "week") + " weeks ago";

    // 1 month ago
    if (dayjs().diff(date, "month") === 1) return "1 month ago";

    // # months ago
    if (dayjs().diff(date, "month") > 1)
      return dayjs().diff(date, "month") + " months ago";

    // 1 year ago
    if (dayjs().diff(date, "year") === 1) return "1 year ago";

    return "";
  };

  return (
    <div
      onClick={() => onOpen(client.id)}
      className="flex flex-col items-start justify-between px-4 py-8 transition-colors bg-white cursor-pointer hover:duration-300 hover:bg-[#e7eeeb]/10 min-w-min border-b border-[#E7EEEB] md:flex-row md:space-y-0 md:items-center "
    >
      <div className="w-full space-y-4">
        <div className="flex items-center">
          <div
            className={`overflow-hidden transition-all ${
              showSelect ? "w-16" : "w-0"
            }`}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                onSelect();
              }}
              className="flex justify-center p-2"
            >
              {selected ? (
                <div className="w-6 h-6  rounded-sm bg-[#30DF87] hover:bg-[#30DF87]/30"></div>
              ) : (
                <div className="w-6 h-6 ring-1 ring-[#30DF87] rounded-sm bg-white hover:bg-[#30DF87]/30"></div>
              )}
            </div>
          </div>
          <div className="flex space-x-4">
            {/* Status */}
            <div className="flex items-center space-x-2 w-44">
              {/* <div
                className={`h-2 w-2 rounded-full bg-black`}
              /> */}
              <div
                className={`px-4 py-1 text-base text-black line-clamp-1 rounded-full ${
                  STATUS_COLOR[client.status]
                } 
              first-letter:capitalize`}
              >
                {STATUS_NAMES[client.status]}
              </div>
            </div>

            <div className="flex flex-col items-start justify-center w-36 lg:w-64">
              {/* Name */}
              <div className="w-full text-base capitalize truncate overflow-ellipsis">
                {client?.businessName}
              </div>

              {/* email */}
              <div className="text-base capitalize truncate text-black/50">
                {client?.firstName
                  ? client?.firstName + " " + client?.lastName
                  : "Incomplete"}
              </div>
            </div>

            {/* Last Updated */}
            <div className="flex items-center w-32 overflow-hidden min-w-min">
              {/* <div className="text-base text-black">{dateStatus()}</div> */}
              <div className="text-base text-black">
                {dateStatus(client?.statusDate)}
                {/* {date
                  ? dayjs(date, "YYYY-MM-DD").format(
                      "DD MMM, YYYY"
                    )
                  : "No date"} */}
              </div>
            </div>
            {/* Admin Notes */}
            <div className="flex flex-col justify-center w-64 space-y-2 overflow-hidden lg:w-70">
              <div className="overflow-y-auto whitespace-pre-wrap max-h-16">
                {client?.notes ? (
                  client?.notes
                ) : (
                  <div className="text-gray-400">No notes</div>
                )}
              </div>
            </div>
            {/* Business */}
            <div className="flex flex-col items-start justify-center w-40 overflow-hidden truncate ">
              <div className="w-full text-base capitalize truncate overflow-ellipsis">
                {formatter.format(client?.weeklyBudget)}
              </div>
              <div className="text-base capitalize truncate whitespace-pre-wrap line-clamp-2 text-black/50">
                {client?.services && client.services.join(", ")}
              </div>
            </div>
            {/* Location */}
            <div className="flex items-center w-32 overflow-hidden lg:w-52 min-w-min">
              <div className="text-base text-black line-clamp-2">
                {client?.businessAddress}
              </div>
            </div>
            {/* Applied */}
            <div className="flex items-center w-32 overflow-hidden min-w-min">
              <div className="text-base text-black">
                {dayjs(client.createdAt).format("DD MMM, YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
