import React, { useEffect, useState } from "react";
import CreatorList from "./CreatorList";
import NavBar from "./NavBar";
import Applicants from "./Applicants";
import ClientList from "./ClientList";
import { useMatchState } from "../../../contexts/MatchContext";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import {
  clearNav,
  useNavDispatch,
  useNavState,
} from "../../../contexts/NavContext";
import CollabList from "./CollabList";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_API_KEY as string
);

export default function Admin() {
  const [navSelect, setNavSelect] = useState("collabs");

  const { isMatching } = useMatchState();
  const { tab } = useNavState();
  const dispatch = useNavDispatch();

  useEffect(() => {
    tab && setNavSelect(tab);
  }, [tab]);

  useEffect(() => {
    isMatching && setNavSelect("creators");
  }, [isMatching]);

  return (
    <div className="flex h-full min-h-screen bg-white">
      {/* NAVBAR */}
      <NavBar onSelect={setNavSelect} selected={navSelect} />
      {/* ACTION BAR */}
      <div className="flex-1 h-screen overflow-y-auto">
        {/* <MetricsBar type={navSelect} /> */}
        {navSelect == "applicants" ? (
          <Applicants />
        ) : navSelect == "clients" ? (
          <InstantSearch searchClient={algoliaClient} indexName="client">
            <ClientList />
          </InstantSearch>
        ) : navSelect == "creators" ? (
          <InstantSearch searchClient={algoliaClient} indexName="creator">
            <CreatorList />
          </InstantSearch>
        ) : navSelect == "collabs" ? (
            <CollabList />
        ) : (
          <div>default</div>
        )}
      </div>
    </div>
  );
}
