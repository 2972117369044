import React from "react";
import { FiLoader, FiRefreshCw } from "react-icons/fi";

export default function Refresh({ loading, onRefresh }) {
  return (
    <button
      onClick={(e) => onRefresh()}
      disabled={loading}
      className="flex items-center space-x-4 text-sm underline text-zinc-600"
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <FiLoader className="w-4 h-4 text-black animate-spin" />
        </div>
      ) : (
        <div>
          <FiRefreshCw className="w-4 h-4 text-black hover:text-zinc-400" />
        </div>
      )}
    </button>
  );
}
