import React, { useState } from "react";
import { Configure, Hits, useSearchBox } from "react-instantsearch";
import { getBriefsByClientID } from "@thatsclutch/shared/services/BriefService";
import { FiChevronLeft, FiLoader } from "react-icons/fi";
import { AmpAPI } from "@thatsclutch/shared";
import { updateCreatorApplicant } from "@thatsclutch/shared/services/ApplicationService";
import { useToasts } from "react-toast-notifications";

export default function RequestCollab({
  match,
  brief,
  client,
  onRequest,
  onClose,
}) {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [editNote, setEditNote] = useState<string>("");
  const [editPrice, setEditPrice] = useState<string>("");
  const getUrl = (url) => {
    if (url && url.includes("http")) {
      return url;
    } else {
      return "https://" + url;
    }
  };
  const requestDemoCollab = async () => {
    setLoading(true);
    try {
      const data = await AmpAPI.post("CampusAPI", "/collab/request", {
        body: {
          matchID: match.id,
		  briefID: brief.id,
          clientID: match.client.id,
          name: brief.serviceName.trim(),
          amount: parseInt(match.deliverableAmount),
          links: [brief.socialLink],
          contractLength: brief.contractLength,
          requirements: brief.details.trim(),
          isOngoing: false,
		  startDate: brief.startDate,
        },
      });
      console.log(data);
      //   const data = await AmpAPI.post("CampusAPI", "/collab/demo", {
      //     body: {
      //       creatorID: match.creator.id,
      //       clientID: client.id,
      //       name: brief.serviceName,
      //       price: parseInt(brief.weeklyBudget), // POPF
      //       requirements: (editNote && editNote.trim()) || brief.details.trim(),
      //     },
      //   });
      addToast("Collab requested successfully!", {
        appearance: "success",
      });
      onClose();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const DemoDetail = ({ title, body, isLink = false }) => (
    <div className="w-full space-y-2 text-base">
      <div className="text-sm text-gray-700">{title}</div>
      {!isLink ? (
        <div className="overflow-x-hidden overflow-y-auto whitespace-pre max-h-28">
          <div className="whitespace-pre-wrap">{body}</div>
        </div>
      ) : (
        <a
          href={getUrl(body)}
          target="_blank"
          className="underline hover:text-green-400"
        >
          {body}
        </a>
      )}
    </div>
  );

  return (
    <div className="p-4 space-y-8">
      <div className="space-y-4">
        {/* Title */}
        <div className="text-3xl font-bold capitalize">
          {match.creator?.firstName} {" <> "} {client?.brand}
        </div>
        {/* SubTitle */}
        <div className="text-gray-700">
          This will send a collab request to {match.creator?.firstName}. They
          need to accept the request to start.
        </div>
      </div>

      {/* Brief Select */}
      <div className="p-6 space-y-4 bg-white border border-gray-100 rounded-md">
        <div className="flex">
          <DemoDetail title="Title" body={brief.serviceName} />
          <DemoDetail title="Due Date" body={"1 month after accepting"} />
        </div>
        <div className="flex">
          <DemoDetail
            title="Price"
            body={"$" + (editPrice || match.price)}
          />
          <DemoDetail title="Assets" body={match.deliverableAmount} />
        </div>

        <div className="space-y-4">
          <DemoDetail
            title="Link to primary social"
            body={brief.socialLink}
            isLink
          />
          <DemoDetail
            title="requirements/notes"
            body={editNote || brief.details}
          />
        </div>
      </div>

      {/* Brief Select */}
      <div className="flex justify-end">
        <button
          className={`px-8 space-x-4 flex justify-center items-center py-4 text-center  rounded-full  ${
            brief && client && !loading
              ? "bg-green-400 hover:opacity-90 text-black"
              : "bg-gray-400 text-white "
          } `}
          disabled={!brief || !client || loading}
          onClick={() => {
            requestDemoCollab();
          }}
        >
          <div>Create Collab</div>
          {loading && (
            <div className="flex items-center h-4">
              <FiLoader className="w-4 h-4 text-black animate-spin" />
            </div>
          )}
        </button>
      </div>
    </div>
  );
}
