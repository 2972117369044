import React from "react";

export default function StatusCard({
  label = "Active",
  value = "ACTIVE",
  onSelect,
  status = "",
}) {
  const active = status === value;
  return (
    <div
      onClick={() => onSelect(active ? "" : value)}
      className={`grid  py-4 px-6 border rounded-md border-zinc-300 place-content-center text-zinc-600 hover:opacity-50 transition-all cursor-pointer ${active &&
        "border-primary-dark bg-primary-light"}`}
    >
      <div className={`text-sm select-none ${active && "text-primary-dark"}`}>
        {label}
      </div>
    </div>
  );
}
