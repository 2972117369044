import React, { useState } from "react";
import { navigate } from "@reach/router";
import { Auth } from "aws-amplify";
import { AppUser } from "../../Auth";
import { Button, Checkbox, Form, Input } from "antd";
import { useToasts } from "react-toast-notifications";
import { useAppStore } from "../../../stores/app";
import jwtDecode from "jwt-decode";
import {
  getCurrentCreator,
  updateCreator,
} from "@thatsclutch/shared/services/CreatorService";

const SetPassword = () => {
  const { addToast } = useToasts();
  const { setUser } = AppUser;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state] = useAppStore();

  const updatePassword = async (values) => {
    try {
      setError("");

      if (!values.terms) {
        addToast("Please accepts terms and conditions.", {
          appearance: "error",
        });
        return;
      }
      if (!values.plusEighteen) {
        addToast("Please accepts that you are 18 and above", {
          appearance: "error",
        });
        return;
      }
      if (!state?.user?.username || !state?.user?.tempPass) {
        addToast("Session expired, login again with temporary passwor.", {
          appearance: "error",
        });
        return;
      }

      setLoading(true);

      const userSnapshot = await Auth.signIn(
        state.user.username,
        state.user.tempPass
      );

      await Auth.completeNewPassword(
        userSnapshot, // the Cognito User Object
        values.password // the new password
      )
        .then(async (user) => {
          await Auth.updateUserAttributes(user, {
            "custom:terms": "1", // user accepted the terms and conditions
          });
          // at this time the user is logged in if no MFA required
          const userGroups =
            user.signInUserSession.accessToken.payload["cognito:groups"];
          const userInfo = {
            ...user.attributes,
            username: user.username,
            userGroups,
          };
          setUser(userInfo);
          const creator = await getCurrentCreator();
          const data = await updateCreator(creator, { status: "PENDING" });

          const session = await Auth.currentSession();
          const sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);

          if (
            sessionIdInfo["cognito:groups"] &&
            sessionIdInfo["cognito:groups"].find((g) => g === "creators")
          ) {
            navigate("/app/signup");
          } else {
            navigate("/app/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            err.code === "NotAuthorizedException" &&
            err.message ===
              "Temporary password has expired and must be reset by an administrator."
          ) {
            console.log("go to setPassword");
          }
          setError(err.message || err);
          addToast(err.message || err, { appearance: "error" });
          console.log("error...: ", err.message || err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(err.message || err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col w-1/2 space-y-6">
        <div className="py-4 text-xl">Create a New Password</div>
        <Form
          form={form}
          layout="vertical"
          onFinish={updatePassword}
          size="large"
        >
          <Form.Item
            label="New Password"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Password must be at least 9 characters long",
                min: 9,
              },
            ]}
          >
            <Input.Password placeholder="Password..." />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Passwords must match"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          {error && <div className="py-4 text-rose-500">{error}</div>}
          <Form.Item
            noStyle
            name="terms"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please agree to terms and conditions",
              },
            ]}
          >
            <Checkbox>
              Accept{" "}
              <a href="/app/termsandconditions" className="text-[#64D698]">
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/app/policy" className="text-[#64D698]">
                Privacy Policy
              </a>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="plusEighteen"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please agree to terms and conditions",
              },
            ]}
          >
            <Checkbox>I confirm I am 18 years or older</Checkbox>
          </Form.Item>
          <div>
            <Button
              name="submit"
              type="primary"
              shape="round"
              size="large"
              className="bg-[#30DF87]"
              loading={isLoading}
              htmlType="submit"
            >
              Update password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SetPassword;
