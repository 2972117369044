import { Switch } from "@headlessui/react";
import {
  deleteService,
  updateService,
} from "@thatsclutch/shared/services/CreatorService";
import React, { useEffect, useMemo, useState } from "react";
import PrimaryButton from "../../../../../../Basic/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../../Basic/Buttons/SecondaryButton";

export default function DetailPage({
  service,
  updatedService,
  onSave,
  onClose,
  onEdit,
}) {
  const [active, setActive] = useState(service?.active || false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const isChanged = useMemo(() => {
    console.log(updatedService, service);
    return (
      updatedService?.name !== service.name ||
      updatedService?.price !== service.price ||
      updatedService?.desc !== service.description
    );
  }, [
    service?.id,
    updatedService.name,
    updatedService.price,
    updatedService.desc,
  ]);

  useEffect(() => {
    setActive(service?.active);

    return () => {
      setActive(service?.active);
    };
  }, [service?.id]);

  const onUpdate = async () => {
    // write to db
    const data = {
      id: service?.id,
      _version: service?._version,
      active: active,
      ...(updatedService?.name && { name: updatedService.name }),
      ...(updatedService?.desc && { description: updatedService.desc }),
      ...(updatedService?.price && { price: updatedService.price }),
    };
    setSaveLoading(true);
    const result = await updateService(data);
    setSaveLoading(false);

    // close & reload
    onSave();
  };

  const toggleService = async () => {
    setActive(!active);
  };

  const onCancel = () => {
    setActive(service?.active);
    onClose(false);
  };
  const onDelete = async () => {
    setDeleteLoading(true);
    const result = await deleteService(service);
    // close and reload
    onSave();
  };

  return (
    <div className="p-8 space-y-8">
      <div className="flex items-center justify-between">
        <div className="text-xl font-semibold">Service details</div>
        <Switch
          checked={active}
          onChange={toggleService}
          className={`${active ? "bg-emerald-400/60" : "bg-slate-400"}
        relative inline-flex h-[28px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">activity</span>
          <span
            aria-hidden="true"
            className={`${active ? "translate-x-[24px]" : "translate-x-0"}
          pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <div className="p-4 px-6 rounded-xl bg-yellow-400/30">
        <div>
          To temporarily hide this service you can toggle it off in the top
          right. Deleting or updating a service will not affect pending or
          in-progress collabs.
        </div>
      </div>
      <div className="flex flex-col items-start justify-between p-4 px-6 space-y-4 border rounded-xl sm:flex-row sm:space-y-0 sm:items-center">
        <div className="pr-4">
          <div className="text-lg font-semibold">
            {updatedService?.name || service?.name}
          </div>
          <div className="text-slate-500">
            ${updatedService?.price / 100 || service?.price / 100}/each
          </div>
          <div className="mt-2">{updatedService?.desc || service?.desc}</div>
        </div>
        <SecondaryButton text={"Edit"} onPress={onEdit} />
      </div>
      {(active !== service?.active || isChanged) && (
        <div className="text-end">Save to confirm changes</div>
      )}
      <div className="flex flex-col justify-end space-y-4 sm:flex-row sm:space-y-0 sm:justify-between sm:space-x-4">
        <SecondaryButton
          text={"Delete"}
          onPress={onDelete}
          danger
          loading={deleteLoading}
        />
        <div className="flex flex-col justify-end space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <SecondaryButton text={"Cancel"} onPress={onCancel} />
          <PrimaryButton
            text={"Save"}
            onPress={onUpdate}
            loading={saveLoading}
          />
        </div>
      </div>
    </div>
  );
}
