import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import SimpleModal from "../../../../../Dialogs/SimpleModal";
import PrimaryButton from "../../../../../Basic/Buttons/PrimaryButton";
import {
  addSkillsToCreator,
  getCreatorSkillsByCreator,
  getSkills,
  removeSkillsFromCreator,
} from "@thatsclutch/shared/services/CreatorService";
import SkillItem from "./SkillItem";
import { FiLoader } from "react-icons/fi";

export default function EditSkills({ open, creator, onClose }) {
  const [loading, setLoading] = useState([]);
  const [skills, setSkills] = useState([]);
  const [currLinks, setCurrLinks] = useState([]);

  useEffect(() => {
    if (open) {
      loadSkills();
      creator && loadLinks();
    }
  }, [creator, open]);

  const loadSkills = async () => {
    setLoading(["skills"]);
    const data = await getSkills();
    setSkills(data); // id, name, type
    setLoading([]);
  };

  const loadLinks = async () => {
    const currentLinks = await getCreatorSkillsByCreator(creator?.id);
    console.log(currentLinks);
    setCurrLinks(currentLinks); // id, creatorID, skillID
  };

  const onCancel = () => {
    onClose(false);
  };

  const handleSelect = async (skill) => {
    setLoading((l) => [...l, skill.id]);
    if (isExistingSkill(skill)) {
      // Remove link
      console.log("Trying to remove...");
      const forDelete = currLinks.filter((link) => link.skillID === skill.id);
      const deleteResults = await removeSkillsFromCreator(forDelete);
      console.log(deleteResults);
    } else {
      // Add link
      console.log("Trying to add...");
      const addResult = await addSkillsToCreator(creator, [skill]);
    }
    await loadLinks();
    setLoading((l) => l.filter((v) => v !== skill.id));
  };

  const isExistingSkill = (skill) => {
    return currLinks?.map((link) => link.skillID).includes(skill.id);
  };

  return (
    <SimpleModal show={open} onClose={onCancel}>
      <Transition.Root show={true}>
        <div className="p-8 space-y-4">
          <div>
            <div className="text-2xl font-semibold">Skill setup</div>
            <div className="mt-2 text-sm text-slate-400">
              Select all that apply to improve matching with clients
            </div>
          </div>
          <div className="space-y-4 overflow-y-auto h-[350px] max-h-min scroll-smooth">
            <div className="flex items-center p-2 mb-2 mr-2 space-x-2 border-b">
              <div className="flex items-center space-x-4 text-lg ">
                What can you do?{" "}
              </div>
              <div>
                {loading.length > 0 && (
                  <div className="items-center">
                    <FiLoader className="w-4 h-4 text-black animate-spin" />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row flex-wrap gap-4">
              {skills
                .filter((v) => v.type === "CATEGORY")
                .map((skill) => (
                  <SkillItem
                    key={skill.id}
                    skill={skill}
                    isExistingSkill={isExistingSkill}
                    handleSelect={handleSelect}
                  />
                ))}
            </div>
            <div className="flex items-center p-2 mb-2 mr-2 space-x-2 border-b">
              <div className="flex items-center space-x-4 text-lg ">
                What platforms do you use?
              </div>
              <div>
                {loading.length > 0 && (
                  <div className="items-center">
                    <FiLoader className="w-4 h-4 text-black animate-spin" />
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row flex-wrap gap-4">
              {skills
                .filter((v) => v.type === "PLATFORM")
                .map((skill) => (
                  <SkillItem
                    key={skill.id}
                    skill={skill}
                    isExistingSkill={isExistingSkill}
                    handleSelect={handleSelect}
                  />
                ))}
            </div>
            <div className="p-2 mb-2 text-lg border-b">
              What tools do you use?
            </div>
            <div className="flex flex-row flex-wrap gap-4">
              {skills
                .filter((v) => v.type === "TOOL")
                .map((skill) => (
                  <SkillItem
                    key={skill.id}
                    skill={skill}
                    isExistingSkill={isExistingSkill}
                    handleSelect={handleSelect}
                  />
                ))}
            </div>
          </div>
          <div className="flex justify-end">
            <PrimaryButton
              loading={loading.length > 0}
              text={loading.length > 0 ? "Saving..." : "Done"}
              onPress={onCancel}
            />
          </div>
        </div>
      </Transition.Root>
    </SimpleModal>
  );
}
