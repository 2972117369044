import React, { useState } from "react";
import { Form, Input, Button, Radio, message } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

function EditCreatorApplicantForm({
  selected,
  onUpdate,
  loading
}) {


  const [form] = Form.useForm();
  const [radioVal, setRadioVal] = useState(selected.extraHelp)

  const onChange = (e) => {
    setRadioVal(e.target.value);
  };

  const onFinishFailed = () => {
    message.error('Saving applicant failed! Please check errors');
  };

  return (
    <div className="p-8">
      <div className='text-[32px] font-semibold mb-6'>Edit Applicant</div>
      <div className='space-y-8 '>

        {/* Name */}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            firstName: selected.firstName,
            lastName: selected.lastName,
            email: selected.email,
            phoneNumber: selected.phoneNumber,
            pronouns: selected.pronouns,
            location: selected.location,
            resume_link: selected.resume_link,
            personal_links: selected.personal_links,
            work_links: selected.work_links,
            extraHelp: selected.extraHelp
          }}
          onFinishFailed={onFinishFailed}
          onFinish={onUpdate}
        >
          <div>
            <label className="pb-2 font-semibold">First Name*</label>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "First name is required",
                },
              ]}
            >
              <input
                type="text"
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
                placeholder=""
                value={selected.firstName}
              />
            </Form.Item>
          </div>

          <div>
            <label className="font-semibold">Last Name*</label>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Last name is required",
                },
              ]}>
              <input
                type="text"
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
                placeholder=""
                value={selected.lastName}
              />
            </Form.Item>
          </div>

          <div>
            <label className="font-semibold">Email Address*</label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Email address is required",
                },
              ]}>
              <input
                type="email"
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
                value={selected.email}
              />
            </Form.Item>
          </div>

          <div>
            <label className="font-semibold">Phone Number*</label>
            <Form.Item name="phoneNumber"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Phone number is required",
                },
              ]}>
              <input
                type="tel"
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
                value={selected.phoneNumber}
              />
            </Form.Item>
          </div>

          <div>
            <label className="font-semibold">Pronouns*</label>
            <Form.Item name="pronouns"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Prounon is required",
                },
              ]}>
              <select
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
              >
                <default>Select an option</default>
                <option>She/Her</option>
                <option>He/Him</option>
                <option>They/Them</option>
              </select>
            </Form.Item>
          </div>

          <div>
            <label className="font-semibold">Location*</label>
            <Form.Item name="location"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Location is required",
                },
              ]}>
              <input
                type="text"
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
                placeholder=""
                value={selected.location}
              />
            </Form.Item>
          </div>

          <div>

            <div className="font-semibold">Does the creator work with any additional people to create content?</div>
            <Form.Item name="extraHelp">
              <Radio.Group onChange={onChange} value={radioVal}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div>
            <label className="font-semibold">Resume Link</label>
            <Form.Item name="resume_link">
              <input
                type="text"
                className="w-full px-6 py-4 text-sm rounded-full outline-none ring-1 ring-black focus:ring-2"
                placeholder=""
                value={selected.resumeLink}
              />
            </Form.Item>
          </div>

          <div className="space-y-2">
            <label className="font-semibold">Personal Links *</label>
            <Form.List name="personal_links">
              {(personal_links, { add, remove }, { errors }) => (
                <>
                  {personal_links.map((link, index) => (
                    <Form.Item
                      required={false}
                      key={link.key}
                    >
                      <Form.Item
                        {...link}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Link cannot be empty",
                          },
                        ]}
                        noStyle
                      >
                        <Input style={{ width: '95%', padding: '12px 24px', fontSize: '16px', background: '#ffffff', borderRadius: '9999px', borderColor: '#000', marginBottom: '0px' }} />
                      </Form.Item>
                      {personal_links.length > 1 ? (
                        <MinusCircleOutlined
                          className="ml-2 dynamic-delete-button"
                          onClick={() => remove(link.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <div className="flex justify-end">
                      <div onClick={() => add()} className="flex items-center space-x-1 cursor-pointer hover:underline">
                        <PlusOutlined />
                        <div>Add Link</div>
                      </div>
                    </div>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>

          <div className="space-y-2">
            <label className="font-semibold">Work Links*</label>
            <Form.List name="work_links">
              {(work_links, { add, remove }, { errors }) => (
                <>
                  {work_links.map((link, index) => (
                    <Form.Item
                      required={false}
                      key={link.key}
                    >
                      <Form.Item
                        {...link}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Link cannot be empty",
                          },
                        ]}
                        noStyle
                      >
                        <Input style={{ width: '95%', padding: '12px 24px', fontSize: '16px', background: '#ffffff', borderRadius: '9999px', borderColor: '#000', marginBottom: '0px' }} />
                      </Form.Item>
                      {work_links.length > 1 ? (
                        <MinusCircleOutlined
                          className="ml-2 dynamic-delete-button"
                          onClick={() => remove(link.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <div className="flex justify-end">
                      <div onClick={() => add()} className="flex items-center space-x-1 cursor-pointer hover:underline">
                        <PlusOutlined />
                        <div>Add Link</div>
                      </div>
                    </div>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="flex-1"
            loading={loading}
            style={{
              borderRadius: 100,
              height: 50,
              width: 100,
              border: 0,
              backgroundColor: '#000',
              color: 'white'
            }}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default EditCreatorApplicantForm;