import React, { useEffect, useState } from "react";
import BasicModal from "../BasicModal";

export default function LoadModal({
  isVisible,
  title = "Are you sure?",
  subtitle = "",
  confirmText = "Confirm",
  onConfirm,
  closable,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [startProgress, setStartProgress] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (progress > 0 && progress < 5) setProgress(progress + 1);
    }, 2000);
  }, [startProgress, progress]);

  const handleConfirm = async () => {
    setLoading(true);
    setProgress(1);
    setTimeout(() => {
      setStartProgress(true);
    }, 500);
    await onConfirm(); // should return true or false=
    setLoading(false);
    setProgress(0);
    setStartProgress(false);
    onClose();
  };

  const progressSteps = [
    "Default",
    "Creating a new profile",
    "Copying over the applicant info & notes",
    "Generating password",
    "Sending email",
    "Hang tight...",
  ];

  return (
    <BasicModal isVisible={isVisible} onClose={onClose} onCancel={onClose}>
      <div className="p-6">
        {/* Title */}
        <div className="space-y-3">
          <div className="text-3xl font-medium">{title}</div>
          <div className="text-[16px]">{subtitle}</div>
        </div>
        {/* Actions */}
        {loading ? (
          <div className="py-4 space-y-4 text-center bg-white">
            <div>{progressSteps[progress]}</div>
            {/* Add gradient */}
            <div className="w-full h-2 overflow-hidden rounded-full bg-slate-300">
              <div
                className={`${
                  startProgress ? "w-full" : "w-0"
                } h-full transition-all ease-linear duration-[10s] bg-green-400`}
              ></div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between mt-6">
            <button onClick={onClose} className="text-gray-400 underline">
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="bg-[#30DF87] hover:bg-[#23C774] text-black px-6 py-3  rounded-full"
            >
              {confirmText}
            </button>
          </div>
        )}
      </div>
    </BasicModal>
  );
}
