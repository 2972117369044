import React from "react";
import { FiLoader } from 'react-icons/fi';

export default function PrimaryButton({ text, onPress, loading = false, icon = null, ...props }) {

  return (
    <div className="relative">
      <button
        onClick={!loading ? onPress : undefined}
        className={`${icon && 'flex items-center'} rounded-full bg-[#30DF87] px-6 py-3 font-light text-black  cursor-pointer active:opacity-70`}
        {...props}>
        {text}
        <div className="pl-1">
          {icon}
        </div>

        <div
          className={`transition-all text-center ${loading && '-translate-x-4 text-gray-400'} `}>
          {loading && (
            <div className="absolute flex items-center h-6 right-4">
              <FiLoader className="w-4 h-4 text-white animate-spin" />
            </div>
          )}
        </div>
      </button>
    </div>
  )
}