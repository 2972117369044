import React from 'react';

function CopyIcon(props) {
  return (
    <div>
      <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 6V18C8 18.5304 8.21071 19.0391 8.58579 19.4142C8.96086 19.7893 9.46957 20 10 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V9.242C20 8.97556 19.9467 8.71181 19.8433 8.46624C19.7399 8.22068 19.5885 7.99824 19.398 7.812L16.083 4.57C15.7094 4.20466 15.2076 4.00007 14.685 4H10C9.46957 4 8.96086 4.21071 8.58579 4.58579C8.21071 4.96086 8 5.46957 8 6V6Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 20V22C16 22.5304 15.7893 23.0391 15.4142 23.4142C15.0391 23.7893 14.5304 24 14 24H6C5.46957 24 4.96086 23.7893 4.58579 23.4142C4.21071 23.0391 4 22.5304 4 22V11C4 10.4696 4.21071 9.96086 4.58579 9.58579C4.96086 9.21071 5.46957 9 6 9H8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );
}

export default CopyIcon;