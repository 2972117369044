import React from "react";

export default function SkillItem({ skill, isExistingSkill, handleSelect }) {
	
	return (
    <div
      onClick={() => handleSelect(skill)}
      className={`${isExistingSkill(skill) &&
        "bg-black text-white hover:bg-slate-800"} select-none px-6 py-2 max-w-min whitespace-nowrap rounded-full border border-black cursor-pointer transition-colors hover:bg-slate-100`}
    >
      {skill.name}
    </div>
  );
}
