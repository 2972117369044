import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Card, Row, Col, Button, Space, Switch } from "antd";
import {
  getCurrentCreator,
  updateCreator,
} from "@thatsclutch/shared/services/CreatorService";
import {
  formatAddress,
  googleAutocomplete,
} from "../../../lib/utils/google-autocomplete";
import { useToasts } from "react-toast-notifications";
import useAuth from "../../Auth/useAuth";
import { FiLoader } from "react-icons/fi";

export function EditAccount({}) {
  const [form] = Form.useForm();
  const [creator, setCreator] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(null);
  const autoCompleteRef = useRef(null);
  const { addToast } = useToasts();
  const [currUser, userLoading, logoutUser] = useAuth();

  useEffect(() => {
    autoCompleteRef.current &&
      edit &&
      googleAutocomplete(autoCompleteRef.current, (data) => {
        const address = formatAddress(data);
        form.setFieldsValue({ address: address.formatted_address });
        setPlace(address);
      });
  }, [autoCompleteRef.current, edit]);

  useEffect(() => {
    loadCreator();
  }, []);

  const loadCreator = async () => {
    setLoading(true);
    const creatorData = await getCurrentCreator();
    setCreator(creatorData);
    setLoading(false);
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);

    setLoading(true);

    const { remoteOnly, street2 } = values;
    if (place === null || !place.address || !place.geo_location)
      return addToast("Address missing or incomplete", {
        appearance: "error",
      });
    const { address, geo_location } = place;

    let params = {
      firstName: values.firstName.toLowerCase().trim(),
      lastName: values.lastName.toLowerCase().trim(),
      phoneNumber: values.phone.toLowerCase().trim(),
      remoteOnly: remoteOnly,
      shortAddress: place.formatted_address || null,
      country: address.country || null,
      state: address.state || null,
      locality: address.locality || null,
      street1: address.street1 || null,
      street2: street2 || null,
      postalCode: address.postalCode || null,
      lat: geo_location.lat || null,
      lng: geo_location.lng || null,
    };

    const data = await updateCreator(creator, params);
    setCreator(data);
    setEdit(false);
    setLoading(false);
  };

  const formatPhone = (num) => {
    let formatted = num.replace(
      /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
      function(_, p1, p2, p3, p4) {
        let output = "";
        if (p1) output = `(${p1}`;
        if (p2) output += `${p2})`;
        if (p3) output += ` ${p3}`;
        if (p4) output += ` ${p4}`;
        return output;
      }
    );
    return formatted;
  };

  const CardRow = ({ label, info, cap = false }) => (
    <p className={`${cap && "capitalize"}`}>
      <span className="text-gray-600">{label}: </span>
      {info}
    </p>
  );

  const onChange = (checked) => {
    form.setFieldsValue({ remoteOnly: checked });
    console.log(`switch to ${checked}`);
  };

  return (
    <div className="p-5">
      <Row>
        {edit ? (
          <Col span={24} lg={{ span: 12 }} className="p-4">
            <Form
              form={form}
              layout="vertical"
              name="complex-form"
              onFinish={onFinish}
              initialValues={{
                firstName: creator?.firstName,
                lastName: creator?.lastName,
                phone: creator?.phoneNumber,
                address: creator?.shortAddress,
                remoteOnly: creator?.remoteOnly,
              }}
            >
              <Form.Item
                label="First Name"
                name="firstName"
                style={{ marginBottom: 12 }}
                rules={[{ required: true, message: "First name required." }]}
              >
                <Input className="capitalize" placeholder="Type here..." />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                style={{ marginBottom: 12 }}
                rules={[{ required: true, message: "Last name required." }]}
              >
                <Input className="capitalize" placeholder="Type here..." />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                style={{ marginBottom: 12 }}
                rules={[{ required: true, message: "Phone number required." }]}
              >
                <Input
                  maxLength={10}
                  addonBefore={<p className="p-0 m-0">+1</p>}
                  placeholder="123-123-1234"
                />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: true, message: "Address required." }]}
              >
                <input
                  ref={autoCompleteRef}
                  name="address"
                  className="w-full p-2 px-3 placeholder-gray-400 border border-gray-300 outline-none rounded-xl"
                  placeholder="Type address here..."
                />
              </Form.Item>

              <Form.Item name="street2" label="Address Line 2 (optional)">
                <Input placeholder="Apartment #, Building etc" maxLength={20} />
              </Form.Item>

              <Form.Item
                label="Work preference"
                name="remoteOnly"
                valuePropName="checked"
                style={{ marginBottom: 8 }}
              >
                <div className="flex items-center space-x-4">
                  <Switch onChange={onChange} defaultChecked={creator?.remoteOnly} />
                  <span>Accept remote only work</span>
                </div>
                
              </Form.Item>

              <Form.Item label=" " colon={false}>
                <Space>
                  <Button
                    className="flex items-center justify-center w-16 bg-primary hover:bg-primary/70"
                    type="undefined"
                    htmlType="submit">
                    {loading ?
                      <FiLoader className="w-4 h-4 text-black animate-spin" />
                      : "Save"
                    }
                  </Button>
                  <Button
                    type="secondary"
                    onClick={(e) => {
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        ) : (
          <Col span={24} lg={{ span: 12 }} className="p-4">
            <Card
              title="My Account"
              loading={loading}
              extra={
                <a
                  onClick={() => {
                    setEdit(true);
                    form.setFieldsValue({
                      firstName: creator?.firstName,
                      lastName: creator?.lastName,
                      phone: creator?.phoneNumber,
                      university: creator?.university.name,
                      address: creator?.shortAddress,
                      remoteOnly: creator?.remoteOnly,
                    });
                  }}
                >
                  Edit
                </a>
              }
            >
              <CardRow
                label="Name"
                info={
                  creator?.firstName +
                  (creator?.lastName && ` ${creator?.lastName}`)
                }
                cap
              />
              {creator?.phoneNumber && (
                <CardRow
                  label="Phone Number"
                  info={"+1 " + formatPhone(creator?.phoneNumber)}
                />
              )}
              <CardRow label="Email" info={creator?.invitationalEmail} />
              <CardRow label="Address" info={creator?.shortAddress} />
              <CardRow
                label="Remote only"
                info={creator?.remoteOnly ? "Yes" : "No"}
              />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
