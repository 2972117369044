import { useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { navigate } from "@reach/router";

const useAuth = (fresh = false) => {
  const [currUser, setCurrUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setLoadingUser(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: fresh,
      });
      const userGroups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      const userInfo = {
        ...user.attributes,
        username: user.username,
        userGroups,
      };

      console.log("groups:", userGroups);
      if (!userGroups.includes("admins") && !userGroups.includes("creators")) {
        throw "not provider or admin";
      }
      setCurrUser(userInfo);
      setLoadingUser(false);
      return true;
    } catch (e) {
      logoutUser();
      return false;
    }
  };

  const logoutUser = async () => {
    await Auth.signOut();
    setCurrUser(null);
    navigate("/app/signin");
  };

  return [currUser, loadingUser, logoutUser, getUser];
};

export default useAuth;
