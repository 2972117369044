import React from "react";
import StatusCard from "./StatusCard";

export default function StatusFilter({ onStatusChange }) {
  const [status, setStatus] = React.useState("");

  const handleChange = (status) => {
    setStatus(status);
    onStatusChange(status);
  };

  return (
    <div className="grid grid-cols-4 gap-4 px-8 overflow-x-auto">
      <StatusCard
        onSelect={handleChange}
        label="Enterprise"
        value="ENTERPRISE"
        status={status}
      />
      <StatusCard
        onSelect={handleChange}
        label="Invited"
        value="INVITED"
        status={status}
      />
      <StatusCard
        onSelect={handleChange}
        label="Active"
        value="ACTIVE"
        status={status}
      />
      <StatusCard
        onSelect={handleChange}
        label="Deactivated"
        value="DEACTIVATED"
        status={status}
      />
    </div>
  );
}
