import React from "react";
import Message from "./Message";
import {
  ArrowPathIcon,
  ArrowRightCircleIcon,
  LinkIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { AmpAPI } from "@thatsclutch/shared";
import { FiLoader } from "react-icons/fi";
import dayjs from "dayjs";
import SubmitModal from "../SubmitModal";
import CollabFeedHelp from "../../../Popups/CollabFeedHelp";
import LinkModal from "../LinkModal";
// import { sortByCreated } from "../../../../util/sort";

export default function MessageStack({ submissions, collab, refresh }) {
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [linkOpen, setLinkOpen] = React.useState(false);
  const [link, setLink] = React.useState("");

  const sendMessage = async () => {
    try {
      setLoading(true);
      console.log("note", message);
      const response = await AmpAPI.post(
        "CampusAPI",
        "/collab/" + collab.id + "/message",
        { body: { links: link, note: message } }
      );
      console.log(response);
      if (response.data) {
        setMessage("");
        setLink("");
        await refresh();
      }
    } catch (e) {
      console.error(e);
      alert("Something went wrong. Contact support");
    } finally {
      setLoading(false);
    }
  };

  const getSender = (sub) => {
    console.log("finding sender");
    if (sub.owner === collab.client.userID)
      return collab.client.firstName + " " + collab.client.lastName;
    return "You";
  };

  const sortByCreated = (a, b) => {
    return dayjs(b.createdAt).diff(dayjs(a.createdAt));
  };

  return (
    <div className="space-y-4">
      <CollabFeedHelp onClose={() => setShowPopup(false)} open={showPopup} />
      <LinkModal
        open={linkOpen}
        onComplete={(link) => {
          setLink(link);
          setLinkOpen(false);
        }}
      />
      <div className="flex items-center justify-between pr-4">
        <div className="flex gap-4">
          <div className="text-2xl font-medium">Collab Feed</div>
          <button
            className="p-2 rounded-full hover:bg-gray-200"
            onClick={() => setShowPopup(true)}
          >
            <QuestionMarkCircleIcon className="w-4 h-4 text-slate-600" />
          </button>
        </div>
        <div className="flex items-center">
          <div className="hidden pr-4 text-xs text-gray-300 sm:block">
            Last updated seconds ago
          </div>
          <div className="relative group ">
            <div className="absolute text-xs text-gray-300 transition-all -translate-x-1/2 opacity-0 group-hover:opacity-100 group-hover:-translate-y-2 left-1/2 -top-4">
              Refresh
            </div>
            <ArrowPathIcon
              onClick={() => refresh()}
              className={`w-5 h-5 text-gray-300 transition-colors cursor-pointer border-3 hover:text-black ${loading &&
                "animate-spin"}`}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse items-center gap-6 md:flex-row">
        <div className="flex items-center flex-1 w-full overflow-hidden transition-all bg-white shadow-2xl shadow-slate-200/50 rounded-2xl ring-1 focus-within:ring-2 ring-green-400 focus-within:ring-offset-2">
          <input
            onChange={(e) => setMessage(e.target.value)}
            className="w-full px-6 py-2 overflow-x-auto text-sm outline-none resize-none line md:text-base "
            placeholder="Type message here..."
          />
          {loading ? (
            <div className="flex items-center p-4 ">
              <div className="flex items-center h-6 right-4">
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              </div>
            </div>
          ) : (
            <div className="flex items-center p-2 px-2 space-x-2">
              <button
                onClick={() => {
                  setLinkOpen(true);
                }}
                className="relative p-2 transition-colors bg-white rounded-xl hover:bg-slate-100"
              >
                <LinkIcon
                  className={`w-5 h-5 ${
                    link ? "text-green-400" : "text-slate-600"
                  }`}
                />
                {link && (
                  <div className="absolute grid items-center w-5 h-5 text-xs text-white bg-green-400 rounded-full -top-1 -right-1">
                    1
                  </div>
                )}
              </button>
              <button
                onClick={sendMessage}
                className="p-2 px-2 text-white transition-colors bg-black rounded-xl hover:opacity-80"
              >
                <PaperAirplaneIcon className="w-5 h-5 text-white" />
              </button>
            </div>
          )}
        </div>
      </div>

      {submissions.sort(sortByCreated).map((submission, i) => (
        <Message
          key={submission.id}
          sender={getSender(submission)}
          submission={submission}
        />
      ))}

      {submissions.length == 0 && (
        <div className="flex items-center justify-center py-4">
          <div className="text-slate-500">No updates yet, say Hello! 👋</div>
        </div>
      )}
    </div>
  );
}
