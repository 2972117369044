import { AmpAPI } from "@thatsclutch/shared/services/AmplifyService";
import React, { useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";

export default function StripeCheck({ creator }: { creator: any }) {
  const [isEnabled, setIsEnabled] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    creator && creator.stripeConnectedID && checkStripe();
  }, []);

  async function checkStripe() {
    try {
      setLoading(true);
      const data = await AmpAPI.post(
        "CampusAPI",
        "/payments/connected-account/check",
        {
          body: {
            connectedAccountId: creator.stripeConnectedID,
          },
        }
      );
      setIsEnabled(data.payoutsEnabled && data.paymentsEnabled);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return loading ? (
    <></>
  ) : isEnabled ? null : (
    <div className="flex p-6 space-x-4 overflow-hidden bg-white border rounded-lg">
      <span className="inline">
        <FiAlertCircle />
      </span>
      <div>
        Your payout method is incomplete,{" "}
        {creator.stripeConnectedID && (
          <span>
            <a
              href="https://connect.stripe.com/express_login"
              target="_blank"
              className="underline "
            >
              click here to fix.
            </a>
          </span>
        )}
      </div>
    </div>
  );
}
