import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Checkbox, Input, Switch } from "antd";
import Header from "./Header";
import {
  formatAddress,
  googleAutocomplete,
} from "../../../../lib/utils/google-autocomplete";
import { useToasts } from "react-toast-notifications";
import Helmet from "react-helmet";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
const CheckboxGroup = Checkbox.Group;

const Location = ({ creator, onNext, onBack, onError }) => {
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [place, setPlace] = useState(null);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    googleAutocomplete(autoCompleteRef.current, (data) => {
      const address = formatAddress(data);
      setPlace(address);
    });
  }, [autoCompleteRef.current]);

  const onFinish = async (values) => {
    const { remoteOnly, street2 } = values;
    if (place === null || !place.address || !place.geo_location)
      return addToast("Address missing or incomplete", {
        appearance: "error",
      });
    const { address, geo_location } = place;
    setLoading(true);
    const data = {
      remoteOnly: remoteOnly,
      shortAddress: place.formatted_address || null,
      country: address.country || null,
      state: address.state || null,
      locality: address.locality || null,
      street1: address.street1 || null,
      street2: street2 || null,
      postalCode: address.postalCode || null,
      lat: geo_location.lat || null,
      lng: geo_location.lng || null,
    };
    const createResults = await updateCreator(creator, data);
    setLoading(false);
    // handle error
    if (createResults.error) return onError("Failed to update business");
    onNext();
  };

  const onChange = (checked) => {
    form.setFieldsValue({ remoteOnly: checked });
    console.log(`switch to ${checked}`);
  };

  return (
    <div className="w-full p-4 md:w-2/3">
      <Header step={3} label={"What is your home address?"} />

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ country: "US", remoteOnly: false }}
        size="large"
        requiredMark={false}
      >
        <Form.Item
          label="Address"
          rules={[{ required: true, message: "Address required." }]}
        >
          <input
            ref={autoCompleteRef}
            name="some_type"
            placeholder="Type address here..."
            className="w-full p-3 px-4 border border-gray-300 rounded-full outline-none"
          />
        </Form.Item>
        {!!place && (
          <Form.Item name="street2" label="Address Line 2 (optional)">
            <Input placeholder="Apartment #, Building etc" maxLength={20} />
          </Form.Item>
        )}

        <Form.Item
          label="Work preference"
          name="remoteOnly"
          style={{ marginBottom: 8 }}
        >
          <div className="flex items-center space-x-4">
            <Switch className="bg-gray-300" onChange={onChange} /> <span>Accept remote only work</span>
          </div>
        </Form.Item>

        <div className="flex mt-16 space-x-4">
          <Button
            name="submit"
            type="secondary"
            shape="round"
            className="w-[100px]"
            size="large"
            onClick={() => onBack()}
          >
            Back
          </Button>
          <Button
            name="submit"
            style={{ background: '#30DF87', border: 'none' }}
            shape="round"
            size="large"
            className="bg-[#30DF87]"
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Location;
