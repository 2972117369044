import React, { useState } from "react";

const SkillPill = ({ skill }) => (
  <div className="px-3 py-1 mt-4 mr-4 text-xs border border-black rounded-sm whitespace-nowrap">
    {skill}
  </div>
);

const THEME_MAP = {
  "#F5F2ED": "bg-[#F5F2ED]",
  "#E1EEF5": "bg-[#E1EEF5]",
  "#E6F5E8": "bg-[#E6F5E8]",
  "#F0F2F5": "bg-[#F0F2F5]",
  "#F3EDF7": "bg-[#F3EDF7]",
};

export default function ServiceItem({ item, themeColor, onUpdate }) {
  return (
    <div
      onClick={() => onUpdate(item)}
      className={`p-4 col-span-1 cursor-pointer rounded-xl w-full ${
        THEME_MAP[themeColor || "#F5F2ED"]
      }`}
    >
      <div className="flex items-center justify-between pb-2 border-b border-black/10 ">
        <div className="text-lg font-medium line-clamp-2 first-letter:capitalize">
          {item.name}
        </div>
        <div className="text-sm text-end">
          <div className="font-medium">${item.price / 100}</div>
          <div className="text-xs font-thin">Per Asset</div>
        </div>
      </div>
      <div className="pt-2 text-sm font-thin line-clamp-3 first-letter:capitalize text-grey-700">
        {item.description}
      </div>
      <div className="flex flex-wrap hidden ">
        <SkillPill skill="Video Editing" />
        <SkillPill skill="Transitions" />
        <SkillPill skill="Aesthetics" />
      </div>
    </div>
  );
}
