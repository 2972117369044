const getAPIURL = (test) => {
  switch (test ? "test" : process.env.GATSBY_AMP_ENV) {
    case "prod":
      return "https://h8r69titfj.execute-api.us-east-1.amazonaws.com/prod/apply";
    case "qa":
      return "https://0owvax7lv5.execute-api.us-east-1.amazonaws.com/v2/apply";
    case "dev":
      return "https://krs4rk7cc5.execute-api.us-east-1.amazonaws.com/v2/apply";
    default:
      return "http://localhost:3002/apply"; //"https://rfvlbwto4e.execute-api.us-east-1.amazonaws.com/v2/apply";
  }
};

const resendTemporaryPassword = async (email) => {
  try {
    const API_URL = getAPIURL(false);
    let myHeaders = new Headers({
		"Content-Type": "application/json",
	});

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ email }),
    };

    return await fetch(API_URL + "/password", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  } catch (e) {
    console.error(e);
    return { error: e };
  }
};
export { resendTemporaryPassword };
