import { createService } from "@thatsclutch/shared/services/CreatorService";
import React, { useState } from "react";
import { useCreatorState } from "../../../../../../../contexts/CreatorContext";
import PrimaryButton from "../../../../../../Basic/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../../Basic/Buttons/SecondaryButton";
import useAuth from "../../../../../../Auth/useAuth";

export default function OverviewPage({ onNext, onBack, service }) {
  const [loading, setLoading] = useState(false);
  const { creator } = useCreatorState();
  const [currUser] = useAuth();

  const onCreateService = async () => {
    setLoading(true);
    const { name, desc, price } = service;
    const data = {
      owner: currUser?.username,
      businessID: "123", // DEPRECATED
      creatorID: creator.id,
      name: name?.trim(),
      description: desc?.trim() || "",
      price: price,
      active: true,
      duration: 0, // DEPRECATED
    };

    const result = await createService(data);

    onNext();
  };

  const THEME_MAP = {
    "#F5F2ED": "bg-[#F5F2ED]",
    "#E1EEF5": "bg-[#E1EEF5]",
    "#E6F5E8": "bg-[#E6F5E8]",
    "#F0F2F5": "bg-[#F0F2F5]",
    "#F3EDF7": "bg-[#F3EDF7]",
  };

  return (
    <div className="p-8 space-y-8">
      <div className="space-y-1">
        <div className="text-2xl font-semibold">Adding a service</div>
        <div className="text-sm font-thin text-gray-700">
          Review your service details
        </div>
      </div>

      
      <div
        className={`p-4 col-span-1 cursor-pointer rounded-xl  w-full bg-[#F5F2ED]/50`}
      >
        <div className="flex items-center justify-between pb-2 border-b border-black/10 ">
          <div className="text-lg font-medium line-clamp-2 first-letter:capitalize">
            {service.name}
          </div>
          <div className="text-sm text-end">
            <div className="font-medium">${service.price / 100}</div>
            <div className="text-xs font-thin">Per Asset</div>
          </div>
        </div>
        <div className="pt-2 text-sm font-thin line-clamp-3 first-letter:capitalize text-grey-700">
          {service.desc}
        </div>
        {/* <div className="flex flex-wrap ">
          <SkillPill skill="Video Editing" />
          <SkillPill skill="Transitions" />
          <SkillPill skill="Aesthetics" />
        </div> */}
      </div>
      <div className="flex items-center justify-end space-x-4">
        <SecondaryButton text={"Edit"} onPress={onBack} />
        <PrimaryButton
          text={"Complete"}
          onPress={onCreateService}
          loading={loading}
        />
      </div>
    </div>
  );
}
