import {
  createBrief,
  updateBrief,
} from "@thatsclutch/shared/services/BriefService";
import { DatePicker, DatePickerProps, Select } from "antd";
import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import LongInput from "../../../../../../Basic/Input/LongInput";
import ShortInput from "../../../../../../Basic/Input/ShortInput";
import BasicModal from "../../../../../../Dialogs/BasicModal";
import PrimaryButton from "../../../../../../Admin/Buttons/PrimaryButton";
import DropdownButton from "antd/es/dropdown/dropdown-button";
import { RangePickerProps } from "antd/es/date-picker";

export default function BriefModal({
  brief,
  clientID,
  open,
  onSubmit,
  onCancel,
}) {
  const [serviceName, setServiceName] = useState("");
  const [weeklyBudget, setWeeklyBudget] = useState("");
  const [assetAmount, setAssetAmount] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [contractLength, setContractLength] = useState(0);
  const [details, setDetails] = useState("");
  const [requirements, setRequirements] = useState("");
  const [socialLink, setSocialLink] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    open && fillInBrief();
  }, [brief, open]);

  const fillInBrief = () => {
    console.log(brief);
    setServiceName(brief?.serviceName || "");
    setWeeklyBudget(brief?.weeklyBudget || "");
    setAssetAmount(brief?.deliverableAmount || 1);
    setStartDate(brief?.startDate ? dayjs(brief?.startDate) : dayjs());
    setContractLength(brief?.contractLength || 0);
    setDetails(brief?.details || "");
    setRequirements((brief?.requirements && brief?.requirements[0]) || "");
    setSocialLink(brief?.socialLink || "");
  };

  const handleSubmit = async () => {
    console.log(serviceName, weeklyBudget, details, requirements, startDate);
    if (
      !serviceName.trim() ||
      !weeklyBudget.trim() ||
      !details ||
      !socialLink.trim() ||
      !requirements.trim()
    )
      return setError("Missing values.");
    else setError("");
    let data = {
      clientID,
      serviceName: serviceName.trim(),
      weeklyBudget: weeklyBudget.trim(),
      deliverableAmount: parseInt(assetAmount),
      startDate: startDate && startDate.format("YYYY-MM-DD"), //format with dayjs
      details: details.trim(),
      requirements: requirements.trim(),
      socialLink: socialLink.trim(),
      contractLength: contractLength,
    };

    try {
      setLoading(true);
      //   create/update brief
      if (brief) {
        const result = await updateBrief(brief, data);
      } else {
        console.log("creating");
        const result = await createBrief({ ...data, status: "INACTIVE" });
        console.log(result);
      }
      onSubmit();
    } catch (e) {
      setError(e.message || e || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelect: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(date);
    console.log(date, dateString);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setContractLength(value);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return (
      (current && current < dayjs().endOf("day")) ||
      current > dayjs().add(1, "months")
    );
  };

  return (
    <BasicModal isVisible={open} onCancel={onCancel}>
      <div className="flex flex-col h-full p-2 space-y-4">
        {/* Header */}
        <div className="pb-4 space-y-2">
          <div className="text-2xl font-medium">
            {brief ? "Edit project" : "Create a project"}
          </div>
          <div className="text-slate-500">
            Projects allow you to organize recommendations based on the client's
            needs.
          </div>
          {/* Error */}
          {error && <div className="text-red-500">{error}</div>}
        </div>
        {/* Inputs */}
        <div className="flex-1 space-y-4 overflow-y-auto">
          <ShortInput
            label={"Name the project"}
            placeholder="Ex. Tiktoks new product"
            onChange={setServiceName}
            value={serviceName}
            required
          />

          <div className="flex space-x-4">
            <ShortInput
              label={"# of assets (monthly)"}
              placeholder="ex: 2"
              onChange={setAssetAmount}
              value={assetAmount}
              required
            />
			<ShortInput
              label={
                "Monthly budget" 
                + (weeklyBudget
                  ? " (with fees $" + parseInt(weeklyBudget) * 1.25 + ")"
                  : "")
              }
              placeholder="$500"
              onChange={setWeeklyBudget}
              value={weeklyBudget}
              required
            />
          </div>

          <div className="flex space-x-4">
            <div className="flex-1 space-y-2">
              <div className="text-sm text-slate-800">Start date</div>
              <DatePicker
                onChange={handleDateSelect}
                value={startDate}
                // size="large"
                disabledDate={disabledDate}
                format={"MMM DD, YYYY"}
                className="border h-[40px]  w-full border-slate-200 focus-within:ring-1 focus-within:ring-emerald-300 rounded-full outline-none"
              />
            </div>
            <div className="flex-1 space-y-2">
              <div className="text-sm text-slate-800">
                Minimum contract length
              </div>
              <Select
                defaultValue="None"
                style={{
                  width: "100%",
                }}
                // size="large"
                bordered={false}
                onChange={handleChange}
                className="flex items-center border h-[40px] w-full border-slate-200 focus-within:ring-1 focus-within:ring-emerald-300 text-sm rounded-full outline-none"
                options={[
                  {
                    value: 0,
                    label: "None",
                  },
                  {
                    value: 1,
                    label: "1 Month",
                  },
                  {
                    value: 2,
                    label: "2 Months",
                  },
                  {
                    value: 3,
                    label: "3 Months",
                  },
                  {
                    value: 6,
                    label: "6 Months",
                  },
                ]}
              />
            </div>
          </div>
          <ShortInput
            label={"Project link"}
            placeholder="Useful link for the project (ex. Media kit, socials, etc.)"
            onChange={setSocialLink}
            value={socialLink}
            required
          />
          <LongInput
            label={"Project description"}
            placeholder="Include goal, specs, and any other relevant information for this project..."
            onChange={setDetails}
            value={details}
            required
          />
          <LongInput
            label={"Internal match notes"}
            placeholder="Any information that will help the team match the client with the right creator..."
            onChange={setRequirements}
            value={requirements}
            required
          />
        </div>

        <div className="flex items-center justify-between pt-6">
          <button onClick={onCancel}>Cancel</button>
          {brief ? (
            <PrimaryButton
              onPress={handleSubmit}
              loading={loading}
              text="Update project"
            />
          ) : (
            <PrimaryButton
              onPress={handleSubmit}
              loading={loading}
              text="Create project"
            />
          )}
        </div>
      </div>
    </BasicModal>
  );
}
