import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AmpAPI } from "@thatsclutch/shared";

export default function CancelModal({ onSuccess, collab, open, setOpen }) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleConfirm = async () => {
    setLoading(true);
    setError("");
    try {
      const data = await AmpAPI.put(
        "CampusAPI",
        "/collab/" + collab.id + "/cancel",
        {}
      );
      console.log(data);
      onSuccess();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={"div"}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-3xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="p-8 py-12 space-y-6 bg-white">
                    <div className="space-y-3">
                      <div className="text-3xl font-medium ">
                        Cancel collab with{" "}
                        <span className="captialize">
                          {collab?.client?.firstName}
                        </span>
                        ?
                      </div>
                      <div className="text-sm font-light">
                        Collab will conclude at end of current cycle. Make sure
                        to complete all weekly submissions.
                      </div>
                    </div>

                    {error && (
                      <div className="p-4 text-sm text-red-500 rounded-xl bg-red-50">
                        {error}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end px-8 pb-8">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-6 py-3 mt-3 text-base font-medium bg-white border rounded-full shadow-sm text-slate-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      disabled={loading}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className={`inline-flex justify-center w-full px-6 py-3 text-lg font-light bg-green-400 text-black border border-transparent rounded-full shadow-sm hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={handleConfirm}
                      disabled={loading}
                    >
                      <div className="flex space-x-2">
                        {loading && (
                          <div className="flex items-center justify-center">
                            <svg
                              className="z-10 w-5 h-5 text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        )}
                        <div>Cancel Collab</div>
                      </div>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
