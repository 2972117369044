import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FiUser } from "react-icons/fi";
import SubmissionHistory from "./SubmissionModal";
import { getProfilePicture } from "@thatsclutch/shared/services/CreatorService";
import { UserOutlined } from "@ant-design/icons";

export default function CollabTabRow({ collab }) {
  const [showHistory, setShowHistory] = useState(false);
  const [creatorPhoto, setCreatorPhoto] = useState(null);

  useEffect(() => {
    getProfileImage();
  }, []);

  const STATUS_COLORS = {
    ACTIVE: "bg-[#30DF87]",
    COMPLETED: "bg-[#149AE5] text-white",
    PAUSED: "bg-yellow-500",
    REQUESTED: "bg-[#E4FDE8]",
    REJECTED: "bg-[#E7EEEB]",
  };

  const getProfileImage = async () => {
    await getProfilePicture(collab.creator).then((src) => {
      setCreatorPhoto(src);
    });
    return creatorPhoto;
  };

  const sortByCreated = (a, b) => {
    if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return 1;
    if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) return -1;
    else return 0;
  };

  return (
    <div className="p-6 space-y-6 rounded-lg shadow-todo">
      <div className="flex items-center justify-between">
        <div className="">
          <div className="font-normal ">{collab?.name}</div>

          <div className="text-xl font-semibold capitalize">
            {collab?.creator?.firstName + " " + collab?.creator?.lastName}
          </div>
        </div>
        <div
          className={`px-3 py-1 lowercase ${
            STATUS_COLORS[collab.status]
          } rounded-full first-letter:capitalize`}
        >
          {collab?.status === "PAUSED" ? "ENDING/ENDED" : collab?.status}
        </div>
      </div>

      {/* 1st row */}
      <div className="flex ">
        <div className="flex-1 ">
          <div className="mb-1 text-gray-600">Project type</div>
          <div className="text-sm text-gray-800">{collab?.isOngoing ? "Ongoing" : "Single campaign"}</div>
        </div>
        <div className="flex-1">
          <div className="mb-1 text-gray-600">Start date</div>
          <div className="text-sm text-gray-800">
            {collab?.startDate
              ? dayjs(collab?.startDate).format("MMMM DD, YYYY")
              : "None"}
          </div>
        </div>
        <div className="flex-1 ">
          <div className="flex-1 mb-1 text-gray-600">End date</div>
          <div className="text-sm text-gray-800">
            {collab.endDate
              ? dayjs(collab.endDate).format("MMMM DD, YYYY")
              : "Not started"}
          </div>
        </div>
      </div>

      {/* 2nd row */}
      <div className="flex">
        <div className="flex-1 ">
          <div className="mb-1 text-gray-600">Client Pay</div>
          <div className="text-sm text-gray-800">{"$" + Math.round(collab?.price * 1.25) / 100}</div>
        </div>
        <div className="flex-1">
          <div className="mb-1 text-gray-600">Creator Pay</div>
          <div className="text-sm text-gray-800">{"$" + Math.round(collab?.price) / 100}</div>
        </div>
        <div className="flex-1 ">
          <div className="flex-1 mb-1 text-gray-600 w-[116px]">Clutch Pay</div>
          <div className="text-sm text-gray-800">{"$" + Math.round(collab?.price * 0.25) / 100}</div>
        </div>
      </div>

      {/* Submission History */}
      {/* {collab.submissions.items.length > 0 ? (
        <div
          className="p-4 rounded-lg cursor-pointer shadow-card"
          onClick={() => setShowHistory(true)}
        >
          <div className="font-semibold text-[21px]">Submission History</div>
          Last submission:{" "}
          {dayjs(
            collab.submissions.items.sort(sortByCreated)[0].createdAt
          ).format("ddd MMMM D, YYYY")}
        </div>
      ) : (
        <div className="py-4 rounded-lg shadow-card bg-[#E7EEEB] flex justify-center items-center text-[#6E776E] text-base font-normal">
          <div>No submissions yet</div>
        </div>
      )} */}
      {/* 
      {showHistory && (
        <SubmissionHistory
          collab={collab}
          open={showHistory}
          close={() => setShowHistory(false)}
        />
      )} */}
    </div>
  );
}
