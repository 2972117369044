import React, { useRef, useState } from "react";
import TodoItem from "./TodoItem";
import { navigate } from "gatsby";
import EditProfile from "../../Header/ProfileImage/EditProfile";
import EditSkills from "../../Header/ProfileImage/EditSkills";
import UploadVideo from "../../Profile/IntroVideo/UploadVideo";
import UploadProfileImage from "../../Header/ProfileImage/UploadProfileImage";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
import { message } from "antd";
import {
  getCreatorByID,
  useCreatorDispatch,
} from "../../../../../contexts/CreatorContext";
import confetti from "canvas-confetti";

export default function Todo({ creator }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const myRef = useRef(null);
  const dispatch = useCreatorDispatch();

  const checkAboutMe =
    creator?.shortBio &&
    creator?.links &&
    creator?.links.length > 0 &&
    creator?.links.filter((v) => v && v.trim().length > 0).length > 0;

  const checkSkills = creator?.skills && creator?.skills.items.length > 0;

  const checkPayoutMethod = creator?.stripeConnectedID;

  const checkIntroVideo = creator?.bannerImage;

  const checkProfilePhoto = creator?.image;

  const checkProfileStatus = creator?.status === "ACTIVE";

  const accountReady = async () => {
    let ready = true;
    let error = "";
    if (creator?.status === "PENDING" || !creator?.stripeConnectedID) {
      ready = false;
      error = "Payment account incomplete";
    } else if (!creator?.shortBio) {
      ready = false;
      error = "Missing profile bio";
    } else if (
      creator &&
      creator.links?.filter((v) => v?.length > 0).length === 0
    ) {
      ready = false;
      error = "Missing social links";
    } else if (creator && creator.skills?.items?.length === 0) {
      ready = false;
      error = "Missing skills";
    } else if (!checkProfilePhoto) {
      ready = false;
      error = "Missing profile photo";
    } else if (!checkIntroVideo) {
      ready = false;
      error = "Missing intro video";
    }
    return { ready, error };
  };

  const handleActivateProfile = async () => {
    try {
      setLoadingStatus(true);
      console.log("handleActivateProfile");
      let { ready, error } = await accountReady();
      if (ready) {
        const data = await updateCreator(creator, {
          status: "ACTIVE",
        });
        message.success("Profile is activated");
        showConfetti();
        await getCreatorByID(dispatch, creator.id);
      } else {
        console.error("NOT READY", ready, error);
        message.warning(error);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingStatus(false);
    }
  };

  const showConfetti = () => {
    var myConfetti = confetti.create(myRef.current, {
      resize: true,
      useWorker: true,
    });
    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
    });
    // and launch a few from the right edge
    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
    });

    setTimeout(() => {
      myConfetti.reset();
    }, 5000);
  };

  return (
    <div className="">
      <div className="p-4 space-y-2 bg-gray-100 border rounded-lg">
        <div className="pb-2 text-sm text-gray-700">
          Get started by completing your profile
        </div>
        <TodoItem
          title="Update about me"
          completed={checkAboutMe}
          onClick={() => setShowEditModal(true)}
        />
        <TodoItem
          title="Add skills"
          completed={checkSkills}
          onClick={() => setShowSkillsModal(true)}
        />
        <TodoItem
          title="Connect a payout method"
          completed={checkPayoutMethod}
          onClick={() =>
            checkPayoutMethod
              ? window.open("https://connect.stripe.com/express_login")
              : navigate("/app/signup#stripe")
          }
        />
        <TodoItem
          title="Add a profile photo"
          completed={checkProfilePhoto}
          onClick={() => setShowProfileModal(true)}
        />
        <TodoItem
          title="Upload a quick intro video"
          completed={checkIntroVideo}
          onClick={() => setShowIntroModal(true)}
        />
        <TodoItem
          title="Activate your profile"
          completed={checkProfileStatus}
          onClick={loadingStatus ? () => {} : handleActivateProfile}
          loading={loadingStatus}
        />
      </div>

      <canvas ref={myRef} className="absolute top-0 w-0 h-0" />

      <EditProfile
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          getCreatorByID(dispatch, creator.id);
        }}
        creator={creator}
      />

      <EditSkills
        open={showSkillsModal}
        onClose={() => {
          setShowSkillsModal(false);
          getCreatorByID(dispatch, creator.id);
        }}
        creator={creator}
      />

      <UploadVideo
        open={showIntroModal}
        creator={creator}
        onClose={() => {
          setShowIntroModal(false);
        }}
        onSubmit={() => {
          setShowIntroModal(false);
          getCreatorByID(dispatch, creator.id);
        }}
        videoUrl={null}
      />

      <UploadProfileImage
        open={showProfileModal}
        creator={creator}
        onClose={() => {
          setShowProfileModal(false);
        }}
        onSubmit={() => {
          setShowProfileModal(false);
          getCreatorByID(dispatch, creator.id);
        }}
      />
    </div>
  );
}
