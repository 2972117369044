import { getProfilePicture } from "@thatsclutch/shared/services/UserService";
import React, { useEffect, useState } from "react";
import { useNavigate } from "@reach/router";
import useAuth from "../../../../Auth/useAuth";
import EditProfile from "./EditProfile";
import UploadProfileImage from "./UploadProfileImage";
import EditSkills from "./EditSkills";
import {
  getCreatorByID,
  useCreatorDispatch,
} from "../../../../../contexts/CreatorContext";
import { Dropdown, Menu, message, Switch } from "antd";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
import Image1 from "../../../../../assets/images/ccLogo.png";
import StartCollabPopup from "../../Popups/StartCollabPopup";
import { FiChevronDown, FiMenu } from "react-icons/fi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileImage({ creator }) {
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useCreatorDispatch();
  const navigate = useNavigate();
  const [currUser, loadingUser] = useAuth();
  const isAdminView =
    !loadingUser &&
    currUser.userGroups?.includes("admins") &&
    window.location.pathname.includes("dashboard");

  useEffect(() => {
    creator && creator.image && loadProfileImage();
    setStatusError("");
  }, [creator]);

  const loadProfileImage = async () => {
    setLoading(true);
    const src = await getProfilePicture(creator);
    console.log(src);
    setImgUrl(src);
    setLoading(false);
  };

  const refreshCreator = async () => {
    setShowPhotoModal(false);
    await getCreatorByID(dispatch, creator.id);
    await loadProfileImage();
  };

  const accountReady = async () => {
    let ready = true;
    let error = "";
    if (creator?.status === "PENDING" || !creator?.stripeConnectedID) {
      ready = false;
      error = "Payment account incomplete";
    }
    if (!creator?.shortBio) {
      ready = false;
      error = "Missing profile bio";
    }
    if (creator && creator.links?.filter((v) => v?.length > 0).length === 0) {
      ready = false;
      error = "Missing social links";
    }
    if (creator && creator.skills?.items?.length === 0) {
      ready = false;
      error = "Missing skills";
    }

    return { ready, error };
  };

  const handleStatus = async (checked) => {
    // return console.log("Status changed:", checked, currUser, creator?.stripeConnectedID )
    setLoadingStatus(true);
    try {
      if (!checked) {
        // PROFILE IS ACTIVE
        const data = await updateCreator(creator, {
          status: "INACTIVE",
        });
        message.success("Profile is inactive");
        await getCreatorByID(dispatch, creator.id);
      } else {
        // PROFILE IS INACTIVE
        let { ready, error } = await accountReady();
        if (ready) {
          const data = await updateCreator(creator, {
            status: "ACTIVE",
          });
          message.success("Profile set to Active");
          await getCreatorByID(dispatch, creator.id);
          setShowPopup(true);
        } else {
          console.error("NOT READY", ready, error);
          message.warning(error);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingStatus(false);
    }
  };

  const menu = (
    <Menu
      className="z-10"
      items={[
        {
          onClick: () => false,
          key: "1",
          label: (
            <div>
              {!!statusError && (
                <div className="p-4 pb-0 text-xs text-center text-red-400">
                  {statusError}
                </div>
              )}

              <div
                className={classNames(
                  creator?.status !== "ACTIVE" && "",
                  "p-4 flex rounded-xl space-x-4 justify-between items-center m-4"
                )}
              >
                <div>
                  {creator?.status === "ACTIVE" ? "Active" : "Inactive"}
                </div>

                <Switch
                  className={`${
                    creator?.status === "ACTIVE"
                      ? "bg-[#64D698]"
                      : "bg-gray-200"
                  }`}
                  loading={loadingStatus}
                  onChange={handleStatus}
                  checked={creator?.status === "ACTIVE"}
                />
              </div>
            </div>
          ),
        },

        isAdminView && {
          onClick: () => navigate("/app/admin"),
          key: "3",
          label: (
            <div>
              <a
                className={classNames("block px-4 py-2 text-sm text-gray-700")}
              >
                Back to admin
              </a>
            </div>
          ),
        },
        {
          onClick: () => setShowPhotoModal(true),
          key: "2",
          label: (
            <div>
              <a
                className={classNames("block px-4 py-2 text-sm text-gray-700")}
              >
                Update photo
              </a>
            </div>
          ),
        },
        {
          onClick: () => setShowEditModal(true),
          key: "4",
          label: (
            <div>
              <a
                className={classNames("block px-4 py-2 text-sm text-gray-700")}
              >
                Edit profile
              </a>
            </div>
          ),
        },
        {
          onClick: () => setShowSkillsModal(true),
          key: "5",
          label: (
            <div>
              <a
                className={classNames("block px-4 py-2 text-sm text-gray-700")}
              >
                Edit skills
              </a>
            </div>
          ),
        },
        !isAdminView && {
          onClick: () => navigate("/app/settings"),
          key: "6",
          label: (
            <div>
              <a
                className={classNames("block px-4 py-2 text-sm text-gray-700")}
              >
                Settings
              </a>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={["click"]} className="z-10">
        <div className="flex items-center gap-4 p-2 pl-6 transition-colors rounded-full cursor-pointer group hover:bg-slate-100 bg-slate-50">
          <div>
            <FiMenu className="w-5 h-5 transition-colors text-slate-500 group-hover:text-slate-800" />
          </div>
          <div
            className={classNames(
              creator?.status !== "ACTIVE" && "",
              "relative flex cursor-pointer  bg-slate-100  text-sm overflow-hidden rounded-full transition-transform focus:outline-none group-hover:scale-105"
            )}
          >
            <span className="sr-only">Open user menu</span>
            {loading && (
              <div className="absolute flex items-center justify-center w-full h-full">
                <svg
                  className="z-10 w-5 h-5 text-black animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            )}
            <img
              className="object-cover w-10 h-10"
              src={imgUrl || Image1}
              alt="profile"
              onLoad={() => setLoading(false)}
            />
          </div>
        </div>
      </Dropdown>

      <UploadProfileImage
        open={showPhotoModal}
        onSubmit={refreshCreator}
        onClose={() => {
          setShowPhotoModal(false);
        }}
        creator={creator}
      />
      <EditProfile
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        creator={creator}
      />
      <EditSkills
        open={showSkillsModal}
        onClose={() => {
          refreshCreator();
          setShowSkillsModal(false);
        }}
        creator={creator}
      />
      {/* <StartCollabPopup open={showPopup} onClose={() => setShowPopup(false)} /> */}
    </div>
  );
}
