import React from "react";

export default function ServiceItem({ service }) {
  return (
    <div
      className="p-4 px-6 overflow-hidden rounded-lg min-w-min bg-gray-50"
      key={service.id}
    >
      <div className="m-0 text-sm text-slate-700">
        {service.active ? "Active" : "Inactive"} - $
        {Number(service.price / 100)}
      </div>
      <div className="text-xl font-medium first-letter:capitalize">
        {service.name}
      </div>
      <div className="text-sm truncate w-52 first-letter:capitalize max-h-24 text-slate-500">
        {service.description}
      </div>
    </div>
  );
}
