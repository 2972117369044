import { navigate } from "gatsby";
import React from "react";
import { FiSettings } from "react-icons/fi";
import Img from "../../../../assets/images/logoWhite.png";
import NavItem from "./NavItem";

export default function NavBar({ onSelect, selected }) {
  return (
    <div className="flex flex-col min-w-min shadow-lg bg-[#0D1C0D] rounded-br-lg justify-between w-1/6 h-screen max-w-xs">
      {/* LOGO */}
      <div className="px-2 pt-8 pb-12">
        <img src={Img} className="object-cover w-16 h-16 rounded-full" height={60} width={60} />
      </div>
      {/* NAV ITEMS */}
      <div className={`flex-1 px-[6px] space-y-4 ${!selected ? 'text-white' : 'text-black'}`}>
        <NavItem selected={selected} onSelect={onSelect} title="Applicants" />
        <NavItem selected={selected} onSelect={onSelect} title="Creators" />
        <NavItem selected={selected} onSelect={onSelect} title="Clients" />
        <NavItem selected={selected} onSelect={onSelect} title="Collabs" />
      </div>
      {/* FOOTER */}
      <div className="flex items-center p-8 space-x-4">
        <div onClick={() => navigate("/app/settings")} className="flex items-center px-2 py-2 pr-6 space-x-4 transition-all cursor-pointer max-w-min hover:rounded-lg">
          <div>
            <FiSettings className="w-4 h-4 text-[#E4FDE8]" />
          </div>
          {/* <IconButton
            onPress={() => navigate("/app/settings")}
            icon={""}
          /> */}
          <div className="text-white">Settings</div>
        </div>
      </div>
    </div>
  );
}
