import { createStore, defaults, createHook } from 'react-sweet-state';

import { produce } from 'immer';

import { initialState } from './state';
import { actions } from './actions';

defaults.mutator = (currentState, producer) => produce(currentState, producer);

const Store = createStore({
  initialState,
  actions
});

export const useBusinessStore = createHook(Store);
