import { getSkills } from "@thatsclutch/shared/services/CreatorService";
import React, { useEffect, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import EditSkills from "../../../Header/ProfileImage/EditSkills";

const SKILLTYPE_COLOR = {
  CATEGORY: "bg-[#e5ebff] text-[#2d50e0]",
  PLATFORM: "bg-[#FFF0F7] text-[#FF66B3]",
  TOOL: "bg-[#E5FFEA] text-[#0A8754]",
  extra: "bg-[#E6E6E6] text-black",
};

export default function Skills({ skills, creator, refresh }) {
  const [fullSkills, setFullSkills] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const skillIDs = skills?.map((x) => x.skillID);

  useEffect(() => {
    getAllSkills();
  }, []);

  const getAllSkills = async () => {
    setLoading(true);
    const result = await getSkills();
    setFullSkills(result);
    setLoading(false);
  };

  const refreshCreator = async () => {
	await refresh();
  };

  return (
    <div className="max-w-2xl space-y-2">
      <div className="flex items-center justify-between">
        <div>Skills</div>
        <button
          className="p-2 cursor-pointer"
          onClick={() => setIsOpenEdit(true)}
        >
          <FiEdit3 className="text-slate-400" size={20} />
        </button>
      </div>

      <div className="flex flex-wrap">
        {fullSkills?.length ? (
          fullSkills
            .filter((x) => {
              return skillIDs.includes(x.id);
            })
            .sort((a, b) => {
              if (a.type < b.type) return -1;
            })
            .map((x) => (
              <div
                key={x.id}
                className={`${
                  SKILLTYPE_COLOR[x.type]
                } rounded-full mb-2 mr-4 px-4 whitespace-nowrap first-letter:capitalize py-2 max-w-max`}
              >
                {x.name}
              </div>
            ))
        ) : loading ? (
          <div className="text-sm text-neutral-300">Loading</div>
        ) : (
          <div className="text-sm text-neutral-300">No skills yet</div>
        )}
      </div>
      <EditSkills
        open={isOpenEdit}
        onClose={() => {
          refreshCreator();
          setIsOpenEdit(false);
        }}
        creator={creator}
      />
    </div>
  );
}
