export const actions = {
  setUser: user => ({ setState }) => {
    setState(draft => {
      draft.user = user;
    });
  },
  setUserGroup: type => ({ setState }) => {
    setState(draft => {
      draft.group.type = type.join(',');
      draft.group.isAdmin = type.includes('admins');
      draft.group.isDirector = type.includes('directors');
      draft.group.isVendor = type.includes('vendors');
      draft.group.isStaff = type.includes('staff');
      draft.group.isProvider = type.includes('providers');
    });
  },
  setMenuItems: items => ({ setState }) => {
    setState(draft => {
      draft.menuItems = items;
    });
  },
  setActiveUser: activeUser => ({ setState }) => {
    setState(draft => {
      draft.setActiveUser = activeUser;
    });
  }
};
