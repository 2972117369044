import dayjs from "dayjs";
import React from "react";
import { Highlight } from "react-instantsearch";
import { showToast, useErrorDispatch } from "../../../contexts/ErrorContext";
import { RiLink, RiMailLine } from "react-icons/ri";
import { CopyContext } from "../../Screens/Admin/CreatorList";
import CopyIcon from "../../../assets/vectors/icons/copy";

const STATUS_COLOR = {
  INVITED: "border-blue-400/40 border-2",
  INACTIVE: "border-yellow-400/40 border-2",
  ACTIVE: "border-green-400/40 border-2",
  REJECTED: "border-rose-400/40 border-2",
};

export default function CreatorHit({ hit, onSelected }) {
  const alertDispatch = useErrorDispatch();
  const { copiedEmails, setCopiedEmails } = React.useContext(CopyContext);

  const copyToClipboard = async (text: string) => {
    if (copiedEmails.includes(text)) {
      const nextCopiedEmails = copiedEmails.filter((email) => email !== text);
      await navigator.clipboard.writeText(nextCopiedEmails.join(","));
      setCopiedEmails(nextCopiedEmails);
      return;
    }
    const nextCopiedEmails = [...copiedEmails, text];
    setCopiedEmails(nextCopiedEmails);
    await navigator.clipboard.writeText(nextCopiedEmails.join(","));
    showToast(alertDispatch, nextCopiedEmails.length + " added to clipboard");
  };

  function getPortfolioPath(creator) {
    let url = "http://localhost:3000";
    if (window.location.hostname === "localhost") {
      return url + `/p/${creator?.username ? creator?.username : creator?.id}`;
    }
    switch (process.env.GATSBY_AMP_ENV) {
      case "qa":
        url = "https://qaclient.thatsclutch.com";
        break;
      case "prod":
        url = "https://client.thatsclutch.com";
        break;
      default:
        url = "http://devclient.thatsclutch.com";
    }
    return url + `/p/${creator?.username ? creator?.username : creator?.id}`;
  }

  return (
    <div
      className="w-full h-full space-y-4 hover:cursor-pointer"
      onClick={(e) => onSelected(hit)}
    >
      <div className="flex justify-between">
        <div>
          <div className="flex items-center pb-2 space-x-4">
            {hit.firstName && hit.lastName && (
              <div className="text-lg first:capitalize">
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="firstName"
                  hit={hit}
                />{" "}
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="lastName"
                  hit={hit}
                />
              </div>
            )}
            {hit.pronouns && (
              <div className="text-xs text-slate-500">
                <Highlight
                  classNames={{
                    highlighted: "px-0",
                  }}
                  attribute="pronouns"
                  hit={hit}
                />
              </div>
            )}
          </div>
          <div className="gap-4 lg:flex lg:items-center">
            <div className="text-sm text-slate-500">
              {hit.invitationalEmail ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyToClipboard(hit.invitationalEmail);
                  }}
                  className="flex items-center space-x-2 hover:opacity-30"
                >
                  <RiMailLine />
                  <Highlight
                    classNames={{
                      highlighted: "px-0",
                    }}
                    attribute="invitationalEmail"
                    hit={hit}
                  />
                  {copiedEmails.includes(hit.invitationalEmail) && (
                    <div className="text-rose-300">Unselect</div>
                  )}
                </div>
              ) : (
                "Incomplete"
              )}
            </div>
            <div className="hidden w-1 h-1 rounded-full lg:block bg-slate-400"></div>
            <div className="text-sm text-slate-500">
              <div
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const url = getPortfolioPath(hit);
                  await navigator.clipboard.writeText(url);
                  showToast(alertDispatch, url + " added to clipboard");
                }}
                className="flex items-center space-x-2 hover:opacity-30"
              >
                <RiLink />
                {hit.username && <div>{hit.username}</div>}
              </div>
            </div>
            <div className="hidden w-1 h-1 rounded-full lg:block bg-slate-400"></div>
            <div className="text-sm text-slate-500">
              {hit.dob} {!hit.dob && "Incomplete"}
            </div>
            <div className="hidden w-1 h-1 rounded-full lg:block bg-slate-400"></div>

            <div className="text-sm text-slate-500">
              {hit.locality ? (
                <span>
                  <Highlight
                    classNames={{
                      highlighted: "px-0",
                    }}
                    attribute="locality"
                    hit={hit}
                  />
                  ,{" "}
                  <Highlight
                    classNames={{
                      highlighted: "px-0",
                    }}
                    attribute="state"
                    hit={hit}
                  />
                </span>
              ) : (
                "Incomplete"
              )}
            </div>
          </div>
        </div>
        <div>
          <div
            className={`px-4 py-1 text-base border  rounded-full text-slate-500 ${
              STATUS_COLOR[hit.status]
            }`}
          >
            {hit.status ? hit.status : "Incomplete"}
          </div>
        </div>
      </div>

      <div className="px-3 py-2 overflow-y-auto text-sm whitespace-pre-wrap bg-white border rounded-md">
        {hit.internalNote ? (
          <Highlight
            classNames={{
              highlighted: "px-0",
            }}
            attribute="internalNote"
            hit={hit}
          />
        ) : (
          <div className="text-slate-300">No notes</div>
        )}
      </div>
      <div className="px-3 py-2 overflow-y-auto text-sm whitespace-pre-wrap bg-white border rounded-md">
        {hit.shortBio ? (
          <Highlight
            attribute="shortBio"
            classNames={{
              highlighted: "px-0",
            }}
            hit={hit}
          />
        ) : (
          <div className="text-slate-300">No bio</div>
        )}
      </div>

      <div className="flex justify-between space-x-4">
        <Highlight
          classNames={{
            highlighted: "text-slate-200 px-0",
            nonHighlighted: "text-slate-400",
          }}
          attribute="industries"
          hit={hit}
        />
      </div>

      <div className="flex justify-between">
        {hit.updatedAt && (
          <div className="text-xs text-slate-400">
            Updated on {dayjs(hit.updatedAt).format("MMMM D, YYYY")}
          </div>
        )}
        {hit.createdAt && (
          <div className="text-xs text-slate-400">
            Applied on {dayjs(hit.createdAt).format("MMMM D, YYYY")}
          </div>
        )}
      </div>
    </div>
  );
}
