import React from "react";
import PrimaryButton from "../../../../../Admin/Buttons/PrimaryButton";
import { AmpAPI } from "@thatsclutch/shared";

export default function UpgradeModal({ onClose, client }) {
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState("ACTIVE");

  const onSubmit = async () => {
    try {
      // create user
      setLoading(true);
      const data = await AmpAPI.put("CampusAPI", "/trial/client/" + client.id, {
        body: {
          status: status,
        },
      });
      console.log(data);
      onClose(true);
    } catch (e) {
      console.log("Error:", e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="pt-4 text-2xl font-semibold sm:text-4xl">
        Upgrade trial to basic client
      </div>
      <div className="text-slate-500">
        Add and confirm an{" "}
        <span className="underline">active payment plan</span> before upgrading
        to paid tier.
      </div>

      {/* Select status */}
      <div className="space-y-4">
        <div
          className={`p-4 bg-gray-50 rounded-lg hover:bg-gray-100 ${status ===
            "ACTIVE" && "border border-black"}`}
          onClick={() => setStatus("ACTIVE")}
        >
          ACTIVE (BASIC)
        </div>
        <div
          className={`p-4 bg-gray-50 rounded-lg hover:bg-gray-100 ${status ===
            "ENTERPRISE" && "border border-black"}`}
          onClick={() => setStatus("ENTERPRISE")}
        >
          ENTERPRISE
        </div>
      </div>

      <div className="flex justify-end pt-4 space-x-4">
        <button onClick={() => onClose(false)} className="px-6 py-3 text-black">
          Close
        </button>
        <PrimaryButton
          text={"Upgrade"}
          loading={loading}
          onPress={() => onSubmit()}
        />
      </div>
    </div>
  );
}
