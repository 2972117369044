import React, { useEffect, useState } from "react";
import IntroVideo from "./IntroVideo";
import ProfileImage from "./ProfileImage";
import BasicInfo from "./BasicInfo";
import About from "./About";
import Metrics from "./Metrics";
import Languages from "./Languages";
import PreviousWork from "./PreviousWork";
import ThemeSelect from "./IntroVideo/ThemeSelect";
import Services from "./Services";
import Skills from "./BasicInfo/Skills";
import Links from "./BasicInfo/Links";

const THEME_CLASS = {
  "#F5F2ED": "bg-[#F5F2ED]",
  "#E1EEF5": "bg-[#E1EEF5]",
  "#E6F5E8": "bg-[#E6F5E8]",
  "#F0F2F5": "bg-[#F0F2F5]",
  "#F3EDF7": "bg-[#F3EDF7]",
};

export default function Profile({ creator, loadCreator }) {
  return (
    <div className="h-screen overflow-y-scroll">
      <div className="max-w-5xl p-4 mx-auto space-y-8 md:p-8">
        {/* About */}
        <div className="flex flex-col md:flex-row">
          {/* Image */}
          <ProfileImage creator={creator} refresh={loadCreator} />
          {/* Info */}
          <div className="flex-1 pt-4 space-y-4 md:p-8 md:pr-0">
            <BasicInfo creator={creator} />
            {/* <Languages /> */}
            {/* <Metrics creator={creator} /> */}
            <About creator={creator} refresh={loadCreator} />
            <Skills skills={creator?.skills?.items} creator={creator} refresh={loadCreator}/>
            <Links links={creator?.links} creator={creator} refresh={loadCreator} />
          </div>
        </div>
        {/* Theme Selector */}
        <div
          className={`${
            THEME_CLASS[creator?.themeColor || "#F5F2ED"]
          } p-6 rounded-xl space-y-4`}
        >
          <div className="font-medium">Choose your theme</div>
          <ThemeSelect refresh={loadCreator} />
        </div>
        {/* Intro Vid */}
        <IntroVideo refresh={loadCreator} />
        {/* Past work */}
        <PreviousWork />
        {/* Services */}
        <Services creatorID={creator?.id} />
      </div>
    </div>
  );
}
