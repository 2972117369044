import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import RejectModal from "../RejectModal";
import CancelModal from "../CancelModal";
import { useNavigate } from "@reach/router";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OptionMenu({ collab, onUpdate }) {
  const [showReject, setShowReject] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const navigate = useNavigate();

  const handleSupport = () => {
    // show support modal
    alert(
      "Please contact support@thatsclutch.com with any questions or issues."
    );
  };
  const handleCancel = () => {
    // show cancel modal
    setShowCancel(true);
  };
  const handleReject = () => {
    // show reject modal
    setShowReject(true);
  };

  return (
    <Menu>
      <div className="relative">
        <Menu.Button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 p-2 rounded-full hover:bg-slate-50 text-slate-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
            />
          </svg>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items
            static
            className="absolute right-0 z-10 w-32 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg outline-none min-w-min top-12 max-h-56 sm:text-sm"
          >
            {collab.status === "REQUESTED" && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => handleReject()}
                    className={classNames(
                      active ? "hover:bg-slate-100" : "hover:bg-slate-50",
                      "relative cursor-pointer select-none py-3 px-6  whitespace-nowrap font-light "
                    )}
                  >
                    Reject Collab
                  </div>
                )}
              </Menu.Item>
            )}
            {(collab.status === "ACTIVE" || collab.status === "ACCEPTED") && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => handleCancel()}
                    className={classNames(
                      active ? "hover:bg-slate-100" : "hover:bg-slate-50",
                      "relative cursor-pointer select-none py-3 px-6  whitespace-nowrap font-light "
                    )}
                  >
                    Cancel Collab
                  </div>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => handleSupport()}
                  className={classNames(
                    active ? "hover:bg-slate-100" : "hover:bg-slate-50",
                    "relative cursor-pointer select-none py-3 px-6  whitespace-nowrap font-light "
                  )}
                >
                  Issue with collab
                </div>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </div>
      <RejectModal
        collab={collab}
        open={showReject}
        setOpen={setShowReject}
        onSuccess={() => {
          onUpdate();
          setShowReject(false);
        }}
      />
      <CancelModal
        collab={collab}
        open={showCancel}
        setOpen={setShowCancel}
        onSuccess={() => {
          onUpdate();
          setShowCancel(false);
        }}
      />
    </Menu>
  );
}
