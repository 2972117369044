import React from "react";
import ShortInput from "../../../../Basic/Input/ShortInput";
import PrimaryButton from "../../../../Admin/Buttons/PrimaryButton";
import {
  addClient,
  addToGroup,
  createUser,
} from "@thatsclutch/shared/services/UserManagementService";
import { Dropdown, Select } from "antd";
import DropdownButton from "antd/es/dropdown/dropdown-button";

export default function NewClient({ onClose }) {
  const [brand, setBrand] = React.useState("");
  const [status, setStatus] = React.useState("ACTIVE");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleCreate = async () => {
    let userID = null;
    try {
      setLoading(true);
      if (email && email.length > 3) {
        console.log("Create cognito user");
        userID = await inviteClient();
        if (userID == null) return alert("Failed to create user");
      }
      console.log("Create client profile");
      const userData = {
        email: email.toLowerCase(),
        brand: brand,
        status: status,
        userID: userID || null,
        //   notes: adminNotes,
        //   flagged: client?.flagged,
      };
      await addClient(userData);
      onClose(true);
    } catch (e) {
      console.log("Error:", e?.message);
    } finally {
      setLoading(false);
    }
  };

  const inviteClient = async () => {
    try {
      // create user
      const created = await createUser({
        email: email.toLowerCase(),
        type: "CLIENT",
      });
      if (created?.error) throw { message: "Failed to create user" };

      const userID = created.result.User.Username;
      await addToGroup({ username: userID, groupname: "clients" });

      return userID;
    } catch (e) {
      console.log("Error:", e?.message);
      return null;
    }
  };

  return (
    <div className="space-y-4">
      <div className="pt-4 text-2xl font-semibold sm:text-4xl">New Client</div>
      <ShortInput
        placeholder={"Target..."}
        label={"Brand Name"}
        value={brand}
        onChange={setBrand}
      />
      <div>
        <div className="space-y-2">
          <div className="text-sm text-slate-800">Status</div>
          <Select
            defaultValue="ACTIVE"
            style={{ width: "100%" }}
            onChange={(status) => {
              console.log(status);
              setStatus(status);
            }}
            options={[
              { value: "ACTIVE", label: "Active" },
              { value: "INACTIVE", label: "Inactive" },
              { value: "ENTERPRISE", label: "Enterprise" },
            ]}
          />
        </div>
      </div>
      <ShortInput
        placeholder={"Leave blank to send invite later"}
        label={"POC Email (Optional)"}
        value={email}
        onChange={setEmail}
      />

      <div className="flex justify-end pt-4 space-x-4">
        <button onClick={() => onClose(false)} className="px-6 py-3 text-black">
          Close
        </button>
        <PrimaryButton
          text={"Create"}
          loading={loading}
          onPress={() => handleCreate()}
        />
      </div>
    </div>
  );
}
