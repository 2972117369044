import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import Header from './Header';
import { updateProvider } from '@thatsclutch/shared/services/UserService';
import { updateCreator } from '@thatsclutch/shared/services/CreatorService';

const Name = ({ provider, onNext, onError }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      ...(provider.firstName && { first: provider.firstName }),
      ...(provider.lastName && { last: provider.lastName })
    });
  }, []);

  const onFinish = async values => {
    console.log('input data:', values);
    // validate input

    // update customer w/name
    setLoading(true);
    const providerData = {
      firstName: values.first,
      lastName: values.last
    };
    const updateResults = await updateCreator(provider, providerData);
    window._dcq.push([
      'identify',
      {
        first_name: values.first,
        last_name: values.last
      }
    ]);
    setLoading(true);
    // handle error
    if (updateResults.error) return onError('Issue updating provider');
    onNext();
  };

  return (
    <div className="w-full p-4 sm:w-2/3">
      <Header step={1} label={'What is your name?'} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        requiredMark={false}
      >
        <Form.Item
          label="First Name"
          name="first"
          autoFocus
          rules={[{ required: true, message: 'Please enter your first name' }]}
          style={{ marginBottom: 8 }}
        >
          <Input
            placeholder=""
            autocapitalize="words"
            style={{ textTransform: 'capitalize' }}
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last"
          rules={[{ required: true, message: 'Please enter your last name' }]}
        >
          <Input placeholder="" className="" autocapitalize="words"
            style={{ textTransform: 'capitalize' }} />
        </Form.Item>
        <div className="mt-16">
          <Button
            name="submit"
            shape="round"
            type='primary'
            className='bg-[#30DF87]'
            size="large"
            loading={loading}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Name;
