import React from "react";

const NavStateContext = React.createContext(undefined);
const NavDispatchContext = React.createContext(undefined);

const initialState = {
  tab: "",
  profileID: "",
};

const clearNav = async (dispatch) => {
  dispatch({ type: "clear", payload: null });
};

function userReducer(state, action) {
  switch (action.type) {
    case "clear": {
      return { tab: null, profileID: false };
    }
    case "navigateTo": {
      return {
        tab: action.payload.tab,
        profileID: action.payload.profileID,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NavProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  return (
    <NavStateContext.Provider value={state}>
      <NavDispatchContext.Provider value={dispatch}>
        {children}
      </NavDispatchContext.Provider>
    </NavStateContext.Provider>
  );
}

function useNavState() {
  const context = React.useContext(NavStateContext);
  if (context === undefined) {
    throw new Error("useNavContext must be used within a NavStateProvider");
  }
  return context;
}

function useNavDispatch() {
  const context = React.useContext(NavDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useNavDispatch must be used within a NavDispatchContext"
    );
  }
  return context;
}

export { NavProvider, useNavState, useNavDispatch, clearNav };
