import React from "react";
import Row from "./Row";
import StatusFilter from "./StatusFilter";
import { getCollabs } from "@thatsclutch/shared/services/CollabService";
import { FiLoader, FiRefreshCw } from "react-icons/fi";
import Refresh from "./Refresh";
import dayjs from "dayjs";

export default function Table({ onSelect }) {
  const [collabs, setCollabs] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    loadCollabs();
  }, []);

  const loadCollabs = async (statusFilter = null) => {
    try {
      setLoading(true);
      const data = await getCollabs(statusFilter);
      if (data) {
        setCollabs(
          data.items.sort((a: any, b: any) =>
            dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pt-4 pb-8 space-y-4 overflow-x-auto">
      {/* Filter */}
      <StatusFilter
        onStatusChange={loadCollabs}
        loading={loading}
        count={collabs.length}
      />
      {/* Header */}
      <div className="w-full overflow-x-auto">
        <div className="w-full px-8 pt-4 divide-y 3xl:max-w-4xl min-w-max">
          <div className="grid grid-cols-8 gap-2 p-4 text-sm rounded-sm text-zinc-400">
            <div className="line-clamp-1">Status</div>
            <div className="col-span-2">Name</div>
            <div className="line-clamp-1">Starts</div>
            <div className="line-clamp-1">Type</div>
            <div className="line-clamp-1">Price</div>
            <div className="line-clamp-1">Assets</div>
            <div className="line-clamp-1 min-w-min">Submissions</div>
          </div>

          {/* Rows */}
          {collabs.map((collab) => (
            <div key={collab.id} onClick={() => onSelect(collab.id)}>
              <Row collab={collab} />
            </div>
          ))}

          <div>
            {/* Empty */}
            {!loading && collabs.length === 0 && (
              <div className="flex items-center justify-center p-8 mt-4 rounded-lg bg-zinc-100">
                <div className="text-base text-zinc-400">No collabs found.</div>
              </div>
            )}

            {/* Loading */}
            {loading && collabs.length === 0 && (
              <div className="flex items-center justify-center p-8 mt-4 rounded-lg bg-zinc-100">
                <div className="text-base text-zinc-400">Loading...</div>
              </div>
            )}

            {/* Footer */}
            {!loading && collabs.length > 0 && (
              <div className="flex items-center justify-center p-8">
                <div className="text-sm text-zinc-400">End of results</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
