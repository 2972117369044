import React, { useState } from "react";
import ShortInput from "../../../../../../Basic/Input/ShortInput";
import LongInput from "../../../../../../Basic/Input/LongInput";
import PrimaryButton from "../../../../../../Basic/Buttons/PrimaryButton";

export default function NamePage({ onNext, currName, currDesc }) {
  const [name, setName] = useState(currName);
  const [errName, setErrName] = useState("");
  const [desc, setDesc] = useState(currDesc);

  const onSubmit = () => {
    if (name.trim().length == 0 || name.length > 40)
      return setErrName("Please enter a service name. (max 40)");
    onNext(name, desc);
  };

  return (
    <div className="p-8 space-y-8">
      <div className="space-y-1">
        <div className="text-2xl font-semibold">Adding a service</div>
        <div className="text-sm font-thin text-gray-700">
          Describe the deliverables you will provide
        </div>
      </div>

      <div className="p-4 px-6 space-y-1 text-sm font-thin text-gray-700 rounded-lg bg-yellow-400/10">
        <div className="font-medium text-black">✨ Hint</div>
        <div className="text-xs whitespace-pre-wrap">
          {`Make it easy for brand to quickly get a feel for what you do and how much you charge.`}
        </div>
      </div>

      <ShortInput
        value={name}
        label={"Title"}
        onChange={setName}
        placeholder="Tiktok Video, Unboxing video, etc."
        error={errName}
      />
      <div>
        <LongInput
          value={desc}
          maxLength={250}
          label="Details"
          onChange={setDesc}
          placeholder="2 revisions per video, my videos are usually 1 min long, etc."
        />
      </div>
      <div className="flex justify-end space-x-4">
        <PrimaryButton text={"Next"} onPress={onSubmit} />
      </div>
    </div>
  );
}
