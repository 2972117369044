// component that allows user to select a theme for their intro video
import React from "react";
import {
  getCurrCreator,
  useCreatorDispatch,
  useCreatorState,
} from "../../../../../../contexts/CreatorContext";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
import { FiLoader } from "react-icons/fi";

export default function ThemeSelect({ refresh }) {
  const { creator } = useCreatorState();
  const dispatch = useCreatorDispatch();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  // Select drop down menu
  const handleThemeChange = async (theme) => {
    console.log(theme);
    setLoading(true);
    setSelected(theme);
    const result = await updateCreator(creator, { themeColor: theme });
    // await getCurrCreator(dispatch);
    await refresh();
    setSelected(null);
    setLoading(false);
  };

  const THEME_MAP = {
    "#F5F2ED": "Cream",
    "#E1EEF5": "Sky",
    "#E6F5E8": "Zen",
    "#F0F2F5": "Air",
    "#F3EDF7": "Dream",
  };
  const THEME_CLASS = {
    "#F5F2ED": "bg-[#F5F2ED]",
    "#E1EEF5": "bg-[#E1EEF5]",
    "#E6F5E8": "bg-[#E6F5E8]",
    "#F0F2F5": "bg-[#F0F2F5]",
    "#F3EDF7": "bg-[#F3EDF7]",
  };

  return (
    <button
      onClick={() => setOpen(!open)}
      className={`p-1 outline-none block bg-white transition-all ${
        open ? "rounded-md" : "rounded-[50px]"
      } cursor-pointer appearance-none hover:rounded-md w-full`}
      onBlur={() => {
        setOpen(false);
      }}
      disabled={loading}
    >
      {/* {THEME_MAP[creator.themeColor || "#F5F2ED"]} */}
      {Object.keys(THEME_MAP)
        .filter((v) => (open ? true : v === creator?.themeColor))
        .map((theme) => (
          <div
            className={`flex items-center transition-colors space-x-4 px-6 py-3 rounded-sm ${open &&
              "hover:bg-gray-50"}`}
            onClick={() => (open ? handleThemeChange(theme) : null)}
          >
            <div
              className={`${
                THEME_CLASS[selected ? selected : theme]
              } h-3 w-3 rounded-full text-transparent`}
            ></div>
            <div>{THEME_MAP[selected ? selected : theme]}</div>
            {loading && (
              <div className="items-center inline">
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              </div>
            )}
          </div>
        ))}
      {Object.keys(THEME_MAP).filter((v) =>
        open ? true : v === creator?.themeColor
      ).length === 0 && (
        <div
          className={`flex items-center transition-colors space-x-4 px-6 py-3 rounded-sm ${open &&
            "hover:bg-gray-50"}`}
          onClick={() => (open ? handleThemeChange(theme) : null)}
        >
          <div className="text-slate-500">Select theme color</div>
          {loading && (
            <div className="items-center inline">
              <FiLoader className="w-4 h-4 text-black animate-spin" />
            </div>
          )}
        </div>
      )}
    </button>
  );
}
