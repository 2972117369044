import React, { useEffect, useState } from "react";
const placeholder = require("../../../../../assets/images/placeholder-profile.png");
import { getProfilePicture } from "@thatsclutch/shared/services/CreatorService";
import UploadImage from "../UploadImage";
import {
  useCreatorDispatch,
  getCreatorByID,
} from "../../../../../contexts/CreatorContext";
import { FiLoader } from "react-icons/fi";

const Tag = ({ name }) => (
  <div className="bg-[#F5F2ED] max-w-fit px-4 py-1 rounded-[4px] capitalize">
    {name}
  </div>
);

function ProfileImage({ creator, refresh }) {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const dispatch = useCreatorDispatch();

  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = async () => {
    setLoading(true);
    const result = await getProfilePicture(creator);
    result && setImage(result);
    setLoading(false);
  };

  const refreshCreator = async () => {
    setOpenUpload(false);
    await refresh();
    await loadImage();
  };

  return (
    <div className="relative h-[50vh] rounded-lg group hover:bg-black">
      {/* {creator.status == "ACTIVE" && (
        <div className="py-1 w-11/12 bg-[#2BEB61] text-center font-medium z-10 absolute top-8 rounded-lg left-1/2 transform -translate-x-1/2">
          Available for work
        </div>
      )} */}
      <div className="absolute hidden text-white transform -translate-x-1/2 left-1/2 top-1/2 group-hover:block">
        Change photo
      </div>
      <div
        className="h-full max-w-md cursor-pointer bg-blend-overlay rounded-xl text-slate-50 hover:opacity-50 "
        onClick={() => {
          setOpenUpload(true);
        }}
      >
        {loading ? (
            <FiLoader className="w-4 h-4 text-black animate-spin" />
        ) : image ? (
          <img
            className={`rounded-lg bg-slate-100 object-cover h-full w-full max-w-md`}
            src={image}
            alt="profile photo"
          />
        ) : (
          <img
            className={`rounded-lg bg-slate-100 object-cover h-full w-full max-w-md`}
            src={placeholder}
            alt="profile photo"
          />
        )}
      </div>
      {/* <div>skills</div> */}
      <UploadImage
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        onSubmit={refreshCreator}
        creator={creator}
      />

      {/* get skills! */}
      <div className="relative z-10 flex flex-wrap gap-2 pl-4 bottom-6 md:bottom-10">
        {creator.skills.length &&
          creator.skills.items
            .filter(
              (x) =>
                x.type == "CATEGORY" &&
                creator.skills?.items.map((s) => s.skillID).includes(x.id)
            )
            .sort((a, b) => {
              if (a.type < b.type) return -1;
            })
            .map((x) => <Tag name={x.name} />)}
      </div>
    </div>
  );
}

export default ProfileImage;
