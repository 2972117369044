import React from "react";
import dayjs from "dayjs";

export default function Row({ collab }) {
  const SubStatusColor = {
    COMPLETED: "text-primary bg-primary-light",
    IN_REVIEW: "text-blue-400 bg-blue-50",
    REVISION: "text-rose-400 bg-rose-50",
	DRAFT: 'text-gray-400 bg-gray-50'
  };

  const SubPill = ({ status }) => (
    <div className={`${SubStatusColor[status]} text-xs  w-8 text-center py-1 rounded-full`}>
      {collab?.submissions?.items &&
        collab.submissions.items.filter((v) => v.status == status).length}
    </div>
  );
  return (
    <div className="grid items-center grid-cols-8 gap-2 p-4 transition-colors cursor-pointer hover:bg-zinc-100">
      <div className="line-clamp-1">
        <div className="lowercase first-letter:capitalize text-zinc-500">{collab.status}</div>
      </div>
      <div className="col-span-2 py-1 space-y-1">
        <div>{collab.name}</div>
        <div className="text-xs capitalize text-zinc-500">
          {collab.client?.brand} {"><"}{" "}
          {collab.creator.firstName + " " + collab.creator.lastName}{" "}
        </div>
      </div>
      <div className="line-clamp-1">
        {collab.startDate
          ? dayjs(collab.startDate).format("MMM DD, YYYY")
          : "-"}
      </div>
      <div className="">{collab.isOngoing ? "Ongoing" : "One-Time"}</div>
      <div className="line-clamp-1">${collab.price / 100}</div>
      <div className="line-clamp-1">{collab.deliverableCount}</div>
      <div className="flex space-x-2 min-w-min">
        <SubPill status="COMPLETED" />
        <SubPill status="IN_REVIEW" />
        <SubPill status="REVISION" />
      </div>
    </div>
  );
}
