import { getSkills } from "@thatsclutch/shared/services/CreatorService";
import React, { useEffect, useState } from "react";
import { FiEdit3, FiInstagram, FiLink } from "react-icons/fi";
import EditSkills from "../../../Header/ProfileImage/EditSkills";
import {
  getCurrCreator,
  useCreatorDispatch,
} from "../../../../../../contexts/CreatorContext";
import LinkInput from "../../../../../Basic/Input/LinkInput";
import EditProfile from "../../../Header/ProfileImage/EditProfile";
import { BsTiktok } from "react-icons/bs";

const SKILLTYPE_COLOR = {
  CATEGORY: "bg-[#e5ebff] text-[#2d50e0]",
  PLATFORM: "bg-[#FFF0F7] text-[#FF66B3]",
  TOOL: "bg-[#E5FFEA] text-[#0A8754]",
  extra: "bg-[#E6E6E6] text-black",
};

export default function Links({ links, creator, refresh }) {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const linkIcon = (link) => {
    if (link && link.includes("instagram"))
      return <FiInstagram className="w-5 h-5 text-[#E4FDE8]" />;
    if (link && link.includes("tiktok"))
      return <BsTiktok className="w-4 h-4 text-[#E4FDE8]" />;
    else return <FiLink className="w-5 h-5 text-[#E4FDE8]" />;
  };

  return (
    <div className="max-w-2xl space-y-2">
      <div className="flex items-center justify-between">
        <div>Links</div>
        <button className="p-2 cursor-pointer" onClick={() => setIsEdit(true)}>
          <FiEdit3 className="text-slate-400" size={20} />
        </button>
      </div>

      <div className="flex space-x-4">
        {links?.length ? (
          links
            .filter((l) => l)
            .map((link, index) => (
              <a
                target="_blank"
                key={index}
                href={link?.includes("http") ? link : "https://" + link}
                className="relative group"
                //   className="flex items-center px-4 py-2 space-x-2 text-white bg-black rounded-full group whitespace-nowrap max-w-max"
              >
                <div className="h-10 w-10   bg-[#0D1C0D] rounded-full grid place-items-center text-white">
                  {linkIcon(link)}
                </div>
                <div
                  className={`absolute group-hover:opacity-100 group-hover:-translate-y-2 -top-full px-3 py-2 overflow-hidden left-1/2  text-xs text-black opacity-0 transition-all translate-y-0w-auto max-w-xs h-auto whitespace-nowrap -translate-x-1/2  rounded-lg  bg-slate-100 pointer-events-none`}
                >
                  {link}
                </div>
                {/* <div className="group-hover:underline">{link}</div> */}
              </a>
            ))
        ) : loading ? (
          <div className="text-sm text-neutral-300">Loading</div>
        ) : (
          <div className="text-sm text-neutral-300">No links yet</div>
        )}
      </div>
      <EditProfile
        open={isEdit}
        onClose={async () => {
          await refresh();
          setIsEdit(false);
        }}
        creator={creator}
      />
    </div>
  );
}
