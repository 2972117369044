import React from "react";
import { FiLoader } from "react-icons/fi";
export default function SecondaryButton({
  text,
  onPress,
  danger = false,
  loading = false,
  ...props
}) {
  return (
    <div className="relative">
      <div
        onClick={!loading ? onPress : undefined}
        className={`${
          danger ? "border-red-500 text-red-500" : "border-slate-200"
        } rounded-full px-6 py-3 bg-white  border active:border-black font-light text-black cursor-pointer text-center`}
        {...props}
      >
        {loading && (
          <div className="absolute flex items-center h-6 right-4">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
          </div>
        )}
        <div
          className={`transition-all text-center ${loading &&
            "-translate-x-4 text-gray-400"} ${
            danger ? "border-red-500 text-red-500" : "border-black"
          }`}
        >
          {text}
        </div>
      </div>
    </div>
  );
}
