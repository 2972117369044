import React from "react";

export default function LongInput({
  label,
  placeholder,
  value,
  maxLength = 2000,
  onChange,
  required = false,
}) {
  return (
    <div className="space-y-2">
      <label className="text-sm text-slate-800">
        {label}
        {required && " *"}
      </label>
      <div className="border rounded-lg border-slate-200 focus-within:border-emerald-300">
        <textarea
          className="bg-transparent leading-normal w-full px-4 py-4 outline-none flex-1 md:min-w-[220px]"
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          rows={5}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      <div className="text-xs font-thin text-right text-gray-600">{`${value.length} / ${maxLength}`}</div>
    </div>
  );
}
