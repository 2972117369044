import React, { useEffect } from "react";
const AdminStateContext = React.createContext();
const AdminDispatchContext = React.createContext();

const initialState = {
  loading: true,
  universities: [],
  currUniversity: null,
  searchTerm: null,
  currSkill: null,
  currLocation: null,
  creatorStatus: "ACTIVE",
};

async function setUniversity(dispatch, university) {
  try {
    localStorage.setItem("universityID", university?.id);
    dispatch({ type: "setUniversity", payload: university });
  } catch (error) {
    console.log(error);
  }
}

function userReducer(state, action) {
  switch (action.type) {
    case "setUniversities": {
      console.log("writing:", action.payload);
      return {
        ...state,
        currUniversity: action.payload.updatedUniversity,
        universities: action.payload.universityList,
        loading: false,
      };
    }
    case "setUniversity": {
      return {
        ...state,
        currUniversity: action.payload,
      };
    }
    case "setSearch": {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    case "setCurrSkill": {
      return {
        ...state,
        currSkill: action.payload,
      };
    }
    case "setCurrLocation": {
      return {
        ...state,
        currLocation: action.payload,
      };
    }
    case "setCreatorStatus": {
      return {
        ...state,
        creatorStatus: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AdminStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);

  return (
    <AdminStateContext.Provider value={state}>
      <AdminDispatchContext.Provider value={dispatch}>
        {children}
      </AdminDispatchContext.Provider>
    </AdminStateContext.Provider>
  );
}

function useAdminState() {
  const context = React.useContext(AdminStateContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationState must be used within a AdminStateProvider"
    );
  }
  return context;
}

function useAdminDispatch() {
  const context = React.useContext(AdminDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationDispatch must be used within a AdminStateProvider"
    );
  }
  return context;
}

export { AdminStateProvider, useAdminState, useAdminDispatch, setUniversity };
