import {
  createMatch,
  updateMatch,
  getMatchesByClientID,
  getMatchesByBriefID,
} from "@thatsclutch/shared/services/MatchingService";
import React, { useEffect, useState } from "react";
import LongInput from "../../../../Basic/Input/LongInput";
import ShortInput from "../../../../Basic/Input/ShortInput";
import BasicModal from "../../../../Dialogs/BasicModal";

function AddMatchModal({
  open,
  onConfirm,
  brief,
  currMatch = null,
  onClose,
  creator,
}) {
  const [page, setPage] = useState("confirm");
  const [assetPrice, setAssetPrice] = useState("");
  const [assetAmount, setAssetAmount] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  useEffect(() => {
    updateData();
  }, [open]);

  const findDuplicateMatch = async () => {
    const matches = await getMatchesByBriefID(brief?.id);
    let creatorIds = matches?.map((item) => item?.creatorID);
    if (creatorIds?.includes(creator.id)) setIsDuplicate(true);
  };

  const updateData = () => {
    if (currMatch) {
      setPage("confirm");
      console.log(currMatch);
      setAssetPrice(currMatch.price + "");
      setAssetAmount(currMatch.deliverableAmount + "");
      setNote(currMatch.adminNote);
    } else {
      findDuplicateMatch();
      setPage("confirm");
      const perAssetPrice = brief.weeklyBudget / (brief.deliverableAmount || 1);
      setAssetPrice(perAssetPrice + "");
      setAssetAmount((brief?.deliverableAmount || "") + "");
      setNote("");
    }
    setLoading(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (currMatch) {
        const result = await updateMatch(currMatch, {
          status: "DRAFT",
          price: parseInt(assetPrice.trim()),
          deliverableAmount: parseInt(assetAmount.trim()),
          adminNote: note.trim(),
        });
      } else {
        const result = await createMatch({
          creatorID: creator.id,
          clientID: brief.clientID,
          briefID: brief.id,
          status: "DRAFT",
          price: parseInt(assetPrice.trim()), // TODO: make this in hundreds
          deliverableAmount: parseInt(assetAmount.trim()),
          adminNote: note.trim(),
        });
      }

      setPage("success");
      onConfirm();
    } catch (e) {
      console.log(e);
      setPage("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicModal isVisible={open} onCancel={onClose}>
      <div className="px-6 py-6">
        {page == "confirm" ? (
          <div className="space-y-6">
            {isDuplicate && (
              <div className="text-red-600">
                This is a duplicate recommendation that already exists for this
                client.
              </div>
            )}
            <div className="flex flex-col justify-center space-y-2">
              <div className="text-2xl font-semibold capitalize">{`${
                currMatch ? "Edit" : "Add "
              } ${creator.firstName}'s profile`}</div>
              <div className="pb-2 text-base text-gray-500">
                Update the details of the recommendation or use the default from
                the brief
              </div>
            </div>

            <div className="flex gap-4 pb-2">
              <div className="flex-1 space-y-2">
                <div className="text-gray-500">Creator notes</div>
                <div className="whitespace-pre-wrap bg-gray-50 p-4 py-2 rounded-sm  max-h-[100px] overflow-y-scroll h-full">
                  {creator.internalNote || "No notes"}
                </div>
              </div>
              <div className="flex-1 space-y-2">
                <div className="text-gray-500">Client notes</div>
                <div className="whitespace-pre-wrap bg-gray-50 p-4 py-2 rounded-sm max-h-[100px] overflow-y-scroll h-full">
                  {brief?.client?.notes || "No notes"}
                </div>
              </div>
            </div>
            <div className="pb-4">
              <div className="space-y-2">
                <div className="text-gray-500">Project notes</div>
                <div className="whitespace-pre-wrap bg-gray-50 p-4 py-2 rounded-sm  max-h-[100px] overflow-y-scroll h-full">
                  {brief.requirements || "No notes"}
                </div>
              </div>
              
            </div>

            <div className="flex space-x-4">
              <ShortInput
                label={"Price per asset"}
                value={assetPrice}
                onChange={setAssetPrice}
                placeholder="100"
                prefix={"$"}
              />
              <ShortInput
                label={"# of assets (monthly)"}
                value={assetAmount}
                onChange={setAssetAmount}
                placeholder="5"
              />
            </div>

            <div className="">
              <LongInput
                label={"Note for the client"}
                value={note}
                onChange={setNote}
                placeholder="Include goal, details, and any other relevant information the creator should know"
              />
            </div>

            <div className="flex items-center justify-between mt-6">
              <button onClick={onClose} className="text-gray-400 underline">
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                disabled={loading}
                className="bg-[#30DF87] text-black px-6 py-4  rounded-full"
              >
                {loading
                  ? "Updating..."
                  : currMatch
                  ? "Update Recommendation"
                  : "Send Recommendation"}
              </button>
            </div>
          </div>
        ) : page == "success" ? (
          <div onMouseDown={onClose}>
            <div>
              <div className="flex flex-col justify-center text-[24px] font-semibold">
                Added!
              </div>
              <div>Recommendation is now available for client.</div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={onClose}
                className="px-6 py-3 mt-6 text-white bg-black rounded-full"
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            <div className="text-[#EC2E2E] font-semibold text-xl ">
              Oh no, something went wrong!
            </div>
            <button
              onClick={onClose}
              className="px-6 py-3 text-white bg-black rounded-full"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </BasicModal>
  );
}

export default AddMatchModal;
