import React from "react";

export default function Hero() {
  return (
	<div className="p-6 overflow-hidden bg-white shadow-2xl sm:space-x-4 sm:flex md:p-6 shadow-slate-200/50 rounded-2xl">
      <div className="space-y-2">
        <div className="text-lg font-semibold">
          🎉 We will notify you of any opportunities  
        </div>
        <div className="pb-4 text-sm text-gray-600 whitespace-pre-line ">
          Check back soon to accept new collabs. In the meantime, we have some
          resources to help you get started. If you have any questions, reach
          out to our team{" "}
          <span>
            <a
              className="underline decoration-indigo-500 decoration-2 underline-offset-1"
              href="mailto:support@thatsclutch.com"
              target="_blank"
            >
              here.
            </a>
          </span>
          <br />
          <br /> Keep your profile active and up to date if you are open to new opportunities!
        </div>
        <div className="flex justify-end space-x-4 sm:justify-start">
          {/* <a
            href="https://clutch.discord.com"
            target={"_blank"}
            className="px-6 py-3 text-sm text-black transition-colors bg-purple-300 rounded-full cursor-pointer hover:bg-purple-600 hover:text-white"
          >
            Join our Discord
          </a> */}
          <a
            href="https://thatsclutch.com/our-blog"
            target={"_blank"}
            className="px-6 py-3 text-sm text-black transition-colors bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 "
          >
            Checkout our blog
          </a>
        </div>
      </div>
      {/* <img src={HeroImage} className="self-end hidden h-48 md:block" /> */}
      <div className="relative items-center justify-center hidden md:w-80">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute object-cover w-full h-full"
        >
          <path
            fill="#64D698"
            d="M41.8,-10.5C50.1,12.1,50.1,40.5,36.1,51.1C22,61.6,-6.1,54.3,-22.5,40.2C-39,26.1,-43.8,5.2,-38.1,-13.8C-32.4,-32.7,-16.2,-49.7,0.3,-49.8C16.7,-49.9,33.4,-33.1,41.8,-10.5Z"
            transform="translate(100 100)"
          />
        </svg>
        <div className="z-0 text-4xl font-bold text-white">v2</div>
      </div>
    </div>
  );
}
