import React from "react";
import "antd/dist/reset.css";
import { Router } from "@reach/router";
import {
  SignIn,
  SetPassword,
  StripeFlowComplete,
  Terms,
  Policy,
  Settings,
  ForgotPassword,
  ResetPassword,
} from "../../components/Screens";
import PrivateAdminRoute from "../../components/Routes/PrivateAdminRoute";
import PrivateRoute from "../../components/Routes/PrivateRoute";
import PublicRoute from "../../components/Routes/PublicRoute";
import { ToastProvider } from "react-toast-notifications";
import { configureAmplify } from "@thatsclutch/shared";
import Onboard from "../../components/Screens/Auth/Onboard";
import Admin from "../../components/Screens/Admin";
import Dashboard from "../../components/Screens/Dashboard";
import { ConfigProvider } from "antd";
const NotFound = () => (
  <div className="p-4">Sorry, nothing here. Check the URL.</div>
);

const App = () => {
  configureAmplify(process.env.GATSBY_AMP_ENV);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00b96b",
        },
      }}
    >
      <ToastProvider placement="bottom-left" autoDismiss>
        <Router>
          {/* AUTH */}
          <PublicRoute path="/app/signin/" component={SignIn} />
          <PublicRoute
            path="/app/signin/:username/:password"
            component={SignIn}
          />
          <PublicRoute path="/app/signin/:username/" component={SignIn} />
          <PublicRoute path="/app/forgotpassword/" component={ForgotPassword} />

          {/* ADMIN */}
          <PrivateAdminRoute path="/app/admin" component={Admin} />
          <PrivateAdminRoute
            path="/app/dashboard/:creatorID"
            component={Dashboard}
          />

          {/* CREATOR */}
          <PublicRoute path="/app/set-password" component={SetPassword} />
          <PrivateRoute path="/app/dashboard" component={Dashboard} />
          <PrivateRoute path="/app/settings" component={Settings} />
          <PrivateRoute path="/app/signup" component={Onboard} />
          <PrivateRoute
            path="/app/stripe-complete"
            component={StripeFlowComplete}
          />

          {/* OTHER */}
          <PublicRoute path="/app/termsandconditions" component={Terms} />
          <PublicRoute path="/app/policy" component={Policy} />

          {/* DEPRECATED */}
          <PublicRoute path="/app/resetpassword/" component={ResetPassword} />

          {/* 404 */}
          <NotFound default />
        </Router>
      </ToastProvider>
    </ConfigProvider>
  );
};

export default App;
