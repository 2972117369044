import React, { useState } from "react";
import { MenuOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Navigation from "../Navigation";
import ProfileImage from "./ProfileImage";
import { useCreatorState } from "../../../../contexts/CreatorContext";

export default function Header({ navSelect }) {
  const [showProfile, setShowProfile] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const { creator } = useCreatorState();

  return (
    <div className="flex items-center justify-between w-full px-4 py-6 mx-auto bg-white md:px-8">
      {/* Nav */}
      <div
        onClick={() => {
          setShowNav(true);
          setShowProfile(false);
        }}
        className="flex items-center justify-center w-10 h-10 transition-colors rounded-full md:hidden hover:bg-slate-100"
      >
        <MenuOutlined />
      </div>
      {showNav && (
        <div className="absolute top-0 left-0 z-50 w-screen h-screen md:hidden">
          <Navigation
            onClose={() => setShowNav(false)}
            navSelect={(nav) => {
              navSelect(nav);
              setShowNav(false);
            }}
          />
        </div>
      )}
      {/* Desktop */}
      <div className="flex items-center justify-between w-auto space-x-4 md:w-full">
        <div className="hidden text-2xl font-medium md:block">
          Welcome back,{" "}
          {creator?.firstName ? (
            <span className="capitalize">{creator?.firstName}!</span>
          ) : (
            <span className="rounded-md text-slate-200 bg-slate-200 animate-pulse">
              loading...
            </span>
          )}
        </div>
        <div className="">
          {/* Profile menu */}
          <ProfileImage creator={creator} />
        </div>
      </div>
    </div>
  );
}
