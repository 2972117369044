import React, { useState } from "react";
import { navigate, useLocation } from "@reach/router";
import { parse } from "query-string";
import { Auth } from "aws-amplify";
import { Button, Spin } from "antd";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { AppUser } from "../../Auth";
import { AmpAPI } from "@thatsclutch/shared/services/AmplifyService";
import { useUniversityStore } from "../../../stores/university";
import { SplitLayout } from "../../Layout";
import { LoginBack } from "../../Routes/PublicRoute";
import {
  getCurrentCreator,
  updateCreator,
} from "@thatsclutch/shared/services/CreatorService";

const StripeFlowComplete = (props) => {
  const [loading, setLoading] = useState(true);
  const [universityState] = useUniversityStore();
  const location = useLocation();
  const { code } = parse(location.search);
  const { setUser } = AppUser;

  React.useEffect(() => {
    if (!code) {
      alert("Error occured, stripe set up incomplete. Please try again.");
      return;
    }
    AmpAPI.post("CampusAPI", "/payments/connected-account", {
      body: { authorizationCode: code },
    })
      .then(async (data) => {
        console.log(data);
        const creator = await getCurrentCreator();
        const creatorRes = await updateCreator(creator, {
          status: "INACTIVE",
          stripeConnectedID: data.stripe_user_id,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const completeStripe = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userGroups =
      user.signInUserSession.accessToken.payload["cognito:groups"];

    if (userGroups.includes("creators")) {
      // handle any owner actions after stripe set up
      // navigate to create venue flow
      navigate("/app/dashboard");
    } else {
      console.log(
        "User does not have permission to set up Stripe, navigating to safety."
      );
      navigate("/");
      return;
    }
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 42, color: "#64D698" }} spin />
  );

  return (
    <SplitLayout rightComponent={<LoginBack />} showLogout showHelp>
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex flex-col mx-auto space-y-6">
          {loading ? (
            <div className="flex flex-col items-center h-full">
              <div>
                <Spin size="large" indicator={antIcon} />
              </div>
              <div className="py-4 my-4 text-xl text-gray-600 mb-14">
                Please wait, finishing up...
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center h-full">
              <div>
                <CheckCircleOutlined
                  style={{ fontSize: 64, color: "#64D698" }}
                />
              </div>
              <div className="py-4 my-4 text-xl text-gray-600 mb-14">
                Payment set up completed!
              </div>
              <Button
                style={{ background: '#000000', border: 'none' }}
                size="large"
                className="w-64 text-white bg-black"
                loading={loading}
                htmlType="submit"
                onClick={() => completeStripe()}
              >
                View Creator Studio
              </Button>
            </div>
          )}
        </div>
      </div>
    </SplitLayout>
  );
};

export default StripeFlowComplete;
