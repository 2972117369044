import React from "react";
import { DatePicker, DatePickerProps, Input, Select } from "antd";
import PrimaryButton from "../../../../../../Admin/Buttons/PrimaryButton";
import dayjs from "dayjs";
import {
  createPlan,
  updatePlan,
} from "@thatsclutch/shared/services/PlanService";

export default function EditPlan({ onClose, plan }: { onClose: any; plan }) {
  const [status, setStatus] = React.useState(plan.status);
  const [link, setLink] = React.useState(plan.link);
  const [startDate, setStartDate] = React.useState(dayjs(plan.startDate));
  const [price, setPrice] = React.useState(plan.price / 100 + "");
  const [contractLength, setContractLength] = React.useState(
    plan.contractLength
  );
  const [paymentType, setPaymentType] = React.useState(plan.paymentType);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      //validate data inputs used in data
      if (!link || !status || !startDate || !contractLength || !paymentType || !price) {
        return alert("Missing values.");
      }
      const data = {
        status: status,
        link: link.trim(),
        price: parseInt(price) * 100 || 0,
        planType: "ENTERPRISE",
        paymentType: paymentType,
        contractLength: contractLength,
        startDate: startDate.format("YYYY-MM-DD"),
      };
      const result = await updatePlan(
        { id: plan?.id, _version: plan?._version },
        data
      );
      onClose(true);
    } catch (e) {
      console.log("Error:", e?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelect: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(date || dayjs());
    console.log(date, dateString);
  };

  return (
    <div className="space-y-4">
      <div className="pt-4 text-2xl font-semibold sm:text-4xl">Edit Plan</div>
      <div className="flex space-x-8">
        <div className="space-y-2 ">
          <div className="text-sm text-slate-800">Price</div>
          <Input
            placeholder={"ex: 400"}
			prefix="$"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="flex-1 space-y-2">
          <div className="text-sm text-slate-800">Link</div>
          <Input
            placeholder={"https://www.stripe.com/checkout"}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
      </div>
      <div className="flex space-x-8">
        <div className="flex-1 space-y-2">
          <div className="text-sm text-slate-800">Status</div>
          <Select
            defaultValue="READY"
            style={{ width: "100%" }}
            value={status}
            onChange={(value) => {
              console.log(value);
              setStatus(value);
            }}
            options={[
              { value: "READY", label: "Ready" },
              { value: "PROCESSING", label: "Processing" },
              { value: "ACTIVE", label: "Active" },
              { value: "HOLD", label: "Hold" },
              { value: "TRIAL", label: "Trial" },
            ]}
          />
        </div>
        <div className="flex-1 space-y-2">
          <div className="text-sm text-slate-800">Payment Type</div>
          <Select
            defaultValue="INVOICE"
            style={{ width: "100%" }}
            value={paymentType}
            onChange={(value) => {
              console.log(value);
              setPaymentType(value);
            }}
            options={[
              { value: "INVOICE", label: "Invoice" },
              { value: "SUBSCRIPTION", label: "Subscrition" },
              { value: "CREDIT_CARD", label: "Credit Card" },
            ]}
          />
        </div>
      </div>
      <div className="flex space-x-8">
        <div className="flex-1 space-y-2">
          <div className="text-sm text-slate-800">Start date</div>
          <DatePicker
            onChange={handleDateSelect}
            value={startDate}
            // size="large"
            format={"MMM DD, YYYY"}
            style={{ width: "100%" }}
          />
        </div>
        <div className="flex-1 space-y-2">
          <div className="text-sm text-slate-800">Contract Length</div>
          <Select
            defaultValue={1}
            style={{ width: "100%" }}
            value={contractLength}
            onChange={(value) => {
              console.log(value);
              setContractLength(value);
            }}
            options={[
              { value: 1, label: "1 month" },
              { value: 2, label: "2 months" },
              { value: 3, label: "3 months" },
              { value: 6, label: "6 months" },
              { value: 12, label: "12 months" },
              { value: 24, label: "24 months" },
            ]}
          />
        </div>
      </div>
      <div className="flex justify-end pt-4 space-x-4">
        <button onClick={() => onClose(false)} className="px-6 py-3 text-black">
          Cancel
        </button>
        <PrimaryButton
          text={"Update Plan"}
          loading={loading}
          onPress={() => handleSubmit()}
        />
      </div>
    </div>
  );
}
