// dangerouslysethtml
// gatsby docs -- run/embed html

import React, { useState, useEffect } from "react";
import {
  deletePreviousWork,
  getPreviousWorks,
} from "@thatsclutch/shared/services/CreatorService";
import { useCreatorState } from "../../../../../contexts/CreatorContext";
import AddWorkModal from "./AddWorkModal";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import dayjs from "dayjs";

function PreviousWork({}) {
  const { creator } = useCreatorState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [works, setWorks] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getWorks();
  }, []);

  const getWorks = async () => {
    try {
      setLoading(true);
      const res = await getPreviousWorks(creator?.id);
	  console.log(res)
      const sortedRes = res?.sort((a, b) =>
        dayjs(a?.createdAt).isBefore(dayjs(b?.createdAt)) ? 1 : -1
      );
      setWorks(sortedRes || []);
      setPage(0);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRemove = async (work) => {
    const res = await deletePreviousWork(work);
    setPage(0);
    await getWorks();
  };

  const handlePrevious = () => {
    if (page) setPage(page - 1);
  };

  const handleNext = () => {
    if (page < works.length - 1) setPage(page + 1);
  };

  const THEME_MAP = {
    "#F5F2ED": "bg-white md:bg-[#F5F2ED]",
    "#E1EEF5": "bg-white md:bg-[#E1EEF5]",
    "#E6F5E8": "bg-white md:bg-[#E6F5E8]",
    "#F0F2F5": "bg-white md:bg-[#F0F2F5]",
    "#F3EDF7": "bg-white md:bg-[#F3EDF7]",
  };

  return (
    <div>
      <div className="h-1/2">
        <div className="flex items-center justify-between pb-8">
          <div className="text-3xl font-medium md:text-4xl">
            My Previous Work
          </div>
          <div
            className="px-8 py-3 text-sm font-thin text-white bg-black border-2 border-black rounded-full cursor-pointer hover:bg-transparent hover:text-black"
            onClick={() => setOpen(true)}
          >
            Add
          </div>
        </div>

        {works.length ? (
          <div
            className={`flex md:px-6 justify-center md:justify-between h-full py-8 md:space-x-6 items-center overflow-x-scroll ${
              THEME_MAP[creator?.themeColor || "#F5F2ED"]
            } rounded-lg`}
          >
            <button
              onClick={handlePrevious}
              className={`hidden md:block p-4 rounded-full cursor-pointer aspect-square bg-white hover:opacity-50 ${page ==
                0 && "opacity-50 cursor-default [&>*]:text-black/20 "}`}
            >
              <FiChevronLeft className="w-12 h-12 -translate-x-0.5" />
            </button>
            <div>
              <iframe
                src={works[page].url}
                allowFullScreen
                className="h-[755px] w-max"
                allow="encrypted-media;"
                style={{ maxWidth: "605px", minWidth: "255px" }}
              ></iframe>
              <div className="flex items-center justify-center pb-4 space-x-4 md:pt-4">
                <button
                  onClick={handlePrevious}
                  className={`md:hidden p-4 rounded-full cursor-pointer aspect-square bg-white hover:opacity-50 ${page ==
                    0 && "opacity-50 cursor-default [&>*]:text-black/20 "}`}
                >
                  <FiChevronLeft className="w-6 h-6 -translate-x-0.5" />
                </button>
                <div className="text-center">
                  {page + 1 + "/" + works.length}
                </div>
                <button
                  onClick={handleNext}
                  className={`md:hidden p-4 rounded-full cursor-pointer aspect-square bg-white hover:opacity-50  ${page ==
                    works.length - 1 &&
                    "opacity-50 cursor-default [&>*]:text-black/20"}`}
                >
                  <FiChevronRight className="w-6 h-6 translate-x-0.5" />
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => handleRemove(works[page])}
                  className="px-4 py-2 text-center border-2 rounded-full text-rose-500 hover:text-white hover:bg-rose-500 border-rose-500"
                >
                  remove
                </button>
              </div>
            </div>
            <button
              onClick={handleNext}
              className={`hidden md:block p-4 rounded-full cursor-pointer aspect-square bg-white hover:opacity-50  ${page ==
                works.length - 1 &&
                "opacity-50 cursor-default [&>*]:text-black/20"}`}
            >
              <FiChevronRight className="w-12 h-12 translate-x-0.5" />
            </button>
          </div>
        ) : (
          <div
            className={`flex px-6 justify-between h-full py-8 space-x-6 items-center overflow-x-scroll bg-[#f3f3f3f3] rounded-lg`}
          >
            <div className="flex flex-col justify-center flex-1 w-full p-8 space-y-4 rounded-lg bg-gray-50 md:bg-white">
              <div className="w-2/3 text-xl font-medium ">
                ✨ Highlight your best work from Tiktok
              </div>
              <div className="w-2/3 text-sm font-thin ">
                We recommend having 3 to 5 tiktok videos to showcase your style and personality.
              </div>
            </div>
          </div>
        )}
      </div>

      <AddWorkModal
        creator={creator}
        show={open}
        onClose={() => {
          getWorks();
          setOpen(false);
        }}
      />
    </div>
  );
}

export default PreviousWork;
