import React, { useEffect, useState } from "react";
import {
  getCreatorApplicant,
  updateCreatorApplicant,
} from "@thatsclutch/shared/services/ApplicationService";
import { FiLoader, FiPower, FiThumbsUp, FiType, FiVideo } from "react-icons/fi";
import dayjs from "dayjs";
import { message } from "antd";
import SideModal from "../../../../../Dialogs/SideModal/index";
import EditCreatorApplicantForm from "../../../../../Forms/EditCreatorApplicantForm";
import {
  addCreator,
  addToGroup,
  createUser,
} from "@thatsclutch/shared/services/UserManagementService";
import ConfirmationModal from "../../../../../Dialogs/ConfirmationModal";
import Autosave from "../../../../../Forms/Autosave";
import PhoneIcon from "../../../../../../assets/vectors/icons/phone.js";
import CopyIcon from "../../../../../../assets/vectors/icons/copy";
import EmailIcon from "../../../../../../assets/vectors/icons/email";
import LocationIcon from "../../../../../../assets/vectors/icons/location";
import BirthdateIcon from "../../../../../../assets/vectors/icons/birthdate";
import MenuIcon from "../../../../../../assets/vectors/icons/menu";
import { FiStar } from "react-icons/fi";
import {
  useErrorDispatch,
  showError,
  showToast,
} from "../../../../../../contexts/ErrorContext";
import AddFirstDemo from "../AddFirstDemo";
import BasicModal from "../../../../../Dialogs/BasicModal";
import { Index, useInstantSearch } from "react-instantsearch";
import { useNavDispatch } from "../../../../../../contexts/NavContext";
import LoadModal from "../../../../../Dialogs/LoadModal";

interface CreatorInterface {
  id: string;
  _version: Number;
  notes: string;
  status: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  pronouns: string;
  personal_links: string[];
  work_links: string[];
  resume_link: string;
  universityID: string;
  university: any;
  location: string;
  extraHelp: boolean;
  dob: string;
  statusDate: string;
  contentType: string;
  createdAt: string;
  industries: string;
}

export default function Details({ currItemID }) {
  const dispatch = useErrorDispatch();
  const navDispatch = useNavDispatch();
  const [creator, setCreator] = useState<CreatorInterface | null>(null);
  const [saving, setSaving] = useState(false);
  const [savingForm, setSavingForm] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [inviteStatus, setInviteStatus] = useState("");
  const [showEditForm, setShowEditForm] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const { refresh } = useInstantSearch();

  const STATUS_COLOR = {
    PENDING_REVIEW: "bg-[#0980AE]",
    CALL_REQUESTED: "bg-[#5523D1]",
    APPROVED: "bg-[#0049F5]",
    REJECTED: "bg-gray-600",
  };

  useEffect(() => {
    currItemID ? loadCreator(currItemID) : setCreator(null);
  }, [currItemID]);

  const handleError = (message) => {
    showError(dispatch, message);
  };

  const loadCreator = async (id) => {
    try {
      setSaving(true);
      const result: any = await getCreatorApplicant(id);
      console.log(result);
      setCreator(result);
      setSaving(false);
      setUpdating(false);
      setRejecting(false);
    } catch (e) {
      handleError("Error loading applicant");
    }
  };

  const saveNoteFast = async (notes) => {
    try {
      const updatedItem = await updateCreatorApplicant({
        id: creator?.id,
        _version: creator?._version,
        notes: notes,
      });
      //   onUpdate(updatedItem);
      refresh();
    } catch (e) {
      handleError("Error saving note");
    }
  };

  const saveNote = async (notes) => {
    try {
      setSaving(true);
      const updatedItem = await updateCreatorApplicant({
        id: creator?.id,
        _version: creator?._version,
        notes: notes,
      });
      await loadCreator(creator?.id);
      //   onUpdate(updatedItem);
      refresh();
    } catch (e) {
      handleError("Error saving notes");
    } finally {
      setSaving(false);
    }
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  const handleFirstDemo = async (updatedItem) => {
    setShowDemoModal(false);
    await loadCreator(creator?.id);
    // await onUpdate(updatedItem);
    refresh();
  };

  //   TESTING ONLY  //
  const testStatusUpdate = async (status) => {
    console.log("updating status");
    setUpdating(true);
    let updatedItem = await updateCreatorApplicant({
      id: creator?.id,
      _version: creator?._version,
      status: status, // PENDING_REVIEW // CALL_REQUESTED
      statusDate: dayjs().format("YYYY-MM-DD"),
    });
    console.log("loading creator");
    await loadCreator(creator?.id);
    console.log("refreshing table");
    // updatedItem && onRefresh(updatedItem);
    refresh();
    setUpdating(false);
  };

  const updateStatus = async () => {
    setUpdating(true);
    let updatedItem;
    try {
      if (creator?.status == "PENDING_REVIEW") {
        updatedItem = await updateCreatorApplicant({
          id: creator?.id,
          _version: creator?._version,
          status: "CALL_REQUESTED",
          statusDate: dayjs().format("YYYY-MM-DD"),
        });
      } else if (creator?.status == "CALL_REQUESTED") {
        updatedItem = await inviteCreator();
        setInviteStatus("");
      }
      await loadCreator(creator?.id);
      //   updatedItem && onRefresh(updatedItem);
      refresh();
    } catch (e) {
      handleError(e.message || "Error updating status");
    } finally {
      setUpdating(false);
      setSaving(false);
    }
  };

  const inviteCreator = async () => {
    try {
      setInviteStatus("Creating new user...");

      const created = await createUser({
        email: creator?.email.trim().toLowerCase(),
        type: "CREATOR",
      });
      if (created.isExisting) {
        setShowInviteModal(false);
        throw { message: "Creator with email already exists." };
      }
      if (created?.error) throw { message: "Failed to create user" };
      setInviteStatus("Adding permissions...");
      const userID = created.result.User.Username;
      await addToGroup({ username: userID, groupname: "creators" });
      setInviteStatus("Creating profile from applicant...");
      const links = creator?.personal_links
        .concat(creator?.work_links)
        .concat(creator?.resume_link);
      const userData = {
        invitationalEmail: creator?.email.toLowerCase(),
        firstName: creator?.firstName,
        lastName: creator?.lastName,
        phoneNumber: creator?.phoneNumber,
        pronouns: creator?.pronouns,
        links: links,
        internalNote: creator?.notes,
        dob: creator?.dob,
        industries: creator?.industries,
        userID,
        status: "INVITED",
      };
      const creatorData = await addCreator(userData);
      console.log(creatorData);
      setInviteStatus("Update applicant status...");
      showToast(dispatch, "Creator invited!");
      return await updateCreatorApplicant({
        id: creator?.id,
        _version: creator?._version,
        status: "APPROVED",
        creatorID: creatorData?.id,
        needsDemo: true,
        statusDate: dayjs().format("YYYY-MM-DD"),
      });
    } catch (e) {
      console.error(e);
      throw { message: "Creator with email already exists." };
      //   handleError(e.message ? e.message : "Error inviting applicant");
    }
  };

  const handleStatusConfirm = async () => {
    try {
      await updateStatus();
      return true;
      //   await new Promise((resolve) => setTimeout(resolve, 15000));
    } catch (e) {
      handleError("Error updating applicant status");
    }
  };

  const handleQuickInvite = async () => {
    try {
      await inviteCreator();
      await loadCreator(creator?.id);
      //   setInviteStatus("");
      //   refresh();
    } catch (e) {
      handleError("Error updating applicant status");
    } finally {
      setUpdating(false);
      setSaving(false);
    }
    return true;
  };

  const handleRejectConfirm = async () => {
    try {
      await onReject();
      return true;
    } catch (e) {
      handleError("Error rejecting applicant");
      return false;
    }
  };

  const onReject = async () => {
    setRejecting(true);
    try {
      let updatedItem = await updateCreatorApplicant({
        id: creator?.id,
        _version: creator?._version,
        status: "REJECTED",
        statusDate: dayjs().format("YYYY-MM-DD"),
      });
      await loadCreator(creator?.id);
      //   await onRefresh(updatedItem);
      refresh();
      showToast(dispatch, "Creator Rejected");
    } catch (e) {
      handleError("Error rejecting applicant");
    }
  };

  const onUpdateCreator = async (applicant) => {
    setSavingForm(true);
    try {
      const result = await updateCreatorApplicant({
        id: creator?.id,
        _version: creator?._version,
        firstName: applicant?.firstName,
        lastName: applicant?.lastName,
        email: applicant?.email,
        phoneNumber: applicant?.phoneNumber,
        location: applicant?.location,
        dob: applicant?.dob,
        resume_link: applicant?.resume_link,
        personal_links: applicant?.personal_links,
        work_links: applicant?.work_links,
        pronouns: applicant?.pronouns,
        extraHelp: applicant?.extraHelp,
      });
      loadCreator(creator?.id);
      //   onUpdate(result);
      refresh();
      message.success("Saved!", 1.5);
    } catch (e) {
      handleError("Error updating applicant");
    } finally {
      setSavingForm(false);
      setShowEditForm(false);
    }
  };

  return !creator ? (
    <div className="flex items-center justify-center h-full">
      <FiLoader className="w-4 h-4 text-black animate-spin" />
    </div>
  ) : (
    <div className="relative p-8 space-y-8 bg-white">
      {/* Name */}
      <div className="flex items-center justify-between space-x-4">
        <div className="flex items-end space-x-4">
          <div className="text-[30px] capitalize">
            {creator?.firstName + " " + creator?.lastName}
          </div>
          <div className="pb-2">{creator?.pronouns}</div>
        </div>

        <div className="w-10">
          {/* this is the edit/reject menu */}
          <button className="relative w-10" onBlur={() => setOpenMenu(false)}>
            <div
              className="flex justify-end"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <MenuIcon />
            </div>
            {openMenu && (
              <div
                className="absolute z-50 px-2 rounded right-1 bg-[#F9FAF5] space-y-3 shadow-lg py-2"
                onBlur={() => setOpenMenu(false)}
              >
                <div
                  onClick={() => setShowEditForm(true)}
                  className="px-6 py-4 whitespace-nowrap hover:bg-[#30DF87] hover:rounded"
                >
                  Edit Information
                </div>
                {(creator?.status == "CALL_REQUESTED" ||
                  creator?.status == "PENDING_REVIEW") && (
                  <div
                    className="px-6 py-4 whitespace-nowrap hover:bg-[#30DF87] hover:rounded"
                    onClick={() => setShowRejectModal(true)}
                  >
                    Reject Applicant
                  </div>
                )}
              </div>
            )}
          </button>
        </div>
      </div>
      {/* QUICK ACTIONS */}
      {creator.status !== "APPROVED" && creator.status !== "REJECTED" && (
        <div className="flex items-center justify-between p-4 space-x-3 rounded-lg bg-slate-50">
          <div>Quick actions</div>
          <div className="flex space-x-4">
            <button
              className="flex px-6 py-4 space-x-2 rounded-full bg-slate-200 hover:opacity-50"
              onClick={() => setShowRejectModal(true)}
              disabled={updating}
            >
              <div>Reject</div>
            </button>
            <button
              className="flex px-6 py-4 space-x-2 rounded-full bg-[#30DF87] hover:opacity-50"
              onClick={() => setShowInviteModal(true)}
              disabled={updating}
            >
              <div>Invite</div>
            </button>
          </div>
        </div>
      )}
      {/* status, applied, vetting call */}
      <div className="flex items-center justify-between text-black">
        <div className="space-y-4">
          <div className="flex items-center space-x-3">
            <div className="font-semibold">Status: </div>
            <div className="flex items-center space-x-2">
              <div
                className={`w-3 h-3 rounded-full lowercase
              ${STATUS_COLOR[creator?.status]}  
              capitalize`}
              ></div>
              <div className="lowercase first-letter:capitalize">
                {creator?.status == "PENDING_REVIEW"
                  ? "Pending Review"
                  : creator?.status == "CALL_REQUESTED"
                  ? "Awaiting Call"
                  : creator?.status == "APPROVED"
                  ? "Invited"
                  : "Rejected"}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <div className="font-semibold">Applied: </div>
            <div className="">
              {dayjs(creator?.createdAt).format("MMM D, YYYY")}{" "}
            </div>
          </div>
        </div>

        {creator?.status === "APPROVED" &&
        creator?.needsDemo !== false &&
        creator.creatorID ? (
          <div>
            <button
              className={`${
                !updating ? "hover:opacity-90" : "opacity-50"
              } flex space-x-2 items-center bg-[#30DF87] rounded-full hover:bg-[#23C774] py-4 px-6 hover:duration-300`}
              onClick={() => setShowDemoModal(true)}
              disabled={updating}
            >
              {updating && (
                <div>
                  <FiLoader className="w-4 h-4 text-black animate-spin" />
                </div>
              )}

              <div>Assign Demo</div>
            </button>
          </div>
        ) : !creator?.needsDemo && creator?.status === "INVITED" ? (
          <div>Demo Requested</div>
        ) : null}

        {(creator?.status == "PENDING_REVIEW" ||
          creator?.status == "CALL_REQUESTED") && (
          <div>
            <button
              className={`${
                !updating ? "hover:opacity-90" : "opacity-50"
              } flex space-x-2 items-center bg-slate-200 rounded-full py-4 px-6 hover:duration-300`}
              onClick={() =>
                (creator?.status == "PENDING_REVIEW" &&
                  setShowRequestModal(true)) ||
                (creator?.status == "CALL_REQUESTED" &&
                  setShowInviteModal(true))
              }
              disabled={updating}
            >
              {updating && (
                <div>
                  <FiLoader className="w-4 h-4 text-black animate-spin" />
                </div>
              )}

              {
                <div>
                  {(creator?.status == "PENDING_REVIEW" &&
                    "Request Vetting Call") ||
                    (creator?.status == "CALL_REQUESTED" &&
                      "Approve and Invite")}
                </div>
              }
            </button>
          </div>
        )}
      </div>

      {creator?.status === "APPROVED" && (
        <div className="py-2 space-y-8">
          <div className="p-4 font-semibold bg-yellow-100 rounded-md first-letter:capitalize">
            {creator.firstName} is now a creator. To edit information or view
            their collabs go to their creator profile.
          </div>
          {creator?.creatorID && (
            <div className="flex items-center justify-between p-4 border border-gray-100 rounded-md">
              <div className="">
                <div className="text-xs">Clutch Creator</div>
                <div className="text-xl">
                  {creator?.firstName + " " + creator?.lastName}
                </div>
              </div>

              <button
                className={`flex space-x-2 items-center text-white bg-black rounded-full hover:bg-gray-800 py-4 px-6 hover:duration-300`}
                onClick={() =>
                  navDispatch({
                    type: "navigateTo",
                    payload: {
                      tab: "creators",
                      profileID: creator?.creatorID,
                    },
                  })
                }
                disabled={updating}
              >
                Go To Profile
              </button>
            </div>
          )}
        </div>
      )}

      {creator?.extraHelp && (
        <div className="px-4 py-2 rounded-md bg-[#30DF87]/75 font-semibold">
          This creator works with additional people to create content
        </div>
      )}

      {creator?.resume_link && (
        <div>
          <div className="pb-2 font-semibold">Resume</div>

          <div className="flex group flex-row justify-between hover:duration-300 items-center hover:bg-[#0A8754] bg-[#E7EEEB] rounded-md px-2 py-[6px]">
            <a
              className="w-full h-full cursor-pointer"
              target="_blank"
              href={
                creator?.resume_link.includes("http")
                  ? creator?.resume_link
                  : "https://" + creator?.resume_link
              }
            >
              <div className="px-4 py-2 rounded-lg group-hover:text-white">
                {creator?.resume_link}
              </div>
            </a>
            <div
              className="cursor-pointer stroke-black group-hover:stroke-white"
              onClick={() => copyToClipboard(creator?.resume_link)}
            >
              <CopyIcon />
            </div>
          </div>
        </div>
      )}

      <div className="flex">
        <div className="flex flex-col flex-1 mr-2">
          <div className="pb-2 font-semibold">Personal Links</div>
          <div className=" bg-[#E7EEEB] px-2 py-4 space-y-2 rounded-lg">
            {creator?.personal_links &&
              creator.personal_links.map((link, i) => (
                <div className="hover:bg-[#0A8754] hover:duration-300 group px-2 py-[6px] cursor-pointer rounded-lg flex items-center justify-between flex-nowrap">
                  <div className="truncate max-w-[200px]">
                    <a
                      target="_blank"
                      href={link?.includes("http") ? link : "https://" + link}
                      className="w-full h-full overflow-hidden whitespace-nowrap text-ellipsis text-inherit group-hover:text-white"
                    >
                      {link?.includes("http") ? link : "https://" + link}
                    </a>
                  </div>
                  {/* add group hover!  */}
                  <div
                    className="cursor-pointer stroke-black group-hover:stroke-white"
                    onClick={() =>
                      copyToClipboard(
                        link?.includes("http") ? link : "https://" + link
                      )
                    }
                  >
                    <CopyIcon />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="pb-2 font-semibold">Work links</div>
          <div className="bg-[#E7EEEB] px-2 py-4 space-y-2 rounded-lg">
            {creator?.work_links &&
              creator.work_links.map((link, i) => (
                <div className="hover:bg-[#0A8754] hover:duration-300 group px-2 py-[6px] cursor-pointer rounded-lg flex items-center justify-between flex-nowrap">
                  <div className="truncate max-w-[200px] group-hover:text-white">
                    <a
                      target="_blank"
                      href={link?.includes("http") ? link : "https://" + link}
                      className="overflow-hidden whitespace-nowrap group-hover:text-white text-ellipsis text-inherit "
                    >
                      {link?.includes("http") ? link : "https://" + link}
                    </a>
                  </div>
                  <div
                    className="cursor-pointer stroke-black group-hover:stroke-white"
                    onClick={() =>
                      copyToClipboard(
                        link?.includes("http") ? link : "https://" + link
                      )
                    }
                  >
                    <CopyIcon />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div>
        <div className="pb-6 font-semibold">Applicant Info</div>
        <div className="flex pb-6 space-x-4">
          <div className="flex-1 mr-2 space-y-6 ">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3 ">
                <div>
                  <EmailIcon />
                </div>
                <div className="truncate max-w-[185px]">
                  <a
                    target={"_blank"}
                    href={"mailto:" + creator?.email}
                    className="underline cursor-pointer text-inherit hover:text-[#0A8754] hover:underline"
                  >
                    {creator?.email}
                  </a>
                </div>
              </div>
              <div
                className="stroke-black cursor-pointer hover:stroke-[#0A8754]"
                onClick={() => copyToClipboard(creator?.email)}
              >
                <CopyIcon />
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <div>
                <LocationIcon />
              </div>
              <div>{creator?.location || "No location"}</div>
            </div>
          </div>
          <div className="flex-1 space-y-6">
            <div className="flex items-center justify-between space-x-3">
              <div className="flex items-center space-x-3 ">
                <div>
                  <PhoneIcon />
                </div>
                <div className="">
                  <a
                    className="underline cursor-pointer text-inherit hover:text-[#0A8754] hover:underline"
                    href={`sms://+1${creator?.phoneNumber?.replace(
                      /\D/g,
                      ""
                    )};?&body=Message%20from%20Clutch.%0A`}
                    target={"_blank"}
                  >
                    {creator?.phoneNumber}
                  </a>
                </div>
              </div>
              <div
                className="cursor-pointer hover:stroke-[#0A8754] stroke-black"
                onClick={() => copyToClipboard(creator?.phoneNumber)}
              >
                <CopyIcon />
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <div>
                <BirthdateIcon />
              </div>
              <div>
                {dayjs(creator?.dob, "YYYY-MM-DD").format("MMMM DD, YYYY")}

                {" (" + dayjs().diff(creator.dob, "year") + " yrs old)"}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between space-x-3">
          <div className="flex items-center flex-1 space-x-3">
            <FiThumbsUp className="w-5 h-5 ml-1" />
            <div>{creator?.industries ? creator?.industries : "Empty"}</div>
          </div>
          <div className="flex items-center flex-1 space-x-3">
            <FiVideo className="w-5 h-5 ml-1" />
            <div>{creator?.contentType ? creator?.contentType : "Empty"}</div>
          </div>
        </div>
      </div>

      {/* Notes */}
      <div>
        <div className="pb-4 font-semibold">Admin Notes</div>
        <Autosave
          adminNotes={creator?.notes}
          saveNote={saveNote}
          saveNoteFast={saveNoteFast}
          isSaving={saving}
          profileID={currItemID}
        />
      </div>

      <Index indexName="client">
        <BasicModal
          isVisible={showDemoModal}
          onCancel={() => setShowDemoModal(false)}
        >
          <AddFirstDemo
            creator={creator}
            onClose={(updatedCreator) => {
              handleFirstDemo(updatedCreator);
            }}
          />
        </BasicModal>
      </Index>

      {/* showRequestModal vetting call */}
      <ConfirmationModal
        closable={true}
        onCancel={setShowRequestModal}
        isVisible={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        title={`You're about to send ${creator?.firstName +
          " " +
          creator?.lastName} an email to book a vetting call`}
        subtitle="They'll be sent a welcome email with the details."
        onConfirm={handleStatusConfirm}
        confirmText={updating ? "Sending..." : "Yes, send"}
        successText={`${creator?.firstName +
          " " +
          creator?.lastName} has been requested a vetting call!`}
      />

      <SideModal visible={showEditForm} onClose={() => setShowEditForm(false)}>
        <EditCreatorApplicantForm
          selected={creator}
          onUpdate={onUpdateCreator}
          loading={savingForm}
        />
      </SideModal>

      {/* invite/approve */}
      {/* <ConfirmationModal
        closable={true}
        onCancel={setShowInviteModal}
        isVisible={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        title={`You're about to send ${creator?.firstName +
          " " +
          creator?.lastName} an email to join Clutch as a Creator`}
        subtitle="They'll be sent a welcome email with their temporary login credentials."
        onConfirm={handleStatusConfirm}
        confirmText={updating ? "Inviting..." : "Yes, invite"}
        successText={`${creator?.firstName +
          " " +
          creator?.lastName} has been invited to join Clutch.`}
        successSubtext="You can now find them in the Creators tab."
      /> */}
      <LoadModal
        closable={true}
        isVisible={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        title={`Invite ${creator?.firstName +
          " " +
          creator?.lastName} to become a Clutch Creator`}
        subtitle="They'll be sent a welcome email and their temporary login credentials"
        onConfirm={handleQuickInvite}
        confirmText={"Yes, invite"}
      />

      {/* reject */}
      <ConfirmationModal
        closable={true}
        onCancel={setShowRejectModal}
        isVisible={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        confirmText={rejecting ? "Rejecting..." : "Yes, reject"}
        title={`Are you sure you want to reject ${creator?.firstName +
          " " +
          creator?.lastName}?`}
        subtitle="They'll be sent a rejection email"
        onConfirm={handleRejectConfirm}
        successText={`${creator?.firstName +
          " " +
          creator?.lastName} has been rejected.`}
      />
    </div>
  );
}
