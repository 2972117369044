import { Auth } from "aws-amplify";

const isBrowser = typeof window !== `undefined`;

export const setUser = (user) =>
  (window.localStorage.gatsbyUser = JSON.stringify({}));

const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const userGroups =
    user.signInUserSession.accessToken.payload["cognito:groups"];
  const userInfo = {
    ...user.attributes,
    username: user.username,
    userGroups, // REMOVE SAVING USERGROUP MAKE DYNAMIC
  };
  return userInfo;
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();
  if (user.username) return user;
  else return false;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logoutUser = (callback) => {
  if (!isBrowser) return;
  setUser({});
  callback();
};
