import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import { AmpAPI } from "@thatsclutch/shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function LinkModal({ onComplete, open }) {
  const cancelButtonRef = useRef(null);
  const [link, setLink] = useState("");
  const [error, setError] = useState("");

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => onComplete("")}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-3xl sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="p-8 py-12 space-y-4 bg-white">
                  <div className="pb-4">
                    <div className="pb-2 text-3xl font-medium">
                      Attach a link
                    </div>
                    <div className="text-sm text-slate-600">
                      This link will not be used as a submission and will not be
                      available for approval and payment. This is for client
                      reference only. Avoid sending final/downloadable drafts.
                    </div>
                    <div className="pt-2 text-sm text-slate-900">
                      Verify the link works before submitting.
                    </div>
                    {/* {dayjs().isAfter(month.endDate) && (
                        <div className="p-4 mt-4 text-sm text-yellow-500 rounded-xl bg-yellow-50">
                          This submission will be marked late. Please submit all
                          submissions on time.
                        </div>
                      )} */}
                  </div>
                  <div className="space-y-4">
                    <input
                      onChange={(e) => setLink(e.target.value)}
                      value={link}
                      placeholder="Asset link... (ex. https://example.com)"
                      className="w-full px-6 py-3 text-sm border rounded-full outline-none focus:border-green-200"
                    />
                    {error && (
                      <div className="py-2 text-xs text-rose-500">{error}</div>
                    )}
                  </div>
                </div>
                <div className="px-8 pb-8 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-6 py-3 text-lg font-light text-white bg-black border border-transparent rounded-full shadow-sm hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      if (!link) return setError("Link is required");
                      // validate link as valid https url
                      else if (!link.match(/https:\/\/[a-z0-9]+/))
                        return setError("Invalid link, must start with https://");
                      else onComplete(link);
                    }}
                  >
                    <div className="flex space-x-2">
                      <div>Attach</div>
                    </div>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-6 py-3 mt-3 text-base font-medium bg-white border rounded-full shadow-sm text-slate-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onComplete("")}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
