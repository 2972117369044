import React, { useEffect, useState } from "react";
import { Button, Collapse, Form, Input, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Header from "./Header";
import { updateProvider } from "@thatsclutch/shared/services/UserService";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
const { Panel } = Collapse;

const Phone = ({ provider, onNext, onBack, onError }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      ...(provider.phoneNumber && { phone: provider.phoneNumber }),
    });
  }, []);

  const onFinish = async (values) => {
    console.log("input data:", values);
    // validate input
    const cleanPhone = values.phone.match(/\d+/g, "").join("");
    console.log("validation: ", cleanPhone);
    if (cleanPhone.length !== 10) {
      return alert("Not enough digits, must be 10");
    }
    // create customer w/name
    setLoading(true);
    const providerData = {
      phoneNumber: cleanPhone,
    };
    const updateResults = await updateCreator(provider, providerData);
    window._dcq.push(["identify", { phone: cleanPhone }]);
    setLoading(true);
    // handle error
    if (updateResults.error) return onError("Issue updating provider");
    // handle error
    onNext();
  };

  return (
    <div className="w-full p-4 md:w-2/3">
      <Header step={2} label={"What is your phone number?"} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        requiredMark={false}
      >
        <Form.Item
          label="Phone Number"
          name="phone"
          autofocus
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log("got value: ", value.match(/\d/g));
                if (value && value.match(/\d/g).length === 10) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("Phone number must contain 10 digits")
                );
              },
            }),
          ]}
        >
          <Input type="tel" maxLength={16} placeholder="(000) 000 - 0000" />
        </Form.Item>
        <Collapse
          ghost
          bordered
          expandIconPosition="right"
          expandIcon={() => null}
        >
          <Panel
            header={
              <p className="text-sm text-slate-400">
                By providing your number you accept receiving text messages from
                Clutch. <a>Learn More</a>
              </p>
            }
            key="1"
          >
            <p className="text-sm text-slate-400">
              Clutch may occasionally have products/services/special offers that
              we think may be of interest to you. By continuing, you confirm you
              want to hear from us with this information, which may include our
              use of automated dialing technology to call or text you at the
              phone number(s) provided, including your wireless number if
              provided.
            </p>
          </Panel>
        </Collapse>
        <div className="flex mt-16 space-x-4">
          <Button
            name="submit"
            type="secondary"
            shape="round"
            className="w-[100px]"
            size="large"

            onClick={() => onBack()}
          >
            Back
          </Button>
          <Button
            name="submit"
            style={{ background: '#30DF87', border: 'none' }}
            shape="round"
            type="primary"
            size="large"
            className="bg-[#30DF87]"
            loading={loading}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Phone;
