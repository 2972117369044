import React from "react";
import Todo from "./Todo";
import NewCollabs from "./NewCollabs";
import ActiveCollabs from "./ActiveCollabs";
import Hero from "./Hero";
import ProfileLink from "./ProfileLink";
import StripeCheck from "./StripeCheck";
import StripeSummary from "./StripeSummary";
import Matches from "./Matches";

export default function Home({ creator, loading, onSelectCollab, navSelect }) {
  return loading ? (
    <div className="flex-1 p-8 space-y-12 text-center bg-gray-50">
      Loading...
    </div>
  ) : (
    <div className="flex-1 p-8 space-y-8 bg-gray-50">
      {/* Stripe Check */}
      <StripeCheck creator={creator} />
      {/* TODO */}
      {creator?.status !== "ACTIVE" ? <Todo creator={creator} /> : <Hero />}

      {/* PROFILE LINK */}
      {creator?.status === "ACTIVE" && <ProfileLink creator={creator} />}

      {/* STRIPE SUMMARY */}
      {creator?.status === "ACTIVE" && <StripeSummary creator={creator} />}

      {/* OPPORTUNITIES */}
      {creator?.status === "ACTIVE" && <Matches />}

      {/* New Collabs */}
      <NewCollabs
        creator={creator}
        onSelectCollab={onSelectCollab}
        viewAll={navSelect}
      />

      {/* Active Collabs */}
      <ActiveCollabs
        creator={creator}
        onSelectCollab={onSelectCollab}
        viewAll={navSelect}
      />
    </div>
  );
}
