import React, { useState, useEffect } from "react";
import { createPreviousWork } from "@thatsclutch/shared/services/CreatorService";
import SimpleModal from "../../../../../Dialogs/SimpleModal";

import UploadWorkForm from "../UploadWorkForm";

//

function AddWorkModal({ show, creator, onClose }) {
  const [showForm, setShowForm] = useState(true);
  const [type, setType] = useState("vertical_video");

  return (
    <SimpleModal show={show} onClose={onClose}>
      <div onClick={onClose} className="absolute cursor-pointer top-8 right-8">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.25 6.75L6.75 17.25"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.75 6.75L17.25 17.25"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {showForm ? (
        <UploadWorkForm type={type} creator={creator} onClose={onClose} />
      ) : (
        <div className="p-8">
          <div className="pb-4">
            <div className="text-3xl font-medium">Add your best work</div>
            <div>What kind of content do you want to upload?</div>
          </div>
          <div className="flex justify-evenly">
            {/* Vertical Video */}
            <div
              onClick={() => {
                setType("vertical_video");
                setShowForm(true);
              }}
              className="cursor-pointer h-[175px] w-[175px] text-center flex-col font-medium shadow-card p-6 flex justify-center items-center rounded-lg"
            >
              <div className="pb-4">Vertical Video</div>
              <div className="flex justify-center space-x-4">
                <div>
                  <svg
                    width="27"
                    height="30"
                    viewBox="0 0 27 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.1665 4.7C20.0272 3.39937 19.3994 1.72905 19.3999 0H14.2499V20.6667C14.2101 21.785 13.738 22.8444 12.9328 23.6216C12.1276 24.3988 11.0523 24.8332 9.93319 24.8333C7.56652 24.8333 5.59986 22.9 5.59986 20.5C5.59986 17.6333 8.36652 15.4833 11.2165 16.3667V11.1C5.46652 10.3333 0.433189 14.8 0.433189 20.5C0.433189 26.05 5.03319 30 9.91652 30C15.1499 30 19.3999 25.75 19.3999 20.5V10.0167C21.4882 11.5164 23.9955 12.3211 26.5665 12.3167V7.16667C26.5665 7.16667 23.4332 7.31667 21.1665 4.7Z"
                      fill="#0D1C0D"
                    />
                  </svg>
                </div>
                <div>
                  <svg
                    width="35"
                    height="34"
                    viewBox="0 0 35 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 0.333984H24.5C29.8333 0.333984 34.1667 4.66732 34.1667 10.0006V24.0006C34.1667 26.5644 33.1482 29.0232 31.3354 30.836C29.5225 32.6489 27.0638 33.6673 24.5 33.6673H10.5C5.16666 33.6673 0.833328 29.334 0.833328 24.0006V10.0006C0.833328 7.43689 1.85178 4.97814 3.66463 3.16529C5.47748 1.35243 7.93623 0.333984 10.5 0.333984ZM10.1667 3.66732C8.57536 3.66732 7.04924 4.29946 5.92402 5.42468C4.7988 6.54989 4.16666 8.07602 4.16666 9.66732V24.334C4.16666 27.6506 6.84999 30.334 10.1667 30.334H24.8333C26.4246 30.334 27.9507 29.7018 29.076 28.5766C30.2012 27.4514 30.8333 25.9253 30.8333 24.334V9.66732C30.8333 6.35065 28.15 3.66732 24.8333 3.66732H10.1667ZM26.25 6.16732C26.8025 6.16732 27.3324 6.38681 27.7231 6.77751C28.1138 7.16821 28.3333 7.69812 28.3333 8.25065C28.3333 8.80318 28.1138 9.33309 27.7231 9.72379C27.3324 10.1145 26.8025 10.334 26.25 10.334C25.6975 10.334 25.1676 10.1145 24.7768 9.72379C24.3862 9.33309 24.1667 8.80318 24.1667 8.25065C24.1667 7.69812 24.3862 7.16821 24.7768 6.77751C25.1676 6.38681 25.6975 6.16732 26.25 6.16732ZM17.5 8.66732C19.7101 8.66732 21.8297 9.54529 23.3925 11.1081C24.9554 12.6709 25.8333 14.7905 25.8333 17.0006C25.8333 19.2108 24.9554 21.3304 23.3925 22.8932C21.8297 24.456 19.7101 25.334 17.5 25.334C15.2899 25.334 13.1702 24.456 11.6074 22.8932C10.0446 21.3304 9.16666 19.2108 9.16666 17.0006C9.16666 14.7905 10.0446 12.6709 11.6074 11.1081C13.1702 9.54529 15.2899 8.66732 17.5 8.66732ZM17.5 12.0006C16.1739 12.0006 14.9021 12.5274 13.9645 13.4651C13.0268 14.4028 12.5 15.6746 12.5 17.0006C12.5 18.3267 13.0268 19.5985 13.9645 20.5362C14.9021 21.4739 16.1739 22.0006 17.5 22.0006C18.8261 22.0006 20.0978 21.4739 21.0355 20.5362C21.9732 19.5985 22.5 18.3267 22.5 17.0006C22.5 15.6746 21.9732 14.4028 21.0355 13.4651C20.0978 12.5274 18.8261 12.0006 17.5 12.0006Z"
                      fill="#0D1C0D"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Image */}
            <div className=" space-y-4 h-[175px] w-[175px] text-center flex-col font-medium shadow-card p-6 flex justify-center items-center rounded-lg">
              <div className="opacity-60">Image</div>
              <div className="flex justify-center opacity-60">
                <div>
                  <svg
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16667 23.3333H23.8333C24.1667 23.3333 24.4167 23.1806 24.5833 22.875C24.75 22.5694 24.7222 22.2778 24.5 22L19.9167 15.875C19.75 15.6528 19.5278 15.5417 19.25 15.5417C18.9722 15.5417 18.75 15.6528 18.5833 15.875L14.25 21.6667L11.1667 17.5417C11 17.3194 10.7778 17.2083 10.5 17.2083C10.2222 17.2083 10 17.3194 9.83334 17.5417L6.5 22C6.27778 22.2778 6.25 22.5694 6.41667 22.875C6.58334 23.1806 6.83334 23.3333 7.16667 23.3333ZM3.83334 30C2.91667 30 2.13167 29.6733 1.47834 29.02C0.825003 28.3667 0.498892 27.5822 0.500003 26.6667V3.33334C0.500003 2.41667 0.82667 1.63167 1.48 0.978336C2.13334 0.325003 2.91778 -0.00110828 3.83334 2.82966e-06H27.1667C28.0833 2.82966e-06 28.8683 0.32667 29.5217 0.980003C30.175 1.63334 30.5011 2.41778 30.5 3.33334V26.6667C30.5 27.5833 30.1733 28.3683 29.52 29.0217C28.8667 29.675 28.0822 30.0011 27.1667 30H3.83334Z"
                      fill="#0D1C0D"
                    />
                  </svg>
                </div>
              </div>
              <div className="text-sm opacity-60">Coming Soon</div>
            </div>
          </div>
        </div>
      )}
    </SimpleModal>
  );
}

export default AddWorkModal;
