import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import { Plan } from "@thatsclutch/backend/API";
import dayjs from "dayjs";
import BasicModal from "../../../../../Dialogs/BasicModal";
import EditPlan from "./EditPlan";
import CreatePlan from "./CreatePlan";
import { getPlansByClientID } from "@thatsclutch/shared/services/PlanService";

const STATUS_COLOR = {
  READY: "bg-yellow-100",
  PROCESSING: "bg-orange-100",
  ACTIVE: "bg-green-100",
  HOLD: "bg-red-100",
  TRIAL: "bg-blue-100",
};

export default function PlanTab({ client }: { client: any }) {
  const [plans, setPlans] = useState<(Plan | null)[]>([]);
  const [loading, setLoading] = useState(true);
  const [editPlan, setEditPlan] = useState<Plan | null>(null);
  const [showCreate, setShowCreate] = useState(false);

  // init load payments if client is available
  useEffect(() => {
    if (client) {
      loadPlans();
    }
  }, [client]);

  // load payments
  const loadPlans = async () => {
    setLoading(true);
    const result = await getPlansByClientID(client.id);
    setPlans(result?.sort(sortByStartDate) || []);
    setLoading(false);
  };

  // sort by start date
  const sortByStartDate = (a: Plan | null, b: Plan | null) =>
    dayjs(b?.startDate).diff(dayjs(a?.startDate));

  return loading ? (
    <div className="flex items-center justify-center h-64">
      <FiLoader className="animate-spin" />
    </div>
  ) : (
    <div className="py-4 space-y-4">
      <div className="flex items-center justify-end p-4 px-6 space-x-8 space-y-2 overflow-hidden border rounded-lg min-w-min">
        <div className="flex-1 text-sm text-slate-500">
          Plans are visible to the client but currently do no block user flow.
        </div>
        {/* Create plan button */}
        <div className="flex items-center space-x-2 ">
          <button
            className="px-4 py-2 text-sm rounded-md text-dark bg-primary-light whitespace-nowrap"
            onClick={() => setShowCreate(true)}
          >
            Create Plan
          </button>
        </div>
      </div>
      <div className="space-y-4">
        {plans.map((plan) => (
          <div
            className="w-full p-4 px-6 space-y-2 overflow-hidden border rounded-lg"
            key={plan?.id}
          >
            <div className="flex justify-between">
              <div className="flex items-center">
                <div className="pr-2">
                  {dayjs(plan?.startDate)
                    .add(plan?.contractLength, "month")
                    .diff(dayjs(), "month")}{" "}
                  months left
                </div>
                <div className="px-2 text-gray-400">
                  {/* end date based on start and contract length*/}
                  Ends on{" "}
                  {dayjs(plan?.startDate)
                    .add(plan?.contractLength, "month")
                    .format("MMM DD, YYYY")}
                </div>
              </div>
              <div
                className={`px-3 py-1 m-0 text-sm rounded-full 
				 first-letter:capitalize ${STATUS_COLOR[plan?.status || 0]}`}
              >
                {plan?.status}
              </div>
            </div>
            <div className="text-lg font-medium first-letter:capitalize">
              {/* start date plan */}${parseInt(plan?.price) / 100} -{" "}
              {dayjs(plan?.startDate).format("MMM DD, YYYY")}
            </div>

            {/* <div className="flex justify-between pb-2">
              <div className="text-sm underline truncate w-52 max-h-24 text-slate-500">
                <a href={plan?.link || ""} target="_blank">
                  {plan?.link || "No link"}
                </a>
              </div>
            </div> */}
            {/* Actions */}
            <div className="flex items-center w-full space-x-4">
              <div className="text-sm text-gray-400">{plan?.paymentType}</div>
              <div className="flex items-center flex-1 min-w-0">
                <div className="overflow-hidden">
                  <a
                    href={plan?.link || ""}
                    target="_blank"
                    className="block text-sm underline truncate text-slate-500"
                  >
                    {plan?.link || "No link"}
                  </a>
                </div>
              </div>
              <button
                className="px-4 py-2 text-sm bg-gray-100 rounded-md text-dark"
                onClick={() => {
                  setEditPlan(plan);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
      <BasicModal isVisible={editPlan} onCancel={() => setEditPlan(null)}>
        <EditPlan
          onClose={(refresh) => {
            setEditPlan(null);
            refresh && loadPlans();
          }}
          plan={editPlan}
        />
      </BasicModal>
      <BasicModal isVisible={showCreate} onCancel={() => setShowCreate(false)}>
        <CreatePlan
          onClose={(refresh) => {
            setShowCreate(false);
            refresh && loadPlans();
          }}
          clientID={client.id}
        />
      </BasicModal>
    </div>
  );
}
