import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";

const AnimWrap = ({ children }) => (
  <Transition
    appear={true}
    show={true}
    enter="ease-out duration-300"
    enterFrom="opacity-0 translate-x-4 sm:translate-y-0 "
    enterTo="opacity-100 translate-x-0"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 translate-x-0"
    leaveTo="opacity-0 translate-x-4 sm:translate-y-0"
  >
    {children}
  </Transition>
);

export { AnimWrap };
