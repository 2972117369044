import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";

function CollabFeedHelp({ onClose, open }) {
  const cancelButtonRef = useRef(null);
  return (
    <>
      <Transition.Root appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 "
          initialFocus={cancelButtonRef}
          onClose={() => onClose()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:max-w-2xl ">
                  <div className="p-10">
                    <div className="flex justify-end pb-2">
                      <button ref={cancelButtonRef} onClick={() => onClose()}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path
                            d="M14.3173 16C14.2773 16 14.2395 15.982 14.215 15.9513L11.9854 13.1529C11.9331 13.0872 11.8304 13.0881 11.7793 13.1547L9.63835 15.9494C9.61398 15.9812 9.57548 16 9.53456 16H8.13181C8.02395 16 7.96359 15.8797 8.0303 15.7977L11.0338 12.106C11.0705 12.0609 11.0707 11.9974 11.0344 11.952L8.02735 8.20162C7.9615 8.11949 8.022 8 8.12943 8H9.68229C9.72224 8 9.75994 8.01789 9.78441 8.04845L12.0138 10.8329C12.0662 10.8984 12.1689 10.8973 12.2198 10.8306L14.3428 8.05071C14.3672 8.01882 14.4057 8 14.4467 8H15.8497C15.9575 8 16.0179 8.12021 15.9513 8.20222L12.9654 11.8796C12.9288 11.9246 12.9285 11.988 12.9647 12.0333L15.9728 15.7986C16.0384 15.8807 15.9779 16 15.8706 16H14.3173Z"
                            fill="#6E776E"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="px-2 py-1 text-xs text-black bg-green-400 rounded-md w-min">
                      NEW
                    </div>
                    <div className="pt-4 pb-2 text-4xl font-semibold">
                      What is a "Collab Feed"?
                    </div>
                    <div className="pb-8 text-sm text-gray-600">
                      All collab updates and messages will be tracked here
                    </div>

                    <div className="grid gap-6">
                      <div className="grid gap-2">
                        <div className="text-xl font-bold">
                          <span className="pr-2">🤩</span>
                          Built-in messaging!
                        </div>
                        <div className="text-base text-gray-600 ">
                          Talk to the client directly about the collab
                        </div>
                      </div>
                      <div className="grid gap-2">
                        <div className="text-xl font-bold">
                          <span className="pr-2">📥</span>
                          Submissions and messages in sync
                        </div>
                        <div className="text-base text-gray-600 ">
                          Submissions will show up in the feed for easy access
                          and a clear timeline
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end pt-8">
                      <div
                        onClick={() => {
                          onClose();
                        }}
                        className="flex items-center px-8 py-3 text-sm font-light text-white capitalize transition-opacity bg-black rounded-full cursor-pointer hover:opacity-90"
                      >
                        <div className="text-white">Got it!</div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default CollabFeedHelp;
