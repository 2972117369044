import React from "react";
import dayjs from "dayjs";
import Skills from "./Skills";
import { FiMapPin } from "react-icons/fi";

function BasicInfo({ creator }) {
  return (
    <div className="space-y-4">
      {/* NAME */}
      <div className="text-6xl font-medium capitalize">
        {creator?.firstName + " " + creator?.lastName}
      </div>
      {/* META DATA (PRONOUNS, AGE, LOCATION, REMOTE_ONLY) */}
      <div className="flex flex-wrap gap-2">
        <div className="bg-[#F1F5F3]   px-4 py-2 whitespace-nowrap rounded-full text-black/[0.6]">
          {creator?.pronouns || "N/A"}
        </div>
        <div className="bg-[#F1F5F3]  px-4 py-2 whitespace-nowrap rounded-full text-black/[0.6]">
          {dayjs().diff(creator?.dob, "year") + " y" || "N/A"}
        </div>
        <div className="bg-[#F1F5F3]  px-4 py-2 whitespace-nowrap rounded-full text-black/[0.6] flex items-center justify-center space-x-2">
          <FiMapPin />
          <div>
            {/* no location */}
            {!creator?.state && !creator?.locality && "No location yet"}

            {/* state only */}
            {creator?.state && !creator?.locality && creator?.state}

            {/* city only */}
            {!creator?.state && creator?.locality && creator?.locality}

            {/* both city and state */}
            {creator?.locality &&
              creator?.state &&
              creator?.locality + ", " + creator?.state}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicInfo;
