import { getClientByID } from "@thatsclutch/shared/services/ClientService";
import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import ProfileImage from "../../../../Basic/ProfileImage";
import SideModal from "../../../../Dialogs/SideModal";
import General from "./General";
import MatchesTab from "./MatchesTab";
import CollabsTab from "./CollabsTab";
import BasicModal from "../../../../Dialogs/BasicModal";
import InviteModal from "./InviteModal";
import PlanTab from "./PlanTab";
import UpgradeModal from "./UpgradeModal";

const Tab = ({ title, selected, onSelect }) =>
  selected ? (
    <div className="flex-1 p-3 px-8 font-medium text-green-600 border-b-2 border-b-green-600">
      {title}
    </div>
  ) : (
    <div
      onClick={() => onSelect(title.toLowerCase())}
      className="flex-1 p-3 px-8 transition-colors border-b cursor-pointer hover:text-slate-800 hover:border-b-slate-800 text-slate-500 border-b-slate-300"
    >
      {title}
    </div>
  );

export default function Details({
  clientID,
  onClose,
  onUpdated,
  defaultTab = "general",
}) {
  const [selectedTab, setSelectedTab] = useState("general");
  const [client, setClient] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showInvite, setShowInvite] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  useEffect(() => {
    clientID ? loadClient() : setClient(null);
    clientID && setSelectedTab(defaultTab);
  }, [clientID]);

  const loadClient = async () => {
    setLoading(true);
    const result = await getClientByID(clientID);
    setClient(result);
    setLoading(false);
  };

  const handleUpdated = async (value) => {
    onUpdated(value);
    setClient(value);
  };

  const handleUpdatedFast = async (value) => {
    onUpdated(value);
  };

  return (
    <SideModal
      visible={!!clientID}
      onClose={() => {
        onClose();
        setSelectedTab("general");
      }}
    >
      {loading || !client ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex justify-center py-4">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
          </div>
        </div>
      ) : (
        <div className="">
          {/* Header */}
          <div className="flex items-center p-8 space-x-6">
            <ProfileImage profileImage={client.image} size={"lg"} />
            <div className="space-y-1">
              {/* BRAND */}
              <div className="text-3xl font-bold capitalize">
                {client.brand}
              </div>
              {/* POC */}
              <div className="flex items-center space-x-2">
                {/* <div className="w-10 h-10 rounded-full bg-slate-300"></div> */}
                <div className="">
                  {client.firstName && (
                    <div className="capitalize">
                      {client.firstName + " " + client.lastName}
                    </div>
                  )}
                  <div className="text-xs capitalize text-slate-400">
                    {client.title}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!client.userID && (
            <div className="p-8">
              <div className="flex justify-between p-4 bg-orange-200 rounded">
                <div className="text-orange-500">
                  Client not linked to any account
                </div>
                <div
                  onClick={() => setShowInvite(true)}
                  className="text-orange-500 underline cursor-pointer hover:text-orange-400"
                >
                  Add Email
                </div>
              </div>
            </div>
          )}
          {client.status === "TRIAL" && (
            <div className="p-8">
              <div className="flex justify-between p-4 bg-pink-100 rounded">
                <div className="text-pink-500">Trial client</div>
                <div
                  onClick={() => setShowUpgrade(true)}
                  className="text-pink-500 underline cursor-pointer hover:text-pink-400"
                >
                  Upgrade to paid
                </div>
              </div>
            </div>
          )}
          {/* Tabs (General/Matches/Collabs) */}
          <div className="flex pt-6">
            <Tab
              selected={selectedTab === "general"}
              title={"General"}
              onSelect={setSelectedTab}
            />
            <Tab
              selected={selectedTab === "projects"}
              title={"Projects"}
              onSelect={setSelectedTab}
            />
            <Tab
              selected={selectedTab === "collabs"}
              title={"Collabs"}
              onSelect={setSelectedTab}
            />
            <Tab
              selected={selectedTab === "payments"}
              title={"Payments"}
              onSelect={setSelectedTab}
            />
          </div>
          <div className="px-8 pb-8">
            {/* General */}
            {selectedTab === "general" ? (
              <General
                clientID={clientID}
                client={client}
                onUpdated={handleUpdated}
                onUpdateFast={handleUpdatedFast}
              />
            ) : selectedTab === "projects" ? (
              <MatchesTab client={client} />
            ) : selectedTab === "payments" ? (
              <PlanTab client={client} />
            ) : (
              <CollabsTab clientID={client.id} />
            )}
          </div>
        </div>
      )}
      {/* New client */}
      <BasicModal
        isVisible={showInvite}
        onCancel={() => setShowInvite(false)}
        width={600}
      >
        <InviteModal
          client={client}
          onClose={(refresh) => {
            setShowInvite(false);
            refresh && loadClient();
          }}
        />
      </BasicModal>
      <BasicModal
        isVisible={showUpgrade}
        onCancel={() => setShowUpgrade(false)}
        width={600}
      >
        <UpgradeModal
          onClose={(refresh) => {
            setShowUpgrade(false);
            refresh && loadClient();
          }}
          client={client}
        />
      </BasicModal>
    </SideModal>
  );
}
