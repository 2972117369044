import React, { useState } from "react";
import SideModal from "../../../../../Dialogs/SideModal";
import Details from "../Details";
import { Index } from "react-instantsearch";
import CreatorApplicantHit from "../../../../../Admin/Search/CreatorApplicantHit";
import SearchTable from "./SearchTable";

export default function MainTable() {
  const [selected, setSelected] = useState(null);
  const [showDetail, setShowDetail] = useState(false);

  return (
    <div className="flex-1 p-8 overflow-y-auto bg-zinc-50">
      <Index indexName="creator_applicant">
        <SearchTable
          hitItem={({ hit }) => (
            <CreatorApplicantHit
              hit={hit}
              onSelected={({ id }) => {
                setSelected(id);
                setShowDetail(true);
              }}
            />
          )}
        />
      </Index>

      <SideModal
        visible={showDetail}
        onClose={() => {
          setShowDetail(false);
          setSelected(null);
        }}
        wide
      >
        <Details currItemID={selected} />
      </SideModal>
    </div>
  );
}
