import React from 'react';

function BirthdateIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 10.5C3 9.96957 3.21071 9.46086 3.58579 9.08579C3.96086 8.71071 4.46957 8.5 5 8.5H19C19.5304 8.5 20.0391 8.71071 20.4142 9.08579C20.7893 9.46086 21 9.96957 21 10.5V19.5C21 20.0304 20.7893 20.5391 20.4142 20.9142C20.0391 21.2893 19.5304 21.5 19 21.5H5C4.46957 21.5 3.96086 21.2893 3.58579 20.9142C3.21071 20.5391 3 20.0304 3 19.5V10.5Z" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 12.5L5.914 14.831C7.101 15.781 8.814 15.686 9.889 14.611C10.1662 14.3337 10.4953 14.1138 10.8575 13.9637C11.2197 13.8137 11.6079 13.7364 12 13.7364C12.3921 13.7364 12.7803 13.8137 13.1425 13.9637C13.5047 14.1138 13.8338 14.3337 14.111 14.611V14.611C14.6304 15.1303 15.3231 15.4398 16.0565 15.4804C16.7898 15.521 17.5124 15.2898 18.086 14.831L21 12.5" stroke="#141414" stroke-width="1.5" stroke-linecap="round" />
    <path d="M12 7.5C11.4696 7.5 10.9609 7.28929 10.5858 6.91421C10.2107 6.53914 10 6.03043 10 5.5C10 4.624 10.677 3.924 11.273 3.283L12 2.5L12.727 3.283C13.323 3.924 14 4.624 14 5.5C14 6.03043 13.7893 6.53914 13.4142 6.91421C13.0391 7.28929 12.5304 7.5 12 7.5Z" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  );
}

export default BirthdateIcon;