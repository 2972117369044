import React from "react";
import StatusCard from "./StatusCard";
import Refresh from "../Refresh";

export default function StatusFilter({ loading, count, onStatusChange }) {
  const [status, setStatus] = React.useState("");

  const handleChange = (status) => {
    setStatus(status);
    onStatusChange(status);
  };

  return (
    <div className="space-y-4">
      {/* Count */}
      <div className="flex items-center justify-start px-8 space-x-4">
        {/* Refresh */}
        <Refresh loading={loading} onRefresh={() => onStatusChange(status)} />
        {/* Count */}
        <div className="text-sm text-zinc-400">{count} collabs</div>
      </div>
      <div className="grid grid-cols-5 gap-4 px-8">
        <StatusCard
          onSelect={handleChange}
          label="Active"
          value="ACTIVE"
          status={status}
        />
        <StatusCard
          onSelect={handleChange}
          label="Requested"
          value="REQUESTED"
          status={status}
        />
        <StatusCard
          onSelect={handleChange}
          label="Accepted"
          value="ACCEPTED"
          status={status}
        />
        <StatusCard
          onSelect={handleChange}
          label="Rejected"
          value="REJECTED"
          status={status}
        />
        <StatusCard
          onSelect={handleChange}
          label="Completed"
          value="COMPLETED"
          status={status}
        />
      </div>
    </div>
  );
}
