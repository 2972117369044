import React from "react";
import {
  addClient,
  addToGroup,
  createUser,
} from "@thatsclutch/shared/services/UserManagementService";
import { Dropdown, Select } from "antd";
import DropdownButton from "antd/es/dropdown/dropdown-button";
import ShortInput from "../../../../../Basic/Input/ShortInput";
import PrimaryButton from "../../../../../Admin/Buttons/PrimaryButton";
import { updateClient } from "@thatsclutch/shared/services/ClientService";

export default function InviteModal({ onClose, client }) {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleInvite = async () => {
    try {
      // create user
      setLoading(true);
      const created = await createUser({
        email: email.toLowerCase(),
        type: "CLIENT",
      });
      if (created?.error) throw { message: "Failed to create user" };

      const userID = created.result.User.Username;
      await addToGroup({ username: userID, groupname: "clients" });

      const result = await updateClient(client, { userID: userID, email: email.toLowerCase() });

      onClose(true);
    } catch (e) {
      console.log("Error:", e?.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="pt-4 text-2xl font-semibold sm:text-4xl">
        Create and link account
      </div>
      <div className="text-slate-500">
        Adding an email will send a temporary password and give them access to
        the client dashboard.
      </div>

      <ShortInput
        placeholder={"johndoe@example.com"}
        label={"Email for POC"}
        value={email}
        onChange={setEmail}
      />

      <div className="flex justify-end pt-4 space-x-4">
        <button onClick={() => onClose(false)} className="px-6 py-3 text-black">
          Close
        </button>
        <PrimaryButton
          text={"Send invite"}
          loading={loading}
          onPress={() => handleInvite()}
        />
      </div>
    </div>
  );
}
