import React, { useEffect, useState } from "react";
import Table from "./Table";
import { getClients } from "@thatsclutch/shared/services/ClientService";
import Details from "./Details";
import StatusFilter from "./StatusFilter";
import Refresh from "./Refresh";
import PrimaryButton from "../../../Admin/Buttons/PrimaryButton";
import BasicModal from "../../../Dialogs/BasicModal";
import NewClient from "./NewClient";
import dayjs from "dayjs";

export default function ClientList() {
  // client list
  const [clients, setClients] = useState<any>([]);
  const [status, setStatus] = useState("");
  const [nextToken, setNextToken] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showNew, setShowNew] = useState(false);

  useEffect(() => {
    loadClients(status, null);
  }, []);

  // load clients
  const loadClients = async (status, nextToken) => {
    setLoading(true);
    const result = await getClients(status, nextToken);
    //sort by createdAt
    if (result && result.items)
      result?.items.sort((a: any, b: any) => {
        if (!a?.createdAt || !b?.createdAt) return 0;
        return dayjs(b.createdAt).isBefore(dayjs(a.createdAt)) ? -1 : 1;
      });

    nextToken
      ? setClients((prev) =>
          [...prev, ...(result?.items || [])].sort((a: any, b: any) => {
            if (!a?.createdAt || !b?.createdAt) return 0;
            return dayjs(b.createdAt).isBefore(dayjs(a.createdAt)) ? -1 : 1;
          })
        )
      : setClients(result?.items);
    setNextToken(result?.nextToken);
    setLoading(false);
  };

  const handleUpdated = async (updatedItem) => {
    await handleItemUpdate(updatedItem);
  };

  const handleItemUpdate = (updatedItem) => {
    const index = clients
      .map((v) => v.id)
      .findIndex((v) => v == updatedItem.id);
    // check that index is valid
    if (index < 0 && index > clients.length - 1) return alert("row not found");
    // replace item in state array with updated item
    const updatedList = [...clients];
    updatedList[index] = updatedItem;
    setClients(updatedList);
  };

  return (
    <div className="space-y-4">
      {/* TITLE */}
      <div className="flex items-center justify-between p-8 py-4 pt-12">
        <div className="text-2xl font-semibold sm:text-4xl">Clients</div>
        <PrimaryButton
          onPress={() => setShowNew(true)}
          text={"New Client"}
          loading={false}
        />
      </div>
      {/* Count */}
      <div className="flex items-center justify-start px-8 space-x-4">
        {/* Refresh */}
        <Refresh
          loading={loading}
          onRefresh={() => loadClients(status, nextToken)}
        />
        {/* Count */}
        {nextToken ? (
          <div
            onClick={() => loadClients(status, nextToken)}
            className="text-sm text-zinc-400"
          >
            Showing first {clients.length} clients,{" "}
            <span className="cursor-pointer hover:text-gray-500">
              click to load more.
            </span>
          </div>
        ) : (
          <div className="text-sm text-zinc-400">
            Showing {clients.length} client{clients.length > 1 && "s"}
          </div>
        )}
      </div>
      {/* Action bar */}

      <StatusFilter
        onStatusChange={(queryStatus) => {
          loadClients(queryStatus, null);
          console.log(queryStatus);
          setStatus(queryStatus);
        }}
      />

      {/* Table */}
      <Table
        clients={clients}
        nextToken={nextToken}
        loading={loading}
        loadMore={() => loadClients(status, nextToken)}
        onSelect={setSelectedClient}
      />

      {/* Detail View */}
      <Details
        clientID={selectedClient?.id || null}
        onClose={() => setSelectedClient(null)}
        onUpdated={handleUpdated}
      />

      {/* New client */}
      <BasicModal isVisible={showNew} onCancel={() => setShowNew(false)}>
        <NewClient
          onClose={(refresh) => {
            setShowNew(false);
            refresh && loadClients(status, null);
          }}
        />
      </BasicModal>
    </div>
  );
}
