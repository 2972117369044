import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import { getCollabByID } from "@thatsclutch/shared/services/CollabService";
import SideModal from "../../../../Dialogs/SideModal";
import Submission from "./Submission";
import dayjs from "dayjs";
import { getSubmissionsByCollab } from "@thatsclutch/shared/services/SubmissionService";

export default function Details({ collabID, onClose, onUpdated }) {
  const [loading, setLoading] = useState(true);
  const [collab, setCollab] = useState<any>(null);
  const [submissions, setSubmissions] = useState<Array<any>>([]);

  useEffect(() => {
    collabID ? loadCollab() : setCollab(null);
  }, [collabID]);

  const loadCollab = async () => {
    const result = await getCollabByID(collabID);
    arrangeSubs(result?.submissions?.items);
    setCollab(result);
    setLoading(false);
  };

  const arrangeSubs = async (subs) => {
    let combined: Array<Array<any>> = [];

    // if no parent, add as new array
    for (let i = 0; i < subs.length; i++) {
      if (subs[i].parentID === null) {
        combined.push([subs[i]]);
      }
    }
    console.log(combined);
    // if has parent, add to existing array
    for (let i = 0; i < subs.length; i++) {
      if (subs[i].parentID) {
        let index = combined.findIndex((v) => v[0].id === subs[i].parentID);
        if (index !== -1) {
          combined[index].push(subs[i]);
        }
      }
    }
    setSubmissions(combined);
  };

  return (
    <SideModal visible={!!collabID} onClose={onClose}>
      {loading || !collab ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex justify-center py-4">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
          </div>
        </div>
      ) : (
        <div className="p-8 space-y-6">
          {/* Header */}
          <div className="space-y-4">
            {/* Title */}
            <div className="flex items-center justify-between">
              <div className="py-4 space-y-2">
                <div className="text-xl">{collab.name}</div>
                <div className="text-xs capitalize text-zinc-500">
                  {collab.client?.brand} {"><"}{" "}
                  {collab.creator.firstName + " " + collab.creator.lastName}{" "}
                </div>
              </div>
              <div className="px-6 py-3 rounded-full bg-zinc-100 text-zinc-600">
                {collab.status}
              </div>
            </div>
            {/* Details */}
            <div className="flex justify-between p-4 border divide-x rounded-xl">
              <div className="flex-1 text-center">
                {collab.isOngoing ? "Ongoing" : "One-Time"}
              </div>
              <div className="flex-1 text-center line-clamp-1">
                ${Math.floor(collab.price / 100 / collab.deliverableCount)} each
              </div>
              <div className="flex-1 text-center line-clamp-1">
                {collab.deliverableCount} asset(s)
              </div>
            </div>
            {/* Dates */}
            <div className="flex justify-between p-4 border rounded-xl">
              {collab.startDate ? (
                <div className="text-sm text-black">
                  Starts: {dayjs(collab.startDate).format("MMM DD, YYYY")}
                </div>
              ) : (
                <div className="text-sm text-zinc-300">Start date</div>
              )}
              {collab.endDate ? (
                <div className="text-sm text-black">
                  Ends: {dayjs(collab.endDate).format("MMM DD, YYYY")}
                </div>
              ) : (
                <div className="text-sm text-zinc-300">End date</div>
              )}
            </div>
            {/* Notes */}
            <div className="flex justify-between p-4 border rounded-xl">
              <div className="text-sm whitespace-pre-line text-zinc-700">
                {collab.requirements || "No description"}
              </div>
            </div>
          </div>
          {/* Body */}
          <div className="space-y-2">
            <div className="text-sm text-zinc-400">Feed</div>

            <div className="space-y-4">
              {submissions.map((versions) =>
                versions.length > 1 ? (
                  <div className="p-4 space-y-2 border rounded-xl bg-zinc-50">
                    <div className="py-2 text-xs text-zinc-400">Versions</div>
                    {versions
                      .sort((a: any, b: any) =>
                        dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1
                      )
                      .map((submission) => (
                        <Submission
                          submission={submission}
                          onUpdated={loadCollab}
                        />
                      ))}
                  </div>
                ) : (
                  <Submission submission={versions[0]} onUpdated={loadCollab} />
                )
              )}
            </div>
            {/* Empty */}
            {collab.submissions.items.length == 0 && (
              <div className="w-full p-4 text-sm text-center border text-zinc-300 rounded-xl border-zinc-200">
                None yet...
              </div>
            )}
          </div>
        </div>
      )}
    </SideModal>
  );
}
