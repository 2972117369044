import React, { useState } from "react";
import useAuth from "../../../../../Auth/useAuth";
import { createPreviousWork } from "@thatsclutch/shared/services/CreatorService";
import { FiLoader } from "react-icons/fi";

function UploadWorkForm({ creator, type, onClose }) {
  const [loading, setLoading] = useState(false);
  const [currUser] = useAuth();
  const [desc, setDesc] = useState("");
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");

  const getEmbedUrl = async (url) => {
    try {
      let res = await fetch(`https://www.tiktok.com/oembed?url=${url}`);
      const jsonData = await res.json();
      return "https://www.tiktok.com/embed/" + jsonData.embed_product_id;
    } catch (e) {
      return false;
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (type == "vertical_video") {
        const embedUrl = await getEmbedUrl(url);
        if (!embedUrl) return setError("Invalid TikTok URL");
        else setError("");
        const data = {
          owner: currUser?.username,
          description: desc,
          url: embedUrl,
          creatorID: creator.id,
          type: "VERTICAL_VIDEO",
        };
        await createPreviousWork(data);
      } else if (type == "image") {
        const data = {
          owner: currUser?.username,
          description: desc,
          url: url,
          creatorID: creator.id,
          type: "IMAGE",
        };
        await createPreviousWork(data);
        console.log(data);
      }
      onClose();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-10 space-y-6">
      <div className="space-y-6">
        <div>
          <div className="text-3xl font-medium">Add a Tiktok video</div>
          <div className="text-sm">
            Tiktok will be highlighted on your Clutch profile. Make sure you’re
            adding the video’s link.
          </div>
        </div>

        <div className="bg-[#E4FDE8] px-8 py-4 rounded-lg">
          <div className="text-lg font-medium">✨ Show off your best work</div>
          <div className="text-sm">
            Drop a TikTok link to one of your top performing videos, even better
            if it’s focused on a brand / campaign!
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <div>
            <div className="pb-1 text-sm font-medium">
              Link to video (Tiktok only)
            </div>
            <input
              type="text"
              placeholder="ex: https://www.tiktok.com/@user/video/123"
              className="w-full p-4 border rounded-lg"
              onChange={(e) => setUrl(e.target.value)}
            />
            {error && (
              <div className="pt-2 text-sm text-rose-500">
                Please add a valid tiktok video link. Press "Share" then "Copy
                Link"
              </div>
            )}
          </div>
          {/* <div>
            <div className="pb-1 text-sm font-medium">Description</div>
            <textarea
              placeholder="Give a brief overview of what this video was all about and why you are an expert at this style of content!"
              className="border p-4 rounded-lg w-full h-[100px]"
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div> */}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={onSubmit}
          disabled={loading}
          className="flex items-center px-8 py-3 space-x-2 text-sm font-thin text-white bg-black rounded-full"
        >
          {loading && (
            <div className="flex items-center h-6">
              <FiLoader className="w-4 h-4 text-white animate-spin" />
            </div>
          )}
          <div>Confirm</div>
        </button>
      </div>
    </div>
  );
}

export default UploadWorkForm;
