import React, { useEffect } from "react";
import { useNavigate } from "@reach/router";
import useAuth from "../Auth/useAuth";
import { CreatorStateProvider } from "../../contexts/CreatorContext";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const navigate = useNavigate();
  const [currUser, userLoading] = useAuth(true);

  useEffect(() => {
    if (!userLoading && !currUser) {
      console.log("not authorized  -> /signin");
      navigate("/app/signin");
    }
  }, [currUser]);

  return userLoading ? null : currUser ? (
    <CreatorStateProvider>
      <Component {...rest} />
    </CreatorStateProvider>
  ) : null;
};

export default PrivateRoute;
