import {
  getClientApplicant,
  updateClientApplicant,
} from "@thatsclutch/shared/services/ApplicationService";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import PillItem from "../../../../../Admin/Info/PillItem";
import Tooltip from "../../../../../Basic/Tooltip";
import Autosave from "../../../../../Forms/Autosave";
import ConfirmationModal from "../../../../../Dialogs/ConfirmationModal";
import {
  addClient,
  addToGroup,
  createUser,
} from "@thatsclutch/shared/services/UserManagementService";
import EditClientApplicantForm from "../../../../../Forms/EditClientApplicantForm";
import MenuIcon from "../../../../../../assets/vectors/icons/menu";
import CopyIcon from "../../../../../../assets/vectors/icons/copy";
import EmailIcon from "../../../../../../assets/vectors/icons/email";
import PhoneIcon from "../../../../../../assets/vectors/icons/phone";
import { formatPhoneNumber } from "../../../../../../lib/utils/phoneNumber";
import {
  showError,
  useErrorDispatch,
} from "../../../../../../contexts/ErrorContext";
import { showToast } from "../../../../../../contexts/ErrorContext";
import LoadModal from "../../../../../Dialogs/LoadModal";

const STATUS_COLOR = {
  PENDING_REVIEW: "bg-slate-200",
  PENDING_PAYMENT: "bg-yellow-400",
  APPROVED: "bg-green-400",
  INVITED: "bg-purple-400",
  REJECTED: "bg-red-400",
};

const SERVICE_COLOR = {
  "Social Media / TikTok Manager": "bg-[#e5ebff] text-[#2d50e0]",
  "Video Editor": "bg-[#FFF0F7] text-[#FF66B3]",
  "Graphic Design": "bg-[#E5FFEA] text-[#0A8754]",
  "Content Creator": "bg-[#F8F9C7] text-[#A1941E]",
  info: "bg-[#E9F2C1] text-black",
  extra: "bg-[#E6E6E6] text-black",
};

interface ClientInterface {
  id: string;
  _version: Number;
  notes: string;
  status: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  links: string[];
  title: string;
  discovery: string;
  brand: string;
  businessSize: string;
  businessLinks: string[];
  services: string[];
  startDate: string;
  weeklyBudget: string;
  statusDate: string;
  flagged: boolean;
}

export default function Details({ currItemID, onRefresh, onUpdate }) {
  const dispatch = useErrorDispatch();
  const [adminNotes, setAdminNotes] = useState("");
  const [client, setClient] = useState<ClientInterface | null>(null);
  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [inviteStatus, setInviteStatus] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    currItemID ? loadClient(currItemID) : setClient(null);
  }, [currItemID]);

  const handleError = (message) => {
    showError(dispatch, message);
  };

  const loadClient = async (id) => {
    try {
      setSaving(true);
      const result = await getClientApplicant(id);
      setClient(result);
      setAdminNotes(result?.notes || "");
      setSaving(false);
      setUpdating(false);
      setRejecting(false);
    } catch (e) {
      handleError("Error loading applicant");
    }
  };

  const saveClientInfo = async (updatedItem) => {
    try {
      await onUpdate(updatedItem);
      await loadClient(client?.id);
      setSaving(false);
    } catch (e) {
      handleError("Error saving applicant information");
    }
  };

  const saveNote = async (notes) => {
    try {
      setSaving(true);
      const updatedItem = await updateClientApplicant({
        id: client?.id,
        _version: client?._version,
        notes: notes,
      });
      await loadClient(client?.id);
      await onUpdate(updatedItem);
    } catch (e) {
      handleError("Error saving notes");
    } finally {
      setSaving(false);
    }
  };

  const saveNoteFast = async (notes) => {
    try {
      const updatedItem = await updateClientApplicant({
        id: client?.id,
        _version: client?._version,
        notes: notes,
      });
      await onUpdate(updatedItem);
    } catch (e) {
      handleError("Error saving notes");
    }
  };

  const updateStatus = async () => {
    setUpdating(true);
    let updatedItem;
    try {
      if (client?.status == "PENDING_REVIEW") {
        updatedItem = await updateClientApplicant({
          id: client?.id,
          _version: client?._version,
          status: "PENDING_PAYMENT",
          statusDate: dayjs().format("YYYY-MM-DD"),
        });
      } else if (client?.status == "PENDING_PAYMENT") {
        updatedItem = await updateClientApplicant({
          id: client?.id,
          _version: client?._version,
          status: "APPROVED",
          statusDate: dayjs().format("YYYY-MM-DD"),
        });
      } else if (client?.status == "APPROVED") {
        setInviting(true);
        updatedItem = await inviteClient();
        setInviteStatus("");
      }
      await loadClient(client?.id);
      updatedItem && onRefresh(updatedItem);
      showToast(dispatch, "Status updated!");
    } catch (e) {
      handleError("Error updating status");
    } finally {
    }
  };

  const inviteClient = async () => {
    try {
      // create user
      setInviteStatus("Creating new user...");
      const created = await createUser({
        email: client?.email.toLowerCase(),
        type: "CLIENT",
      });
      if (created?.error) throw { message: "Failed to create user" };
      setInviteStatus("Adding permissions...");

      const userID = created.result.User.Username;
      await addToGroup({ username: userID, groupname: "clients" });

      setInviteStatus("Creating profile from applicant...");

      const userData = {
        email: client?.email.toLowerCase(),
        firstName: client?.firstName,
        lastName: client?.lastName,
        phoneNumber: client?.phoneNumber,
        brand: client?.businessName,
        address: { shortAddress: client?.businessAddress },
        businessLinks: client?.businessLinks,
        businessSize: client?.businessSize,
        notes: adminNotes,
        title: client?.title,
        discovery: client?.discovery,
        services: client?.services,
        startDate: client?.startDate,
        budget: client?.weeklyBudget,
        statusDate: client?.statusDate,
        flagged: client?.flagged,
        userID,
        status: "INVITED",
      };

      await addClient(userData);
      setInviteStatus("Update applicant status to invited...");
      showToast(dispatch, "Client invited!");
      return await updateClientApplicant({
        id: client?.id,
        _version: client?._version,
        status: "INVITED",
        statusDate: dayjs().format("YYYY-MM-DD"),
      });
    } catch (e) {
      handleError("Error inviting applicant");
    }
  };

  const handleInviteConfirm = async () => {
    try {
      await updateStatus();
      //   await new Promise((resolve) => setTimeout(resolve, 15000));
      //   showToast(dispatch, "Client invited!");
    } catch (e) {
      handleError("Error inviting applicant");
    }
  };

  const handleRejectConfirm = async () => {
    try {
      await onReject();
      return true;
    } catch (e) {
      handleError("Error rejecting applicant");
      return false;
    }
  };

  const onReject = async () => {
    setRejecting(true);
    try {
      const result = await updateClientApplicant({
        id: client?.id,
        _version: client?._version,
        status: "REJECTED",
        statusDate: dayjs().format("YYYY-MM-DD"),
      });
      loadClient(client?.id);
      onRefresh(result);
       showToast(dispatch, "Client rejected!");
    } catch (e) {
      handleError("Error rejecting applicant");
    }
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return !client ? (
    <div className="flex items-center justify-center h-full">
      <FiLoader className="w-4 h-4 text-black animate-spin" />
    </div>
  ) : (
    <div className="p-8 space-y-8 bg-white">
      {/* Name and title */}
      <div className="flex items-center justify-between">
        <div>
          <div className="text-3xl font-medium capitalize">
            {client?.firstName + " " + client?.lastName}
          </div>
          <div className="capitalize text-neutral-500">{client?.title}</div>
        </div>
        <div className="flex items-center space-x-4">
          {client?.status != "INVITED" && client?.status != "REJECTED" && (
            <div className="flex items-center py-8 space-x-6">
              <div
                onClick={() => setShowRejectModal(true)}
                className="underline cursor-pointer hover:opacity-70 text-neutral-500"
              >
                Reject
              </div>

              <button
                className={`${
                  !updating ? "hover:opacity-90" : "opacity-50"
                } flex space-x-2 items-center bg-[#30DF87] rounded-full py-3 px-4 hover:opacity-50  `}
                onClick={() =>
                  ((client?.status == "PENDING_REVIEW" ||
                    client?.status == "PENDING_PAYMENT") &&
                    updateStatus()) ||
                  (client?.status == "APPROVED" && setShowInviteModal(true))
                }
                disabled={updating}
              >
                {client?.status == "PENDING_REVIEW" && updating && (
                  <div>
                    <FiLoader className="w-4 h-4 text-black animate-spin" />
                  </div>
                )}

                <div>
                  {client?.status == "PENDING_REVIEW"
                    ? "Request Payment"
                    : client?.status == "PENDING_PAYMENT"
                    ? "Approve"
                    : client?.status == "APPROVED"
                    ? "Invite"
                    : ""}
                </div>
              </button>
            </div>
          )}
          <div className="w-10">
            {/* menu */}
            <button className="relative w-10" onBlur={() => setOpenMenu(false)}>
              <div
                className="flex justify-end"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <MenuIcon />
              </div>
              {openMenu && (
                <div
                  className="absolute z-50 px-2 rounded right-1 bg-[#F9FAF5] space-y-3 shadow-lg py-2"
                  onBlur={() => setOpenMenu(false)}
                >
                  <div
                    onClick={() => setShowEditForm(true)}
                    className="px-6 py-4 whitespace-nowrap hover:bg-[#30DF87] hover:rounded"
                  >
                    Edit Information
                  </div>
                </div>
              )}
            </button>
          </div>
        </div>

        <EditClientApplicantForm
          onClose={() => {
            setShowEditForm(!showEditForm);
          }}
          visible={showEditForm}
          selected={client}
          onSaved={saveClientInfo}
        />
      </div>

      {/* status and applied */}
      <div className="space-y-[14px]">
        <div>
          <span className="font-semibold">Status: </span>
          <span
            className={`px-3 py-1 text-sm text-black lowercase rounded-full 
              ${STATUS_COLOR[client?.status] || 0}  
              capitalize`}
          >
            {client?.status == "PENDING_REVIEW"
              ? "Pending Review"
              : client?.status == "PENDING_PAYMENT"
              ? "Pending Payment"
              : client?.status == "APPROVED"
              ? "Approved"
              : client?.status == "INVITED"
              ? "Invited"
              : "Rejected"}
          </span>
        </div>
        <div>
          <span className="font-semibold">Applied: </span>
          {dayjs(client?.createdAt).format("MMM D, YYYY")}
        </div>
      </div>

      {/* Business & address & size */}
      <div>
        <div className="text-sm">
          <div className="text-lg capitalize">{client?.businessName}</div>
          <div className="capitalize">Address: {client?.businessAddress}</div>
          <div>Size: {client?.businessSize}</div>
        </div>
      </div>

      <div>
        <div className="pb-2 font-semibold">Contact</div>
        <div className="flex space-x-4">
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <EmailIcon />

                <a
                  target={"_blank"}
                  href={"mailto:" + client?.email}
                  className="pl-2 hover:text-green-600"
                >
                  {client?.email}
                </a>
              </div>
              <div
                className="cursor-pointer stroke-black hover:stroke-green-600"
                onClick={() => copyToClipboard(client?.email)}
              >
                <CopyIcon />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <PhoneIcon />
                <a
                  href={`sms://+1${client?.phoneNumber?.replace(
                    /\D/g,
                    ""
                  )};?&body=Message%20from%20Clutch.%0A`}
                  target={"_blank"}
                  className="pl-2 hover:text-green-600"
                >
                  {formatPhoneNumber(client?.phoneNumber)}
                </a>
              </div>
              <div
                className="cursor-pointer stroke-black hover:stroke-green-600"
                onClick={() => copyToClipboard(client?.phoneNumber)}
              >
                <CopyIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Links */}
      <div>
        <div className="pb-2 font-semibold">Links</div>
        <div className="bg-[#E7EEEB] px-2 py-4 space-y-2 rounded-lg">
          {client?.businessLinks &&
            client.businessLinks.map((link, i) => (
              <div className="hover:bg-[#0A8754] hover:duration-300 group px-2 py-[6px] cursor-pointer rounded-lg flex items-center justify-between flex-nowrap">
                <div className="truncate max-w-[200px]">
                  <a
                    target="_blank"
                    href={link?.includes("http") ? link : "https://" + link}
                    className="w-full h-full overflow-hidden whitespace-nowrap text-ellipsis text-inherit group-hover:text-white"
                  >
                    {link?.includes("http") ? link : "https://" + link}
                  </a>
                </div>
                {/* add group hover!  */}
                <div
                  className="cursor-pointer stroke-black group-hover:stroke-white"
                  onClick={() =>
                    copyToClipboard(
                      link?.includes("http") ? link : "https://" + link
                    )
                  }
                >
                  <CopyIcon />
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Service */}
      <div>
        <div className="pb-2 font-semibold">Services requested</div>
        <div className="flex w-full space-x-4 overflow-x-auto">
          {client?.services &&
            client.services.map((service, i) => (
              <div className="whitespace-nowrap">
                <PillItem
                  text={service}
                  style={SERVICE_COLOR[service] || "bg-gray-200 text-gray-600"}
                />
              </div>
            ))}
        </div>
      </div>
      {/* Discovery */}
      <div>
        <div className="pb-2 font-semibold">Clutch discovery</div>
        <div>{client?.discovery}</div>
      </div>

      <Autosave
        adminNotes={client?.notes}
        saveNote={saveNote}
        saveNoteFast={saveNoteFast}
        isSaving={saving}
        profileID={currItemID}
      />

      {/* <ConfirmationModal
        closable={true}
        onCancel={setShowInviteModal}
        isVisible={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        confirmText={inviting ? "Inviting..." : "Yes, invite"}
        title={`You're about to send ${client?.firstName +
          " " +
          client?.lastName} an email to join Clutch as a Client.`}
        subtitle="They'll be sent a welcome email with their temporary login credentials."
        onConfirm={handleInviteConfirm}
        successText={`${client?.firstName +
          " " +
          client?.lastName} has been invited to join Clutch.`}
        successSubtext="You can now find them in the Clients tab."
      /> */}
      <LoadModal
        closable={true}
        isVisible={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        title={`Invite ${client?.businessName} to join Clutch`}
        subtitle="They'll be sent a welcome email and their temporary login credentials"
        onConfirm={handleInviteConfirm}
        confirmText={"Yes, invite"}
      />

      <ConfirmationModal
        closable={true}
        onCancel={setShowRejectModal}
        isVisible={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        confirmText={rejecting ? "Rejecting..." : "Yes, reject"}
        title={`Are you sure you want to reject ${client?.firstName +
          " " +
          client?.lastName}?`}
        subtitle="They'll be sent a rejection email"
        onConfirm={handleRejectConfirm}
        successText={`${client?.firstName +
          " " +
          client?.lastName} has been rejected.`}
      />
    </div>
  );
}
