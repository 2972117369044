import React, { useState } from "react";
import { Configure, Hits, useSearchBox } from "react-instantsearch";
import ClientHit from "../../../../../Admin/Search/ClientHit";
import { getBriefsByClientID } from "@thatsclutch/shared/services/BriefService";
import DropDown from "../../../../../Admin/Selectors/Dropdown";
import { FiChevronLeft, FiLoader } from "react-icons/fi";
import { AmpAPI } from "@thatsclutch/shared";
import { updateCreatorApplicant } from "@thatsclutch/shared/services/ApplicationService";

export default function AddFirstDemo({ creator, onClose }) {
  const { query, refine: setQuery } = useSearchBox();
  const [briefs, setBriefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [clientSelected, setClientSelected] = useState<any>(null);
  const [briefSelected, setBriefSelected] = useState<any>(null);
  const [editNote, setEditNote] = useState<string>("");
  const [editPrice, setEditPrice] = useState<string>("");
  const [showEdit, setShowEdit] = useState<any>(null);

  const transformItems = (items) => {
    return items.map((item) => ({
      ...item,
      name:
        item?.firstName?.toLowerCase() + " " + item?.lastName?.toLowerCase(),
    }));
  };

  const loadClientBriefs = async (clientHit) => {
    setLoading(true);
    const data = await getBriefsByClientID(clientHit.id);
    setLoading(false);
    setBriefs(data);
  };

  const getUrl = (url) => {
    if (url && url.includes("http")) {
      return url;
    } else {
      return "https://" + url;
    }
  };

  const requestDemoCollab = async () => {
    setDemoLoading(true);
    try {
      const data = await AmpAPI.post("CampusAPI", "/collab/demo", {
        body: {
          creatorID: creator.creatorID,
          clientID: clientSelected.id,
          name: briefSelected.serviceName,
          price: parseInt(editPrice) || parseInt(briefSelected.weeklyBudget), // POPF
          requirements:
            (editNote && editNote.trim()) || briefSelected.details.trim(),
        },
      });
      console.log(data);
      const data2 = await updateCreatorApplicant({
        id: creator?.id,
        _version: creator?._version,
        needsDemo: false,
      });
      console.log(data2);
      onClose(data2);
    } catch (e) {
      console.log(e);
    } finally {
      setDemoLoading(false);
    }
  };

  const DemoDetail = ({ title, body, isLink = false }) => (
    <div className="w-full space-y-2 text-base">
      <div className="text-sm text-gray-700">{title}</div>
      {!isLink ? (
        <div className="overflow-x-hidden overflow-y-auto whitespace-pre max-h-28">
          <div className="whitespace-pre-wrap">{body}</div>
        </div>
      ) : (
        <a
          href={getUrl(body)}
          target="_blank"
          className="underline hover:text-green-400"
        >
          {body}
        </a>
      )}
    </div>
  );

  return (
    <div className="p-4 space-y-8">
      <div className="space-y-4">
        {/* Title */}
        <div className="text-3xl font-bold capitalize">
          Assign {creator?.firstName}'s First Demo
        </div>
        {/* SubTitle */}
        <div className="text-gray-700">
          For who is this demo and which brief template do you want to use? You
          can edit the template notes in the next stage
        </div>
      </div>

      {/* Client Select */}
      <div className="space-y-2">
        <div>Client</div>
        <div className="relative">
          <Configure hitsPerPage={1} />
          {query && !clientSelected && (
            <div className="absolute top-0 max-h-[400px] left-0 z-50 w-full overflow-y-scroll bg-white divide-y rounded-md shadow-xl translate-y-14">
              <Hits
                transformItems={transformItems}
                hitComponent={({ hit }) => (
                  <ClientHit
                    hit={hit}
                    onSelected={(e) => {
                      setQuery(hit?.brand);
                      loadClientBriefs(hit);
                      setClientSelected(hit);
                      setBriefSelected(null);
                    }}
                  />
                )}
                classNames={{
                  item: " hover:bg-gray-50 p-0",
                  emptyRoot: "p-4",
                  list: "p-0",
                }}
              />
            </div>
          )}

          <input
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setClientSelected(false);
            }}
            placeholder="Search for a client"
            className="w-full px-4 py-3 border border-gray-100 rounded-md outline-none focus:border-green-500"
          />
        </div>
      </div>

      {/* Brief Select */}
      <div className="space-y-2">
        <div className="flex items-center justify-between ">
          <div className="flex items-center space-x-4">
            <div>Brief Template</div>
            {loading && (
              <div className="flex items-center h-4">
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              </div>
            )}
          </div>
        </div>

        {briefSelected ? (
          <div className="p-6 space-y-4 bg-white border border-gray-100 rounded-md">
            <div className="flex">
              <DemoDetail title="Demo Title" body={briefSelected.serviceName} />
              <DemoDetail title="Due Date" body={"1 week after accepting"} />
            </div>
            <div className="flex">
              {showEdit ? (
                <>
                  <div className="w-full space-y-2 text-base">
                    <div className="text-sm text-gray-700">Demo Price</div>
                    <div className="flex items-center space-x-2">
						<div>$</div>
					<input
                      value={editPrice}
                      onChange={(e) => {
                        setEditPrice(e.target.value);
                      }}
                      placeholder="Weekly payout"
                      className="px-4 py-3 overflow-x-hidden whitespace-pre-wrap border border-gray-100 rounded-md outline-none resize focus:border-green-500"
                    /></div>
                  </div>
                </>
              ) : (
                <DemoDetail
                  title="Demo Price"
                  body={"$" + (editPrice || briefSelected.weeklyBudget)}
                />
              )}
              <DemoDetail
                title="Link to primary social"
                body={"tiktok.com"}
                isLink
              />
            </div>
            {showEdit ? (
              <>
                <div className="w-full space-y-2 text-base">
                  <div className="text-sm text-gray-700">
                    Demo requirements/notes
                  </div>
                  <textarea
                    value={editNote}
                    onChange={(e) => {
                      setEditNote(e.target.value);
                    }}
                    placeholder="Requirements or notes for creator about their collab..."
                    className="w-full px-4 py-3 overflow-x-hidden whitespace-pre-wrap border border-gray-100 rounded-md outline-none resize h-28 focus:border-green-500"
                  />
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => {
                      setEditNote("");
                      setEditPrice("");
                      setShowEdit(false);
                    }}
                    className="flex px-4 py-3 space-x-2 text-center bg-gray-100 border border-gray-100 rounded-md outline-none hover:bg-gray-200 active:bg-gray-100 "
                  >
                    <FiChevronLeft className="w-4 h-4 text-black" />
                    <div>Cancel</div>
                  </button>
                  <button
                    onClick={() => {
                      setShowEdit(false);
                    }}
                    className="w-full px-4 py-3 text-center text-white bg-black rounded-md outline-none hover:bg-gray-800 active:bg-gray-900"
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <div className="space-y-8">
                <DemoDetail
                  title="Demo requirements/notes"
                  body={editNote || briefSelected.details}
                />

                <div className="flex pt-4 space-x-4 border-t border-gray-200 ">
                  <button
                    onClick={() => {
                      setBriefSelected(null);
                    }}
                    className="flex px-4 py-3 space-x-2 text-center bg-gray-100 border border-gray-100 rounded-md outline-none hover:bg-gray-200 active:bg-gray-100 "
                  >
                    <FiChevronLeft className="w-4 h-4 text-black" />
                    <div className="whitespace-nowrap">Switch Template</div>
                  </button>
                  <button
                    onClick={() => {
                      setEditPrice(editPrice || briefSelected.weeklyBudget);
                      setEditNote(editNote || briefSelected.details);
                      setShowEdit(true);
                    }}
                    className="w-full px-4 py-3 text-center bg-gray-100 border border-gray-100 rounded-md outline-none hover:bg-gray-200 active:bg-gray-100 "
                  >
                    Edit Details
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <DropDown
            defaultValue={"Select collab proposal"}
            items={briefs || []}
            titleProp="serviceName"
            onSelect={(v) => {
              setBriefSelected(v);
              setEditPrice("");
              setEditNote("");
            }}
            selected={briefSelected}
            disabled={loading || !briefs || briefs.length === 0}
          />
        )}
      </div>

      {/* Brief Select */}
      <button
        className={`w-full space-x-4 flex justify-center items-center py-4 text-center  rounded-full  ${
          briefSelected && clientSelected && !showEdit
            ? "bg-green-400 hover:opacity-90 text-black"
            : "bg-gray-400 text-white "
        } `}
        disabled={!briefSelected || !clientSelected || showEdit || demoLoading}
        onClick={() => {
          requestDemoCollab();
        }}
      >
        <div>Send Demo Collab</div>
        {demoLoading && (
          <div className="flex items-center h-4">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
          </div>
        )}
      </button>
    </div>
  );
}
