import { getCollabsByClientID } from "@thatsclutch/shared/services/ClientService";
import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import CollabTabRow from "./CollabTabRow";
import dayjs from "dayjs";

export default function CollabsTab({ clientID }) {
  const [collabs, setCollabs] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clientID && loadCollabs();
  }, []);

  const loadCollabs = async () => {
    setLoading(true);
    const results = await getCollabsByClientID(clientID);
	console.log(results)
    setCollabs(results);
    setLoading(false);
  };

  const sortByCreated = (a, b) => {
    if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return 1;
    if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) return -1;
    else return 0;
  };

  return (
    <div className="py-6 space-y-4">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex justify-center py-4">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
          </div>
        </div>
      ) : collabs.length == 0 ? (
        <div className="flex justify-center py-4 rounded-lg bg-slate-50">
          None yet
        </div>
      ) : (
        collabs
          .sort(sortByCreated)
          .map((item) => <CollabTabRow collab={item} />)
      )}
    </div>
  );
}
