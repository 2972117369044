import React, { useState } from "react";
import { FiMail, FiMapPin, FiPhone, FiStar, FiUsers } from "react-icons/fi";
import { FaGlobe, FaInstagram, FaTiktok } from "react-icons/fa";
import InfoRow from "../InfoRow";
import Autosave from "../../../../../Forms/Autosave";
import { updateClient } from "@thatsclutch/shared/services/ClientService";
import CopyIcon from "../../../../../../assets/vectors/icons/copy";
import dayjs from "dayjs";

const STATUS = {
  INVITED: { color: "#E4FDE8", copy: "Invite sent", textColor: "black" },
  PENDING: { color: "#E4FDE8", copy: "Setting up", textColor: "black" },
  ACTIVE: { color: "#E4FDE8", copy: "Active", textColor: "black" },
  EMPTY: { color: "#eeeeee", copy: "None", textColor: "black" },
  ENTERPRISE: { color: "#eeeeee", copy: "Enterprise", textColor: "black" },
  TRIAL: { color: "#fdba74", copy: "Trial", textColor: "black" },
};

const MATCH_STATUS = {
  NEEDS_MATCHES: {
    color: "#149AE5",
    copy: "Needs Recommendations",
    textColor: "black",
  },
  MATCHES_SENT: {
    color: "#333333",
    copy: "Recommendations Sent",
    textColor: "white",
  },
};

export default function General({ clientID, client, onUpdated, onUpdateFast }) {
  const [saving, setSaving] = useState(false);

  const handleSaveNote = async (note) => {
    setSaving(true);
    const result = await updateClient(client, {
      notes: note,
    });
    await onUpdated(result?.updateClient);
    setSaving(false);
  };
  const handleSaveNoteFast = async (note) => {
    const result = await updateClient(client, {
      notes: note,
    });
    await onUpdateFast(result?.updateClient);
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <div className="pt-8">
      {/* Incomplete Payment Method Alert */}
      {!client.stripeID && (
        <div className="bg-[#E7EEEB] text-medium mb-6 px-6 py-3 rounded-md">
          Client hasn't set up their payment method
        </div>
      )}

      {/* Profile */}
      <div className="">
        {/* Status row */}
        <InfoRow
          label={"Status"}
          text={
            <div
              className={`px-3 py-1 text-sm text-${
                STATUS[client?.status || "EMPTY"].textColor
              } lowercase rounded-full 
		  		bg-[${STATUS[client?.status || "EMPTY"].color}]
	  			first-letter:capitalize`}
            >
              {STATUS[client?.status || "EMPTY"].copy}
            </div>
          }
          rightLabel="Matching"
          rightText={
            <div
              className={`px-3 py-1 text-sm text-white lowercase rounded-full 
		  		bg-[#149AE5]
	  			first-letter:capitalize`}
            >
              Matching
            </div>
          }
        />
        {/* Dates Row */}
        <InfoRow
          label={"Joined On"}
          text={dayjs(client?.createdAt).format("MMM. DD, YYYY")}
          rightLabel="Last Matched On"
          rightText={"N/A"}
        />
      </div>

      {/* Contact */}
      <div>
        <div className="pt-6 text-xl font-medium">Contact</div>
        <InfoRow
          label={""}
          text={
            <a
              target={"_blank"}
              href={"mailto:" + client.email}
              className="underline"
            >
              {client.email || "N/A"}
            </a>
          }
          copy={true}
          value={client.email}
          icon={<FiMail className="w-4 h-4 " />}
          rightText={client.phoneNumber}
          rightCopy={true}
          rightValue={client.phoneNumber}
          rightIcon={<FiPhone className="w-4 h-4 " />}
        />
      </div>

      {/* Links */}
      <div>
        <div className="pt-6 text-xl font-medium">Links</div>
        <div className="flex items-center pt-4 space-x-6">
          {client?.businessLinks?.map((link) => (
            <a
              target="_blank"
              href={link?.includes("http") ? link : "https://" + link}
              className="bg-[#E7EEEB] px-6 py-3 rounded-md hover:bg-[#0A8754] w-full space-x-4 group   transition-all  cursor-pointer flex items-center justify-between flex-nowrap"
            >
              <div className="truncate max-w-[200px]">
                <div className="overflow-hidden transition-all whitespace-nowrap text-ellipsis text-inherit group-hover:text-white">
                  {link?.includes("http") ? link : "https://" + link}
                </div>
              </div>
              <div
                className="transition-all cursor-pointer stroke-black group-hover:stroke-white"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(
                    link?.includes("http") ? link : "https://" + link
                  );
                }}
              >
                <CopyIcon />
              </div>
            </a>
          ))}
        </div>
      </div>
      {/* Notes */}
      <div className="pt-6 text-xl font-medium">Notes</div>
      <div className="pt-6">
        <Autosave
          adminNotes={client.notes}
          saveNote={handleSaveNote}
          saveNoteFast={handleSaveNoteFast}
          isSaving={saving}
          profileID={clientID}
        />
      </div>
      {/* About */}
      <div className="pt-6 text-xl font-medium">About</div>
      <InfoRow
        label={"Industry"}
        text={
          (client.industry &&
            client.industry.length > 0 &&
            client.industry[0]) ||
          "None"
        }
        icon={<FiStar className="w-4 h-4 " />}
        rightLabel={"No. of employess"}
        rightText={client.businessSize}
        rightIcon={<FiUsers className="w-4 h-4 " />}
      />
      <InfoRow
        label={"Location"}
        text={client.address?.shortAddress || "None"}
        icon={<FiMapPin className="w-4 h-4 " />}
      />
    </div>
  );
}
