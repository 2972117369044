export const googleAutocomplete = async (inputRef, callback) =>
  new Promise((resolve, reject) => {
    console.log('attempting google autocomplete...');

    if (typeof window === 'undefined') {
      console.log('undefined window');
      return reject('Need valid window object');
    }

    try {
      let autocomplete = new window.google.maps.places.Autocomplete(inputRef, {
        componentRestrictions: { country: ['us'] },
        fields: ['formatted_address', 'address_components', 'geometry'],
        types: ['address']
      });
      new window.google.maps.event.addListener(
        autocomplete,
        'place_changed',
        function() {
          var place = autocomplete.getPlace();
          callback(place);
        }
      );
      resolve(autocomplete);
    } catch (e) {
      reject(e);
    }
  });

export const formatAddress = data => {
  const formatted_address = data.formatted_address;
  const location = data.geometry.location;
  const geo_location = { lat: location.lat(), lng: location.lng() };
  const addressComponent = data.address_components;
  let street1 = '',
    postalCode = '',
    locality = '',
    country = '',
    state = '';
  for (let component of addressComponent) {
    const type = component.types[0];
    switch (type) {
      case 'street_number': {
        street1 = `${component.long_name} ${street1}`;
        break;
      }
      case 'route': {
        street1 += component.short_name;
        break;
      }
      case 'postal_code': {
        postalCode = `${component.long_name}${postalCode}`;
        break;
      }
      case 'locality':
        locality = component.long_name;
        break;
      case 'administrative_area_level_1': {
        state = component.short_name;
        break;
      }
      case 'country':
        country = component.long_name;
        break;
    }
  }
  return {
    geo_location,
    formatted_address,
    address: { street1, locality, state, postalCode, country }
  };
};
