import React from 'react';

export function AuthForm({children, title, error}) {
  return (
    <div className="container-login100">
        <form className="card-body auth-forms100">
          <h2>{title}</h2>
          {error && (
            <p className="text-danger">
              {error.message ? error.message : error}
            </p>
          )}
          {children}
        </form>
    </div>
  );
}

export function Email({handleUpdate, email, autoComplete}) {
  return (
    <div className="form-group">
      <label htmlFor="phone">Phone Number</label>
      <input
        onChange={handleUpdate}
        name="email"
        type="tel"
        value={email}
        className="form-control"
        // autoComplete={autoComplete}
        id="enterEmailAddress"
        // aria-describedby="phone"
        placeholder="+1234567890"
      />
    </div>
  );
}

export function PhoneNumber({handleUpdate, tel, autoComplete}) {
  return (
    <div className="form-group">
      <label htmlFor="enterEmailAddress">Phone number</label>
      <input
        onChange={handleUpdate}
        name="tel"
        type="tel"
        value={tel}
        className="form-control"
        autoComplete={autoComplete}
        id="enterPhoneNumber"
        aria-describedby="emailHelp"
        placeholder="Enter phone number"
      />
    </div>
  );
}

export function Password({handleUpdate, password, autoComplete}) {
  return (
    <div className="form-group">
      <label htmlFor="enterPassword">Password</label>
      <input
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name="password"
        value={password}
        type="password"
        className="form-control"
        placeholder="Password"
        id="enterPassword"
      />
    </div>
  );
}

export function ConfirmationCode({handleUpdate, auth_code, autoComplete}) {
  return (
    <div className="form-group">
      <label htmlFor="enterCode">Confirmation Code</label>
      <input
        onChange={handleUpdate}
        autoComplete={autoComplete}
        name="auth_code"
        value={auth_code}
        type="text"
        className="form-control"
        placeholder="######"
        id="enterCode"
      />
    </div>
  );
}
