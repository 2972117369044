import React from "react";

const ErrorStateContext = React.createContext(undefined);
const ErrorDispatchContext = React.createContext(undefined);

const initialState = {
  showErrorToast: false,
  showToast: false,
  message: "",
};

const displayErrorToast = async (dispatch) => {
  dispatch({ type: "showToastError", payload: true });
};

const showError = (dispatch, message) => {
  dispatch({ type: "showToastError", payload: message });
  setTimeout(() => dispatch({ type: "clear", payload: null }), 5000);
};
const showToast = (dispatch, message) => {
  dispatch({ type: "showToast", payload: message });
  setTimeout(() => dispatch({ type: "clear", payload: null }), 5000);
};

const closeToast = (dispatch) => {
  dispatch({ type: "clear", payload: null });
};

function userReducer(state, action) {
  switch (action.type) {
    case "showToastError": {
      return { showErrorToast: true, message: action.payload };
    }
    case "showToast": {
      return { showToast: true, message: action.payload };
    }
    case "clear": {
      return { showToast: false, showErrorToast: false, message: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ErrorProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  return (
    <ErrorStateContext.Provider value={state}>
      <ErrorDispatchContext.Provider value={dispatch}>
        {children}
        {state.showErrorToast && (
          <div
            className="absolute flex items-center justify-between px-6 py-4 space-x-6 rounded-lg cursor-pointer right-4 top-4 bg-inherit "
            style={{ background: "#EC2E2E", color: "white", zIndex: 1500 }}
            onClick={() => closeToast(dispatch)}
          >
            <div>{state.message ? state.message : "Something went wrong."}</div>
            <div className="px-6 py-2 border border-white rounded-full shadow-card">
              Close
            </div>
          </div>
        )}
        {state.showToast && (
          <div
            className="absolute flex items-center justify-between px-6 py-4 space-x-6 text-white bg-black rounded-lg cursor-pointer right-4 top-4 bg-inherit "
            style={{ zIndex: 1500 }}
            onClick={() => closeToast(dispatch)}
          >
            <div>{state.message ? state.message : "Something went wrong."}</div>
            <div className="px-6 py-2 text-white border border-white rounded-full shadow-card">
              Close
            </div>
          </div>
        )}
      </ErrorDispatchContext.Provider>
    </ErrorStateContext.Provider>
  );
}

function useErrorState() {
  const context = React.useContext(ErrorStateContext);
  if (context === undefined) {
    throw new Error("useErrorContext must be used within a ErrorStateProvider");
  }
  return context;
}

function useErrorDispatch() {
  const context = React.useContext(ErrorDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useErrorDispatch must be used within a ErrorDispatchContext"
    );
  }
  return context;
}

export {
  ErrorProvider,
  useErrorState,
  useErrorDispatch,
  displayErrorToast,
  showError,
  showToast,
};
