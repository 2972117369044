import React, { useState } from "react";
import ShortInput from "../../../../../../Basic/Input/ShortInput";
import PrimaryButton from "../../../../../../Basic/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../../Basic/Buttons/SecondaryButton";

export default function EditPricePage({ currPrice, onNext, onBack }) {
  const [price, setPrice] = useState(currPrice / 100 + "" || "");
  const [errPrice, setErrPrice] = useState(null);

  const onSubmit = () => {
    const formattedPrice = Number(price) && parseInt(price);
    if (!formattedPrice)
      return setErrPrice("Please enter a valid number (ex: 45)");
    if (formattedPrice < 10 || formattedPrice > 9999)
      return setErrPrice("Please enter a valid price. ($10 - $9,999)");
    onNext(formattedPrice * 100);
  };

  return (
    <div className="p-8 space-y-8">
      <div>
        <div className="text-2xl font-semibold">Edit a weekly service</div>
        <div className="mt-2 text-sm text-slate-500">
          Enter the price per deliverable
        </div>
      </div>

      <div className="p-4 px-6 rounded-xl bg-yellow-400/30">
        <div>
          The total weekly service price will depend on how many deliverables a
          client requests.
        </div>
      </div>

      <ShortInput
        value={price}
        label={"Price"}
        prefix={"$"}
        error={errPrice}
        onChange={setPrice}
        placeholder="ex: 45"
      />
      <div className="flex justify-end space-x-4">
        <SecondaryButton text={"Back"} onPress={onBack} />
        <PrimaryButton text={"Next"} onPress={onSubmit} />
      </div>
    </div>
  );
}
