import React, { useState } from "react";
import ClientApplicant from "./ClientApplicants";
import CreatorApplicant from "./CreatorApplicants";
import { InstantSearch } from "react-instantsearch";
import algoliasearch from "algoliasearch";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_API_KEY as string
);

export default function Applicant() {
  const [page, setPage] = useState("creators");

  const Tab = ({ title }) => (
    <div
      className={`${page == title.toLowerCase() &&
        "text-[#0A8754] font-[600]"} text-lg cursor-pointer p-2`}
      onClick={() => setPage(title.toLowerCase())}
    >
      {title}
    </div>
  );

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex items-baseline px-6 py-12 space-x-8">
        <div className="text-2xl font-semibold sm:text-4xl">Applicants</div>
        <div className="flex space-x-4">
          <Tab title="Creators" />
          <Tab title="Clients" />
        </div>
      </div>
      {/* Main tables */}
      {page == "clients" ? (
        <InstantSearch
          searchClient={searchClient}
          indexName="creator_applicant"
        >
          <ClientApplicant />
        </InstantSearch>
      ) : (
        <InstantSearch
          searchClient={searchClient}
          indexName="creator_applicant"
        >
          <CreatorApplicant />
        </InstantSearch>
      )}
    </div>
  );
}
