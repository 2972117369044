import { AmpAPI } from "@thatsclutch/shared/services/AmplifyService";
import React, { useEffect } from "react";

export default function StripeCheck({ creator }: { creator: any }) {
  const [isEnabled, setIsEnabled] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    creator && creator.stripeConnectedID && checkStripe();
  }, []);

  async function checkStripe() {
    try {
      setLoading(true);
      const data = await AmpAPI.post(
        "CampusAPI",
        "/payments/connected-account/check",
        {
          body: {
            connectedAccountId: creator.stripeConnectedID,
          },
        }
      );
      setIsEnabled(data.payoutsEnabled && data.paymentsEnabled);
    } catch (e) {
      console.log(e);	
    } finally {
      setLoading(false);
    }
  }

  return loading ? (
    <></>
  ) : isEnabled ? null : (
    <div className="p-6 mx-8 space-y-4 overflow-hidden bg-white border rounded-lg text-rose-400">
      Payout method is incomplete
    </div>
  );
}
