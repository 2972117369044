import React, { useState, useEffect } from 'react';
import { Layout, Menu, message, PageHeader, Button } from 'antd';
import { navigate } from '@reach/router';
import { EditAccount } from './EditAccount';
import { Auth } from 'aws-amplify';
import { AppUser } from '../../Auth';
import UpdatePassword from './UpdatePassword';
import SupportRequest from './SupportRequest';
import HelpScreen from './HelpScreen';
import Terms from './Terms';
import Policy from './Policy';
import { useAppStore } from '../../../stores/app';
import { useUniversityStore } from '../../../stores/university';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useBusinessStore } from '../../../stores/business';
import { SettingsLayout } from '../../Layout/AppLayouts';
import useAuth from '../../Auth/useAuth';
import Helmet from 'react-helmet';
const { Sider, Content } = Layout;

const SettingsNav = () => {
  const [appState, appActions] = useAppStore();
  const [universityState, universityActions] = useUniversityStore();
  const [businessState, businessActions] = useBusinessStore();
  const [selected, setSelected] = useState('edit');
  const [isMobile, setIsMobile] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [currUser, userLoading, logoutUser] = useAuth();

  useEffect(() => {
    if (appState.group?.isDirector || appState.group?.isProvider) {
      checkStripe();
    }
  }, [appState]);

  const checkStripe = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (!user.attributes['custom:stripe_connected_id']) {
      setStripeError(true);
    }
  };

  const onSelect = ({ item, key, keyPath, domEvent }) => {
    if (key === 'logout') {
      logoutUser();
      universityActions.setUniversity(null);
      appActions.setUserGroup([]);
      businessActions.setBusiness(null);
      return;
    } else if (key === 'stripe') {
      const hide = message.loading('Redirecting to Stripe...', 0);
      // Dismiss manually and asynchronously
      setTimeout(() => {
        hide();
        window.open('https://connect.stripe.com/express_login');
      }, 2500);
    }
    setSelected(key);
  };

  return (
    <SettingsLayout>
      <Helmet>
        <script
          async
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC32slxGtvobIOSv3WTQhOQVpstWCOYm2E&libraries=places"
        ></script>
      </Helmet>
      {stripeError && (
        <div className="flex items-center justify-between p-3 bg-red-200">
          <div className="flex items-center p-3">
            <InfoCircleOutlined className="red-600 " style={{ color: 'red' }} />
            <p className="p-0 px-3 m-0 text-red-600">
              Warning! You did not complete stripe set up. Fund transfer will
              not work properly.
            </p>
          </div>
          <Button type="primary" onClick={() => navigate('/app/signup#stripe')}>
            Complete payment set up
          </Button>
        </div>
      )}
      <Layout className="h-full" hasSider={true}>
        <Sider
          theme="light"
          breakpoint="lg"
          collapsedWidth={0}
          zeroWidthTriggerStyle={{ top: 0 }}
          onBreakpoint={broken => {
            setIsMobile(broken);
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <Menu
            onClick={onSelect}
            mode={'vertical'}
            theme="light"
            className="h-full"
          >
            <Menu.Item key="edit">Edit Account</Menu.Item>
            <Menu.Item key="password">Change Password</Menu.Item>
            <Menu.Item key="stripe">Stripe Dashboard</Menu.Item>
            <Menu.Item key="support">Support</Menu.Item>
            <Menu.Item key="help">Help</Menu.Item>
            <Menu.Item key="terms">Terms & Conditions</Menu.Item>
            <Menu.Item key="policy">Privacy Policy</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" danger>
              Log Out
            </Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <div className="relative h-full bg-white">
            {selected === 'edit' ? (
              <EditAccount />
            ) : selected === 'password' ? (
              <div>
                <UpdatePassword />
              </div>
            ) : selected === 'support' ? (
              <SupportRequest />
            ) : selected === 'help' ? (
              <HelpScreen />
            ) : selected === 'terms' ? (
              <div className="h-full overflow-y-scroll">
                <Terms />
              </div>
            ) : selected === 'policy' ? (
              <div className="h-full overflow-y-scroll">
                <Policy />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </Content>
      </Layout>
    </SettingsLayout>
  );
};

export default SettingsNav;
