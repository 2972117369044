import React, { useEffect, useState } from "react";
import { message } from "antd";
import {
  deleteProfileVideo,
  getProfileVideo,
  updateCreatorVideo,
} from "@thatsclutch/shared/services/UserService";
import { Transition } from "@headlessui/react";
import { FiLoader } from "react-icons/fi";
import SimpleModal from "../../../../../Dialogs/SimpleModal";

export default function UploadVideo({
  open,
  videoUrl: currVideoUrl,
  onClose,
  onSubmit,
  creator,
}) {
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(currVideoUrl);

  useEffect(() => {
    if (!currVideoUrl) {
      loadIntroVideo();
    }
  }, [currVideoUrl]);

  const loadIntroVideo = async () => {
    const src = await getProfileVideo(creator);
    setVideoUrl(src);
  };

  const handleUpload = async (currFile) => {
    try {
      if (currFile === null) return;
      setLoading(true);

      const isLt50M = currFile?.size / 1024 / 1024 < 900;
      const isMp4OrMov =
        currFile.type === "video/mp4" || currFile.type === "video/quicktime";

      if (!isMp4OrMov) {
        setLoading(false);
        message.error("You can only upload MP4/QuickTime video file!");
        return;
      } else if (!isLt50M) {
        setLoading(false);
        message.error("Video must be smaller than 900MB!");
        return;
      } else {
        await updateCreatorVideo(currFile, creator).then((data) => {
          getBase64(currFile, (videoUrl) => {
            setVideoUrl(videoUrl);
            setLoading(false);
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // getBase allows you to preview image
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const deleteVideo = async () => {
    setLoading(true);
    await deleteProfileVideo(creator);
    await onSubmit();
    setVideoUrl(null);
    setLoading(false);
  };

  return (
    <SimpleModal show={open} onClose={onClose}>
      <Transition.Root show={true}>
        <button onClick={onClose} className="absolute top-8 right-8">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.25 6.75L6.75 17.25"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.75 6.75L17.25 17.25"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="p-8 space-y-8">
          <div className="pr-8">
            <div className="text-3xl font-medium">Upload video</div>
            <div className="mt-2 text-sm text-slate-500">
              Make a good first impression! Show who you are as a creator and
              what you can do to elevate a brand.{" "}
              <span className="font-medium underline">
                Must be a .MP4 file format. Please keep the video under 30
                seconds!
              </span>
            </div>
          </div>
          {!videoUrl && (
            <div className="p-4 px-6 space-y-4 bg-green-100 rounded-xl">
              <div className="text-lg font-medium">
                ✨ Tips for a great intro video ✨
              </div>
              <div className="text-sm whitespace-pre-line">
                1. Introduce yourself <br />
                2. Summarize your skills and qualifications
                <br />
                3. Highlight your style and what makes you unique
              </div>
              <div>
                <a
                  href="https://drive.google.com/file/d/1Vk8iJtQeqiAjvRy3Kd_xwzHbV5LvtIyP/view?usp=sharing"
                  target="_blank"
                  className="underline "
                >
                  Example video
                </a>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center justify-center space-y-6 ">
            {loading ? (
              <div className="flex flex-col items-center justify-center space-y-4">
                <div>Uploading... Please wait... (up to 5 min)</div>
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              </div>
            ) : videoUrl ? (
              <video
                controls
                className={`object-contain aspect-[9/16] max-h-72 mx-auto rounded-lg bg-slate-100 cursor-pointer`}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <form>
                <input
                  onChange={(e) => handleUpload(e.target.files[0])}
                  type="file"
                  accept="video/mp4"
                  className="pb-3 cursor-pointer w-60 text-hidden"
                />
                <div className="text-sm text-center text-gray-400">
                  Max Size: 900MB ({"<"} 1 min)
                </div>
              </form>
            )}

            {videoUrl && (
              <div className="space-x-4">
                <button
                  onClick={() => {
                    deleteVideo();
                  }}
                  className="px-8 py-3 text-sm font-thin text-black rounded-full bg-gray-50"
                >
                  Remove
                </button>
                <button
                  onClick={() => {
                    setVideoUrl(null);
                    onSubmit();
                  }}
                  className="px-8 py-3 text-sm font-thin text-white bg-black rounded-full"
                >
                  Looks good!
                </button>
              </div>
            )}
          </div>
        </div>
      </Transition.Root>
    </SimpleModal>
  );
}
