import React, { useEffect, useState } from "react";
import {
  FiAtSign,
  FiInstagram,
  FiLink,
  FiLoader,
  FiMail,
  FiPhone,
  FiShare2,
} from "react-icons/fi";
import { BsTiktok } from "react-icons/bs";

import dayjs from "dayjs";
import { UserOutlined } from "@ant-design/icons";
import {
  getCreator,
  updateCreator,
} from "@thatsclutch/shared/services/CreatorService";
import { getCollabsByCreatorID } from "@thatsclutch/shared/services/CollabService";
import { Dropdown, Image } from "antd";
import ServiceItem from "../ListItem/ServiceItem";
import { navigate } from "gatsby";
import { deactivateCreator } from "@thatsclutch/shared/services/AdminService";
import { useToasts } from "react-toast-notifications";
import LocationBlackIcon from "../../../../../assets/vectors/icons/locationBlack";
import ConfirmationModal from "../../../../Dialogs/ConfirmationModal";
import MenuIcon from "../../../../../assets/vectors/icons/menu";
import Autosave from "../../../../Forms/Autosave";
import {
  useMatchDispatch,
  useMatchState,
} from "../../../../../contexts/MatchContext";
import InfoRow from "../../ClientList/Details/InfoRow";
import AddMatchModal from "../AddMatchModal";
import CopyIcon from "../../../../../assets/vectors/icons/copy";
import CollabItem from "./CollabItem";
import ProfileImage from "../../../../Basic/ProfileImage";
import { PatternFormat } from "react-number-format";
import {
  showError,
  useErrorDispatch,
} from "../../../../../contexts/ErrorContext";
import { useInstantSearch } from "react-instantsearch";
import StripeCheck from "./StripeCheck";
import DropDown from "../../../../Admin/Selectors/Dropdown";

const STATUS_COLOR = {
  INVITED: "bg-slate-400",
  PENDING: "bg-yellow-400",
  ACTIVE: "bg-green-400",
  INACTIVE: "bg-blue-400",
  DEACTIVATED: "bg-red-400",
};

interface CreatorInterface {
  id: string;
  _version: Number;
  internalNote: string;
  status: string;
  phoneNumber: string;
  invitationalEmail: string;
  firstName: string;
  lastName: string;
  pronouns: string;
  skills: any;
  services: any;
  links: string[];
  state: string;
  locality: string;
  remoteOnly: boolean;
  universityID: string;
  university: any;
  dob: string;
  createdAt: string;
  industries: string;
  shortBio: string;
}

const TYPE_STYLE = {
  CATEGORY: "bg-[#E5EBFF] text-[#2D50E0]",
  PLATFORM: "bg-[#FFF0F7] text-[#FF66B3]",
  TOOL: "bg-[#E5FFEA] text-[#0A8754]",
};
export default function Details({ currItem, onRefresh, onUpdate, onClose }) {
  const { addToast } = useToasts();
  const [adminNotes, setAdminNotes] = useState("");
  const { isMatching, brief } = useMatchState();
  const dispatch = useMatchDispatch();
  const [creator, setCreator] = useState<CreatorInterface | null>(null);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collabsLoading, setCollabsLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showAddMatch, setShowAddMatch] = useState(false);
  const [tabSelected, setTabSelected] = useState("services");
  const [collabs, setCollabs] = useState([]);
  const errorDispatch = useErrorDispatch();
  const { refresh } = useInstantSearch();

  const handleError = (message) => {
    showError(errorDispatch, message);
  };

  useEffect(() => {
    if (currItem) {
      loadCreator(currItem);
    } else {
      setCreator(null);
      setTabSelected("services");
      setCollabs([]);
    }
  }, [currItem]);

  const loadCreator = async (id) => {
    setSaving(true);
    const result: any = await getCreator(id);
    setAdminNotes(result.internalNote || "");
    setCreator(result);
    setSaving(false);
  };
  const loadCollabs = async () => {
    setCollabsLoading(true);
    const result: any = await getCollabsByCreatorID(currItem);
    setCollabs(result);
    setCollabsLoading(false);
  };

  const saveNote = async (notes) => {
    try {
      setSaving(true);
      const updatedItem = await updateCreator(creator, {
        internalNote: notes,
      });
      await loadCreator(creator?.id);
      //   onUpdate(updatedItem);
      refresh();
    } catch (e) {
      handleError("Error saving notes");
    } finally {
      setSaving(false);
    }
  };
  const handleVerifiedUpdate = async (verifiedStatus) => {
    try {
      setSaving(true);
      const updatedItem = await updateCreator(creator, {
        verifiedStatus: verifiedStatus,
      });
      await loadCreator(creator?.id);
      refresh();
    } catch (e) {
      handleError("Error saving notes");
    } finally {
      setSaving(false);
    }
  };
  const saveNoteFast = async (notes) => {
    const updatedItem = await updateCreator(creator, {
      internalNote: notes,
    });
    // onUpdate(updatedItem);
    refresh();
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  const handleDeactivate = async () => {
    try {
      await onDeactivate();
      return true;
    } catch (e) {
      console.log("error updating applicant status");
      return false;
    }
  };

  const onDeactivate = async () => {
    try {
      setLoading(true);
      const result = await deactivateCreator(creator.id);
      if (!result || result?.error)
        throw result?.error || "Failed to deactivate";
      //   onRefresh(); // loadCreators
      refresh();
      addToast("Creator Deactivated!", {
        appearance: "success",
      });
      await loadCreator(creator?.id);
    } catch (e) {
      addToast("Failed to deactivate", {
        appearance: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const isDeactivated = creator?.status === "DEACTIVATED";

  const linkIcon = (link) => {
    if (link && link.includes("instagram"))
      return <FiInstagram className="w-5 h-5 text-[#E4FDE8]" />;
    if (link && link.includes("tiktok"))
      return <BsTiktok className="w-4 h-4 text-[#E4FDE8]" />;
    else return <FiLink className="w-5 h-5 text-[#E4FDE8]" />;
  };

  function formatPhoneNumber(number) {
    const cleaned = ("" + number).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  }

  function getProfilePath() {
    let url = "http://localhost:3000";
    if (window.location.hostname === "localhost") {
      return url + `/p/${creator?.username ? creator?.username : creator?.id}`;
    }
    switch (process.env.GATSBY_AMP_ENV) {
      case "qa":
        url = "https://qaclient.thatsclutch.com";
        break;
      case "prod":
        url = "https://client.thatsclutch.com";
        break;
      default:
        url = "http://devclient.thatsclutch.com";
    }
    return url + `/p/${creator?.username ? creator?.username : creator?.id}`;
  }

  return !creator ? (
    <div className="flex items-center justify-center h-full">
      <FiLoader className="w-4 h-4 text-black animate-spin" />
    </div>
  ) : (
    <div className="w-full space-y-8 overflow-x-hidden">
      {/* HEADER */}
      <div className="flex items-center justify-between px-8 pt-8">
        {/* STATUS */}
        <div className="flex items-center space-x-3 text-base">
          <div
            className={`w-3 h-3 rounded-full lowercase
              ${STATUS_COLOR[creator?.status]}  
              capitalize`}
          ></div>
          <div className="lowercase first-letter:capitalize">
            {creator?.status}
          </div>
          <div className="text-xs">
            {creator?.stripeConnectedID || "Not connected to Stripe"}
          </div>
        </div>
        {isMatching ? (
          <button
            onClick={() => setShowAddMatch(true)}
            className="px-6 py-3 font-light text-black bg-green-400 rounded-full"
          >
            Add as Recommendation
          </button>
        ) : (
          <div className="flex space-x-4">
            {/* this is the edit/reject menu */}
            <a
              href={
                getProfilePath()
                // "/portfolio/" +
                // creator.id
                // "http://localhost:3000/portfolio/" + creator.id
              }
              target="_blank"
              className="appearance-none cursor-pointer hover:text-black"
            >
              <div className="flex items-center space-x-2 bg-gray-50 px-6 hover:text-black py-4 text-left transition-colors whitespace-nowrap hover:bg-[#30DF87] rounded">
                <div>Public Profile</div>
                <FiShare2 />
              </div>
            </a>
            <button
              className="relative px-3 "
              onBlur={() => setOpenMenu(false)}
            >
              <div
                className="flex justify-end p-2"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <MenuIcon />
              </div>
              {openMenu && (
                <div
                  className="absolute z-50 px-2 rounded top-8 right-1 bg-[#F9FAF5] space-y-3 shadow-lg py-2"
                  onBlur={() => setOpenMenu(false)}
                >
                  <div
                    className="px-6 py-4 text-left transition-colors whitespace-nowrap hover:bg-[#30DF87] rounded"
                    onClick={() => navigate("/app/dashboard/" + creator.id)}
                  >
                    View Studio
                  </div>
                  {!isDeactivated && (
                    <div
                      className="px-6 py-4 text-left transition-colors whitespace-nowrap hover:bg-[#30DF87] rounded"
                      onClick={() => setShowDeactivateModal(true)}
                    >
                      Deactivate
                    </div>
                  )}
                </div>
              )}
            </button>
          </div>
        )}
      </div>

      {/* STRIPE */}
      {creator.stripeConnectedID && <StripeCheck creator={creator} />}

      {/* NAME & PHOTO */}
      <div className="flex px-8 space-x-8">
        {/* IMAGE */}
        <div className="">
          <ProfileImage profileImage={creator?.image} size="lg" />
        </div>
        {/* PROFILE */}
        <div className="flex flex-col justify-center space-y-10">
          {/* NAME & BIO */}
          <div className="">
            <div className="text-sm text-gray-400">
              {creator?.pronouns || "They/Them"}
            </div>
            <div className="text-3xl font-semibold capitalize ">
              {creator?.firstName + " " + creator?.lastName}
            </div>
            <div className="flex items-center space-x-4">
              {creator?.username && (
                <div className="pt-2 text-xs text-gray-400">
                  {creator?.username}
                </div>
              )}
              <div className="pt-2 text-xs text-gray-400">
                Joined on {dayjs(creator?.createdAt).format("MM/DD/YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BIO & LINKS */}
      <div className="px-8 space-y-6">
        <div className="text-sm text-gray-700 whitespace-pre-wrap">
          {creator?.shortBio || <div className="text-gray-500">No bio yet</div>}
        </div>
        {/* Contact & Links */}
        <div className="flex flex-wrap items-center">
          <a
            href={`sms://+1${creator?.phoneNumber?.replace(
              /\D/g,
              ""
            )};?&body=Message%20from%20Clutch.%0A`}
            target={"_blank"}
            className="pb-2 pr-4"
          >
            <div className="relative group h-10 w-10 bg-[#0D1C0D] rounded-full grid place-items-center text-white">
              <FiPhone className="w-5 h-5 text-[#E4FDE8]" />
              <div
                className={`absolute group-hover:opacity-100 group-hover:-translate-y-2 -top-full px-3 py-2 overflow-hidden left-1/2  text-xs text-black opacity-0 transition-all translate-y-0w-auto h-auto whitespace-nowrap -translate-x-1/2  rounded-lg  bg-slate-100 pointer-events-none`}
              >
                {formatPhoneNumber(creator?.phoneNumber)}
              </div>
            </div>
          </a>
          <button
            className="pb-2 pr-4"
            onClick={() => copyToClipboard(creator?.invitationalEmail)}
          >
            <div className="relative h-10 w-10 group bg-[#0D1C0D] rounded-full grid place-items-center text-white">
              <FiMail className="w-5 h-5 text-[#E4FDE8]" />
              <div
                className={`absolute group-hover:opacity-100 group-hover:-translate-y-2 -top-full px-3 py-2 overflow-hidden left-1/2  text-xs text-black opacity-0 transition-all translate-y-0w-auto h-auto whitespace-nowrap -translate-x-1/2  rounded-lg  bg-slate-100 pointer-events-none`}
              >
                {creator?.invitationalEmail}
              </div>
            </div>
          </button>
          {creator?.links?.length > 0
            ? creator.links.map(
                (link, i) =>
                  link && (
                    <a
                      target="_blank"
                      href={link?.includes("http") ? link : "https://" + link}
                      className="relative pb-2 pr-4 group"
                    >
                      <div className="h-10 w-10   bg-[#0D1C0D] rounded-full grid place-items-center text-white">
                        {linkIcon(link)}
                      </div>
                      <div
                        className={`absolute group-hover:opacity-100 group-hover:-translate-y-2 -top-full px-3 py-2 overflow-hidden left-1/2  text-xs text-black opacity-0 transition-all translate-y-0w-auto max-w-xs h-auto whitespace-nowrap -translate-x-1/2  rounded-lg  bg-slate-100 pointer-events-none`}
                      >
                        {link}
                      </div>
                    </a>
                  )
              )
            : null}
        </div>
      </div>

      {/* DETAILS */}
      <div className="px-8 space-y-8 text-base">
        {/* LOCATION & DOB & FOLLOWERS */}
        <div className="flex justify-between text-sm">
          <div className="w-full space-y-2">
            <div className="font-medium">Location</div>
            <div>
              {creator.locality && creator.state ? (
                creator.locality + ", " + creator.state
              ) : (
                <div className="text-gray-500">None yet</div>
              )}
            </div>
          </div>
          <div className="w-full space-y-2">
            <div className="font-medium">Work Preference</div>
            <div>{creator?.remoteOnly ? "Remote only" : "Flexible"}</div>
          </div>
          <div className="w-full space-y-2">
            <div className="font-medium">Birthday</div>
            <div>
              {dayjs(creator?.dob, "YYYY-MM-DD").format("MMM DD, YYYY")}

              {" (" + dayjs().diff(creator.dob, "year") + "y)"}
            </div>
          </div>
        </div>
        {/* Interests */}
        <div className="space-y-4 text-sm">
          <div className="font-medium">Industries of interest</div>
          <div className="flex flex-wrap items-center overflow-x-auto gap-x-4 gap-y-2 ">
            {creator?.industries ? (
              creator?.industries
                .split(", ")
                .map((item) => (
                  <div className="bg-[#E4FDE8] px-3 py-1 rounded-full">
                    {item}
                  </div>
                ))
            ) : (
              <div className="py-1 text-gray-500">None yet</div>
            )}
          </div>
        </div>
        {/* Skills */}
        <div className="space-y-4 text-sm">
          <div className="font-medium">Skills</div>
          <div className="flex flex-wrap w-full overflow-x-auto gap-x-4 gap-y-2">
            {creator?.skills.items.length > 0 ? (
              creator.skills.items
                .sort((a, b) => {
                  if (a.skill.type < b.skill.type) return -1;
                })
                .map(({ skill }, i) => (
                  <div
                    key={i}
                    className={`${
                      TYPE_STYLE[skill.type]
                    } whitespace-nowrap mb-3 px-3 py-1  rounded-full`}
                  >
                    {skill.name}
                  </div>
                ))
            ) : (
              <div className="py-1 text-gray-500">None yet</div>
            )}
          </div>
        </div>
      </div>

      <div className="p-6 mx-8 space-y-4 rounded-lg bg-gray-50">
        {/* VERIFIED */}
        <div className="space-y-4 text-sm ">
          <div className="font-medium">Verified Status</div>
          <DropDown
            defaultValue={"Choose verified status"}
            items={["PREMIUM", "VERIFIED", "GROWING", "NEW"]}
            onSelect={handleVerifiedUpdate}
            selected={creator?.verifiedStatus || ""}
            disabled={saving}
            loading={saving}
          />
        </div>

        {/* NOTES */}
        <div className="space-y-4 text-sm">
          <div className="font-medium">Admin notes</div>
          <Autosave
            adminNotes={adminNotes}
            saveNote={saveNote}
            saveNoteFast={saveNoteFast}
            isSaving={saving}
            profileID={currItem}
          />
        </div>
      </div>

      {/* TABS */}
      <div className="text-base">
        <div className="flex px-8 border-b border-gray-200">
          <button
            className={`px-8 py-2 hover:text-black ${
              tabSelected == "services"
                ? "border-b border-black text-black"
                : "text-gray-500"
            } `}
            onClick={() => setTabSelected("services")}
          >
            Services
          </button>
          <button
            className={`px-8 py-2 hover:text-black ${
              tabSelected == "collabs"
                ? "border-b border-black text-black"
                : "text-gray-500"
            } `}
            onClick={() => {
              loadCollabs();
              setTabSelected("collabs");
            }}
          >
            Collabs
          </button>
        </div>
        {/* SERVICES & COLLABS */}
        {tabSelected == "services" ? (
          <div className="px-8 pt-4 pb-8 space-y-4">
            {creator.services.items?.length > 0 ? (
              <div className="flex flex-col gap-4">
                {creator.services?.items.map((s) => {
                  return <ServiceItem service={s} />;
                })}
              </div>
            ) : (
              <div className="p-4 text-sm text-center text-gray-400">
                No services yet
              </div>
            )}
          </div>
        ) : (
          <div className="px-8 pt-4 pb-8 space-y-4">
            <div className="flex flex-col gap-4">
              {collabsLoading && (
                <div className="flex items-center justify-center p-4">
                  <FiLoader className="w-4 h-4 text-black animate-spin" />
                </div>
              )}
              {collabs?.length > 0 ? (
                collabs?.map((c) => <CollabItem collab={c} />)
              ) : (
                <div className="p-4 text-sm text-center text-gray-400">
                  No collabs yet
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {isMatching && (
        <AddMatchModal
          open={showAddMatch}
          onClose={() => setShowAddMatch(false)}
          onConfirm={() => {}}
          creator={creator}
          brief={brief}
        />
      )}
      <ConfirmationModal
        closable={true}
        isVisible={showDeactivateModal}
        onClose={() => setShowDeactivateModal(false)}
        confirmText={loading ? "Deactivating..." : "Yes, deactivate"}
        title={`Are you sure you want to deactivate ${creator?.firstName +
          " " +
          creator?.lastName}?`}
        onConfirm={handleDeactivate}
        successText={`${creator?.firstName +
          " " +
          creator?.lastName} has been deactivated.`}
      />
    </div>
  );
}
