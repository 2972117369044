import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import confetti from "canvas-confetti";
import SubmitCollabPopup from "../../../Popups/SubmitCollabPopup";

export default function StartModal({ setOpen, open, onSubmit }) {
  const cancelButtonRef = useRef(null);
  const myRef = useRef(null);
  //   const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // clear state if !open
    open && onActivate();
  }, [open]);

  const onActivate = () => {
    var myConfetti = confetti.create(myRef.current, {
      resize: true,
      useWorker: true,
    });
    confetti({
      particleCount: 50,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
    });
    // and launch a few from the right edge
    confetti({
      particleCount: 50,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
    });

    // setTimeout(() => {
    //   myConfetti.reset();
    // }, 5000);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-3xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="flex relative flex-col items-center justify-center w-full h-96 p-16 mx-auto overflow-hidden  space-y-12 bg-gradient-to-tr from-[#30DF87] via-[#B3FFAB] to-[#58FFD6] shadow-slate-200/50 rounded-2xl ">
                    <div
                      className="absolute p-2 transition-colors rounded-full cursor-pointer top-4 right-4 hover:bg-white/30"
                      onClick={() => setOpen(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                    <canvas ref={myRef} className="absolute top-0 w-0 h-0" />
                    <div
                      className="text-5xl cursor-pointer animate-bounce"
                      onClick={() => onActivate()}
                    >
                      🎉
                    </div>
                    <div className="text-4xl font-semibold text-center">
                      Congrats on starting your collab!{" "}
                    </div>
                    <div
                      onClick={() => {
                        onSubmit();
                      }}
                      className="flex items-center px-8 py-3 text-sm font-light text-white capitalize transition-opacity bg-black rounded-full cursor-pointer hover:opacity-90"
                    >
                      <div className="text-white">Let's go!</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* <SubmitCollabPopup onClose={() => setShowPopup(false)} open={showPopup} /> */}
    </>
  );
}
