import React, { useState, useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import { Auth } from 'aws-amplify';
import { Button, Form, Checkbox, Input, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useToasts } from 'react-toast-notifications';

const ResetPassword = () => {
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams();

  const { Title } = Typography;

  useEffect(() => {
    form.setFieldsValue({
      username: params.username,
      password: params.password,
      code: params.code
    });
    return () => {};
  }, [params]);

  const resetPassword = values => {
    const { username, password, code } = values;

    setLoading(true);

    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        setIsDisabled(true);
        setLoading(false);
        addToast('Password changed successfully. Redirecting you to login...', {
          appearance: 'success'
        });
        setTimeout(() => {
          navigate('/app/signin');
        }, 6000);
      })
      .catch(error => {
        setIsDisabled(false);
        setLoading(false);
        addToast(
          error.message ||
            'Something went wrong. Please confirm inputs or contact us at support@thatsclutch.com',
          { appearance: 'error' }
        );
      });
  };

  return (
    <div className="flex flex-col items-center justify-center mt-5 mb-5">
      <div className="flex flex-col w-1/2 space-y-6">
        <Title className="text-center">Confirm Password Reset</Title>
        <Form
          form={form}
          initialValues={{
            terms: false
          }}
          layout="vertical"
          onFinish={values => {
            setLoading(true);
            resetPassword(values);
          }}
          size="large"
          requiredMark={false}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              { required: true, message: 'Please enter your email!' }
            ]}
          >
            <Input name="username" placeholder="example@domain.com" />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter the code you received in your email.'
              }
            ]}
          >
            <Input
              name="code"
              placeholder="Code..."
              suffix={
                <Tooltip
                  title="Check your email for your code. Can't find it? Click here to
                  send it again."
                  onClick={() => {
                    navigate('/forgotpassword');
                  }}
                >
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            hasFeedback
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Password..." />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!'
                  );
                }
              })
            ]}
          >
            <Input.Password placeholder="Confirm Password..." />
          </Form.Item>
          <div>
            <Button
              name="submit"
              type="primary"
              shape="round"
              className="bg-[#30DF87]"
              style={{ background: '#30DF87', border: 'none' }}
              size="large"
              loading={isLoading}
              htmlType="submit"
              disabled={isDisabled}
            >
              Reset Password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
