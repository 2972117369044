import React from "react";
import Clipboard from "../../../../../assets/vectors/Clipboard";

export default function NavItem({
  text,
  onSelect,
  icon = <Clipboard />,
  active = false,
  isNew = false,
}) {
  return (
    <div onClick={() => onSelect(text)}>
      <div
        className={`relative cursor-pointer p-4 w-full rounded-lg flex items-center space-x-4 transition-all text-center md:text-left  ${active &&
          "bg-[#30DF87]"} `}
      >
        {icon}
        <div>{text}</div>
        {isNew && !active && (
          <div className="absolute right-0 top-0 translate-y-1/2 bg-[#30DF87] text-xs rounded-md px-2 py-1">New</div>
        )}
      </div>
    </div>
  );
}
