import React, { useState } from 'react';
import BasicModal from './BasicModal';

function ConfirmationModal({
  onClose,
  isVisible, // shows or hides modal
  closable,
  title = "Are you sure?",
  subtitle = "",
  confirmText = "",
  onConfirm,
  successText = '',
  successSubtext = ''
}) {


  const [page, setPage] = useState("confirm")

  const handleConfirm = async () => {
    // loading
    const result = await onConfirm() // should return true or false=

    if (result) {
      setPage("success")
    } else {
      setPage("error")
    }
  }

  return (
    <BasicModal isVisible={isVisible} onClose={onClose} closable={closable}>
      <div className='px-6 py-6'>
        {page == "confirm" ? (
          <div>
            <div className='flex flex-col justify-center space-y-3'>
              <div className='font-semibold text-[24px]'>
                {title}
              </div>
              <div className='text-[16px]'>
                {subtitle}
              </div>
            </div>
            <div className='flex items-center justify-between mt-6'>
              <button onClick={onClose} className="text-gray-400 underline">
                Cancel
              </button>
              <button onClick={handleConfirm} className="bg-[#30DF87] hover:bg-[#23C774] text-black px-6 py-3  rounded-full">
                {confirmText}
              </button>
            </div>
          </div>
        ) : page == "success" ? (
          <div onMouseDown={onClose}>
            <div>
              <div className='flex flex-col justify-center text-[24px] font-semibold'>
                {successText}
              </div>
              <div>{successSubtext}</div>
            </div>

            <div className='flex justify-center' >
              <button onClick={onClose} className="px-6 py-3 mt-6 text-white bg-black rounded-full" >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div className='space-y-6'>
            <div>
              <div className='text-[#EC2E2E] font-semibold text-xl '>
                Oh no, something went wrong!
              </div>
              <div>Please try again. If the error keeps occuring please contact {" "}
                <a
                  className="underline cursor-pointer text-inherit hover:text-[#0A8754] hover:underline"
                  href="mailto:support@thatsclutch.com"
                >
                  support
                </a>.
              </div>
              {/* 
support@thatsclutch.com
 */}
            </div>
            <button onClick={onClose} className="px-6 py-3 text-white bg-black rounded-full">
              Close
            </button>
          </div>
        )
        }
      </div>
    </BasicModal>
  );
}

export default ConfirmationModal;

