import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AmpAPI } from "@thatsclutch/shared";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
import { Auth } from "aws-amplify";

const CheckBox = ({ text, marked, onClick }) => (
  <div
    onClick={onClick}
    className="flex items-center p-4 space-x-4 rounded-lg cursor-pointer bg-slate-50"
  >
    {marked ? (
      <div className="w-4 h-4 bg-green-400 rounded-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-4 h-4 text-black"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      </div>
    ) : (
      <div className="w-4 h-4 border border-black rounded-md "></div>
    )}
    <div className="flex-1 text-base font-light">{text}</div>
  </div>
);

export default function AcceptModal({
  children,
  onSuccess,
  collab,
  creator,
  clientName,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [marked, setMarked] = useState<any>([]);

  useEffect(() => {
    // clear state if !open
    !open && setMarked([0]);
  }, [open]);

  const handleConfirm = async () => {
    setLoading(true);
    setError("");
    try {
      // start subscription
      const data = await AmpAPI.post("CampusAPI", "/collab", {
        body: {
          product: { name: collab.name },
          collabID: collab.id,
          cycles: 1,
        },
      });
      if (!data?.collab) throw data;
      console.log("Collab start results", data);

      await onSuccess();
      setOpen(false);
    } catch (e) {
		console.log(e)
      setError(e.message || e);
    } finally {
      setLoading(false);
    }
    // show errors
  };

  return (
    <div>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Transition.Root show={open} as={"div"}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-3xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="p-8 py-12 space-y-6 bg-white">
                    <div className="space-y-3">
                      <div className="text-3xl font-medium ">
                        Accept your collab with{" "}
                        <span className="captialize">{clientName}</span>?
                      </div>
                      <div className="text-sm font-light">
                        By accepting this collab you confirm that you will
                        submit the described deliverables for the duration of the collab.
                      </div>
                    </div>

                    <div className="space-y-4">
                      <CheckBox
                        text="I will submit the requested number of assets"
                        marked={marked.includes(1)}
                        onClick={() => {
                          if (marked.includes(1))
                            setMarked((curr) => curr.filter((v) => v != 1));
                          else setMarked((v) => [...v, 1]);
                        }}
                      />
                      <CheckBox
                        text="The client can request revisions for each submission"
                        marked={marked.includes(2)}
                        onClick={() => {
                          if (marked.includes(2))
                            setMarked((curr) => curr.filter((v) => v != 2));
                          else setMarked((v) => [...v, 2]);
                        }}
                      />
                      <CheckBox
                        text="I will get paid when my submission gets approved by the client"
                        marked={marked.includes(3)}
                        onClick={() => {
                          if (marked.includes(3))
                            setMarked((curr) => curr.filter((v) => v != 3));
                          else setMarked((v) => [...v, 3]);
                        }}
                      />
                      <CheckBox
                        text="I agree to the terms of this collab"
                        marked={marked.includes(4)}
                        onClick={() => {
                          if (marked.includes(4))
                            setMarked((curr) => curr.filter((v) => v != 4));
                          else setMarked((v) => [...v, 4]);
                        }}
                      />
                    </div>
                    {error && (
                      <div className="p-4 text-sm text-red-500 rounded-xl bg-red-50">
                        {error.toString()}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end px-8 pb-8">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-6 py-3 mt-3 text-base font-medium bg-white border rounded-full shadow-sm text-slate-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`inline-flex justify-center w-full px-6 py-3 text-lg font-light ${
                        marked.length == 5
                          ? "bg-green-400 text-black "
                          : "bg-slate-300 text-slate-500"
                      } border border-transparent rounded-full shadow-sm hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={handleConfirm}
                      disabled={loading || marked.length !== 5}
                    >
                      <div className="flex space-x-2">
                        {loading && (
                          <div className="flex items-center justify-center">
                            <svg
                              className="z-10 w-5 h-5 text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        )}
                        <div>Accept collab</div>
                      </div>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
