import React from 'react';

function Clipboard(props) {
  return (
    <div>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.854 7.14592C10.9006 7.19236 10.9375 7.24754 10.9627 7.30828C10.9879 7.36903 11.0009 7.43415 11.0009 7.49992C11.0009 7.56568 10.9879 7.63081 10.9627 7.69155C10.9375 7.7523 10.9006 7.80747 10.854 7.85392L7.85402 10.8539C7.80758 10.9005 7.7524 10.9374 7.69166 10.9626C7.63091 10.9878 7.56579 11.0008 7.50002 11.0008C7.43425 11.0008 7.36913 10.9878 7.30839 10.9626C7.24764 10.9374 7.19247 10.9005 7.14602 10.8539L5.64602 9.35392C5.59953 9.30743 5.56266 9.25224 5.5375 9.1915C5.51234 9.13076 5.49939 9.06566 5.49939 8.99992C5.49939 8.93417 5.51234 8.86907 5.5375 8.80833C5.56266 8.74759 5.59953 8.6924 5.64602 8.64592C5.73991 8.55203 5.86725 8.49929 6.00002 8.49929C6.06577 8.49929 6.13087 8.51223 6.19161 8.53739C6.25234 8.56255 6.30753 8.59943 6.35402 8.64592L7.50002 9.79292L10.146 7.14592C10.1925 7.09935 10.2476 7.06241 10.3084 7.0372C10.3691 7.012 10.4343 6.99902 10.5 6.99902C10.5658 6.99902 10.6309 7.012 10.6917 7.0372C10.7524 7.06241 10.8076 7.09935 10.854 7.14592Z" fill="#0D1C0D"/>
<path d="M4 1.5H3C2.46957 1.5 1.96086 1.71071 1.58579 2.08579C1.21071 2.46086 1 2.96957 1 3.5V14C1 14.5304 1.21071 15.0391 1.58579 15.4142C1.96086 15.7893 2.46957 16 3 16H13C13.5304 16 14.0391 15.7893 14.4142 15.4142C14.7893 15.0391 15 14.5304 15 14V3.5C15 2.96957 14.7893 2.46086 14.4142 2.08579C14.0391 1.71071 13.5304 1.5 13 1.5H12V2.5H13C13.2652 2.5 13.5196 2.60536 13.7071 2.79289C13.8946 2.98043 14 3.23478 14 3.5V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V3.5C2 3.23478 2.10536 2.98043 2.29289 2.79289C2.48043 2.60536 2.73478 2.5 3 2.5H4V1.5Z" fill="#0D1C0D"/>
<path d="M9.5 1C9.63261 1 9.75979 1.05268 9.85355 1.14645C9.94732 1.24021 10 1.36739 10 1.5V2.5C10 2.63261 9.94732 2.75979 9.85355 2.85355C9.75979 2.94732 9.63261 3 9.5 3H6.5C6.36739 3 6.24021 2.94732 6.14645 2.85355C6.05268 2.75979 6 2.63261 6 2.5V1.5C6 1.36739 6.05268 1.24021 6.14645 1.14645C6.24021 1.05268 6.36739 1 6.5 1H9.5ZM6.5 0C6.10218 0 5.72064 0.158035 5.43934 0.43934C5.15804 0.720644 5 1.10218 5 1.5V2.5C5 2.89782 5.15804 3.27936 5.43934 3.56066C5.72064 3.84196 6.10218 4 6.5 4H9.5C9.89782 4 10.2794 3.84196 10.5607 3.56066C10.842 3.27936 11 2.89782 11 2.5V1.5C11 1.10218 10.842 0.720644 10.5607 0.43934C10.2794 0.158035 9.89782 0 9.5 0L6.5 0Z" fill="#0D1C0D"/>
</svg>

    </div>
  );
}

export default Clipboard;