import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import NamePage from "./NamePage";
import PricePage from "./PricePage";
import OverviewPage from "./OverviewPage";
import { AnimWrap } from "../Layout";
import { FiX } from "react-icons/fi";

export default function ServiceAddModal({ open, onSuccess, onCancel }) {
  const cancelButtonRef = useRef(null);
  const [page, setPage] = useState(0);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    // temporary clean up function, clear after successful upload instead
    open && cleanUp();
  }, [open]);

  const cleanUp = () => {
    setName("");
    setDesc("");
    setPrice("");
    setPage(0);
  };

  const showPage = {
    0: (
      <AnimWrap key={0}>
        <NamePage
          currName={name}
          currDesc={desc}
          onNext={(name, desc) => {
            setName(name);
            setDesc(desc);
            setPage(1);
          }}
        />
      </AnimWrap>
    ),
    1: (
      <AnimWrap key={1}>
        <PricePage
          currPrice={price}
          onNext={(val) => {
            setPrice(val);
            setPage(2);
          }}
          onBack={() => {
            setPage(0);
          }}
        />
      </AnimWrap>
    ),
    2: (
      <AnimWrap key={2}>
        <OverviewPage
          onNext={onSuccess}
          onBack={() => {
            setPage(1);
          }}
          service={{ name, desc, price }}
        />
      </AnimWrap>
    ),
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:max-w-lg">
                <Transition.Root show={true}>
                  <div onClick={onCancel} className="absolute z-50 p-4 cursor-pointer top-4 right-4">
                    <FiX />
                  </div>
                  {showPage[page]}
                </Transition.Root>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
