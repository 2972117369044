import React from 'react';

function PhoneIcon() {
  return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1392 19.3609C6.69541 19.3609 0.63916 13.3047 0.63916 5.86091C0.641975 4.58409 1.10835 3.35173 1.95159 2.39296C2.79482 1.43419 3.95753 0.814256 5.22354 0.648408C5.54627 0.612625 5.872 0.680573 6.15351 0.842399C6.43502 1.00422 6.65767 1.25152 6.78916 1.54841L8.67353 5.94528C8.77128 6.17351 8.81047 6.42254 8.78755 6.66975C8.76463 6.91697 8.68032 7.15454 8.54228 7.36091L6.98603 9.74216C7.69074 11.1723 8.85189 12.3269 10.286 13.0234L12.6392 11.4578C12.8453 11.3188 13.0835 11.2347 13.3312 11.2134C13.5789 11.1921 13.8279 11.2342 14.0548 11.3359L18.4517 13.2109C18.7486 13.3424 18.9958 13.565 19.1577 13.8466C19.3195 14.1281 19.3874 14.4538 19.3517 14.7765C19.1858 16.0425 18.5659 17.2052 17.6071 18.0485C16.6483 18.8917 15.416 19.3581 14.1392 19.3609ZM5.41104 2.13903C4.5063 2.25534 3.67498 2.69742 3.07272 3.38251C2.47046 4.06761 2.13856 4.94873 2.13916 5.86091C2.14164 9.04274 3.40672 12.0935 5.65662 14.3434C7.90652 16.5933 10.9573 17.8584 14.1392 17.8609C15.0513 17.8615 15.9325 17.5296 16.6176 16.9274C17.3026 16.3251 17.7447 15.4938 17.861 14.589L13.4642 12.714L11.1204 14.2797C10.9048 14.4222 10.6559 14.5066 10.398 14.5247C10.1401 14.5428 9.88196 14.4939 9.64853 14.3828C7.90192 13.5379 6.4891 12.1317 5.63603 10.389C5.52356 10.1564 5.47309 9.89865 5.48952 9.64078C5.50594 9.3829 5.58871 9.13364 5.72978 8.91716L7.29541 6.53591L5.41104 2.13903Z" fill="#141414" />
      </svg>
  );
}

export default PhoneIcon;