import React from "react";

export default function ShortInput({
  label,
  placeholder,
  value,
  error = null,
  prefix = null,
  onChange,
  extraClass = "",
  required = false,
  ...props
}) {
  return (
    <div className="flex-1 space-y-2">
      <label className="text-sm text-slate-800">
        {label}
        {required && " *"}
      </label>
      <div className="flex border h-[40px] border-slate-200  focus-within:border-emerald-300 rounded-full items-center">
        {prefix && <div className="pl-4">{prefix}</div>}
        <input
          className={`bg-transparent px-4 h-10  py-2 outline-none flex-1 md:min-w-[200px] ${extraClass}`}
          value={value}
          placeholder={placeholder}
          type="text"
          onClick={(e) => e.stopPropagation()}
          autoCapitalize="sentences"
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      </div>
      <div className="text-xs text-red-400">{error}</div>
    </div>
  );
}
