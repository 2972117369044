import {
  getClientApplicants,
  getNextClientApplicants,
} from "@thatsclutch/shared/services/ApplicationService";
import React, { useEffect, useState } from "react";
import { FiLoader, FiX } from "react-icons/fi";
import SearchInput from "../../../../../components/Admin/Inputs/SearchInput";
import Dropdown from "../../../../../components/Admin/Selectors/Dropdown";
import SideModal from "../../../../Dialogs/SideModal";
import ListItem from "./ApplicantItem";
import Details from "./Details";
import {
  useErrorDispatch,
  showError,
} from "../../../../../contexts/ErrorContext";
import { Index } from "react-instantsearch";
import Search from "../../../../Admin/Search";
import ClientApplicantHit from "../../../../Admin/Search/ClientApplicantHit";
import dayjs from "dayjs";

interface StatusType {
  value: string;
  title: string;
}
const STATUS_TYPES = [
  { value: "PENDING_REVIEW", title: "Pending review" },
  { value: "PENDING_PAYMENT", title: "Pending Payment" },
  { value: "APPROVED", title: "Approved" },
  { value: "INVITED", title: "Invited" },
  { value: "REJECTED", title: "Rejected" },
];
interface ServiceType {
  value: string;
}
const SERVICE_TYPES = [
  { value: "Content Creator" },
  { value: "Graphic Design" },
  { value: "Social Media / TikTok Manager" },
  { value: "Video Editor" },
  { value: "Web Designer" },
];

export default function ClientApplicant() {
  const dispatch = useErrorDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [selected, setSelected] = useState(null);
  const [select, setSelect] = useState<any[]>([]);
  const [showSelect, setShowSelect] = useState(false);
  const [showDetail, setShowDetail] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientStatus, setClientStatus] = useState<StatusType | null>(STATUS_TYPES[0]);
  const [service, setService] = useState<ServiceType | null>(null);
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);

  useEffect(() => {
    loadClients();
  }, [clientStatus, service, searchTerm]);

  const handleError = (message) => {
    showError(dispatch, message);
  };

  const loadClients = async () => {
    setLoading(true);
    try {
      const data = await getClientApplicants(
        searchTerm,
        clientStatus?.value || "",
        service?.value || "",
        null
      );
      setNextToken(data?.nextToken);
      data?.items && setClients(data.items);
    } catch (e) {
      handleError("Error loading client applicants");
    } finally {
      setLoading(false);
    }
  };

  const loadNextData = async () => {
    setLoadingMore(true);
    try{    
      const data = await getClientApplicants(
        searchTerm,
        clientStatus?.value || "",
        service?.value || "",
        nextToken
      );
      setNextToken(data?.nextToken || null);
      data?.items && setClients((prev) => [...prev, ...data.items]);
    }catch(e){
      showError(dispatch, "Error loading more client applicants");
    }finally{   
      setLoadingMore(false);
    }
  };

  const handleItemUpdate = (updatedItem) => {
    const index = clients
      .map((v) => v.id)
      .findIndex((v) => v == updatedItem.id);
    // check that index is valid
    if (index < 0 && index > clients.length - 1) return handleError("Row not found");
    // replace item in state array with updated item
    const updatedList = [...clients];
    updatedList[index] = updatedItem;
    setClients(updatedList);
  };

  const sortByCreated = (a, b) => {
    if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return 1;
    if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) return -1;
    else return 0;
  };

  return (
    <div className="pb-12">
      <div className="flex items-center justify-between px-6">
        <div className="flex space-x-6">
          <Dropdown
            onSelect={setClientStatus}
            defaultValue="All Statuses"
            items={STATUS_TYPES}
            titleProp="title"
            selected={clientStatus}
          />

          <Dropdown
            onSelect={setService}
            defaultValue="All Services"
            items={SERVICE_TYPES}
            titleProp="value"
            selected={service}
          />
        </div>
        {showSelect ? (
          <div className="flex space-x-4">
            <div
              onClick={async () => {
                // copy emails
                let emailStr = select.join(",");
                await navigator.clipboard.writeText(emailStr);
                alert("Copied " + select.length + " emails to clipboard!");
              }}
              className="px-4 py-2 flex items-center border whitespace-nowrap rounded cursor-pointer transition-colors hover:bg-[#E7EEEB] "
            >
              {"Copy " + select.length + " emails"}
            </div>
            <div
              onClick={() => {
                setSelect([]);
                setShowSelect(false);
              }}
              className="flex p-2 items-center cursor-pointer hover:bg-[#E7EEEB] rounded-full"
            >
              <FiX className="w-4 h-4 text-black" />
            </div>
          </div>
        ) : (
          <div
            onClick={() => setShowSelect(true)}
            className="px-4 py-2 border rounded  whitespace-nowrap cursor-pointer transition-colors hover:bg-[#E7EEEB]"
          >
            Select
          </div>
        )}
      </div>
      <div className="flex flex-row justify-start pl-6 space-x-6">
        {loading && (
          <div className="flex justify-start pt-6">
            <div className="flex items-center pl-6 space-x-4 rounded-full bg-neutral-200">
              <div className="">Loading</div>
              <div className="p-2 rounded-full">
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              </div>
            </div>
          </div>
        )}
        {!loading && searchTerm && (
          <div className="flex justify-start pt-6">
            <div className="flex items-center pl-6 space-x-4 rounded-full bg-[#30DF87]">
              <div className="">Matching "{searchTerm}"</div>
              <div className="">
                <div
                  className="p-2 rounded-full cursor-pointer hover:bg-gray-100 active:bg-black group"
                  onClick={() => setSearchTerm("")}
                >
                  <FiX className="w-4 h-4 text-black group-active:text-white " />
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading && clientStatus != null && (
          <div className="flex justify-start pt-6">
            <div className="flex items-center pl-6 space-x-4 rounded-full bg-[#30DF87]">
              <div className="">
                {clients.length} results with status "{clientStatus?.title}"
              </div>
              <div className="">
                <div
                  className="p-2 rounded-full cursor-pointer hover:bg-gray-100 active:bg-black group"
                  onClick={() => setClientStatus(null)}
                >
                  <FiX className="w-4 h-4 text-black group-active:text-white " />
                </div>
              </div>
            </div>
          </div>
        )}
        {!loading && service != null && (
          <div className="flex justify-start pt-6">
            <div className="flex items-center pl-6 space-x-4 rounded-full bg-[#30DF87]">
              <div className="">Service: "{service.value}"</div>
              <div className="">
                <div
                  className="p-2 rounded-full cursor-pointer hover:bg-gray-100 active:bg-black group"
                  onClick={() => setService(null)}
                >
                  <FiX className="w-4 h-4 text-black group-active:text-white " />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mx-6 mt-6 overflow-hidden overflow-x-auto border border-black/[.2] rounded-lg">
        <div className="flex items-center px-4 py-4 bg-[#E7EEEB] text-base text-black rounded-lg min-w-min">
          <div
            className={`transition-all cursor-pointer overflow-hidden  ${
              showSelect ? " py-1 w-16" : "w-0"
            }`}
            onClick={() => {
              if (select.length >= clients.length) {
                setSelect((prev) =>
                  prev.filter((v) => !clients.map((v) => v.email).includes(v))
                );
              } else {
                setSelect((prev) => [
                  ...prev,
                  ...clients
                    .filter((v) => !prev.includes(v.email)) // remove duplicates from load more
                    .map((v) => v.email),
                ]);
              }
            }}
          >
            <div className="flex justify-center px-2">
              {select.length < clients.length ? (
                <div className="w-4 h-4  rounded-sm bg-[#30DF87] hover:bg-[#30DF87]/60"></div>
              ) : (
                <div className="w-4 h-4 ring-1 ring-[#30DF87]  rounded-sm bg-white hover:bg-[#30DF87]/60"></div>
              )}
            </div>
          </div>
          <div className="flex space-x-4 ">
            <div className="w-44">Status</div>
            <div className="w-36 lg:w-64">Company</div>
            <div className="w-32 ">Status Updated</div>
            <div className="w-64 lg:w-70">Notes</div>
            <div className="w-40 ">Budget/Needs</div>
            <div className="w-32 lg:w-52">Location</div>
            <div className="w-32 ">Applied On</div>
          </div>
        </div>

        {clients.sort(sortByCreated).map((client) => (
          <ListItem
            key={client.id}
            client={client}
            onOpen={(id) => {
              setSelected(id);
              setShowDetail(true);
            }}
            showSelect={showSelect}
            selected={select.includes(client?.email)}
            onSelect={() => {
              if (select.includes(client?.email))
                setSelect((prev) => prev.filter((v) => v != client.email));
              else setSelect((prev) => [...prev, client.email]);
            }}
          />
        ))}

        <SideModal
          visible={showDetail}
          onClose={() => {
            setShowDetail(false);
            setSelected(null);
          }}
        >
          <Details
            onUpdate={handleItemUpdate}
            onRefresh={loadClients}
            currItemID={selected}
          />
        </SideModal>

        {!loading && clients.length === 0 && (
          <div className="w-full p-6 text-center text-gray-400">
            No applicants!
          </div>
        )}

        {nextToken && (
          <div
            className="w-full flex items-center justify-center space-x-4 py-4 text-center text-black transition-colors cursor-pointer hover:bg-[#E9F2C1]"
            onClick={loadNextData}
          >
            {loadingMore && (
              <div>
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              </div>
            )}
            <div>Load more</div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-end w-full px-6 py-4 space-x-4 text-center text-neutral-500">
        <div>
          {clients.length} result{clients.length > 1 && "s"}
        </div>
      </div>
    </div>
  );
}
