import React from 'react';
import { LayoutScroll } from '../../Layout';
const myHTML = `<html>
<p><strong>Terms &amp; Conditions</strong></p>
<p><span style="font-weight: 400;">&zwj;</span></p>
<p><span style="font-weight: 400;">Effective date:&nbsp;</span><strong>03.15.2022</strong></p>
<p><span style="font-weight: 400;">Welcome to Clutch. Please read on to learn the rules and restrictions that govern your use of our website(s), products, services and applications (the &ldquo;Services&rdquo;). If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</span></p>
<p><span style="font-weight: 400;">Email: support@thatsclutch.com</span></p>
<p><span style="font-weight: 400;">Address: 4201 Main St, Suite 200-152, Houston, TX 77002</span></p>
<p><span style="font-weight: 400;">These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and&nbsp;</span><strong>VISEAT CONCIERGE, INC.</strong><span style="font-weight: 400;">&nbsp;(&ldquo;Clutch,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the Privacy Policy&lt;https://www.thatsclutch.com/privacy-policy&gt;.</span><strong>&nbsp;Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (&ldquo;Additional Terms&rdquo;), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms, including </strong><a href="https://drive.google.com/file/d/1IHB68xUJcDQF1hWJ6N0-Sp-kc19A1W9-/view?usp=sharing"><strong>the Work Product Contract</strong></a><strong> [</strong><a href="https://drive.google.com/file/d/1IHB68xUJcDQF1hWJ6N0-Sp-kc19A1W9-/view?usp=sharing"><strong>https://drive.google.com/file/d/1IHB68xUJcDQF1hWJ6N0-Sp-kc19A1W9-/view?usp=sharing</strong></a><strong>] entered into between Student Provider and the Customer (as those terms are defined below).&nbsp;</strong></p>
<p><strong>Please read these Terms carefully.&nbsp;</strong><span style="font-weight: 400;">They cover important information about Services provided to you and any charges, taxes, and fees we bill you.&nbsp;</span><strong>These Terms include information about&nbsp;</strong><a href="https://docs.google.com/document/d/1K6V104z7pXEPjRDQVVEIaASR5JhCgDEh/edit#heading=h.30j0zll"><strong>future changes to these Terms</strong></a><strong>,&nbsp;</strong><a href="https://docs.google.com/document/d/1K6V104z7pXEPjRDQVVEIaASR5JhCgDEh/edit#bookmark=id.3znysh7"><strong>limitations of liability</strong></a><strong>,&nbsp;</strong><a href="https://docs.google.com/document/d/1K6V104z7pXEPjRDQVVEIaASR5JhCgDEh/edit#bookmark=id.2et92p0"><strong>a class action waiver and resolution of disputes by arbitration instead of in court</strong></a><strong>. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</strong></p>
<p><strong>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</strong><span style="font-weight: 400;">&nbsp;EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE&nbsp;</span><a href="https://docs.google.com/document/d/1K6V104z7pXEPjRDQVVEIaASR5JhCgDEh/edit#bookmark=id.2et92p0"><span style="font-weight: 400;">ARBITRATION AGREEMENT SECTION BELOW</span></a><span style="font-weight: 400;">, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>Will These Terms Ever Change?</strong></li>
</ol>
<p><span style="font-weight: 400;">We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at https://www.thatsclutch.com, send you an email, and/or notify you by some other means.&nbsp;</span></p>
<p><span style="font-weight: 400;">If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.&nbsp;</span></p>
<p><span style="font-weight: 400;">Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What About My Privacy?</strong></li>
</ol>
<p><span style="font-weight: 400;">Clutch takes the privacy of its users very seriously. For the current Clutch Privacy Policy, please click here&lt;https://www.thatsclutch.com/privacy-policy&gt;.</span></p>
<p><em><span style="font-weight: 400;">Children&rsquo;s Online Privacy Protection Act</span></em></p>
<p><span style="font-weight: 400;">The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service Student Providers obtain parental consent before they knowingly collect personally identifiable information online from children who are under 13 years of age. We do not knowingly collect or solicit personally identifiable information from children under 13 years of age; if you are a child under 13 years of age, please do not attempt to register for or otherwise use the Services or send us any personal information. If we learn we have collected personal information from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided us personal information, please contact us at support@thatsclutch.com.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What Are The Basics Of Using Clutch?</strong></li>
</ol>
<p><span style="font-weight: 400;">You may be required to sign up for an account, select a password and user name (&ldquo;Clutch User ID&rdquo;), and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your Clutch User ID a name that you do not have the right to use, or another person&rsquo;s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission.</span></p>
<p><span style="font-weight: 400;">You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you&rsquo;ve received your parent&rsquo;s or guardian&rsquo;s permission to use the Services and have gotten your parent or guardian to agree to these Terms on your behalf).&nbsp;</span></p>
<p><span style="font-weight: 400;">You will only use the Services for your own internal, personal use, and not on behalf of or for the benefit of any third party. You will comply with all laws that apply to you, your use of the Services, and your actions and omissions that relate to the Services (for example, Student Providers must comply with all laws that relate to the Student Provider Services (as defined below)). If your use of the Services is prohibited by applicable laws, then you aren&rsquo;t authorized to use the Services. We can&rsquo;t and won&rsquo;t be responsible for your using the Services in a way that breaks the law.</span></p>
<p><span style="font-weight: 400;">You will not share your Clutch User ID, account or password with anyone, and you must protect the security of your Clutch User ID, account, password and any other access tools or credentials. You&rsquo;re responsible for any activity associated with your Clutch User ID and account.</span></p>
<p><em><span style="font-weight: 400;">Connecting Student Providers and Customers</span></em></p>
<p><span style="font-weight: 400;">Clutch connects students providing services (&ldquo;Student Providers&rdquo;) and students looking to acquire their services (&ldquo;Customers&rdquo;). When we use the word &ldquo;you&rdquo; in these Terms, it refers to any user, regardless of whether he or she is a Student Provider or Customer, while if we use one of those specific terms, it only applies to that category of user.</span></p>
<p><span style="font-weight: 400;">Clutch uses reasonable efforts to vet each potential Student Provider, including checking each Student Provider's background, experience, and relevant certifications, before recommending a certain Student Provider to Customers. However, before receiving services from any Student Provider (&ldquo;Student Provider Services&rdquo;), Customers are responsible for making their own determinations that the recommended Student Provider is suitable. Clutch is only responsible for connecting Student Providers and Customers, and can&rsquo;t and won&rsquo;t be responsible for making sure that Student Provider Services are actually provided or are up to a certain standard of quality. Clutch similarly can&rsquo;t and won&rsquo;t be responsible for ensuring that information (including credentials) a Customer or Student Provider provides about himself or herself is accurate or up-to-date. We don&rsquo;t control the actions of any Customer or Student Provider, and Student Providers aren&rsquo;t our employees.&nbsp;</span></p>
<p><strong>Student Providers are independent contractors of Customer and not employees, partners, representatives, agents, joint venturers, independent contractors or franchises of Clutch. Clutch does not offer the Student Provider Services and does not employ individuals to perform the Student Provider Services. You hereby acknowledge that Clutch does not supervise, direct, control or monitor the Student Provider Services and expressly disclaims any responsibility and liability for the Student Provider Services, including but not limited to any warranty or condition of good and workmanlike services, warranty or condition of quality or fitness for a particular purpose, or compliance with any law, regulation, or code.</strong></p>
<p><span style="font-weight: 400;">If a Customer selects a Student Provider to provide Student Provider Services, Customer and Student Provider must agree upon the specifics of the services in a mutually executed statement of work (&ldquo;Statement of Work&rdquo;). In addition, Customer and Student Provider are deemed contractually bound by the terms of the Work Product Contract [insert URL] with respect to confidentiality agreements and invention assignment agreements, and Customer and Student Provider hereby agree that the Work Product Contract applies to all Student Provider Services provided to Customer hereunder.&nbsp;</span></p>
<p><span style="font-weight: 400;">Customer and Student Provider are responsible for complying with any local requirements, including applicable laws, rules, and regulations. Clutch does not assume any responsibility for any consequence of using the Work Product Contract. The Work Product Contract is not intended to and does not (a) constitute legal advice, (b) create an attorney-client relationship, or (c) constitute advertising or a solicitation of any type. Clutch expressly disclaims any and all liability with respect to actions or omissions based on the Work Product Contract.</span></p>
<p><span style="font-weight: 400;">Except for the Statement of Work, Customers and Student Providers must not enter into separate agreements outside of Clutch relating to Student Provider Services (&ldquo;Outside Agreements&rdquo;). If you do enter into any Outside Agreement we may terminate your Clutch account, and you acknowledge that Clutch is not a party to and isn&rsquo;t responsible for enforcing any Outside Agreement.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What About Messaging?</strong></li>
</ol>
<p><span style="font-weight: 400;">As part of the Services, you may receive communications through the Services, including messages that Clutch sends you (for example, via email or SMS). When signing up for the Services, you will receive a welcome message and instructions on how to stop receiving messages.&nbsp;</span><strong>By signing up for the Services and providing us with your wireless number, you confirm that you want Clutch to send you information regarding your account or the Services or that we think may be of interest to you, which may include Clutch using automated dialing technology to text you at the wireless number you provided, and you agree to receive communications from Clutch, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from Clutch.</strong><span style="font-weight: 400;">&nbsp;You agree to indemnify and hold Clutch harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way related to your breach of the foregoing.&nbsp;</span></p>
<p><span style="font-weight: 400;">You acknowledge and agree that Customers or Student Providers may contact you in relation to the Student Provider Services through any contact information provided in your account or in connection with such Student Provider Services, or through contact information you provide directly to the Customer or Student Provider (as applicable). Clutch does not bear any responsibility or liability for any such contact between you and any Customer and/or Student Provider.&nbsp;</span></p>
<p><span style="font-weight: 400;">Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the Services.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>Are There Restrictions In How I Can Use The Services?</strong></li>
</ol>
<p><span style="font-weight: 400;">You represent, warrant, and agree that you will not provide or contribute anything, including any Content or User Submission (as those terms are defined below), to the Services, or otherwise use or interact with the Services, in a manner that:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">infringes or violates the intellectual property rights or any other rights of anyone else (including Clutch);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by Clutch;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">jeopardizes the security of your Clutch User ID, account or anyone else&rsquo;s (such as allowing someone else to log in to the Services as you);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">attempts, in any manner, to obtain the password, account, or other security information from any other user;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">violates the security of any computer network, or cracks any passwords or security encryption codes;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services&rsquo; infrastructure);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">&ldquo;crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or portion of or relating to the Services or Content (through use of manual or automated means);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">copies or stores any significant portion of the Content; or</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</span></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">A violation of any of the foregoing is grounds for termination of your right to use or access the Services.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>Are There Any Terms Specific To Me As A Student Provider?</strong></li>
</ol>
<p><span style="font-weight: 400;">No Student Provider may collect any information from or relating to Customers (&ldquo;Customer Information&rdquo;), whether via the Services, in the course of performing Student Provider Services, or otherwise, beyond what is necessary to perform the Student Provider Services for the applicable Customer from or about whom such Customer Information was collected. Student Providers also must not use any Customer Information beyond what is necessary to perform the Student Provider Services for such applicable Customer. Upon the conclusion of the Student Provider Services for a Customer (or otherwise upon the request of such Customer or Clutch), Student Provider must properly destroy all Customer Information from or relating to such Customer and make no further use of it whatsoever. Student Providers must collect, use, maintain, and transmit all Customer Information in compliance with all applicable laws.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What Are My Rights In The Services?</strong></li>
</ol>
<p><span style="font-weight: 400;">The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the &ldquo;Content&rdquo;) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else&rsquo;s (including Clutch's) rights.</span></p>
<p><span style="font-weight: 400;">Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You understand that Clutch owns the Services. You won&rsquo;t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What About Anything I Contribute To The Services &ndash; Do I Have To Grant Any Licenses To Clutch Or To Other Users?</strong></li>
</ol>
<p><em><span style="font-weight: 400;">User Submissions</span></em></p>
<p><span style="font-weight: 400;">Anything you post, upload, share, store, message in chat, or otherwise provide through the Services is your &ldquo;User Submission&rdquo;. Some User Submissions may be viewable by other users. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.&nbsp;</span></p>
<p><span style="font-weight: 400;">You agree that you will not post, upload, share, store, message in chat, or otherwise provide through the Services any User Submissions that: (i) infringe any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or (viii) that violate any law.</span></p>
<p><em><span style="font-weight: 400;">Licenses</span></em></p>
<p><span style="font-weight: 400;">In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our Privacy Policy&lt;https://www.thatsclutch.com/privacy-policy&gt; to the extent they relate to User Submissions that are also your personally-identifiable information.</span></p>
<p><span style="font-weight: 400;">By submitting User Submissions through the Services, you hereby do and shall grant Clutch a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our successors&rsquo; and assigns&rsquo;) businesses, including without limitation for promoting and redistributing part or all of this site or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after your termination of your account or the Services. You also hereby do and shall grant each user of this site and/or the Services a non-exclusive, perpetual license to access your User Submissions through this site and/or the Services, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Submissions, including after your termination of your account or the Services. For clarity, the foregoing license grants to us and our users do not affect your other ownership or license rights in your User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.</span></p>
<p><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">Finally, you understand and agree that Clutch, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What If I See Something On The Services That Infringes My Copyright?</strong></li>
</ol>
<p><span style="font-weight: 400;">In accordance with the DMCA, we&rsquo;ve adopted the following policy toward copyright infringement.&nbsp; We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content Student Providers, members or users and (2) remove and discontinue service to repeat offenders.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><em><span style="font-weight: 400;">Procedure for Reporting Copyright Infringements</span></em><span style="font-weight: 400;">.&nbsp; If you believe that material or content residing on or accessible through the Services infringes your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing the following information to Clutch's Designated Agent to Receive Notification of Claimed Infringement (our &ldquo;Designated Agent,&rdquo; whose contact details are listed below):</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Identification of works or materials being infringed;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Company is capable of finding and verifying its existence;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Contact information about the notifier including address, telephone number and, if available, email address;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its agent, or the law; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><em><span style="font-weight: 400;">Once Proper Bona Fide Infringement Notification is Received by the Designated Agent</span></em><span style="font-weight: 400;">.&nbsp; Upon receipt of a proper notice of copyright infringement, we reserve the right to:</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">remove or disable access to the infringing material;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">terminate such content provider's access to the Services if he or she is a repeat offender.</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><em><span style="font-weight: 400;">Procedure to Supply a Counter-Notice to the Designated Agent</span></em><span style="font-weight: 400;">.&nbsp; If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner's agent, or, pursuant to the law, the content provider may send us a counter-notice containing the following information to the Designated Agent:</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">A physical or electronic signature of the content provider;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Content provider's name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider&rsquo;s address is located, or, if the content provider's address is located outside the United States, for any judicial district in which Company is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</span></li>
</ol>
<p><span style="font-weight: 400;">If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the counter-notice to the original complaining party informing that person that Company may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Company's discretion.&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">Please contact Clutch's Designated Agent at the following address:</span></p>
<p><span style="font-weight: 400;">VISeat Concierge, Inc.</span></p>
<p><span style="font-weight: 400;">Attn: DMCA Designated Agent</span></p>
<p><span style="font-weight: 400;">4201 Main Street, Suite 201-152, Houston, TX&nbsp;</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>Who Is Responsible For What I See And Do On The Services?</strong></li>
</ol>
<p><span style="font-weight: 400;">Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren&rsquo;t liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can&rsquo;t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.&nbsp;</span></p>
<p><span style="font-weight: 400;">You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</span></p>
<p><span style="font-weight: 400;">The Services may contain links or connections to third-party websites or services that are not owned or controlled by Clutch. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that Clutch is not responsible for such risks.&nbsp;</span></p>
<p><span style="font-weight: 400;">Clutch has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, Clutch will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.&nbsp;</span></p>
<p><span style="font-weight: 400;">If there is a dispute between participants on this site or Services, or between users and any third party, you agree that Clutch is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release Clutch, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: &ldquo;A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.&rdquo;</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>Will Clutch Ever Change The Services?</strong></li>
</ol>
<p><span style="font-weight: 400;">We&rsquo;re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>Do The Services Cost Anything?</strong></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Paid Services. Our Services may be subject to payments now or in the future (the &ldquo;Paid Services&rdquo;).Please see our Paid Services page&lt;</span><a href="https://docs.google.com/spreadsheets/d/1vlSeZ25DPMR-TAHoNJ6boi93hqHWTTf0-Sn1IuBllA8/edit#gid=2100487255"><span style="font-weight: 400;">Clutch Paid Services Agreement</span></a><span style="font-weight: 400;">&gt; for a description of the current Paid Services. Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms. Customer and Student Provider acknowledge and agree that Customer will pay all fees due for Student Provider Services to Clutch and Clutch will remit all owed fees to Student Provider via the Payment Processor (as described below).</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Billing. We use a third-party payment processor (the &ldquo;Payment Processor&rdquo;) to bill you through a payment account linked to your account on the Services (your &ldquo;Billing Account&rdquo;) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms.Currently, we use Stripe, Inc. as our Payment Processor. You can access Stripe&rsquo;s Terms of Service at&nbsp;</span><a href="https://stripe.com/us/checkout/legal"><span style="font-weight: 400;">https://stripe.com/us/checkout/legal</span></a><span style="font-weight: 400;">&nbsp;and their Privacy Policy at&nbsp;</span><a href="https://stripe.com/us/privacy"><span style="font-weight: 400;">https://stripe.com/us/privacy</span></a><span style="font-weight: 400;">. We are not responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms, and you authorize us, through the Payment Processor, to charge your chosen payment provider (your &ldquo;Payment Method&rdquo;). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor makes even if it has already requested or received payment.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Payment Method. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS&lt;LINK&gt;. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Change in Amount Authorized. If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to receive, and we shall provide, notice of the amount to be charged and the date of the charge before the scheduled date of the transaction. Any agreement you have with your payment provider will govern your use of your Payment Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What If I Want To Stop Using The Services?&nbsp;</strong></li>
</ol>
<p><span style="font-weight: 400;">You&rsquo;re free to do that at any time by contacting us at support@thatsclutch.com; please refer to our Privacy Policy&lt;https://www.thatsclutch.com/privacy-policy&gt;, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.&nbsp;</span></p>
<p><span style="font-weight: 400;">Clutch is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. Clutch has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms; for example, a Customer who believes that a Student Provider may be in breach of these Terms is not able to enforce these Terms against that Student Provider.</span></p>
<p><span style="font-weight: 400;">Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.&nbsp;</span></p>
<p><span style="font-weight: 400;">If you have deleted your account by mistake, contact us immediately at support@thatsclutch.com &ndash; we will try to help, but unfortunately, we can&rsquo;t promise that we can recover or restore anything.</span></p>
<p><span style="font-weight: 400;">Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.&nbsp;</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What About Mobile Applications?</strong></li>
</ol>
<p><span style="font-weight: 400;">You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an &ldquo;App Store&rdquo;). Each App Store may have its own terms and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use our application is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of Use, the more restrictive or conflicting terms and conditions in these Terms of Use apply.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>I Use The Clutch App Available Via The Apple App Store &ndash; Should I Know Anything About That?</strong></li>
</ol>
<p><span style="font-weight: 400;">These Terms apply to your use of all the Services, including our iOS applications (the &ldquo;Application&rdquo;) available via the Apple, Inc. (&ldquo;Apple&rdquo;) App Store, but the following additional terms also apply to the Application:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Both you and Clutch acknowledge that the Terms are concluded between you and Clutch only, and not with Apple, and that Apple is not responsible for the Application or the Content;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">You will only use the Application in connection with an Apple device that you own or control;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Application;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">&nbsp;In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple&rsquo;s sole warranty obligation to you will be to refund to you the purchase price, if any, of the Application;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">You acknowledge and agree that Clutch, and not Apple, is responsible for addressing any claims you or any third party may have in relation to the Application;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application infringes that third party&rsquo;s intellectual property rights, Clutch, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">&nbsp;You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &ldquo;terrorist supporting&rdquo; country, and that you are not listed on any U.S. Government list of prohibited or restricted parties;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Both you and Clutch acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party terms of agreement which may affect or be affected by such use; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Both you and Clutch acknowledge and agree that Apple and Apple&rsquo;s subsidiaries are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</span></li>
</ol>
<ol>
<li style="font-weight: 400;" aria-level="1"><strong>What Else Do I Need To Know?</strong></li>
</ol>
<p><em><span style="font-weight: 400;">Warranty Disclaimer.</span></em><span style="font-weight: 400;">&nbsp;Clutch and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Clutch and all such parties together, the &ldquo;Clutch Parties&rdquo;) make no representations or warranties concerning the Services, including without limitation regarding any Content contained in or accessed through the Services, and the Clutch Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Clutch Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Services, including any Student Provider Services. THE SERVICES AND CONTENT ARE PROVIDED BY Clutch (AND ITS LICENSORS AND SUPPLIERS) ON AN &ldquo;AS-IS&rdquo; BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.&nbsp;</span></p>
<p><em><span style="font-weight: 400;">Limitation of Liability</span></em><span style="font-weight: 400;">. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE Clutch PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO Clutch IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.&nbsp;</span></p>
<p><em><span style="font-weight: 400;">Indemnity</span></em><span style="font-weight: 400;">. You agree to indemnify and hold the Clutch Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms.&nbsp;</span></p>
<p><em><span style="font-weight: 400;">Assignment</span></em><span style="font-weight: 400;">. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Clutch's prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</span></p>
<p><em><span style="font-weight: 400;">Choice of Law</span></em><span style="font-weight: 400;">. These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of Texas, without regard to the conflicts of laws provisions thereof.</span></p>
<p><em><span style="font-weight: 400;">Arbitration Agreement.&nbsp;</span></em><span style="font-weight: 400;">Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with Clutch and limits the manner in which you can seek relief from Clutch. Both you and Clutch acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms, Clutch's officers, directors, employees and independent contractors (&ldquo;Personnel&rdquo;) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</span></p>
<p><em><span style="font-weight: 400;">(a) Arbitration Rules; Applicability of Arbitration Agreement</span></em><span style="font-weight: 400;">. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in Houston, TX. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the &ldquo;Rules&rdquo;) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction.&nbsp;</span></p>
<p><span style="font-weight: 400;">(b)&nbsp;</span><em><span style="font-weight: 400;">Costs of Arbitration</span></em><span style="font-weight: 400;">. The Rules will govern payment of all arbitration fees. Clutch will pay all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Clutch will not seek its attorneys&rsquo; fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.&nbsp;</span></p>
<p><span style="font-weight: 400;">(c)&nbsp;</span><em><span style="font-weight: 400;">Small Claims Court; Infringement</span></em><span style="font-weight: 400;">. Either you or Clutch may assert claims, if they qualify, in small claims court inHouston, TX or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.&nbsp;</span></p>
<p><span style="font-weight: 400;">(d)&nbsp;</span><em><span style="font-weight: 400;">Waiver of Jury Trial</span></em><span style="font-weight: 400;">. YOU AND Clutch WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Clutch are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and Clutch over whether to vacate or enforce an arbitration award, YOU AND Clutch WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.</span></p>
<p><span style="font-weight: 400;">(e)&nbsp;</span><em><span style="font-weight: 400;">Waiver of Class or Consolidated Actions</span></em><span style="font-weight: 400;">. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor Clutch is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (g) below.</span><strong>&nbsp;</strong></p>
<p><span style="font-weight: 400;">(f)&nbsp;</span><em><span style="font-weight: 400;">Opt-out</span></em><span style="font-weight: 400;">. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 4201 Main Street, 201-152, Houston, TX postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms&rsquo; arbitration agreement.</span></p>
<p><span style="font-weight: 400;">(g)&nbsp;</span><em><span style="font-weight: 400;">Exclusive Venue</span></em><span style="font-weight: 400;">. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or Clutch to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and Clutch agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in, respectively, Houston, TX, or the federal district in which that county falls.&nbsp;</span></p>
<p><span style="font-weight: 400;">(h)&nbsp;</span><em><span style="font-weight: 400;">Severability</span></em><span style="font-weight: 400;">. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with Clutch.</span></p>
<p><em><span style="font-weight: 400;">Miscellaneous</span></em><span style="font-weight: 400;">. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the Clutch may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and Clutch agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Clutch, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Clutch, and you do not have any authority of any kind to bind Clutch in any respect whatsoever.&nbsp;</span></p>
<p><span style="font-weight: 400;">Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and Clutch agree there are no third-party beneficiaries intended under these Terms.</span></p>
<p><br /><br /></p>
</html>`;
const Terms = () => {
  return (
    <LayoutScroll>
      <div className="p-8">
        <div dangerouslySetInnerHTML={{ __html: myHTML }} />
      </div>
    </LayoutScroll>
  );
};

export default Terms;
