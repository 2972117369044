import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import { AmpAPI } from "@thatsclutch/shared";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function SubmitModal({
  children,
  onComplete,
  collabID,
  //   month,
  parentID = null,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // clear state if !open
  }, [open]);

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      if (!link.trim()) throw "Invalid link.";

      const result = await AmpAPI.put(
        "CampusAPI",
        "/collab/" + collabID + "/submit",
        {
          body: {
            links: link.trim(),
            note: note.trim(),
            // isLate: dayjs().isAfter(month.endDate),
            // lateDate: month.endDate.subtract(1, "h").format(),
            // parentID: parentID,
          },
        }
      );

      setLink("");
      setNote("");
      setOpen(false);
      onComplete();
    } catch (e) {
      setError(e.message || e);
      console.error(e.message || e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="w-full md:w-min"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {children}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-3xl sm:my-8 sm:w-full sm:max-w-2xl">
                  <div className="p-8 py-12 space-y-4 bg-white">
                    <div className="pb-4">
                      <div className="pb-2 text-3xl font-medium">
                        {parentID ? "New revision" : "Final Asset"}
                      </div>
                      <div className="text-sm text-slate-600">
                        Upload your files to Google Drive or similar and paste
                        your link here. The client will get notified about your
                        submission.
                      </div>
                      <div className="pt-2 text-sm text-slate-900">
                        Verify the link works before submitting.
                      </div>
                      {/* {dayjs().isAfter(month.endDate) && (
                        <div className="p-4 mt-4 text-sm text-yellow-500 rounded-xl bg-yellow-50">
                          This submission will be marked late. Please submit all
                          submissions on time.
                        </div>
                      )} */}
                    </div>
                    <div className="space-y-4">
                      <input
                        onChange={(e) => setLink(e.target.value)}
                        value={link}
                        placeholder="Asset link... (ex. https://example.com)"
                        className="w-full px-6 py-3 text-sm border rounded-full outline-none focus:border-green-200"
                      />
                      <textarea
                        onChange={(e) => {
                          if (e.target.value.length > 500)
                            return setError("Note too long");
                          else setError("");
                          setNote(e.target.value);
                        }}
                        value={note}
                        placeholder="Message... (optional)"
                        className="w-full h-24 px-6 py-4 text-sm border border-gray-200 outline-none focus:ring-0 focus:outline-none rounded-3xl focus:border-green-200"
                      />
                      {error && (
                        <div className="py-2 text-xs text-red-500">{error}</div>
                      )}
                    </div>
                  </div>
                  <div className="px-8 pb-8 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-6 py-3 text-lg font-light text-white bg-black border border-transparent rounded-full shadow-sm hover:opacity-60 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <div className="flex space-x-2">
                        {loading && (
                          <div className="flex items-center justify-center">
                            <svg
                              className="z-10 w-5 h-5 text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        )}
                        <div>Submit for review</div>
                      </div>
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-6 py-3 mt-3 text-base font-medium bg-white border rounded-full shadow-sm text-slate-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
