import { getCollabsByCreatorID } from "@thatsclutch/shared/services/CollabService";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ProfileImage from "../../../Basic/ProfileImage";
export default function Collabs({ creatorID, creator, onSelectCollab }) {
  // TODO get all collabs
  const [collabs, setCollabs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    creatorID && getCollabs();
  }, [creatorID]);

  const getCollabs = async () => {
    setLoading(true);
    const result = await getCollabsByCreatorID(creatorID);
    console.log(result);
    setCollabs(result);
    setLoading(false);
  };

  const sortByCreated = (a, b) => {
    if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return 1;
    if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) return -1;
    else return 0;
  };

  return creator.status !== "ACTIVE" ? (
    <div className="w-full h-screen max-w-6xl p-8 mx-auto space-y-8 overflow-y-scroll ">
      <div className="text-2xl font-medium">My Collabs</div>
      <div>Complete your profile checklist to see and manage collabs</div>
    </div>
  ) : (
    <div className="h-screen overflow-y-scroll bg-gray-50 ">
      <div className="w-full max-w-6xl pb-8 mx-auto">
        <div className="grid grid-cols-1 gap-8 px-8 md:p-8 lg:grid-cols-2">
          <div className="flex-1 pt-8 space-y-6 md:pt-0">
            <div className="flex justify-between">
              <div className="flex items-end space-x-4">
                <div className="text-2xl font-medium">Requests</div>
                {loading && (
                  <span className="pb-2">
                    <svg
                      className="w-4 h-4 animate-spin text-slate-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </div>
              <div className="text-2xl font-medium">
                {collabs &&
                  collabs.filter((v) => v.status === "REQUESTED").length}
              </div>
            </div>
            {/* Requests table */}
            {collabs &&
              collabs
                .filter((v) => v.status == "REQUESTED")
                .sort(sortByCreated)
                .map((collab) => (
                  <div
                    onClick={() => onSelectCollab(collab)}
                    className="z-20 p-6 transition-all bg-white shadow-xl cursor-pointer hover:shadow-slate-200/90 rounded-xl shadow-slate-200/50"
                  >
                    <div className="space-y-6">
                      {/* TOP */}
                      <div className="flex items-center justify-between space-x-4 ">
                        <div className="flex items-center space-x-4">
                          {/* <div className="">
                          <ProfileImage
                            profileImage={collab?.client?.image}
                            size="sm"
                          />
                        </div> */}
                          <div>
                            <div className="text-xl font-medium capitalize ">
                              {collab.name}
                            </div>
                            <div className="capitalize text-slate-500">
                              {collab?.client?.brand}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* BOTTOM */}
                      <div className="flex items-center justify-between p-4 divide-x rounded text-slate-600 bg-slate-50 ">
                        <div className="flex-1 h-full px-4 text-center">
                          {"$" + collab?.price / 100}
                        </div>
                        <div className="flex-1 h-full px-4 text-center">
                          {collab?.deliverableCount} assets
                        </div>
                        <div className="flex-1">
                          <div className="flex-1 h-full px-4 text-center">
                            {collab?.isOngoing ? "Monthly" : "One-time"}
                          </div>
                        </div>
                      </div>
                      {/* <div className="whitespace-pre-wrap text-slate-600 line-clamp-3">
                      {collab.requirements}
                    </div> */}
                    </div>
                  </div>
                ))}
            {loading ? (
              <div className="p-6 text-center transition-all text-slate-400 bg-slate-100 rounded-xl">
                Loading...
              </div>
            ) : (
              collabs &&
              collabs.filter((v) => v.status === "REQUESTED").length == 0 && (
                <div className="p-6 text-center transition-all text-slate-400 bg-slate-100 rounded-xl">
                  None yet
                </div>
              )
            )}
          </div>
          <div className="space-y-6">
            <div className="flex justify-between">
              <div className="flex items-end space-x-4">
                <div className="text-2xl font-medium">Active Collabs</div>
                {loading && (
                  <span className="pb-2">
                    <svg
                      className="w-4 h-4 animate-spin text-slate-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </span>
                )}
              </div>
              <div className="text-2xl font-medium">
                {collabs &&
                  collabs.filter(
                    (v) => v.status !== "REQUESTED" && v.status !== "REJECTED"
                  ).length}
              </div>
            </div>
            {/* Collab Table */}
            {collabs &&
              collabs
                .filter(
                  (v) => v.status !== "REQUESTED" && v.status !== "REJECTED"
                )
                .sort(sortByCreated)
                .map((collab) => (
                  <div
                    onClick={() => onSelectCollab(collab)}
                    className="z-20 p-6 space-y-6 transition-all bg-white shadow-xl cursor-pointer hover:shadow-slate-200/90 rounded-xl shadow-slate-200/50"
                  >
                    <div className="flex items-center justify-between ">
                      {/* TOP */}
                      <div className="flex items-center justify-between space-x-4 ">
                        <div className="flex items-center space-x-4">
                          {/* <div>
                          <ProfileImage
                            profileImage={collab?.client?.image}
                            size="sm"
                          />
                        </div> */}
                          <div>
                            <div className="text-xl font-medium capitalize ">
                              {collab.name}
                            </div>
                            <div className="capitalize text-slate-500">
                              {collab?.client?.brand}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* BOTTOM */}
                      <div className="space-y-4 text-end">
                        <div className="flex justify-end">
                          <div className="px-2 py-1 text-xs text-white bg-blue-400 rounded-md">
                            {collab?.status === "PAUSED"
                              ? "ENDING"
                              : collab.status}
                          </div>
                        </div>
                        {/* {(collab?.status === "ACTIVE" ||
                        collab?.status === "ACCEPTED") && (
                        <div>
                          {collab?.isOngoing ? (
                            <div className="text-sm text-slate-400">
                              Monthly
                            </div>
                          ) : (
                            <div>
                              <div className="text-sm text-slate-400">
                                Ending on:
                              </div>
                              <div className="text-base text-slate-800">
                                {dayjs(collab?.endDate).format("DD MMM, YYYY")}
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}
                      </div>
                    </div>
                    {/* BOTTOM */}
                    <div className="flex items-center justify-between p-4 divide-x rounded text-slate-600 bg-slate-50 ">
                      <div className="flex-1 h-full px-4 text-center">
                        {"$" + collab?.price / 100}
                      </div>
                      <div className="flex-1 h-full px-4 text-center">
                        {collab?.deliverableCount} assets
                      </div>
                      <div className="flex-1">
                        <div className="flex-1 h-full px-4 text-center">
                          {collab?.isOngoing ? "Monthly" : "One-time"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {loading ? (
              <div className="p-6 text-center transition-all text-slate-400 bg-slate-100 rounded-xl">
                Loading...
              </div>
            ) : (
              collabs &&
              collabs.filter((v) => v.status !== "REQUESTED").length == 0 && (
                <div className="p-6 text-center transition-all text-slate-400 bg-slate-100 rounded-xl">
                  None yet
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
