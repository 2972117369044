import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, Input, Row, Col, Divider } from 'antd';
import { useToasts } from 'react-toast-notifications';

const UpdatePassword = () => {
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const onUpdate = async values => {
    console.log('Values: ', values);

    // get new and old passwords
    const { oldPassword, confirmPassword } = values;
    if (oldPassword.length < 6 || confirmPassword < 6) {
      addToast('Password must be at least 6 characters long', {
        appearance: 'error'
      });
      return;
    }
    setLoading(true);
    // get user
    const user = await Auth.currentAuthenticatedUser();
    // changepass
    Auth.changePassword(user, oldPassword, confirmPassword)
      .then(data => {
        console.log(data);
        form.resetFields();
        addToast('Password has been updated', {
          appearance: 'success'
        });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        addToast(err.message, {
          appearance: 'error'
        });
        setLoading(false);
      });
  };

  return (
    <div className="p-5 ">
      <Row>
        <Col span={24} lg={{ span: 12 }} className="p-4">
          <Form
            form={form}
            layout="vertical"
            onFinish={onUpdate}
            name="complex-form"
          >
            {/* <Space
              split={}
              direction="vertical"
              size={4}
            > */}
            <Form.Item
              label="Current Password"
              name="oldPassword"
              hasFeedback
              rules={[
                { required: true, message: 'Please enter your password!' }
              ]}
            >
              <Input.Password placeholder="Type password..." />
            </Form.Item>
            <Divider type="horizontal" />
            <Form.Item
              label="New Password"
              name="newPassword"
              hasFeedback
              rules={[
                { required: true, message: 'Please enter your password!' }
              ]}
            >
              <Input.Password placeholder="Type password..." />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Confirm password must match.');
                  }
                })
              ]}
            >
              <Input.Password placeholder="Type password..." />
            </Form.Item>

            {/* </Space> */}
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Update Password
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatePassword;
