import React, { useState } from "react";
import { message } from "antd";
import { updateCreatorPicture } from "@thatsclutch/shared/services/UserService";
import { Transition } from "@headlessui/react";
import { FiLoader } from "react-icons/fi";
import SimpleModal from "../../../../Dialogs/SimpleModal";

export default function UploadImage({ open, onClose, onSubmit, creator }) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleUpload = async (currFile) => {
    try {
      setLoading(true);

      // check image size and type
      const isLt2M = currFile?.size / 1024 / 1024 < 10;
      const isJpgOrPng =
        currFile.type === "image/jpeg" || currFile.type === "image/png";

      if (!isJpgOrPng) {
        setLoading(false);
        message.error("You can only upload JPG/PNG file!");
        return;
      } else if (!isLt2M) {
        setLoading(false);
        message.error("Image must smaller than 10MB!");
        return;
      } else {
        await updateCreatorPicture(currFile, creator).then((data) => {
          getBase64(currFile, (imageUrl) => {
            setImageUrl(imageUrl);
            setLoading(false);
            onSubmit();
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // getBase allows you to preview image
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  return (
    <SimpleModal show={open} onClose={onClose}>
      <Transition.Root show={true}>
        <div className="p-8 space-y-8">
          <div>
            <div className="text-2xl font-semibold">Profile photo</div>
            <div className="mt-2 text-sm text-slate-500">
              Update your public profile photo
            </div>
          </div>
          <div className="p-4 px-6 rounded-xl bg-[#E4FDE8]">
            <div className="text-xl font-medium ">
              ✨ A good photo helps you stick out from the crowd.
            </div>
            <div className="text-sm font-thin ">
              It puts your personality out there for the world to see and shows
              people that you take pride in yourself.
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-6">
            {loading ? (
              <FiLoader className="w-4 h-4 text-black animate-spin" />
            ) : imageUrl ? (
              <img
                src={imageUrl}
                width={80}
                height={80}
                className="w-32 h-32 rounded-lg "
              />
            ) : (
              ""
            )}
            <form>
              <input
                onChange={(e) => handleUpload(e.target.files[0])}
                type="file"
                accept=".jpeg, .png"
                className="pb-3 cursor-pointer w-60 text-hidden"
              />
              <div className="text-center ">
                <div>Recommended size: 1600 x 1200 </div>
                <div>Max size: 10MB</div>
              </div>
            </form>
          </div>
        </div>
      </Transition.Root>
    </SimpleModal>
  );
}
