import React, { useEffect, useState } from "react";
import {
  useAdminDispatch,
  useAdminState,
} from "../../../../contexts/AdminContext";
import { FiX, FiLoader } from "react-icons/fi";
import moment from "moment";
import ListItem from "./ListItem";
import {
  getCreators,
  getSkills,
} from "@thatsclutch/shared/services/CreatorService";
import ActionBar from "../ActionBar";
import SideModal from "../../../Dialogs/SideModal";
import Details from "./Details";
import { Index } from "react-instantsearch";
import SearchTable from "./SearchTable";
import CreatorHit from "../../../Admin/Search/CreatorHit";
import { useMatchState } from "../../../../contexts/MatchContext";
import MatchBar from "../MatchBar";
import {
  clearNav,
  useNavDispatch,
  useNavState,
} from "../../../../contexts/NavContext";

export const CopyContext = React.createContext({
  copiedEmails: [],
  setCopiedEmails: (val) => {},
});

export default function CreatorList() {
  const navDispatch = useNavDispatch();
  const { profileID = null } = useNavState();
  const [selected, setSelected] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const { isMatching, brief } = useMatchState();
  const [copiedEmails, setCopiedEmails] = useState(
    (localStorage.getItem("copiedEmails") &&
      JSON.parse(localStorage.getItem("copiedEmails"))) ||
      []
  );

  useEffect(() => {
    localStorage.setItem("copiedEmails", JSON.stringify(copiedEmails));
  }, [copiedEmails]);

  useEffect(() => {
    if (profileID) {
      setSelected(profileID);
      setShowDetail(true);
      clearNav(navDispatch);
    }
  }, []);

  return (
    <div className="h-full bg-zinc-50">
      {isMatching ? (
        <MatchBar />
      ) : (
        <div className="flex items-baseline p-8 py-4 pt-12">
          <div className="text-2xl font-semibold sm:text-4xl">Creators</div>
        </div>
      )}
      <div className="flex flex-col flex-1 p-8 overflow-y-auto">
        <CopyContext.Provider value={{ copiedEmails, setCopiedEmails }}>
          <Index indexName="creator">
            <SearchTable
              hitItem={({ hit }) => (
                <CreatorHit
                  hit={hit}
                  onSelected={({ id }) => {
                    setSelected(id);
                    setShowDetail(true);
                  }}
                />
              )}
            />
          </Index>
        </CopyContext.Provider>

        <SideModal
          visible={showDetail}
          onClose={() => {
            setShowDetail(false);
            setSelected(null);
          }}
          wide={true}
        >
          <Details
            onUpdate={() => {}}
            onRefresh={() => {}}
            currItem={selected}
            onClose={() => {
              setShowDetail(false);
              setSelected(null);
            }}
          />
        </SideModal>
      </div>
    </div>
  );
}
