import React, { useState } from "react";
import { message } from 'antd';
import { updateClientApplicant } from "@thatsclutch/shared/services/ApplicationService";
import { formatPhoneNumber } from '../../lib/utils/phoneNumber.js'
import SideModal from "../Dialogs/SideModal/index";
import { useErrorDispatch, showError } from "../../contexts/ErrorContext";

function EditClientApplicantForm({
  selected,
  onSaved,
  visible,
  onClose
}) {

  const dispatch = useErrorDispatch()
  const [firstName, setFirstName] = useState(selected?.firstName)
  const [lastName, setLastName] = useState(selected?.lastName)
  const [email, setEmail] = useState(selected?.email)
  const [phoneNumber, setPhoneNumber] = useState(selected?.phoneNumber)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState("")

  const handleError = () => {
    showError(dispatch, "Error updating applicant");
  };

  const checkEmail = /^([A-Za-z\d-\._+]+)@([a-z\d-]+)\.([a-z]{2,5})$/.test(email)
  const checkAnswers = () => {
    let tempError = "";
    if (!firstName.trim()) tempError = "Missing first name";
    else if (!lastName.trim()) tempError = "Missing last name";
    else if (!email.trim()) tempError = "Missing email address";
    else if (checkEmail == false) tempError = "Invalid email address"
    else if (phoneNumber.replace(/[^\d]/g, "").length != 10)
      tempError = "Missing or invalid phone";
    setError(tempError);
    return !tempError;
  };


  const onSavePOC = async (client) => {
    try {
      setSaving(true);
      checkAnswers()
      const result = await updateClientApplicant({
        id: client?.id,
        _version: client?._version,
        firstName,
        email,
        lastName,
        phoneNumber: phoneNumber ? phoneNumber.replace(/[^\d]/g, "") : "",
      });
      console.log(result)
      setSaving(false)
      await onSaved(result);
      onClose();
      message.success("Saved", 1.5)
    } catch (e) {
      handleError()
    }
  };

  const pocChanged =
    firstName !== selected?.firstName ||
    lastName !== selected?.lastName || email !== selected?.email ||
    phoneNumber !== formatPhoneNumber(selected?.phoneNumber);

  return (
    <SideModal visible={visible} onClose={onClose}>
      <div className="p-8">
        <div className="mb-6">
          <div className='text-[32px] font-semibold pb-3'>Edit Applicant</div>
          {!!error && <div className="text-red-400">{error}</div>}
        </div>
        <div className='flex flex-col space-y-8'>

          <div>
            <div className="pb-2">First Name</div>
            <input
              className="w-full px-6 py-4 rounded-full outline-none ring-1 ring-black focus:ring-2"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div>
            <div className="pb-2">Last Name</div>
            <input
              className="w-full px-6 py-4 rounded-full outline-none ring-1 ring-black focus:ring-2"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div>
            <div className="pb-2">Email Address</div>
            <input
              className="w-full px-6 py-4 rounded-full outline-none ring-1 ring-black focus:ring-2 "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <div className="pb-2">Phone Number</div>
            <input
              className="w-full px-6 py-4 rounded-full outline-none ring-1 ring-black focus:ring-2"
              value={phoneNumber}
              type="tel"
              onChange={(e) => {
                // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
                const formattedPhoneNumber = formatPhoneNumber(
                  e.target.value
                );
                // we'll set the input value using our setInputValue
                setPhoneNumber(formattedPhoneNumber);
              }}
            />
          </div>

          <div className="flex justify-end">
            <button
              disabled={saving || !pocChanged}
              onClick={() => checkAnswers() && onSavePOC(selected)}
              className={`${pocChanged
                ? "bg-black hover:opacity-50 cursor-pointer"
                : "bg-slate-200"
                } px-6 py-3 text-white  rounded-full  `}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </SideModal>
  );
}

export default EditClientApplicantForm;