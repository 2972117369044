import { getProfilePicture } from "@thatsclutch/shared/services/UserService";
import { Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const STATUS = {
  INVITED: { color: "#E4FDE8", copy: "Invite sent", textColor: "black" },
  PENDING: { color: "#E4FDE8", copy: "Setting up", textColor: "black" },
  ACTIVE: { color: "#E4FDE8", copy: "Active", textColor: "black" },
  EMPTY: { color: "#eeeeee", copy: "None", textColor: "black" },
  DEACTIVATED: { color: "#eeeeee", copy: "Deactivated", textColor: "black" },
  ENTERPRISE: { color: "#eeeeee", copy: "Enterprise", textColor: "black" },
  TRIAL: { color: "#fdba74", copy: "Trial", textColor: "black" },
};

const MATCH_STATUS = {
  NEEDS_MATCHES: {
    color: "#149AE5",
    copy: "Needs Recommendations",
    textColor: "black",
  },
  MATCHES_SENT: {
    color: "#333333",
    copy: "Recommendations Sent",
    textColor: "white",
  },
};

export default function Row({ client, onClick }) {
  const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    getProfileImage();
  }, []);

  const getProfileImage = async () => {
    await getProfilePicture(client).then((src) => {
      setProfilePhoto(src);
    });
    return profilePhoto;
  };

  return (
    <div
      key={client.id + client.updatedAt}
      onClick={onClick}
      className="grid items-center w-full grid-cols-7 gap-2 p-4 transition-colors cursor-pointer hover:bg-zinc-100"
    >
      {/* 1 */}
      <div className="line-clamp-1">
        <div className="flex lowercase first-letter:capitalize text-zinc-500">
          <div
            className={`line-clamp-1 capitalize min-w-max px-3 py-1 text-sm text-${
              STATUS[client?.status || "EMPTY"].textColor
            } lowercase rounded-full 
				  bg-[${STATUS[client?.status || "EMPTY"].color}]
              first-letter:capitalize`}
          >
            {STATUS[client?.status || "EMPTY"].copy}
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className="py-1 space-y-1">
        <div className="capitalize">{client?.brand}</div>
      </div>
      {/* 3 */}
      <div className="line-clamp-1">${client.budget}</div>
      {/* 4 */}
      <div className="line-clamp-1">
        {client.priority === "HIGH" ? "Yes" : "No"}
      </div>
      {/* 5 */}
      <div className="line-clamp-1">
        {dayjs(client?.createdAt).format("DD MMM, YYYY")}
      </div>
      {/* 6 */}
      <div className="col-span-2 text-sm min-w-min">
        <div className="whitespace-pre-line line-clamp-1">{client?.notes}</div>
      </div>
    </div>
  );
}
