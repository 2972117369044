import React, { useState, useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import { Auth } from 'aws-amplify';

import { AppUser } from '../../Auth';

import { Button, Form, Checkbox, Input, Typography } from 'antd';
import { useToasts } from 'react-toast-notifications';

import { useAppStore } from '../../../stores/app';

const ForgotPassword = () => {
  const { Title, Text } = Typography;
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams();

  useEffect(() => {
    form.setFieldsValue({
      username: params.username
    });
    return () => {};
  }, [params]);

  const sendResetPasswordCode = values => {
    Auth.forgotPassword(values.username)
      .then(() => {
        addToast(
          'Success! Please check your email for the code. Redirecting to reset password page...',
          {
            appearance: 'success'
          }
        );
        setTimeout(() => {
          navigate('/app/resetpassword');
        }, 6000);
        setLoading(false);
        setIsDisabled(true);
      })
      .catch(err => {
        setLoading(false);
        setIsDisabled(false);
        addToast(
          err.message ||
          'Something went wrong. Please double check your email or contact us at support@thatsclutch.com.',
          {
            appearance: 'error'
          }
        );
      });
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col w-1/2 space-y-6">
        <div className="text-center">
          <Title level={2}>Request Password Reset</Title>
          <Text level={5}>An email will be sent with a retreival code</Text>
        </div>
        <Form
          form={form}
          initialValues={{
            terms: false
          }}
          layout="vertical"
          onFinish={values => {
            setLoading(true);
            sendResetPasswordCode(values);
          }}
          size="large"
          requiredMark={false}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid email!',
              },
              { required: true, message: 'Please enter your email!' }
            ]}
          >
            <Input name="username" placeholder="example@domain.com" />
          </Form.Item>
          <div>
            <Button
              name="submit"
              type="primary "
              className="bg-[#30DF87]"
              style={{ background: '#30DF87', border: 'none' }}
              shape="round"
              size="large"
              loading={isLoading}
              htmlType="submit"
              disabled={isDisabled}
            >
              Send Reset Email
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
