import React from "react";
import { FiLoader } from "react-icons/fi";
import Row from "./Row";

export default function Table({
  clients,
  nextToken,
  loading,
  loadMore,
  onSelect,
}) {
  return (
    <div className="pb-8 space-y-4 overflow-x-auto ">
      {/* Header */}
      <div className="w-full overflow-x-auto min-w-fit">
        <div className="w-full px-8 pt-4 divide-y">
          <div className="grid grid-cols-7 gap-2 p-4 text-sm rounded-sm text-zinc-400">
            <div className="line-clamp-1">Status</div>
            <div className="">Name</div>
            <div className="line-clamp-1">Budget</div>
            <div className="line-clamp-1">Matching</div>
            <div className="line-clamp-1">Joined</div>
            <div className="col-span-2 line-clamp-1">Notes</div>
          </div>

          {/* Rows */}
          {clients &&
            clients.length > 0 &&
            clients.map((item) => (
              <Row key={item.id} onClick={() => onSelect(item)} client={item} />
            ))}

          {/* PAGINATION */}
          {nextToken && (
            <div
              className="w-full flex items-center justify-center space-x-4 py-4 text-center text-black transition-colors cursor-pointer hover:bg-[#E9F2C1]"
              onClick={loadMore}
            >
              {loading && (
                <div>
                  <FiLoader className="w-4 h-4 text-black animate-spin" />
                </div>
              )}
              <div>Load more</div>
            </div>
          )}

          <div>
            {/* Empty */}
            {!loading && clients.length === 0 && (
              <div className="flex items-center justify-center p-8 mt-4 rounded-lg bg-zinc-100">
                <div className="text-base text-zinc-400">No clients found.</div>
              </div>
            )}

            {/* Loading */}
            {loading && clients.length === 0 && (
              <div className="flex items-center justify-center p-8 mt-4 rounded-lg bg-zinc-100">
                <div className="text-base text-zinc-400">Loading...</div>
              </div>
            )}

            {/* Footer
            {!loading && clients.length > 0 && (
              <div className="flex items-center justify-center p-8">
                <div className="text-sm text-zinc-400">End of results</div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
