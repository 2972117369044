import React from "react";
import { Drawer, Grid } from "antd";

const { useBreakpoint } = Grid;

export default function SideModal({
  visible,
  onClose,
  children,
  wide = false,
}) {
  const screens = useBreakpoint();

  return (
    <Drawer
      placement="right"
      width={wide ? "50%" : screens.md ? "50%" : "90%"}
      onClose={onClose}
      open={visible}
      closable={false}
      size="large"
      headerStyle={{ backgroundColor: "#FFFFFF" }}
      bodyStyle={{ padding: 0, margin: 0, backgroundColor: "#FFFFFF" }}
    >
      {children}
    </Drawer>
  );
}
