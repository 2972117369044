import React from "react";

export default function LinkInput({
  label,
  values,
  error = null,
  prefix = null,
  onChange,
  extraClass = "",
  required = false,
  ...props
}) {
  return (
    <div className="space-y-2">
      <label className="text-sm text-slate-800">
        {label}
        {required && " *"}
      </label>
      <div className="space-y-2">
        <div className="flex border h-[40px] border-slate-200 focus-within:ring-1 focus-within:ring-emerald-300 rounded-full items-center">
          {prefix && <div className="pl-4">{prefix}</div>}
          <input
            className={`bg-transparent px-4 h-10  py-2 outline-none flex-1 md:min-w-[220px] ${extraClass}`}
            value={values[0] || ""}
            placeholder={"required (ex: https://tiktok.com/me)"}
            type="text"
            autoCapitalize="sentences"
            onChange={(e) => onChange(e.target.value, 0)}
            {...props}
          />
        </div>
        <div className="flex border h-[40px] border-slate-200 focus-within:ring-1 focus-within:ring-emerald-300 rounded-full items-center">
          {prefix && <div className="pl-4">{prefix}</div>}
          <input
            className={`bg-transparent px-4 h-10  py-2 outline-none flex-1 md:min-w-[220px] ${extraClass}`}
            value={values[1] || ""}
            placeholder={"optional (example: IG,FB)"}
            type="text"
            autoCapitalize="sentences"
            onChange={(e) => onChange(e.target.value, 1)}
            {...props}
          />
        </div>
        <div className="flex border h-[40px] border-slate-200 focus-within:ring-1 focus-within:ring-emerald-300 rounded-full items-center">
          {prefix && <div className="pl-4">{prefix}</div>}
          <input
            className={`bg-transparent  px-4 h-10 py-2 outline-none flex-1 md:min-w-[220px] ${extraClass}`}
            value={values[2] || ""}
            placeholder={"optional (example: portfolio)"}
            type="text"
            autoCapitalize="sentences"
            onChange={(e) => onChange(e.target.value, 2)}
            {...props}
          />
        </div>
      </div>
      <div className="text-xs text-red-400">{error}</div>
    </div>
  );
}
