import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "antd";

const HelpScreen = () => {
  // const [text, setText] = useState('')
  // useEffect(() => {
  //   const notionDocId = "29d9005bff344f18816c914e7f48ad09"
  //   fetch("https://potion-api.now.sh/html?id=" + notionDocId)
  //     .then(res => res.text())
  //     .then(text => {
  //       setText(text)
  //     }).catch(e => {
  //     })
  // }, []);

  return (
    <div className="p-8 space-y-4">
      <div className="text-center text-lg font-bold">Coming soon!</div>
      <div className="text-center">
        For help or questions, please reach out to{" "}
        <a
          href={"mailto: support@thatsclutch.com"}
          target="_blank"
          className="text-inherit underline"
        >
          support@thatsclutch.com
        </a>
      </div>
    </div>
    // <div className="p-5 h-full overflow-y-auto" dangerouslySetInnerHTML={{ __html: text }}>
    // </div>
  );
};

export default HelpScreen;
