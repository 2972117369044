import React from "react";
import { FiCopy } from "react-icons/fi";

export default function InfoRow({
  label,
  text,
  value,
  icon = null,
  copy = false,
  rightLabel = null,
  rightText,
  rightValue,
  rightIcon = null,
  rightCopy = false,
}: any) {
  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <div className="flex justify-between py-4 space-x-6 border-b border-b-slate-100">
      {/* Left */}
      <div className="flex items-center justify-between flex-1 space-x-2">
        <div className="flex items-center space-x-4 ">
          {!!icon && icon}
          {label && <div className="text-sm font-medium">{label}</div>}
          <div className="text-sm">{text}</div>
        </div>
        {copy && (
          <div
            className="p-2 cursor-pointer hover:opacity-50"
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(value);
            }}
          >
            <FiCopy />
          </div>
        )}
      </div>
      {/* Right */}
      {rightText && (
        <div className="flex items-center justify-between flex-1 space-x-2">
          <div className="flex items-center space-x-4 ">
            {!!rightIcon && rightIcon}
            {rightLabel && (
              <div className="text-sm font-medium">{rightLabel}</div>
            )}
            <div className="text-sm ">{rightText}</div>
          </div>
          {rightCopy && (
            <div
              className="p-2 cursor-pointer hover:opacity-50"
              onClick={(e) => {
                e.preventDefault();
                copyToClipboard(rightValue);
              }}
            >
              <FiCopy />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
