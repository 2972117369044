import React, { useEffect, useMemo, useState } from "react";
import SimpleModal from "../../../../../Dialogs/SimpleModal";
import { Transition } from "@headlessui/react";
import DetailPage from "./DetailPage";
import EditNamePage from "./EditNamePage";
import EditPricePage from "./EditPricePage";

export default function ServiceEditModal({
  open = false,
  onSave,
  service,
  onClose,
}) {
  const [page, setPage] = useState("detail");
  const [updatedService, setUpdatedService] = useState({
    name: service?.name,
    desc: service?.description,
    price: service?.price,
  });

  useEffect(() => {
    // temporary clean up function, clear after successful upload instead
    open && cleanUp();
  }, [open]);

  const cleanUp = () => {
    setUpdatedService({
      name: service?.name,
      desc: service?.description,
      price: service?.price,
    });
    setPage("detail");
  };

  const showPage = {
    detail: (
      <DetailPage
        service={service}
        onClose={onClose}
        onSave={onSave}
        updatedService={updatedService}
        onEdit={() => {
          setPage("name");
        }}
      />
    ),
    name: (
      <EditNamePage
        currName={updatedService?.name || service?.name}
        currDesc={updatedService?.desc || service?.description}
        onBack={() => setPage("detail")}
        onNext={(name, desc) => {
          setUpdatedService({ ...updatedService, name, desc });
          setPage("price");
        }}
      />
    ),
    price: (
      <EditPricePage
        currPrice={updatedService?.price || service?.price}
        onNext={(val) => {
          setUpdatedService({ ...updatedService, price: val });
          setPage("detail");
        }}
        onBack={() => {
          setPage("name");
        }}
      />
    ),
  };

  const onCancel = () => {
    onClose(false);
  };

  return (
    <SimpleModal show={open} onClose={onCancel}>
      <Transition.Root show={true}>{showPage[page]}</Transition.Root>
    </SimpleModal>
  );
}
