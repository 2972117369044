import React, { useState } from "react";
import NavItem from "./NavItem";
const img = require("../../../../assets/images/ccLogoBlack.png");
import { CloseOutlined } from "@ant-design/icons";
import { FiHelpCircle, FiHome, FiUser } from "react-icons/fi";
import HelpPopup from "../Popups/HelpPopup";

export default function Navigation({ onClose = null, navSelect, selected }) {
  const [showHelp, setShowHelp] = useState(false);
  return (
    <div className="z-20 flex flex-col items-center w-full h-full px-8 py-3 space-y-24 bg-white border-r md:w-72 border-r-slate-100 md:items-start md:space-y-8">
      {/* LOGO */}
      <div className="flex items-center space-x-4 text-lg">
        <img src={img} className="object-cover -ml-1" height={50} width={50} />
        <div>Clutch</div>
      </div>
      {/* NAV Items */}
      <div className="flex-1 w-full space-y-4">
        <NavItem
          active={selected === "Home"}
          text="Home"
          onSelect={navSelect}
          icon={<FiHome />}
        />
        <NavItem
          active={selected === "Collabs"}
          text="Collabs"
          onSelect={navSelect}
        />
        <NavItem
          active={selected === "Profile"}
          text="Profile"
          icon={<FiUser />}
          onSelect={navSelect}
		//   isNew={true}
        />
        <div onClick={() => setShowHelp(true)}>
          <NavItem text="Help" icon={<FiHelpCircle />} onSelect={navSelect} />
          <HelpPopup open={showHelp} onClose={() => setShowHelp(false)} />
        </div>
      </div>
      <div
        onClick={() => onClose()}
        className="flex items-center justify-center w-12 h-12 rounded-full md:hidden bg-emerald-200 hover:bg-slate-300"
      >
        <CloseOutlined className="" />
      </div>
      <div className="flex items-center">
        <div className="p-4 text-slate-500">v1.1.0</div>
        <a
          href="https://www.thatsclutch.com/our-blog"
          target="_blank"
          className="underline"
        >
          What's new? 🤩
        </a>
      </div>
    </div>
  );
}
