import dayjs from "dayjs";
import React from "react";
import {
  CurrentRefinements,
  Hits,
  Pagination,
  RefinementList,
  SearchBox,
  Stats,
} from "react-instantsearch";
import CreatorApplicantHit from "../../../../../../Admin/Search/CreatorApplicantHit";
import SideModal from "../../../../../../Dialogs/SideModal";
import Details from "../../Details";

export default function SearchTable({ hitItem, defaultValue = "" }) {
  const transformItems = (items) => {
    return items.map((item) => ({
      ...item,
      dob: Math.abs(dayjs(item?.dob).diff(dayjs(), "year")) + " years old",
      industries: item.industries
        ? item?.industries.split(",").join(" - ")
        : "",
    }));
  };
  //   Causes refresh when details open
  const transformRefinementList = (items) => {
    return items.map((item) => ({
      ...item,
      // make lower case and swap underscore for space
      label: item.label.toLowerCase().replace(/_/g, " "),
    }));
  };

  return (
    <div className="relative min-w-[400px] space-y-6">
      <div className="flex justify-between px-2 overflow-x-auto">
        <RefinementList
          key="status_refinement_list"
          attribute="status"
          //   transformItems causes state to refresh when state changes
          //   transformItems={transformRefinementList}
          classNames={{
            list: "flex space-x-4",
            selectedItem: "font-normal",
          }}
          className="text-base font-normal capitalize text-slate-600"
        />
      </div>
      <SearchBox
        className=""
        defaultValue={defaultValue}
        placeholder="Search all creator applicants..."
        classNames={{
          root: "",
          form: "relative",
          input:
            "border-none border-green-500 rounded-md pl-8 pr-4 py-2 w-full",
          submitIcon: "absolute top-0 left-0 bottom-0 w-6",
        }}
      />
      <div className="flex items-center justify-between space-x-8">
        <div className="flex items-center space-x-4">
          <CurrentRefinements />
          <Stats
            classNames={{
              root: "text-sm text-slate-500",
            }}
          />
        </div>
        <Pagination padding={2} showLast={false} showFirst={false} />
      </div>
      <div className="space-y-4">
        <Hits
          transformItems={transformItems}
          hitComponent={hitItem}
          classNames={{
            item: " hover:bg-gray-50 p-0 rounded-md",
            emptyRoot: "p-4",
            list: "p-0 space-y-4",
          }}
        />
      </div>
    </div>
  );
}
