import { getMatchesByBriefID } from "@thatsclutch/shared/services/MatchingService";
import React from "react";

const MatchStateContext = React.createContext(undefined);
const MatchDispatchContext = React.createContext(undefined);

type stateType = {
  isMatching: boolean;
  brief: {
	id: string;
	client: any;
  } | null;
};

const initialState: stateType = {
  isMatching: false,
  brief: null,
};

const exitMatching = async (dispatch) => {
  dispatch({ type: "clear", payload: null });
};

function userReducer(state, action) {
  switch (action.type) {
    case "clear": {
      return { brief: null, isMatching: false };
    }
    case "startMatching": {
      return { ...state, brief: action.payload, isMatching: true };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MatchProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  return (
    <MatchStateContext.Provider value={state}>
      <MatchDispatchContext.Provider value={dispatch}>
        {children}
      </MatchDispatchContext.Provider>
    </MatchStateContext.Provider>
  );
}

function useMatchState() {
  const context = React.useContext(MatchStateContext);
  if (context === undefined) {
    throw new Error("useMatchContext must be used within a MatchStateProvider");
  }
  return context;
}

function useMatchDispatch() {
  const context = React.useContext(MatchDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useMatchDispatch must be used within a MatchDispatchContext"
    );
  }
  return context;
}

export { MatchProvider, useMatchState, useMatchDispatch, exitMatching };
