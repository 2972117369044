import React, { useState, useEffect } from "react";
import { FiLoader, FiX } from "react-icons/fi";

function Autosave({ profileID, adminNotes, saveNote, saveNoteFast, isSaving }) {
  const [notes, setNotes] = useState(adminNotes);

  useEffect(() => {
    return () => {
      profileID === null && adminNotes !== notes && saveNoteFast(notes);
    };
  }, [profileID, adminNotes, notes]);

//   useEffect(() => {
//     !isSaving &&
//       adminNotes !== notes &&
//       profileID &&
//       alert("Don't forget to save notes!");
//   }, [profileID, adminNotes, isSaving]);

  return (
    <div className="p-px bg-gray-100 rounded-xl">
      <div
        className={`flex  items-center transition-all overflow-hidden justify-end  ${
          adminNotes != notes ? " p-4" : "h-0 p-0"
        }`}
      >
        {isSaving ? (
          <div className="flex items-center p-2 px-4 space-x-2">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
            <div>Saving...</div>
          </div>
        ) : adminNotes !== notes ? (
          <div className="flex items-center justify-between flex-grow space-x-8">
            <button
              onClick={() => setNotes(adminNotes)}
              className="flex items-center p-2 transition-all rounded-full cursor-pointer hover:bg-gray-200 group "
            >
              <FiX className="w-4 h-4 text-black" />
              <div className="w-0 overflow-hidden text-xs transition-all group-hover:px-4 group-hover:w-min whitespace-nowrap">
                Discard changes
              </div>
            </button>

            <div
              className="px-6 py-3 text-white bg-black rounded-full cursor-pointer hover:bg-black/70"
              onClick={() => saveNote(notes)}
            >
              Save
            </div>
          </div>
        ) : (
          <div>
            <div className="p-2 px-4 text-gray-400 rounded-full">Saved</div>
          </div>
        )}
      </div>
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        className="block w-full outline-none focus:border-green-400 leading-loose p-4 h-[300px] bg-white border-px border-gray-100 rounded-xl"
        placeholder="+ New Note"
      />
    </div>
  );
}

export default Autosave;
