import React, { useState } from "react";
import ShortInput from "../../../../../../Basic/Input/ShortInput";
import PrimaryButton from "../../../../../../Basic/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../../Basic/Buttons/SecondaryButton";

export default function PricePage({ currPrice, onNext, onBack }) {
  const [price, setPrice] = useState(currPrice / 100 + "" || "");
  const [errPrice, setErrPrice] = useState('');

  const onSubmit = () => {
    const formattedPrice = Number(price) && parseInt(price);
    if (!formattedPrice)
      return setErrPrice("Please enter a valid number (ex: 45)");
    if (formattedPrice < 10 || formattedPrice > 9999)
      return setErrPrice("Please enter a valid price. ($10 - $9,999)");
    onNext(formattedPrice * 100);
  };

  return (
    <div className="p-8 space-y-8">
      <div className="space-y-1">
        <div className="text-2xl font-semibold">Adding a service</div>
        <div className="text-sm font-thin text-gray-700">
          Enter the price per deliverable
        </div>
      </div>

      <div className="p-4 px-6 space-y-1 text-sm font-thin text-gray-700 rounded-lg bg-yellow-400/10">
        <div className="font-medium text-black">✨ Pricing Guidelines</div>
        <div className="text-xs whitespace-pre-wrap">
          {`- Collabs on Clutch typically range between $100-350 per asset, but rates are completely set by YOU. Charge what you deserve!\n\n- Don’t over think it - find a rate that feels right for the time and effort you’re putting in to create the asset.`}
        </div>
      </div>

      <ShortInput
        value={price}
        label={"Price per asset"}
        prefix={"$"}
        error={errPrice}
        onChange={(v) => {
          if (v[0] === "0") {
            v = v.slice(1);
          }
          setPrice(v);
          setErrPrice(null);
        }}
        placeholder="ex: 45"
        type="number"
      />
      <div className="flex items-center justify-end space-x-4">
        <SecondaryButton text={"Back"} onPress={onBack} />
        <PrimaryButton text={"Next"} onPress={onSubmit} />
      </div>
    </div>
  );
}
