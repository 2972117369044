import React from 'react';
import { FiLoader } from 'react-icons/fi';
export default function PrimaryButton({
  text,
  onPress,
  loading = false,
  ...props
}) {
  return (
    <div className="relative">
      <div
        onClick={!loading ? onPress : undefined}
        className="px-6 py-3 font-light text-white bg-black border border-black rounded-full cursor-pointer hover:opacity-90 active:opacity-70"
        {...props}
      >
        {loading && (
          <div className="absolute flex items-center h-6 right-4">
            <FiLoader className="w-4 h-4 text-white animate-spin" />
          </div>
        )}
        <div
          className={`transition-all text-center ${loading &&
            '-translate-x-4 text-gray-400'}`}
        >
          {text}
        </div>
      </div>
    </div>
  );
}
