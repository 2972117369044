import React from 'react';
import { LayoutScroll } from '../../Layout';

const myHTML = `
<h1>Privacy Policy</h1>
<p>Effective date:&nbsp;02.23.2022 </p>
<p>At Clutch, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data.&nbsp;By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy.&nbsp; </p>
<p>Remember that your use of Clutch's Services is at all times subject to our Terms of Use&lt;https://docs.google.com/document/d/1wWErzETO_EN-7zvq91p5Ut2SsQzWHWjL/edit?usp=sharing&amp;ouid=102155899634844646264&amp;rtpof=true&amp;sd=true&gt;, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use. </p>
<p>You may print a copy of this Privacy Policy by clicking here&lt;https://docs.google.com/document/d/1wWErzETO_EN-7zvq91p5Ut2SsQzWHWjL/edit?usp=sharing&amp;ouid=102155899634844646264&amp;rtpof=true&amp;sd=true&gt;. If you have a disability, you may access this Privacy Policy in an alternative format by contacting support@thatsclutch.com. </p>
<p>Privacy Policy Table of Contents</p>
<p>What this Privacy Policy Covers</p>
<p>Personal Data</p>
<ul>
<li>Categories of Personal Data We Collect</li>
<li>Categories of Sources of Personal Data</li>
<li>Our Commercial or Business Purposes for Collecting Personal Data </li>
</ul>
<p>How We Share Your Personal Data</p>
<p>Tracking Tools and Opt-Out</p>
<p>Data Security and Retention</p>
<p>Personal Data of Children</p>
<p>Other State Law Privacy Rights</p>
<p>Changes to this Privacy Policy</p>
<p>Contact Information</p>
<p>What this Privacy Policy Covers</p>
<p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual and also includes information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control or people we don&rsquo;t manage. </p>
<p>Personal Data</p>
<p>Categories of Personal Data We Collect</p>
<p>This chart details the categories of Personal Data that we collect and have collected over the past 12 months: </p>
<p>Category of Personal Data&nbsp;</p>
<p>Examples of Personal Data We Collect</p>
<p>Categories of Third Parties With Whom We Share this Personal Data:&nbsp; </p>
<p>Profile or Contact Data</p>
<ul>
<li>First and last name</li>
<li>Email</li>
<li>Phone number</li>
<li>Mailing address</li>
<li>Profile picture&nbsp;</li>
<li>Instagram account information</li>
<li>Unique identifiers such as passwords</li>
</ul>
<ul>
<li>Service Providers</li>
</ul>
<p>Identifiers</p>
<ul>
<li>Information included in the W-9 form (including name, address and taxpayer identification number (which may include social security number)) </li>
</ul>
<p>Payment Data</p>
<ul>
<li>Payment card type</li>
<li>Last 4 digits of payment card</li>
<li>Billing address, phone number, and email</li>
</ul>
<ul>
<li>Service Providers (specifically our payment processing partner, currently Stripe, Inc.) </li>
</ul>
<p>Commercial Data</p>
<ul>
<li>Purchase history</li>
<li>Consumer profiles</li>
</ul>
<p>Consumer Demographic Data</p>
<ul>
<li>Zip code</li>
</ul>
<p>Non-Public Education Data</p>
<ul>
<li>College graduation month and year </li>
</ul>
<p>Categories of Sources of Personal Data</p>
<p>We collect Personal Data about you from the following categories of sources: </p>
<ul>
<li>You</li>
</ul>
<ul>
<li>When you provide such information directly to us. </li>
</ul>
<ul>
<li>When you create an account or use our interactive tools and Services. </li>
<li>When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires. </li>
<li>When you send us an email or otherwise contact us. </li>
</ul>
<ul>
<li>When you use the Services and such information is collected automatically. </li>
</ul>
<ul>
<li>Through Cookies (defined in the &ldquo;Tracking Tools and Opt-Out&rdquo; section below). </li>
<li>If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable. </li>
<li>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices. </li>
</ul>
<p>Our Commercial or Business Purposes for Collecting Personal Data </p>
<ul>
<li>Providing, Customizing and Improving the Services </li>
</ul>
<ul>
<li>Creating and managing your account or other user profiles. </li>
<li>Processing orders or other transactions; billing. </li>
<li>Providing you with the products, services or information you request. </li>
<li>Meeting or fulfilling the reason you provided the information to us. </li>
<li>Providing support and assistance for the Services. </li>
<li>Improving the Services, including testing, research, internal analytics and product development. </li>
<li>Personalizing the Services, website content and communications based on your preferences. </li>
<li>Doing fraud protection, security and debugging.</li>
<li>Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the &ldquo;CCPA&rdquo;). </li>
</ul>
<ul>
<li>Marketing the Services</li>
</ul>
<ul>
<li>Marketing and selling the Services.</li>
</ul>
<ul>
<li>Corresponding with You</li>
</ul>
<ul>
<li>Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about Clutch or the Services. </li>
<li>Sending emails and other communications according to your preferences or that display content that we think will interest you. </li>
</ul>
<ul>
<li>Meeting Legal Requirements and Enforcing Legal Terms </li>
</ul>
<ul>
<li>Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities. </li>
<li>Protecting the rights, property or safety of you, Clutch or another party. </li>
<li>Enforcing any agreements with you.</li>
<li>Responding to claims that any posting or other content violates third-party rights. </li>
<li>Resolving disputes.</li>
</ul>
<p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice. </p>
<p>How We Share Your Personal Data</p>
<p>We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a &ldquo;sale&rdquo; of your Personal Data. For more information, please refer to the state-specific sections below. </p>
<ul>
<li>Service Providers. These parties help us provide the Services or perform business functions on our behalf. They include: </li>
</ul>
<ul>
<li>Hosting, technology and communication providers.</li>
<li>Payment processors.</li>
</ul>
<ul>
<li>Our payment processing partner Stripe, Inc. (&ldquo;Stripe&rdquo;) collects your voluntarily-provided payment card information necessary to process your payment. </li>
<li>Please see Stripe&rsquo;s terms of service and privacy policy for information on its use and storage of your Personal Data. </li>
</ul>
<p>Legal Obligations</p>
<p>We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under &ldquo;Meeting Legal Requirements and Enforcing Legal Terms&rdquo; in the &ldquo;Our Commercial or Business Purposes for Collecting Personal Data&rdquo; section above.&nbsp;We may also share any Personal Data that we collect from you with the Student Customer or the IRS solely as necessary for tax purposes. </p>
<p>Business Transfers</p>
<p>All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices. </p>
<p>Data that is Not Personal Data</p>
<p>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you. </p>
<p>Tracking Tools and Opt-Out</p>
<p>The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, &ldquo;Cookies&rdquo;) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data&ndash; usually text files &ndash; placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support &ldquo;Do Not Track&rdquo; requests sent from a browser at this time. </p>
<p>We use Essential Cookies. Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable. </p>
<p>You can decide whether or not to accept Cookies through your internet browser&rsquo;s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work. </p>
<p>To explore what Cookie settings are available to you, look in the &ldquo;preferences&rdquo; or &ldquo;options&rdquo; section of your browser&rsquo;s menu. To find out more information about Cookies, including information about how to manage and delete Cookies, please visit&nbsp;<a href="https://www.google.com/url?q=http://www.allaboutcookies.org/&amp;sa=D&amp;source=editors&amp;ust=1645657860324866&amp;usg=AOvVaw07w6yq-60vND5a9Vv59Tv1">http://www.allaboutcookies.org/. </a></p>
<p>Data Security and Retention</p>
<p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure. </p>
<p>We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally. </p>
<p>Personal Data of Children</p>
<p>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 13 years of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Data to us, please contact us at support@thatsclutch.com. </p>
<p>State Law Privacy Rights</p>
<p>California Resident Rights</p>
<p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties&rsquo; direct marketing purposes; in order to submit such a request, please contact us at support@thatsclutch.com. </p>
<p>Nevada Resident Rights</p>
<p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at support@thatsclutch.com with the subject line &ldquo;Nevada Do Not Sell Request&rdquo; and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A. </p>
<p>Changes to this Privacy Policy</p>
<p>We&rsquo;re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will alert you to any such changes by placing a notice on the Clutch website, by sending you an email and/or by some other means. Please note that if you&rsquo;ve opted not to receive legal notice emails from us (or you haven&rsquo;t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected. </p>
<p>Contact Information:</p>
<p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at: </p>
<ul>
<li>https://www.thatsclutch.com</li>
<li>support@thatsclutch.com</li>
<li>4201 Main St, Suite 200-152, Houston, TX 77002</li>
</ul>


`;
const Policy = () => {
  return (
    <LayoutScroll>
      <div className="p-8">
        <div dangerouslySetInnerHTML={{ __html: myHTML }} />
      </div>
    </LayoutScroll>
  );
};

export default Policy;
