import React from "react";
import Table from "./Table";
import Details from "./Details";

export default function CollabList() {
  const [collabID, setCollabID] = React.useState(null);
  return (
    <div className="h-full overflow-y-auto bg-zinc-50">
      {/* TITLE */}
      <div className="flex items-baseline p-8 py-4 pt-12">
        <div className="text-2xl font-semibold sm:text-4xl">Collabs</div>
      </div>
      {/* Table */}
      <Table onSelect={setCollabID} />
      {/* Detail modal */}
      <Details
        collabID={collabID}
        onClose={() => setCollabID(null)}
        onUpdated={() => {}}
      />
    </div>
  );
}
