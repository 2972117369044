import React from "react";
import { Modal } from "antd";
export default function BasicModal({
  onCancel,
  isVisible,
  children,
  width = 700,
}) {
  return (
    <Modal
      open={isVisible}
      footer={null}
      destroyOnClose={true}
      maskClosable={true}
      closable={false}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
      style={{ borderRadius: 20, paddingY: 0, paddingX: 0 }}
      width={width}
    >
      {children}
    </Modal>
  );
}
