import React from "react";

export default function NavItem({ selected, onSelect, title = "N/A" }) {
  const creatrorIcon = (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6515 5.5C12.4736 5.50002 12.2976 5.53569 12.1337 5.60491C11.9698 5.67413 11.8215 5.77549 11.6975 5.903L11.501 6.1055L11.3025 5.903C11.1785 5.77551 11.0301 5.67418 10.8663 5.60498C10.7024 5.53579 10.5264 5.50014 10.3485 5.50014C10.1706 5.50014 9.99457 5.53579 9.83071 5.60498C9.66686 5.67418 9.51853 5.77551 9.3945 5.903C9.14157 6.16415 9.00015 6.51345 9.00015 6.877C9.00015 7.24055 9.14157 7.58985 9.3945 7.851L11.501 10L13.6055 7.851C13.8584 7.58985 13.9999 7.24055 13.9999 6.877C13.9999 6.51345 13.8584 6.16415 13.6055 5.903C13.4815 5.77549 13.3332 5.67413 13.1693 5.60491C13.0054 5.53569 12.8294 5.50002 12.6515 5.5V5.5ZM0 14.5H1V12C1.00079 11.3372 1.26444 10.7018 1.73311 10.2331C2.20178 9.76444 2.8372 9.50079 3.5 9.5H6.5C7.1628 9.50079 7.79822 9.76444 8.26689 10.2331C8.73556 10.7018 8.99921 11.3372 9 12V14.5H10V12C9.99894 11.0721 9.62985 10.1824 8.9737 9.5263C8.31756 8.87015 7.42793 8.50106 6.5 8.5H3.5C2.57207 8.50106 1.68244 8.87015 1.0263 9.5263C0.370148 10.1824 0.00105868 11.0721 0 12V14.5ZM5 1.5C5.49445 1.5 5.9778 1.64662 6.38893 1.92133C6.80005 2.19603 7.12048 2.58648 7.3097 3.04329C7.49892 3.50011 7.54843 4.00277 7.45196 4.48773C7.3555 4.97268 7.1174 5.41814 6.76777 5.76777C6.41814 6.1174 5.97268 6.3555 5.48773 6.45196C5.00277 6.54843 4.50011 6.49892 4.04329 6.3097C3.58648 6.12048 3.19603 5.80005 2.92133 5.38893C2.64662 4.9778 2.5 4.49445 2.5 4C2.5 3.33696 2.76339 2.70107 3.23223 2.23223C3.70107 1.76339 4.33696 1.5 5 1.5M5 0.5C4.30777 0.5 3.63108 0.705271 3.0555 1.08986C2.47993 1.47444 2.03133 2.02107 1.76642 2.66061C1.50151 3.30015 1.4322 4.00388 1.56725 4.68282C1.7023 5.36175 2.03564 5.98539 2.52513 6.47487C3.01461 6.96436 3.63825 7.2977 4.31718 7.43275C4.99612 7.5678 5.69985 7.49848 6.33939 7.23358C6.97893 6.96867 7.52556 6.52007 7.91014 5.9445C8.29473 5.36892 8.5 4.69223 8.5 4C8.5 3.07174 8.13125 2.1815 7.47487 1.52513C6.8185 0.868749 5.92826 0.5 5 0.5V0.5Z"
        fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
      />
    </svg>
  );

  const applicationIcon = (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_288_1859)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.854 7.64599C10.9006 7.69244 10.9375 7.74761 10.9627 7.80836C10.9879 7.8691 11.0009 7.93423 11.0009 7.99999C11.0009 8.06576 10.9879 8.13088 10.9627 8.19163C10.9375 8.25237 10.9006 8.30755 10.854 8.35399L7.85401 11.354C7.80756 11.4006 7.75239 11.4375 7.69164 11.4627C7.63089 11.4879 7.56577 11.5009 7.50001 11.5009C7.43424 11.5009 7.36912 11.4879 7.30837 11.4627C7.24763 11.4375 7.19245 11.4006 7.14601 11.354L5.64601 9.85399C5.59952 9.80751 5.56264 9.75232 5.53748 9.69158C5.51232 9.63084 5.49937 9.56574 5.49937 9.49999C5.49937 9.43425 5.51232 9.36915 5.53748 9.30841C5.56264 9.24767 5.59952 9.19248 5.64601 9.14599C5.73989 9.05211 5.86723 8.99936 6.00001 8.99936C6.06575 8.99936 6.13085 9.01231 6.19159 9.03747C6.25233 9.06263 6.30752 9.09951 6.35401 9.14599L7.50001 10.293L10.146 7.64599C10.1925 7.59943 10.2476 7.56249 10.3084 7.53728C10.3691 7.51207 10.4342 7.4991 10.5 7.4991C10.5658 7.4991 10.6309 7.51207 10.6916 7.53728C10.7524 7.56249 10.8076 7.59943 10.854 7.64599Z"
          fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
        />
        <path
          d="M4 2H3C2.46957 2 1.96086 2.21071 1.58579 2.58579C1.21071 2.96086 1 3.46957 1 4V14.5C1 15.0304 1.21071 15.5391 1.58579 15.9142C1.96086 16.2893 2.46957 16.5 3 16.5H13C13.5304 16.5 14.0391 16.2893 14.4142 15.9142C14.7893 15.5391 15 15.0304 15 14.5V4C15 3.46957 14.7893 2.96086 14.4142 2.58579C14.0391 2.21071 13.5304 2 13 2H12V3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289C13.8946 3.48043 14 3.73478 14 4V14.5C14 14.7652 13.8946 15.0196 13.7071 15.2071C13.5196 15.3946 13.2652 15.5 13 15.5H3C2.73478 15.5 2.48043 15.3946 2.29289 15.2071C2.10536 15.0196 2 14.7652 2 14.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H4V2Z"
          fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
        />
        <path
          d="M9.5 1.5C9.63261 1.5 9.75979 1.55268 9.85355 1.64645C9.94732 1.74021 10 1.86739 10 2V3C10 3.13261 9.94732 3.25979 9.85355 3.35355C9.75979 3.44732 9.63261 3.5 9.5 3.5H6.5C6.36739 3.5 6.24021 3.44732 6.14645 3.35355C6.05268 3.25979 6 3.13261 6 3V2C6 1.86739 6.05268 1.74021 6.14645 1.64645C6.24021 1.55268 6.36739 1.5 6.5 1.5H9.5ZM6.5 0.5C6.10218 0.5 5.72064 0.658035 5.43934 0.93934C5.15804 1.22064 5 1.60218 5 2V3C5 3.39782 5.15804 3.77936 5.43934 4.06066C5.72064 4.34196 6.10218 4.5 6.5 4.5H9.5C9.89782 4.5 10.2794 4.34196 10.5607 4.06066C10.842 3.77936 11 3.39782 11 3V2C11 1.60218 10.842 1.22064 10.5607 0.93934C10.2794 0.658035 9.89782 0.5 9.5 0.5L6.5 0.5Z"
          fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_288_1859">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const clientsIcon = (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 1.83333H5.33333C4.97971 1.83333 4.64057 1.97381 4.39052 2.22386C4.14048 2.4739 4 2.81304 4 3.16667V11.1667C4 11.5203 4.14048 11.8594 4.39052 12.1095C4.64057 12.3595 4.97971 12.5 5.33333 12.5H13.3333C13.687 12.5 14.0261 12.3595 14.2761 12.1095C14.5262 11.8594 14.6667 11.5203 14.6667 11.1667V3.16667C14.6667 2.81304 14.5262 2.4739 14.2761 2.22386C14.0261 1.97381 13.687 1.83333 13.3333 1.83333ZM9.33333 3.5C9.77536 3.5 10.1993 3.67559 10.5118 3.98815C10.8244 4.30071 11 4.72464 11 5.16667C11 5.60869 10.8244 6.03262 10.5118 6.34518C10.1993 6.65774 9.77536 6.83333 9.33333 6.83333C8.89131 6.83333 8.46738 6.65774 8.15482 6.34518C7.84226 6.03262 7.66667 5.60869 7.66667 5.16667C7.66667 4.72464 7.84226 4.30071 8.15482 3.98815C8.46738 3.67559 8.89131 3.5 9.33333 3.5ZM12.6667 10.5H6V10.3333C6 9.10067 7.50267 7.83333 9.33333 7.83333C11.164 7.83333 12.6667 9.10067 12.6667 10.3333V10.5Z"
        fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
      />
      <path
        d="M2.66666 5.83333H1.33333V13.8333C1.33333 14.5687 1.93133 15.1667 2.66666 15.1667H10.6667V13.8333H2.66666V5.83333Z"
        fill={`${selected == title.toLowerCase() ? "black" : "white"}`}
      />
    </svg>
  );

  const collabsIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );

  return (
    <div
      onClick={() => {
        onSelect(title.toLowerCase());
      }}
      className={`flex w-full transition-colors rounded-lg px-8 py-4 space-x-3 
      ${
        selected == title.toLowerCase()
          ? "bg-[#E4FDE8] "
          : "bg-transparent hover:rounded-lg hover:bg-[#E4FDE8]/30 text-white cursor-pointer"
      }`}
    >
      <div>
        {title == "Applicants" && applicationIcon}
        {title == "Creators" && creatrorIcon}
        {title == "Clients" && clientsIcon}
        {title == "Collabs" && collabsIcon}
      </div>
      <div>{title}</div>
    </div>
  );
}
