import React, { useEffect, useState } from "react";
import ServiceItem from "./ServiceItem";
import ServiceAddModal from "./ServiceAddModal";
import Skeleton from "./ServiceItem/Skeleton";
import {
  getCreatorByID,
  getCurrCreator,
  useCreatorDispatch,
  useCreatorState,
} from "../../../../../contexts/CreatorContext";
import useAuth from "../../../../Auth/useAuth";
import AddButton from "../../../../../assets/vectors/Add";
import ServiceEditModal from "./ServiceEditModal";

export default function Services({ creatorID }) {
  const [loading, setLoading] = useState(false);
  const [showAddService, setShowAddService] = useState(false);
  const { creator } = useCreatorState();
  const dispatch = useCreatorDispatch();
  const [currUser] = useAuth();

  const [showEditService, setShowEditService] = useState(false);
  const [editService, setEditService] = useState(null);

  const onCreated = async () => {
    setShowAddService(false);
    onReload();
  };

  const onReload = async () => {
    setLoading(true);
    if (currUser?.userGroups?.includes("admins") && creatorID) {
      await getCreatorByID(dispatch, creatorID);
    } else {
      await getCurrCreator(dispatch);
    }
  };

  const sortByDate = (a, b) => {
    if (a.createdAt < b.createdAt) return 1;
    else if (a.createdAt > b.createdAt) return -1;
    else return 0;
  };

  const openEditService = (item) => {
    setEditService(item);
    setShowEditService(true);
  };

  const onUpdated = () => {
    setShowEditService(false);
    onReload();
  };

  return (
    <div className="">
      <ServiceAddModal
        open={showAddService}
        onSuccess={onCreated}
        onCancel={() => setShowAddService(false)}
      />
      <ServiceEditModal
        open={showEditService}
        onClose={() => {
          console.log("Closing");
          setShowEditService(false);
        }}
        onSave={onUpdated}
        service={editService}
      />
      <div className="flex flex-col w-full py-8 space-y-8 ">
        {/* Header */}
        <div className="flex items-center justify-between space-x-4">
          <div className="text-xl font-medium md:text-4xl">My Services</div>
          <button
            onClick={() => setShowAddService(true)}
            className="px-8 py-3 text-sm font-thin text-white bg-black border-2 border-black rounded-full hover:bg-transparent hover:text-black"
          >
            Add Service
          </button>
        </div>
        {creator && creator?.services.items.length ? (
          <div className="grid gap-8 md:grid-cols-2">
            {creator?.services.items.length &&
              creator?.services.items
                .sort(sortByDate)
                .map((item) => (
                  <ServiceItem
                    key={item.id}
                    item={item}
                    themeColor={creator?.themeColor}
                    onUpdate={openEditService}
                  />
                ))}
          </div>
        ) : (
          <div className="rounded-lg bg-[#f3f3f3f3] text-center py-16 px-16 flex items-center justify-center">
            <div className="max-w-sm text-xl font-medium md:text-2xl">
              Add a service to show brands what you offer
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
