import React from "react";
import { Highlight } from "react-instantsearch";

export default function ClientHit({ hit, onSelected }) {
  return (
    <div
      className="w-full h-full hover:cursor-pointer"
      onClick={(e) => onSelected(hit)}
    >
      <p className="text-base capitalize ">{hit.brand}</p>
      <p className="text-sm capitalize">{hit.name}</p>
      {/* <p className="">{hit.email}</p> */}
      <p className="px-3 py-2 overflow-y-scroll text-sm whitespace-pre-wrap rounded-md max-h-14 bg-slate-100 text-slate-500">
        {hit.notes ? (
          <Highlight
            classNames={{
              highlighted: "px-0",
            }}
            attribute="notes"
            hit={hit}
          />
        ) : (
          "No notes"
        )}
      </p>
    </div>
  );
}
