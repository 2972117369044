import React, { useEffect, useState } from "react";
import { message } from "antd";
import Header from "./Header";
import Icon from "../../../../assets/vectors/undraw_Credit_card_re_blml.svg";
import { getStripeClientID } from "@thatsclutch/shared/services/PaymentService";
import { Auth } from "aws-amplify";
import { navigate } from "gatsby";
import { getProvider } from "@thatsclutch/shared/services/UserService";
import { getCurrentCreator } from "@thatsclutch/shared/services/CreatorService";

const Stripe = ({ onNext }) => {
  const [loading, setLoading] = useState(true);
  const clientID = getStripeClientID(process.env.GATSBY_AMP_ENV);
  const stripeUri = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${clientID}&scope=read_write&redirect_uri`;
  const redirectUri = `${stripeUri}=${window.location.origin}/app/stripe-complete/`;
  const [prefill, setPrefill] = useState({});
  useEffect(() => {
    //check if has cognito id
    checkConnected();
  }, []);

  const checkConnected = async () => {
    const creator = await getCurrentCreator();
    if (creator) {
      setPrefill({
        "stripe_user[first_name]": creator.firstName,
        "stripe_user[last_name]": creator.lastName,
        "stripe_user[country]": "US",
        "stripe_user[phone_number]": creator.phoneNumber,
        "stripe_user[email]": creator.invitationalEmail,
        "stripe_user[product_description]": "Clutch creator",
        "stripe_user[business_type]": "individual",
      });
      setLoading(false);
    }
  };

  const connectStripe = async () => {
    const hide = message.loading("Redirecting to Stripe...", 0);
    // Dismiss manually and asynchronously
    setTimeout(() => {
      hide();
      const url = new URL(redirectUri);
      for (let [key, value] of Object.entries(prefill)) {
        url.searchParams.append(key, value);
      }
      console.log(prefill, url.toString());
      window.location.href = url.toString();
    }, 2500);
  };

  const onFinish = async () => {
    // create customer w/name
    // setLoading(true);
    // handle error
    // setLoading(true);
  };

  return (
    <div className="w-full p-4 md:w-2/3">
      <Header step={4} label={"Let's get paid"} />
      <Icon className="w-[150px] h-[150px] mb-14" />
      <div className="max-w-xs text-gray-600 mb-14">
        Payments are handled by Stripe. A secure way to get paid.
      </div>
      {loading ? (
        <div>Checking Account...</div>
      ) : (
        <div className="flex">
          <div
            onClick={connectStripe}
            className="text-base rounded px-4 py-2 bg-[#5433FF] shadow-sm text-white cursor-pointer hover:opacity-90"
          >
            Set Up Payments
          </div>
        </div>
      )}
    </div>
  );
};

export default Stripe;
