import { Spin } from "antd";
import React, { useState } from "react";
import {
  getCreatorByID,
  useCreatorDispatch,
} from "../../../../../contexts/CreatorContext";
import { AmpAPI } from "@thatsclutch/shared";
import {
  showToast,
  useErrorDispatch,
} from "../../../../../contexts/ErrorContext";

export default function ProfileLink({ creator }) {
  const [username, setUsername] = useState(creator?.username || "");
  const [loading, setLoading] = useState(false);
  const [editUsername, setEditUsername] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useCreatorDispatch();

  const envPrefix = process.env.GATSBY_ENV === "prod" ? "" : "dev";

  function isValidUsername(username) {
    // Define the regular expression for the username
    const regex = /^[A-Za-z]\w{2,30}$/;

    // Test the username against the regular expression
    return regex.test(username);
  }

  const saveUsername = async () => {
    try {
      setLoading(true);

      if (
        username.trim() === "" ||
        username.trim().length < 3 ||
        username.trim().length > 30
      ) {
        setError("Username must be at least 3 - 30 characters long");
        // throw an error
        throw new Error("Username must be at least 3 characters long");
      }
      if (!isValidUsername(username.trim()))
        throw new Error(
          "Username must be at least 3 characters long and can only contain letters, numbers, and underscores"
        );
      // check if username is taken
      const data = await AmpAPI.post("CampusAPI", "/collab/creator/username", {
        body: {
          username: username.trim(),
          creatorID: creator.id,
        },
      });
      console.log(data);

      if (!data || !data.username) {
        throw new Error("Username already taken");
      }
      await getCreatorByID(dispatch, creator.id);
      setError("");
      setEditUsername(false);
    } catch (e) {
      if (username.trim() === creator.username)
        setError("Username already saved");
      else setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return creator?.username && !editUsername ? (
    <div className="p-6 space-y-4 overflow-hidden bg-white shadow-2xl shadow-slate-200/50 rounded-2xl">
      <div>
        <div className="text-lg font-semibold">
          Set up and share your Clutch Portfolio
        </div>
        <div className="text-sm text-gray-600 whitespace-pre-line">
          Pick a unique username and we will give you a free portfolio page to
          share! Make it easier for brands to work with you through Clutch.
        </div>
      </div>
      <div className="flex flex-col items-center justify-between flex-1 p-4 space-y-2 border rounded-md md:space-y-0 bg-gray-50 md:flex-row md:space-x-2 md:p-2 md:pl-4 ">
        <div
          onClick={async () => {
            await navigator.clipboard.writeText(
              "https://client.thatsclutch.com/p/" + creator?.username
            );
            alert("Copied to clipboard");
          }}
          className="pl-4 underline cursor-pointer line-clamp-1 hover:opacity-60"
        >
          {envPrefix}client.thatsclutch.com/p/{creator?.username}
        </div>
        <button
          className="px-6 py-3 text-sm text-black transition-colors bg-gray-100 rounded-full cursor-pointer hover:opacity-60"
          onClick={() => setEditUsername(true)}
        >
          Change
        </button>
      </div>
    </div>
  ) : (
    <div className="p-6 space-y-4 overflow-hidden bg-white border rounded-lg">
      <div>
        <div className="text-lg font-semibold">
          NEW! Clutch portfolio on us!
        </div>
        <div className="text-sm text-gray-600 whitespace-pre-line">
          Pick a unique username and we will give you a free portfolio page to
          share! Make it easier for brands to work with through Clutch.
        </div>
      </div>
      <div className="flex flex-col justify-between max-w-xl space-y-4 lg:items-center lg:space-x-4 lg:flex-row lg:space-y-0">
        <div className="flex items-center flex-1 p-0 bg-gray-100 border rounded-md md:space-x-2 md:p-2 md:pl-4 ">
          <span className="hidden md:block">client.thatsclutch.com/p/</span>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="flex-1 px-4 py-2 text-sm bg-gray-200 border rounded-sm"
            placeholder="ex: alexUGC"
          />
        </div>

        {loading ? (
          <div className="flex items-center space-x-4">
            <Spin />
            <div>Checking...</div>
          </div>
        ) : (
          <div className="flex space-x-4">
            <button
              className="px-6 py-3 text-sm text-black transition-colors bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 hover:text-black"
              onClick={saveUsername}
            >
              Save
            </button>
            {creator?.username && (
              <button
                className="px-6 py-3 text-sm text-black transition-colors bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 hover:text-black"
                onClick={() => setEditUsername(false)}
              >
                Cancel
              </button>
            )}
          </div>
        )}
      </div>
      {error && <div className="text-xs text-rose-500">{error}</div>}
    </div>
  );
}
