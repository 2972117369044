import dayjs from "dayjs";
import React, { useState } from "react";
import {
  exitMatching,
  useMatchDispatch,
  useMatchState,
} from "../../../../contexts/MatchContext";
import Details from "../ClientList/Details";
import PrimaryButton from "../../../Admin/Buttons/PrimaryButton";

export default function MatchBar() {
  const { brief } = useMatchState();
  const dispatch = useMatchDispatch();
  const [openDetail, setOpenDetail] = useState(null);

  const handleExitMatching = () => {
    dispatch({ type: "clear" });
  };

  return (
    <div className="mx-6 mb-4 text-black">
      <div className="flex items-center justify-between py-4 pt-12">
        <div className="text-2xl font-semibold sm:text-4xl">
          {"Matching"}{" "}
          {brief && brief.serviceName && `for ${brief.serviceName}`}
        </div>
        <PrimaryButton onPress={handleExitMatching} text={"Done Matching"} />
      </div>
      {/* <div className="flex items-center justify-between pb-4 space-x-2">
        <button onClick={handleExitMatching} className="font-light underline">
          Exit matching
        </button>
        <button
          onClick={() => {
            setOpenDetail(brief?.client?.id);
          }}
          className="font-light underline"
        >
          Peek Client
        </button>
      </div> */}
      <div className="p-6 px-8 space-y-4 bg-gray-100 rounded-xl">
        <div className="grid grid-cols-3 gap-4 lg:grid-cols-5">
          <div className="space-y-2">
            <div className="text-xs font-medium text-slate-600">Client</div>
            <button
              onClick={() => {
                setOpenDetail(brief?.client?.id);
              }}
              className="font-light underline"
            >
              <div className="overflow-hidden font-medium underline capitalize cursor-pointer whitespace-nowrap text-ellipsis">
                {brief?.client?.brand}
              </div>
            </button>
          </div>
          <div className="col-span-2 space-y-2 lg:col-span-1">
            <div className="text-xs font-medium text-slate-600">Project</div>
            <div className="overflow-hidden text-base whitespace-nowrap text-ellipsis">
              {brief.serviceName}
            </div>
          </div>

          <div className="space-y-2">
            <div className="text-xs font-medium text-slate-600">
              Monthly Budget
            </div>
            <div className="w-24 overflow-hidden text-base whitespace-nowrap text-ellipsis">
              ${brief.weeklyBudget}
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-xs font-medium text-slate-600">
              Monthly Assets
            </div>
            <div className="w-24 overflow-hidden text-base whitespace-nowrap text-ellipsis">
              {brief.deliverableAmount}
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-xs font-medium text-slate-600">Start Date</div>
            <div className="overflow-hidden text-base whitespace-nowrap text-ellipsis">
              {brief.startDate &&
                dayjs(brief.startDate, "YYYY-MM-DD").format("MMM DD, YYYY")}
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <div className="text-xs font-medium text-slate-600">
            Notes for matching (internal)
          </div>
          <div className="overflow-hidden text-base whitespace-pre-line text-ellipsis">
            {brief?.requirements || "No match notes"}
          </div>
        </div>
      </div>

      <Details
        clientID={openDetail}
        onClose={() => setOpenDetail(null)}
        onUpdated={() => {}}
        defaultTab="recommendations"
      />
    </div>
  );
}
