import React, { useEffect } from "react";
import { useNavigate } from "@reach/router";
import { AppUser } from "../Auth";
import { SplitLayout } from "../Layout";
import Image1 from "../../assets/images/login_image1.jpg";
import Image2 from "../../assets/images/login_image2.jpg";
import Image3 from "../../assets/images/login_image3.jpg";
import Image4 from "../../assets/images/login_image4.jpg";
export const LoginBack = () => {
  return (
    <div className="h-full w-full relative hidden lg:block bg-gradient-to-tr from-[#E4FDE8] to-[#E4F1FD] rounded-tl-3xl">
      {/* IMAGE 1 */}
      <div className="absolute left-[5%] aspect-[7/10] w-[15vw] xl:w-[13vw] overflow-hidden bg-white bottom-[20%] rounded-3xl">
        <img
          src={Image4}
          className="object-cover w-full h-full bg-slate-50 rounded-2xl"
          alt="influencer"
          layout="fill"
        />
        <div className="absolute space-y-2 bottom-4 left-4">
          <div className="flex">
            <div className="px-3 py-1 text-xs text-black bg-green-400 rounded-lg text-light">
              Active
            </div>
          </div>
          <div className="text-2xl font-bold text-white">{"Videos"}</div>
        </div>
      </div>
      {/* IMAGE 2 */}
      <div className="absolute right-[15%] aspect-[7/10] w-[15vw] xl:w-[13vw] overflow-hidden bg-white top-[20%] rounded-3xl">
        <img
          src={Image3}
          className="object-cover w-full h-full bg-slate-50 rounded-2xl"
          alt="influencer"
          layout="fill"
        />
        <div className="absolute bottom-0 left-0 p-4 space-y-2 bg-gradient-to-b from-transparent to-black/40">
          <div className="flex">
            <div className="px-3 py-1 text-xs text-black bg-green-400 rounded-lg text-light">
              Active
            </div>
          </div>
          <div className="text-2xl font-bold text-white ">
            {"Social Media Management"}
          </div>
        </div>
      </div>
      {/* IMAGE 3 */}
      <div className="absolute left-[22%] aspect-[7/10] w-[15vw] xl:w-[13vw] overflow-hidden bg-white top-[5%] rounded-3xl">
        <img
          src={Image2}
          className="object-cover w-full h-full bg-slate-50 rounded-2xl"
          alt="influencer"
          layout="fill"
        />
        <div className="absolute space-y-2 bottom-4 left-4">
          <div className="flex">
            <div className="px-3 py-1 text-xs text-black bg-green-400 rounded-lg text-light">
              Active
            </div>
          </div>
          <div className="text-2xl font-bold text-white">
            {"Tiktoks & Reels"}
          </div>
        </div>
      </div>
      {/* IMAGE 4 */}
      <div className="absolute right-[30%] aspect-[7/10] w-[15vw] xl:w-[13vw] overflow-hidden bg-white bottom-[5%] rounded-3xl">
        <img
          src={Image1}
          className="object-cover w-full h-full bg-slate-50 rounded-2xl"
          alt="influencer"
          layout="fill"
        />
        <div className="absolute space-y-2 bottom-4 left-4">
          <div className="flex">
            <div className="px-3 py-1 text-xs text-black bg-green-400 rounded-lg text-light">
              Active
            </div>
          </div>
          <div className="text-2xl font-bold text-white">
            {"Graphic Design"}
          </div>
        </div>
      </div>
    </div>
  );
};

const PublicRoute = ({ component: Component, location, ...rest }) => {
  const navigate = useNavigate();

  return (
    <SplitLayout rightComponent={<LoginBack />}>
      <Component {...rest} />
    </SplitLayout>
  );
};

export default PublicRoute;
