import React, { useEffect } from "react";
import { useNavigate } from "@reach/router";
import useAuth from "../Auth/useAuth";
import { AdminStateProvider } from "../../contexts/AdminContext";
import { CreatorStateProvider } from "../../contexts/CreatorContext";
import { MatchProvider } from "../../contexts/MatchContext";
import { ErrorProvider } from "../../contexts/ErrorContext";
import { NavProvider } from "../../contexts/NavContext";

const PrivateAdminRoute = ({ component: Component, location, ...rest }) => {
  const navigate = useNavigate();
  const [currUser, userLoading, logoutUser] = useAuth(true);

  useEffect(() => {
    if (!userLoading && !currUser) {
      console.log("not authorized  -> /signin");
      navigate("/app/signin");
    }
    if (currUser && !currUser.userGroups.includes("admins")) {
      logoutUser();
    }
  }, [currUser]);

  return userLoading ? null : currUser &&
    currUser.userGroups.includes("admins") ? (
    <ErrorProvider>
      <AdminStateProvider>
        <CreatorStateProvider>
          <MatchProvider>
            <NavProvider>
              <Component {...rest} />
            </NavProvider>
          </MatchProvider>
        </CreatorStateProvider>
      </AdminStateProvider>
    </ErrorProvider>
  ) : null;
};

export default PrivateAdminRoute;
