import React, { useState, useEffect } from "react";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
import { FiCheck, FiEdit3, FiLoader, FiX } from "react-icons/fi";
import {
  getCurrCreator,
  useCreatorDispatch,
  useCreatorState,
} from "../../../../../contexts/CreatorContext";
import LongInput from "../../../../Basic/Input/LongInput";

const List = ({ title, items, emptyVal }) => (
  <div className="px-5 py-4 border border-[#EFEFEF] rounded-lg ">
    <div className="pb-2 font-medium">{title}</div>
    <div>
      {items ? (
        items.map((x) => <li className="capitalize">{x}</li>)
      ) : (
        <div className="text-sm text-gray-400">{emptyVal}</div>
      )}
    </div>
  </div>
);

function About({ creator, refresh }) {
  const [bio, setBio] = useState(creator?.shortBio || null);
  const [editBio, setEditBio] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useCreatorDispatch();

  const updateBio = async () => {
    setLoading(true);
    const data = await updateCreator(creator, {
      shortBio: bio.trim(),
    });
    console.log(data);
    refreshCreator();
    setLoading(false);
    setEditBio(false);
  };

  const refreshCreator = async () => {
    await refresh();
  };

  return (
    <div className="max-w-2xl md:px-0">
      <div className="flex items-center justify-between">
        <div>About me</div>
        {editBio ? (
          <div className="flex space-x-4">
            <button
              onClick={() => updateBio()}
              className="flex items-center justify-end px-4 space-x-2 text-sm text-white bg-black rounded-full cursor-pointer w-max hover:bg-transparent hover:ring-black hover:text-black hover:ring-2"
            >
              <div>Save Changes</div>
              {loading && (
                <FiLoader className="w-4 h-4 text-black animate-spin" />
              )}
            </button>
            <button
              className="p-2 cursor-pointer"
              onClick={() => setEditBio(false)}
            >
              <FiX className="text-rose-400" size={20} />
            </button>
          </div>
        ) : (
          <button
            className="p-2 cursor-pointer"
            onClick={() => {
              setEditBio(true);
              setBio(creator.shortBio);
            }}
          >
            <FiEdit3 className="text-slate-400" size={20} />
          </button>
        )}
      </div>
      {/* BIO */}
      {editBio ? (
        <LongInput
          value={bio || ""}
          maxLength={1000}
          label=""
          onChange={(t) => setBio(t)}
          placeholder={`Things to include: 
- A snappy introduction
- Show off skills & achievements
- Why choose you?
- What can you specifically help the client with?
- Share collab stats or growth metrics!`}
        />
      ) : (
        <div className="pr-8 whitespace-pre-line max-h-[265px] overflow-scroll text-black/60">
          {creator.shortBio}
        </div>
      )}
      <div className="px-4 hidden py-5 relative text-base border border-[#EFEFEF] rounded-lg md:overflow-y-scroll md:min-h-[150px] max-w-2xl">
        <div className="pb-2 font-medium">About me</div>
        <div className="absolute cursor-pointer top-3 right-5">
          {!editBio ? (
            <div onClick={() => setEditBio(true)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4">
                  <path
                    d="M4.75 19.2499L9 18.2499L18.2929 8.95696C18.6834 8.56643 18.6834 7.93327 18.2929 7.54274L16.4571 5.70696C16.0666 5.31643 15.4334 5.31643 15.0429 5.70696L5.75 14.9999L4.75 19.2499Z"
                    stroke="#0D1C0D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.25 19.25H13.75"
                    stroke="#0D1C0D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <div
              onClick={() => {
                setEditBio(false);
                setBio(creator.shortBio || null);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M17.25 6.75L6.75 17.25Z" fill="#0D1C0D" />
                <path
                  d="M17.25 6.75L6.75 17.25"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.75 6.75L17.25 17.25"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>

        <div className="">
          <div>
            <div className={editBio ? "hidden" : ""}>
              {creator?.shortBio ? (
                <div className="whitespace-pre-line">{creator.shortBio}</div>
              ) : (
                <div className="text-black/[0.6]">Empty</div>
              )}
            </div>
            {editBio && (
              <div>
                <textarea
                  className="w-full h-full outline-none ring-0"
                  rows={5}
                  maxLength={1000}
                  style={{ resize: "none" }}
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
                <div className="flex justify-end">
                  <div
                    onClick={() => updateBio()}
                    className="flex items-center justify-end px-4 py-2 space-x-2 text-sm text-white bg-black rounded-full cursor-pointer w-max hover:bg-transparent hover:ring-black hover:text-black hover:ring-2"
                  >
                    <div>Save Changes</div>
                    {loading && (
                      <FiLoader className="w-4 h-4 text-black animate-spin" />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <List
          title="I create for:"
          emptyVal="Industries can't be updated yet. Coming soon."
          items={creator.industries && creator.industries.split(", ")}
        />
        <List
          title="I've worked with:"
          emptyVal="Brands can't be updated yet. Coming soon."
          items={creator.brands}
        /> */}
    </div>
  );
}

export default About;
