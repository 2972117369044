import { getBriefsByClientID } from "@thatsclutch/shared/services/BriefService";
import { getMatchesByClientID } from "@thatsclutch/shared/services/MatchingService";
import React, { useEffect, useState } from "react";
import { FiLoader } from "react-icons/fi";
import Brief from "./Brief";
import BriefModal from "./BriefModal";
import dayjs from "dayjs";

export default function MatchesTab({ client }) {
  const [briefs, setBriefs] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [showBriefModal, setShowBriefModal] = useState(false);
  const [editBrief, setEditBrief] = useState(null);

  useEffect(() => {
    client && loadBriefs();
  }, [client]);

  const loadBriefs = async () => {
    setLoading(true);
    const result = await getBriefsByClientID(client.id);
    console.log(result, result?.sort(sortByDate));
    setBriefs(result);
    setLoading(false);
  };

  const sortByDate = (a, b) => {
    if (a.status === "INACTIVE" && b.status !== "INACTIVE") {
      return 1; // a is inactive and b is active, sort a after b
    } else if (b.status === "INACTIVE" && a.status !== "INACTIVE") {
      return -1; // b is inactive and a is active, sort b after a
    } else {
      // both items are either active or inactive, sort by createdAt
      if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) {
        return -1; // a is older than b, sort a before b
      } else if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) {
        return 1; // b is older than a, sort b before a
      } else {
        return 0; // a and b have the same createdAt value
      }
    }
  };

  return (
    <div className="space-y-6 ">
      <div className="flex items-center justify-between pt-6">
        <div className="text-xl font-medium">Projects</div>
        {briefs.length > 0 && (
          <button
            onClick={() => setShowBriefModal(true)}
            className="px-8 py-4 text-white bg-black rounded-full"
          >
            New Project
          </button>
        )}
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex justify-center py-4">
            <FiLoader className="w-4 h-4 text-black animate-spin" />
          </div>
        </div>
      ) : briefs && briefs.length ? (
        briefs
          .sort(sortByDate)
          .map((item) => (
            <Brief
              brief={item}
              client={client}
              onUpdate={setEditBrief}
              onRefresh={loadBriefs}
            />
          ))
      ) : (
        <div className="pt-6">
          <button
            onClick={() => setShowBriefModal(true)}
            className="bg-[#30DF87] rounded-full px-6 py-3 w-full"
          >
            Start Matching
          </button>
        </div>
      )}

      <BriefModal
        brief={editBrief}
        clientID={client.id}
        open={editBrief || showBriefModal}
        onCancel={() => {
          setEditBrief(null);
          setShowBriefModal(false);
        }}
        onSubmit={() => {
          setEditBrief(null);
          setShowBriefModal(false);
          loadBriefs();
        }}
      />
    </div>
  );
}
