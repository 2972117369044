import React from 'react';
import 'modern-normalize/modern-normalize.css';
import { useNavigate } from '@reach/router';
import {
  SettingOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  LeftOutlined
} from '@ant-design/icons';
import Img from '../../assets/images/ccLogo.png';
import { Button, Layout, notification } from 'antd';
import { setUser } from '../Auth/AppUser';
import { Auth } from 'aws-amplify';
import useAuth from '../Auth/useAuth';
const { Header, Content } = Layout;

function PrivateLayout({ children, extraHeader }) {
  const [currUser, loadingUser] = useAuth();
  const navigate = useNavigate();
  const isProvdierView =
    !loadingUser &&
    currUser.userGroups?.includes('admins') &&
    window.location.pathname.includes('provider');

  return (
    <Layout className="h-screen">
      <div className="fixed z-30 flex-col w-screen pt-1 bg-white shadow-lg ">
        <div className="flex items-center justify-between w-full py-1 ">
          <div className="flex items-center">
            <img
              src={Img}
              className="object-cover mt-1 ml-4"
              height={50}
              width={50}
            />

            {loadingUser ? null : currUser?.userGroups.includes('admins') ? (
              <div className="flex flex-col justify-center ml-4">
                <div className="p-0 m-0 text-lg font-medium leading-normal ">
                  Clutch
                </div>
                <div className="p-0 pt-1 m-0 text-xs leading-normal text-gray-500">
                  {isProvdierView ? 'Viewing as Admin' : 'Admin'}
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center ml-4">
                <div className="p-0 m-0 text-lg font-medium leading-normal ">
                  Hustle Hub
                </div>
                {/* <div className="p-0 pt-1 m-0 text-xs leading-normal text-gray-500">
                  {process.env.ENV || 'Develop'} - 0.0.1
                </div> */}
              </div>
            )}
          </div>
          <div className="mr-4">
            {isProvdierView ? (
              <Button
                icon={<LogoutOutlined />}
                onClick={() => navigate('/app/admin')}
              >
                Back To Admin
              </Button>
            ) : (
              <div className="flex items-center space-x-4">
                {/* {currUser?.userGroups.includes('admins') && <WhitelistDrawer />} */}
                <Button
                  icon={<SettingOutlined />}
                  onClick={() => navigate('/app/settings')}
                >
                  <div className="hidden sm:block"></div>
                </Button>
              </div>
            )}
          </div>
        </div>
        {!!extraHeader && extraHeader}
      </div>
      <Content className="pt-16 bg-white">{children}</Content>
    </Layout>
  );
}

function SettingsLayout({ children }) {
  return (
    <Layout className="h-screen bg-gray-50">
      <Header
        style={{ backgroundColor: 'white', padding: 0 }}
        className="fixed z-20 flex items-center justify-between w-screen h-full border-b border-gray-200 shadow-sm"
      >
        <div className="flex items-center ml-6">
          <Button
            type="link"
            style={{ marginBottom: '-2px' }}
            onClick={() => window.history.back()}
            icon={<LeftOutlined />}
          />

          <p className="p-0 m-0 ml-6 text-lg font-bold">Settings</p>
        </div>
      </Header>
      <Content className="h-full mt-16 overflow-y-auto bg-gray-50">
        {children}
      </Content>
    </Layout>
  );
}

function Layout2({ children, isUserNav }) {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 overflow-hidden">{children}</div>
    </div>
  );
}

function LayoutScroll({ children }) {
  return <div className="h-full overflow-y-scroll">{children}</div>;
}

function AppContent({ children }) {
  return (
    <div className="app-content-100">
      <div className="container">{children}</div>
    </div>
  );
}

function SplitLayout({
  children,
  rightComponent,
  showLogout = false,
  showHelp = false
}) {
  const navigate = useNavigate();

  const onLogout = async () => {
    setUser({});
    await Auth.signOut();
    navigate('/app/signin');
  };

  return (
    <div className="flex flex-col h-screen">
      <Header
        style={{ backgroundColor: 'white', padding: 0 }}
        className="z-20 flex items-center justify-between w-screen border-b border-gray-200"
      >
        <div className="flex items-center justify-between flex-1">
          <div className="flex items-center">
            <img
              src={Img}
              className="object-cover ml-4 h-14 w-14"
              height={50}
              width={50}
            />
            <div className="flex flex-col justify-center ml-4">
              <div className="p-0 m-0 leading-normal md:text-lg md:font-medium ">
                Clutch
              </div>
            </div>
          </div>
          <div>
            {showHelp && (
              <Button
                icon={<QuestionCircleOutlined />}
                type="link"
                onClick={() => {
                  const args = {
                    message: 'Need help?',
                    description:
                      'If you have questions or issues please contact our team: support@thatsclutch.com',
                    duration: 0,
                    placement: 'bottomRight'
                  };
                  notification.open(args);
                }}
                className="mr-4"
              />
            )}
            {showLogout && (
              <Button type="ghost" onClick={onLogout} className="mr-4">
                Log out
              </Button>
            )}
          </div>
        </div>
      </Header>
      <div className="flex flex-1 overflow-hidden">
        <div className="flex-1 overflow-auto">{children}</div>
        <div className="flex-1 hidden lg:block">{rightComponent}</div>
      </div>
    </div>
  );
}

export {
  AppContent,
  SplitLayout,
  LayoutScroll,
  Layout2,
  PrivateLayout,
  SettingsLayout
};
