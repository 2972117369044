import React, { useState } from 'react';
import { Button, Form, Cascader, Input, Row, Col, Card, Divider } from 'antd';
import { useToasts } from 'react-toast-notifications';
import { AmpAPI } from '@thatsclutch/shared/services/AmplifyService';
import { Auth } from 'aws-amplify';
const { TextArea } = Input;

const SupportRequest = () => {
  const [form] = Form.useForm();
  const { addToast } = useToasts();
  const [isLoading, setLoading] = useState(false);
  const [altSupport, setAltSupport] = useState(false);

  const onContact = async values => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('Values: ', {
        topic: values.issue[0],
        text: values.message,
        email: user.attributes.email
      });
      const result = await AmpAPI.post('CampusAPI', '/support/message', {
        body: {
          topic: values.issue[0],
          text: values.message,
          email: user.attributes.email
        }
      });
      if (result.error) {
        throw result.error.message;
      }
    } catch (e) {
      // send email
      console.error(e);
      addToast(e, {
        appearance: 'error'
      });
      setAltSupport(true);
      setLoading(false);
      return;
    }

    // on success
    form.resetFields();
    addToast('Request has been sent.', {
      appearance: 'success'
    });
    setLoading(false);
  };
  const options = [
    {
      value: 'Issue with Customer',
      label: 'Issue with Customer'
    },
    {
      value: 'Technical Support',
      label: 'Technical Support'
    },
    {
      value: 'Billing Issue',
      label: 'Billing Issue'
    },
    {
      value: 'I would like to deactivate my account',
      label: 'I would like to deactivate my account'
    },
    {
      value: 'Configure Services',
      label: 'Configure Services'
    },
    {
      value: 'Configure Availability',
      label: 'Configure Availability'
    },
    {
      value: 'Update Business',
      label: 'Update Business'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ];

  return (
    <div className="p-5 ">
      <Row>
        <Col span={24} lg={{ span: 12 }} className="p-4">
          <Form form={form} layout="vertical" onFinish={onContact}>
            <Form.Item
              label="Issue Category"
              name="issue"
              rules={[{ required: true, message: 'Please select a category.' }]}
            >
              <Cascader options={options} placeholder="Please select" />
            </Form.Item>
            <Divider type="horizontal" />
            <Form.Item
              label="Explain Issue"
              name="message"
              rules={[{ required: true, message: 'Please enter a message.' }]}
            >
              <TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                maxLength={250}
                showCount
                placeholder="ex. Cannot upload a food item photo..."
              />
            </Form.Item>

            <Button type="primary" htmlType="submit" loading={isLoading}>
              Send Request
            </Button>
          </Form>
        </Col>
        {altSupport && (
          <Col span={24} lg={{ span: 12 }} className="p-4">
            <Card title="Contact Info">
              <p>
                Please contact{' '}
                <span>
                  <a href="mailto:support@thatsclutch.com">
                    support@thatsclutch.com
                  </a>
                </span>{' '}
                if the support request is not successful.
              </p>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SupportRequest;
