import { AmpAPI } from "@thatsclutch/shared";
import { Button } from "antd";
import dayjs from "dayjs";
import React from "react";

interface SubmissionProps {
  submission: any;
  onUpdated: () => void;
}

const Submission: React.FC<SubmissionProps> = ({ submission, onUpdated }) => {
  const [loading, setLoading] = React.useState(false);

  const onApprove = async () => {
    try {
      setLoading(true);
      const response = await AmpAPI.post(
        "CampusAPI",
        "/collab/" +
          submission.collabID +
          "/submission/" +
          submission.id +
          "/approve",
        {
          body: {},
        }
      );

      if (response?.error) {
        console.log(response.error);
        throw "Approval failed. Contact support.";
      }
      await onUpdated();
    } catch (e) {
      setLoading(false);
      console.error(e);
      alert("Something went wrong. Contact support");
    }
  };

  return (
    <div className="p-4 space-y-2 bg-white border rounded-lg border-light">
      <div className="flex items-center justify-between space-x-4">
        <div className="flex-1 space-y-2 overflow-hidden">
          {submission.links && (
            <a
              href={submission.links && submission.links}
              rel="noreferrer"
              target="_blank"
              className="underline overflow-ellipsis whitespace-nowrap"
            >
              {submission.links}
            </a>
          )}
          <div className="text-sm text-zinc-700">{submission.note}</div>
          <div className="text-sm text-rose-400">{submission.revisionNote}</div>
        </div>
        {submission.status === "IN_REVIEW" && (
          <Button
            type="default"
            onClick={onApprove}
            disabled={loading}
            loading={loading}
          >
            Approve & Pay
          </Button>
        )}
      </div>
      {/* <div>{submission.parentID}</div> */}
      <div className="flex items-center justify-between">
        <div className="text-xs text-zinc-500">{submission.status}</div>
        <div className="text-xs text-zinc-400">
          {dayjs(submission.createdAt).format("H:m a - M/D/YYYY")}
        </div>
      </div>
    </div>
  );
};

export default Submission;
