import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment, useRef, useState } from "react";

function HelpPopup({ onClose, open }) {
  const cancelButtonRef = useRef(null);
  return (
    <>
      <Transition.Root appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={() => onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:max-w-2xl">
                  <div className="">
                    <div className="flex justify-end pt-4 pr-6">
                      <button ref={cancelButtonRef} onClick={() => onClose()}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="12" fill="#EEEEEE" />
                          <path
                            d="M14.3173 16C14.2773 16 14.2395 15.982 14.215 15.9513L11.9854 13.1529C11.9331 13.0872 11.8304 13.0881 11.7793 13.1547L9.63835 15.9494C9.61398 15.9812 9.57548 16 9.53456 16H8.13181C8.02395 16 7.96359 15.8797 8.0303 15.7977L11.0338 12.106C11.0705 12.0609 11.0707 11.9974 11.0344 11.952L8.02735 8.20162C7.9615 8.11949 8.022 8 8.12943 8H9.68229C9.72224 8 9.75994 8.01789 9.78441 8.04845L12.0138 10.8329C12.0662 10.8984 12.1689 10.8973 12.2198 10.8306L14.3428 8.05071C14.3672 8.01882 14.4057 8 14.4467 8H15.8497C15.9575 8 16.0179 8.12021 15.9513 8.20222L12.9654 11.8796C12.9288 11.9246 12.9285 11.988 12.9647 12.0333L15.9728 15.7986C16.0384 15.8807 15.9779 16 15.8706 16H14.3173Z"
                            fill="#6E776E"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="p-6 pb-12">
                      <div className="pb-10">
                        <div className="text-4xl font-semibold">
                          Need help? <br />
                          See our how-to guides
                        </div>
                        <div>
                          We cover everything from accepting collabs and
                          managing a collab to getting paid.
                        </div>
                      </div>
                      <div>
                        <a
                          href="https://nervous-hat-0d4.notion.site/Creator-How-To-Guides-03e0e69041c541e0b572c943b5ccc29b?pvs=4"
                          target="_blank"
                        >
                          <button className="rounded-full px-8 py-4 bg-[#30DF87]">
                            Go To How-To Guides
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="bg-[#E7EEEB] px-8 py-6">
                      <div className="text-[rgb(13,28,13)] opacity-80 font-semibold pb-1 text-[20px]">
                        Reach out to support
                      </div>
                      <div className="text-[rgba(20,20,20)] opacity-70 ">
                        If the How-To guides didn't help you solve your problem,
                        reach out to us at{" "}
                        <a
                          href="mailto:support@thatsclutch.com"
                          className="font-semibold underline"
                        >
                          support@thatsclutch.com
                        </a>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default HelpPopup;
