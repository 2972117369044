import React, { useState } from "react";
import { FiArrowDown, FiChevronDown, FiLoader } from "react-icons/fi";
// import { FiArrowDown, FiX } from 'react-icons/fi';
import { RiArrowDownSFill } from "react-icons/ri";

export default function DropDown({
  titleProp = "",
  items,
  selected,
  defaultValue = "",
  onSelect,
  disabled = false,
  loading = false,
}) {
  const [showList, setShowList] = useState(false);
  const [isDefault, setIsDefault] = useState(true);

  const onToggle = () => {
    setShowList(!showList);
  };
  const handleSelection = (item) => {
    onSelect(item);
    setIsDefault(false);
    setShowList(false);
  };

  return (
    <div className="relative">
      <button
        onBlur={() => setShowList(false)}
        className="relative w-full text-left bg-white whitespace-nowrap"
        disabled={disabled}
      >
        <div
          onClick={onToggle}
          className="flex items-center justify-between w-full px-4 py-2 space-x-6 border border-gray-100 rounded-lg cursor-pointer"
        >
          {/* <div className="cursor-pointer">{isDefault ? defaultValue : selected}</div> */}
          <div
            className={`${
              disabled ? "text-gray-400" : "text-black"
            } lowercase cursor-pointer first-letter:uppercase`}
          >
            {selected
              ? titleProp
                ? selected[titleProp]
                : selected
              : defaultValue}
          </div>
          {loading ? (
            <FiLoader className="w-6 h-6 text-gray-400 animate-spin" />
          ) : (
            <FiChevronDown className="w-6 h-6 text-gray-400" />
          )}
        </div>

        {items && showList && (
          <div
            className="absolute z-50 w-full mt-1 overflow-hidden overflow-y-scroll bg-white divide-y rounded-md shadow-lg divide-neutral-200 min-w-max max-h-92"
            onBlur={onToggle}
          >
            {defaultValue && (
              <div
                className="px-6 py-4 cursor-pointer text-neutral-500 hover:bg-[#eee]/40"
                onClick={() => {
                  handleSelection(null);
                }}
              >
                {defaultValue}
              </div>
            )}
            {items.map((item, i) => (
              <div
                key={i}
                onClick={() => handleSelection(item)}
                className="px-6 py-4 text-black lowercase cursor-pointer hover:bg-[#eee]/40 first-letter:uppercase"
              >
                {titleProp ? item[titleProp] : item}
              </div>
            ))}
          </div>
        )}
      </button>
    </div>
  );
}
