import { getMatchesByBriefID } from "@thatsclutch/shared/services/MatchingService";
import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { FiChevronDown, FiLoader } from "react-icons/fi";
import {
  useMatchDispatch,
  useMatchState,
} from "../../../../../../../contexts/MatchContext";
import { updateBrief } from "@thatsclutch/shared/services/BriefService";
import MatchItem from "./MatchItem";

export default function Brief({ brief, client, onUpdate, onRefresh }) {
  const [expanded, setExpanded] = useState(false);
  const [matches, setMatches] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { isMatching } = useMatchState();
  const dispatch = useMatchDispatch();

  const loadMatches = async () => {
    setLoading(true);
    const result = await getMatchesByBriefID(brief?.id);
    setMatches(result);
    setLoading(false);
  };

  const startMatching = () => {
    dispatch({
      type: "startMatching",
      payload: { ...brief, client, matches: matches },
    });
  };

  const updateStatus = async (status) => {
    setLoading(true);
    try {
      const result = await updateBrief(brief, {
        status,
      });
      onRefresh();
    } catch (e) {
      console.log("Error updating brief");
    } finally {
      setLoading(false);
    }
  };

  const formatLink = (unformattedLink: string) => {
    if (!unformattedLink) return "Invalid link";
    if (!unformattedLink.includes("http"))
      return "https://" + unformattedLink.trim();
    return unformattedLink.trim();
  };

  return (
    <div
      key={brief.id}
      className="p-6 space-y-4 bg-white border-t shadow-xl border-slate-50 shadow-slate-200/50 rounded-xl"
    >
      <div className={`flex items-center justify-between`}>
        <div>
          {brief.status === "ACTIVE" ? (
            <div className="text-xs font-medium text-green-400">Active</div>
          ) : (
            <div className="text-xs font-medium text-rose-400">Inactive</div>
          )}
          <div className="text-xl font-bold">{brief?.serviceName}</div>
        </div>
        <div className="flex space-x-4">
          <div className="space-y-2 text-right">
            <div className="text-xs text-slate-500">Monthly budget</div>
            <div className="text-base">${brief?.weeklyBudget}</div>
          </div>
          <button
            onClick={() => {
              !expanded && loadMatches();
              setExpanded(!expanded);
            }}
            className="flex items-center justify-center p-4 transition-all rounded-full cursor-pointer hover:bg-slate-200"
          >
            <FiChevronDown
              className={`transition-all   ${expanded && "rotate-180"}`}
              size={24}
            />
          </button>
        </div>
      </div>
      {expanded && (
        <div className="space-y-4">
          {!brief.status ||
            (brief.status === "INACTIVE" && (
              <div className="flex items-center justify-between p-4 font-semibold bg-yellow-100 rounded-md first-letter:capitalize">
                <div>
                  This collab proposal is inactive and is not visible to the
                  client
                </div>
                <button
                  onClick={() => updateStatus("ACTIVE")}
                  className="text-black underline cursor-pointer"
                >
                  Activate
                </button>
              </div>
            ))}
          <div className="flex py-4 space-x-16 border-gray-100 border-y">
            <div className="flex-1 space-y-2">
              <div className="text-sm font-medium text-gray-600">
                Start date
              </div>
              <div className="text-base">
                {brief?.startDate &&
                  dayjs(brief?.startDate, "YYYY-MM-DD").format("MMM DD, YYYY")}
              </div>
            </div>
            <div className="flex-1 space-y-2 overflow-hidden text-ellipsis">
              <div className="text-sm font-medium text-gray-600">
                Social that it's for
              </div>
              <a
                href={formatLink(brief?.socialLink)}
                target="_blank"
                rel="noreferrer"
				className="text-base underline line-clamp-1 hover:text-green-500/100"
              >
                {formatLink(brief?.socialLink) || "instagram.com/@someuser"}
              </a>
            </div>
          </div>
          <div className="space-y-2">
            <div className="text-sm font-medium text-gray-600">
              Collab details
            </div>
            <div className="leading-normal whitespace-pre-wrap">
              {brief?.details}
            </div>
          </div>
          <div className="pt-6 space-y-2 border-t border-gray-100">
            <div className="text-sm font-medium text-gray-600">
              Notes for matching (internal)
            </div>
            <div className="leading-normal whitespace-pre-wrap">
              {brief?.requirements || "No notes"}
            </div>
          </div>
          <div className="flex items-center justify-between py-4 space-x-6 border-t border-gray-100">
            {(brief.status === null || brief.status === "ACTIVE") && (
              <button
                onClick={() => updateStatus("INACTIVE")}
                className="underline cursor-pointer hover:text-red-500/100 text-red-500/50"
              >
                Deactivate
              </button>
            )}
            <div className="flex justify-end flex-grow">
              <button
                onClick={() => onUpdate(brief)}
                className="font-medium underline cursor-pointer"
              >
                Edit project
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between pt-6">
            <div className="text-xl font-medium">
              Recommendations ({matches.length})
            </div>
            {isMatching ? (
              <div>Currently Matching</div>
            ) : (
              <button
                onClick={startMatching}
                className="bg-[#30DF87] rounded-full px-6 py-3"
              >
                Add Recommendations
              </button>
            )}
          </div>
          {/* MATCHES */}
          <div className="space-y-4">
            {loading ? (
              <div className="flex items-center justify-center h-full">
                <div className="flex justify-center py-4">
                  <FiLoader className="w-4 h-4 text-black animate-spin" />
                </div>
              </div>
            ) : matches?.length == 0 ? (
              <div className="flex justify-center py-4 rounded-lg bg-slate-50">
                None found. Add recommendations!
              </div>
            ) : (
              matches.map((item) => (
                <MatchItem
                  match={item}
                  brief={brief}
                  onUpdate={() => {
                    loadMatches();
                  }}
                />
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}
