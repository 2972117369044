import React from "react";

function PillItem({ text, style }) {
  return (
    <div
      className={`${style} rounded-full px-3 first-letter:capitalize py-1 max-w-max`}
    >
      {text}
    </div>
  );
}

export default PillItem;
