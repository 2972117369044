import {
	ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";
import { CheckBadgeIcon as CheckBadgeIconSolid } from "@heroicons/react/24/solid";
import { Spin } from "antd";
import React from "react";

export default function TodoItem({
  completed,
  title,
  onClick,
  loading = false,
}) {
  return (
    <div
      onClick={onClick}
      className="flex items-center justify-between px-6 py-3 transition-opacity bg-white border rounded-md cursor-pointer hover:opacity-70 group"
    >
      <div className="flex items-center w-full space-x-6">
        {completed ? (
          <CheckBadgeIconSolid className="w-8 h-8" strokeWidth={1} />
        ) : (
          <CheckBadgeIcon className="w-8 h-8 text-gray-500" strokeWidth={1} />
        )}
        <div className="line-clamp-1">{title}</div>
      </div>

      {loading ? (
        <div>
          <Spin spinning={loading} />
        </div>
      ) : (
        <div className="transition-all duration-500 opacity-0 group-hover:opacity-100">
          <ArrowRightIcon
            className="w-4 h-4 text-gray-500"
            strokeWidth={1}
          />
        </div>
      )}
    </div>
  );
}
