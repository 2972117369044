import { getProfilePicture } from "@thatsclutch/shared/services/UserService";
import React, { useEffect, useState } from "react";

export default function ProfileImage({ profileImage, size = "sm" }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    loadImage();
  }, [profileImage]);

  const loadImage = async () => {
    const result = await getProfilePicture({ image: profileImage });
    result && setImage(result);
  };

  const sizes = {
    xs: 32,
    sm: 48,
    md: 64,
    lg: 96,
	xl: 200,
  };

  const placeholder = {
    xs:
      "h-8 w-8 rounded-full min-w-max bg-gradient-to-br from-slate-300 to-slate-200 text-slate-200",
    sm:
      "h-12 w-12 rounded-full min-w-max bg-gradient-to-br from-slate-300 to-slate-200 text-slate-200",
    md:
      "h-16 w-16 rounded-full min-w-max bg-gradient-to-br from-slate-300 to-slate-200 text-slate-200",
    lg:
      "h-24 w-24 rounded-full min-w-max bg-gradient-to-br from-slate-300 to-slate-200 text-slate-200",
  };
  
  const loading = {
    xs: "h-8 w-8 rounded-full min-w-max bg-slate-200 text-slate-200",
    sm: "h-12 w-12 rounded-full min-w-max bg-slate-200 text-slate-200",
    md: "h-16 w-16 rounded-full min-w-max bg-slate-200 text-slate-200",
    lg: "h-24 w-24 rounded-full min-w-max bg-slate-200 text-slate-200",
  };

  return !profileImage ? (
    <div className="z-10 min-w-max">
      <div className={placeholder[size]}></div>
    </div>
  ) : image ? (
    <div className="z-10 min-w-max ">
      <img
        className={`rounded-full bg-slate-100 object-cover aspect-square ${size === 'xl' ? 'rounded-xl' : 'rounded-full'}`}
        src={image}
        height={sizes[size]}
        width={sizes[size]}
        alt="profile photo"
      />
    </div>
  ) : (
    <div className="z-10 min-w-max">
      <div className={loading[size]}></div>
    </div>
  );
}
