import React, { useState } from "react";
import Kanban from "./Kanban";
import Table from "./Table";
import MainTable from "./MainTable";

export default function Applicants() {
  const [page, setPage] = useState("table"); // Remember value in localStorage

  const Tab = ({ title }) => (
    <div
      className={` ${page == title.toLowerCase() &&
        "text-[#0A8754] font-[600] border-b-[#0A8754] border-b-2"} text-center w-24  text-base cursor-pointer p-2`}
      onClick={() => setPage(title.toLowerCase())}
    >
      {title}
    </div>
  );

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
	  <MainTable />
    </div>
  );
}
