import React, { useState, useEffect } from "react";
import { navigate, useParams } from "@reach/router";
import { Auth } from "aws-amplify";
import { Button, Form, Checkbox, Input } from "antd";
import { useToasts } from "react-toast-notifications";
import { useAppStore } from "../../../stores/app";
import useAuth from "../../Auth/useAuth";
import { resendTemporaryPassword } from "../../../lib/utils/publicAPI";

const SignIn = () => {
  const { addToast } = useToasts();
  const [state, actions] = useAppStore();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const params = useParams();
  const [currUser, userLoading, logoutUser, getUser] = useAuth();

  useEffect(() => {
    if (params.username)
      form.setFieldsValue({
        username: params.username,
        password: params.password,
      });
    return () => {};
  }, [params]);

  useEffect(() => {
    if (currUser) {
      if (currUser.userGroups.includes("admins")) navigate("/app/admin");
      else if (currUser.userGroups.includes("creators")) {
        window._dcq.length &&
          window._dcq.push(["identify", { email: currUser.email }]);
        navigate("/app/dashboard");
      } else {
        logoutUser();
        addToast("You are not yet a creator or have been deactivated.", {
          appearance: "error",
        });
        setLoading(false);
      }
    }
  }, [currUser]);

  const login = async (values) => {
    const username = values.username?.trim()?.toLowerCase();
    try {
      setLoading(true);
      const userSnapshot = await Auth.signIn(username, values.password);
      if (userSnapshot.challengeName === "NEW_PASSWORD_REQUIRED") {
        actions.setUser({ ...userSnapshot, tempPass: values.password });
        navigate("/app/set-password");
        return;
      }
      const success = await getUser();
      if (!success)
        addToast("You are not yet a creator or have been deactivated.", {
          appearance: "error",
        });
    } catch (err) {
      console.error(err);
      if (err.message.includes("expired")) {
        // call endpoint to resend temporary password
        const data = await resendTemporaryPassword(username);
        console.log("result from resend", data);
        if (data?.error)
          addToast(data.error || "Something went wrong. Try again later.", { appearance: "error" });
        else setShowResend(true);
      } else {
        addToast(err.message || err, { appearance: "error" });
        console.log("error...: ", err.message || err);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex flex-col w-1/2 gap-8">
        <div className="mb-6">
          <div>Clutch Studio</div>
          <div className="text-[40px] font-semibold">Welcome back</div>
        </div>
		{showResend && (
            <div className="p-2 px-4 text-sm text-yellow-600 rounded bg-yellow-400/20">
              Temporary password expired. A new one has been sent to your email.
            </div>
          )}

        <Form
          form={form}
          initialValues={{
            terms: false,
          }}
          layout="vertical"
          onFinish={login}
          size="large"
          requiredMark={false}
        >
          
          <Form.Item
            placeholder="Email"
            name="username"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              { required: true, message: "Please enter your Username!" },
            ]}
          >
            <Input
              name="username"
              placeholder="example@domain.com"
              style={{ textTransform: "lowercase" }}
            />
          </Form.Item>

          <Form.Item
            placeholder="Password"
            name="password"
            rules={[{ required: true }]}
            extra={
              <div className="flex justify-end mt-1 mb-4 ">
                <a href="/app/forgotpassword" className="text-[#64D698]">
                  Forgot Password?
                </a>
              </div>
            }
          >
            {/* <input name="password" type="password" className="w-full p-3 rounded-full outline-none ring-1 ring-gray-400" /> */}
            <Input.Password name="password" placeholder="Password..." />
          </Form.Item>

          <div className="">
            <Button
              name="submit"
              style={{ background: "#30DF87", border: "none" }}
              shape="round"
              size="large"
              className="w-32 bg-[#30DF87]"
              loading={isLoading}
              htmlType="submit"
            >
              Sign In
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
