import React, { useState, useEffect } from "react";
import { navigate, useParams } from "@reach/router";
import { Button, Form, Checkbox, Input } from "antd";
import { Helmet } from "react-helmet";

import { useToasts } from "react-toast-notifications";
import { SplitLayout } from "../../../Layout";
import { LoginBack } from "../../../Routes/PublicRoute";
import Name from "./Name";
import Phone from "./Phone";
import BusinessName from "./Business";
import Business from "./Business";
import Location from "./Location";
import Stripe from "./Stripe";
import {
  getProvider,
  getProviderByID,
} from "@thatsclutch/shared/services/UserService";
import { Auth } from "aws-amplify";
import { setUser } from "../../../Auth/AppUser";
import Graduation from "./Graduation";
import {
  getCreator,
  getCurrentCreator,
} from "@thatsclutch/shared/services/CreatorService";

const Onboard = (props) => {
  const { addToast } = useToasts();
  const [page, setPage] = useState("name");
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    checkPageParam(window.location.hash);
    getCreatorInfo();
  }, []);

  const checkPageParam = (page) => {
    if (page) {
      setPage(page.split("#")[1]);
    }
  };

  const getCreatorInfo = async () => {
    const creator = await getCurrentCreator();
    if (!creator) {
      // automatically redirect if creator does not exist
      setUser({});
      await Auth.signOut();
      navigate("/app/signin");
    }
    setCreator(creator);
  };

  const syncCreator = async (callback) => {
    const creator = await getCurrentCreator();
    setCreator(creator);
    callback();
  };

  const nextPage = async () => {
    switch (page) {
      case "name":
        await getCreatorInfo();
        addToast("Name saved successfully", {
          appearance: "success",
        });
        setPage("phone");
        return;
      case "phone":
        await getCreatorInfo();
        addToast("Phone saved successfully", {
          appearance: "success",
        });
        setPage("location");
        // setPage("graduation");
        return;
      case "graduation":
        await getCreatorInfo();
        addToast("Graduation saved successfully", {
          appearance: "success",
        });
        setPage("location");
        return;
      case "location":
        addToast("Location saved successfully", {
          appearance: "success",
        });
        setPage("stripe");
        return;
      default:
        alert("no page handle");
    }
  };

  const showError = (errMessage) => {
    addToast(errMessage, {
      appearance: "error",
    });
  };

  return (
    <SplitLayout rightComponent={<LoginBack />} showLogout showHelp>
      <Helmet>
        <script
          async
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC32slxGtvobIOSv3WTQhOQVpstWCOYm2E&libraries=places"
        ></script>
      </Helmet>
      <div className="h-full flex flex-col items-center justify-center">
        {!creator ? (
          <div>Loading...</div>
        ) : page === "name" ? (
          <Name
            provider={creator}
            onNext={() => syncCreator(() => setPage("phone"))}
            onError={showError}
          />
        ) : page === "phone" ? (
          <Phone
            provider={creator}
            onNext={() => syncCreator(() => setPage("location"))}
            onBack={() => setPage("name")}
            onError={showError}
          />
        ) : page === "location" ? (
          <Location
            creator={creator}
            onNext={() => syncCreator(() => setPage("stripe"))}
            onBack={() => setPage("phone")}
            onError={() => {}}
          />
        ) : page === "stripe" ? (
          <Stripe onNext={() => {}} />
        ) : null}
      </div>
    </SplitLayout>
  );
};

export default Onboard;
