import { AmpAPI } from "@thatsclutch/shared";
import React, { useEffect } from "react";
import { FiLoader } from "react-icons/fi";

export default function StripeSummary({ creator }) {
  const [balance, setBalance] = React.useState("$0.00");
  const [totalPayouts, setTotalPayouts] = React.useState("$0.00");
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getStripeSummary();
  }, []);

  const getStripeSummary = async () => {
    setLoading(true);
    try {
      const results = await AmpAPI.get("CampusAPI", "/trial/payments", {});
      console.log(results);
      // Format as money string
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // hide cents
        minimumFractionDigits: 0,
      });
      const balance = results?.balance?.available[0].amount / 100 ?? 0;
      const formattedBalance = formatter.format(balance);
      setBalance(formattedBalance);

      const totalPayouts = results?.payouts.data.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0
      );

      const formattedPayout = formatter.format(totalPayouts / 100);

      setTotalPayouts(formattedPayout);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 overflow-hidden bg-white shadow-2xl sm:space-x-4 sm:flex md:p-6 shadow-slate-200/50 rounded-2xl">
      <div className="flex-1">
        <div className="flex items-center justify-start space-x-4">
          <div className="text-lg font-semibold">Clutch Bank</div>
          {loading && (
            <div className="flex items-center h-6 right-4">
              <FiLoader className="w-4 h-4 text-slate-500 animate-spin" />
            </div>
          )}
        </div>
        <div className="text-sm text-gray-600 whitespace-pre-line">
          Your balance will be payed out to you every 2-3 days.
        </div>
        <a
          href="https://connect.stripe.com/express_login"
          target="_blank"
        >
          <div className="pt-4 text-green-500 cursor-pointer">
            View Stripe Dashboard
          </div>
        </a>
      </div>
      <div className="flex items-end pt-4 space-x-4 sm:pt-0 sm:space-y-4 sm:space-x-0 sm:flex-col">
        <div className="text-sm text-gray-600 whitespace-pre-line">
          Current Balance:{" "}
          <span className="text-base font-bold text-black">{balance}</span>
        </div>
        <div className="text-sm text-gray-600 whitespace-pre-line">
          Total Earnings:{" "}
          <span className="text-base font-bold text-black">{totalPayouts}</span>
        </div>
      </div>
    </div>
  );
}
