import { Divider } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import {
  CurrentRefinements,
  Hits,
  Pagination,
  RefinementList,
  SearchBox,
  Stats,
} from "react-instantsearch";
import { CopyContext } from "..";
import {
  showToast,
  useErrorDispatch,
} from "../../../../../contexts/ErrorContext";

export default function SearchTable({ hitItem, defaultValue = "" }) {
  const { copiedEmails, setCopiedEmails } = React.useContext(CopyContext);
  const alertDispatch = useErrorDispatch();
  const transformItems = (items) => {
    return items.map((item) => ({
      ...item,
      dob: Math.abs(dayjs(item?.dob).diff(dayjs(), "year")) + " years old",
      industries: item.industries
        ? item?.industries.split(",").join(" - ")
        : "",
      location:
        item.locality && item.state
          ? item.locality + ", " + item.state
          : "Incomplete",
    }));
  };

  //   Keep state from refreshing during details open
  const transformRefinementList = (items) => {
    return items.map((item) => ({
      ...item,
      // make lower case and swap underscore for space
      label: item.label.toLowerCase().replace(/_/g, " "),
    }));
  };

  return (
    <div className="relative min-w-[400px] space-y-6">
      {copiedEmails && copiedEmails.length > 0 && (
        <div
          onClick={async (e) => {
            setCopiedEmails([]);
          }}
          className="flex justify-between p-4 text-black rounded-md cursor-pointer hover:opacity-50 bg-emerald-200"
        >
          <div className="">
            {copiedEmails.length} email{copiedEmails.length > 1 ? "s" : ""}{" "}
            copied to clipboard
          </div>
          <div>Clear</div>
        </div>
      )}
      <div className="space-y-2 overflow-x-auto">
        <div className="flex items-center space-x-4">
          <div className="w-12 text-sm text-gray-500">Status</div>
          <Divider type="vertical" />
          <RefinementList
            attribute="status"
            //   transformItems={transformRefinementList}
            classNames={{
              list: "flex space-x-4",
              selectedItem: "font-normal",
            }}
            className="text-base font-normal capitalize text-slate-600"
          />
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-12 text-sm text-gray-500">Type</div>
          <Divider type="vertical" />
          <RefinementList
            attribute="verifiedStatus"
            //   transformItems={transformRefinementList}
            classNames={{
              list: "flex space-x-4",
              selectedItem: "font-normal",
            }}
            className="text-base font-normal capitalize text-slate-600"
          />
        </div>
      </div>
      <SearchBox
        className=""
        defaultValue={defaultValue}
        placeholder="Search all creator applicants..."
        classNames={{
          root: "",
          form: "relative",
          input:
            "border-none border-green-500 rounded-md pl-8 pr-4 py-2 w-full",
          submitIcon: "absolute top-0 left-0 bottom-0 w-6",
        }}
      />
      <div className="flex items-center justify-between space-x-8">
        {/* <CurrentRefinements /> */}
        <Stats
          classNames={{
            root: "text-xs text-slate-400",
          }}
        />
        <div className="flex items-center space-x-4">
          <Pagination padding={2} showLast={false} showFirst={false} />
        </div>
      </div>
      <div className="space-y-4">
        <Hits
          transformItems={transformItems}
          hitComponent={hitItem}
          classNames={{
            item: " hover:bg-gray-50 p-0 rounded-md",
            emptyRoot: "p-4",
            list: "p-0 space-y-4",
          }}
        />
      </div>
    </div>
  );
}
