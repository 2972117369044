import React, { useEffect, useMemo, useState } from "react";

import { Transition } from "@headlessui/react";
import SimpleModal from "../../../../../Dialogs/SimpleModal";
import ShortInput from "../../../../../Basic/Input/ShortInput";
import LongInput from "../../../../../Basic/Input/LongInput";
import PrimaryButton from "../../../../../Basic/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../Basic/Buttons/SecondaryButton";
import LinkInput from "../../../../../Basic/Input/LinkInput";
import { updateCreator } from "@thatsclutch/shared/services/CreatorService";
import {
  getCreatorByID,
  useCreatorDispatch,
} from "../../../../../../contexts/CreatorContext";

export default function EditProfile({ open, creator, onClose }) {
  const dispatch = useCreatorDispatch();
  const [loading, setLoading] = useState(false);
  const [editCreator, setEditCreator] = useState({
    firstName: creator?.firstName,
    lastName: creator?.lastName,
    links: creator?.links || ["", "", ""],
    bio: creator?.shortBio || "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    // temporary clean up function, clear after successful upload instead
    open && cleanUp();
  }, [open]);

  const onSubmit = async () => {
    const { firstName, lastName, links, bio } = editCreator;
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !links[0].trim() ||
      !bio.trim()
    ) {
      console.log("Missing value");
      return setError("Missing values, all fields required.");
    }
    setError("");
    if (
      firstName === creator.firstName &&
      lastName === creator.lastName &&
      links === creator.links &&
      bio === creator.bio
    ) {
      console.log("No changes to save!");
      return onCancel();
    }
    if (bio.length > 1000) return alert("bio message too long");
    setLoading(true);
    const data = await updateCreator(creator, {
      firstName: firstName.toLowerCase().trim(),
      lastName: lastName.toLowerCase().trim(),
      links,
      shortBio: bio,
    });

    console.log(data);
    await getCreatorByID(dispatch, creator.id);
    setLoading(false);
    console.log("Creator updated");
    onCancel();
  };

  const cleanUp = () => {
    setEditCreator({
      firstName: creator?.firstName,
      lastName: creator?.lastName,
      links: creator?.links || ["", "", ""],
      bio: creator?.shortBio || "",
    });
    setError("");
  };

  const onCancel = () => {
    onClose(false);
  };

  return (
    <SimpleModal show={open} onClose={onClose}>
      <Transition.Root show={true}>
        <div className="p-8 space-y-8">
          <div>
            <div className="text-2xl font-semibold">Edit profile</div>
            <div className="mt-2 text-sm text-slate-500">
              Update your public profile info
            </div>
          </div>
          <div className="space-y-4">
            <div>{!!error && <div className="text-red-400">{error}</div>}</div>
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <ShortInput
                value={editCreator.firstName}
                label={"First name"}
                onChange={(t) =>
                  setEditCreator((v) => ({ ...v, firstName: t }))
                }
                placeholder="type here..."
                extraClass="capitalize"
                error={null}
                required
              />
              <ShortInput
                value={editCreator.lastName}
                label={"Last name"}
                onChange={(t) => setEditCreator((v) => ({ ...v, lastName: t }))}
                placeholder="type here..."
                extraClass="capitalize"
                error={null}
                required
              />
            </div>
            <LongInput
              value={editCreator.bio || ""}
              maxLength={1000}
              label="About me"
              onChange={(t) => setEditCreator((v) => ({ ...v, bio: t }))}
              placeholder={`Things to include: 
- A snappy introduction
- Show off skills & achievements
- Why choose you?
- What can you specifically help the client with?
- Share collab stats or growth metrics!`}
              required
            />
            <LinkInput
              values={editCreator.links}
              label={"Links"}
              onChange={(t, p) => {
                let newLinks = editCreator.links;
                newLinks[p] = t;
                setEditCreator((v) => ({ ...v, links: newLinks }));
              }}
              error={null}
              required
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <SecondaryButton text={"Cancel"} onPress={onCancel} />
            <PrimaryButton loading={loading} text={"Save"} onPress={onSubmit} />
          </div>
        </div>
      </Transition.Root>
    </SimpleModal>
  );
}
