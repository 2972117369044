import { getCollabsByCreatorID } from "@thatsclutch/shared/services/CollabService";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

export default function NewCollabs({ creator, onSelectCollab, viewAll }) {
  const [collabs, setCollabs] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    creator && getCollabs();
  }, [creator]);

  const getCollabs = async () => {
    setLoading(true);
    const result = await getCollabsByCreatorID(creator.id);
    if (result) setCollabs(result.filter((v) => v?.status === "REQUESTED"));
    setLoading(false);
  };

  const sortByCreated = (a, b) => {
    if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) return 1;
    if (dayjs(b.createdAt).isBefore(dayjs(a.createdAt))) return -1;
    else return 0;
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <div className="text-sm text-gray-600">New Collab Requests</div>
        <div
          onClick={() => viewAll("Collabs")}
          className="text-sm text-gray-600 cursor-pointer hover:underline"
        >
          View all ({collabs.length})
        </div>
      </div>
      {/* List */}
      {loading ? (
        <div className="flex items-center justify-center p-4 text-sm text-gray-400 bg-white border rounded-md">
          Loading...
        </div>
      ) : collabs.length > 0 ? (
        <div className="space-y-4">
          {collabs
            .sort(sortByCreated)
            .slice(0, 3)
            .map((collab) => (
              <div
                onClick={() => onSelectCollab(collab)}
                className="flex justify-between p-4 px-6 space-x-4 bg-white shadow-2xl cursor-pointer shadow-slate-200/50 rounded-xl"
              >
                <div className="">
                  <div className="text-lg capitalize">{collab.name}</div>
                  <div className="flex space-x-4">
                    <div className="text-sm text-gray-500 capitalize">
                      {collab.client?.brand}
                    </div>
                  </div>
                </div>
                <div className="space-y-2 text-right">
                  <div className="text-sm text-gray-500">
                    Requested on {dayjs(collab.createdAt).format("MMMM Do")}
                  </div>
                  <div className="text-sm text-gray-500">View details</div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="flex items-center justify-center p-4 text-sm text-gray-400 bg-white border rounded-md">
          None yet!
        </div>
      )}
    </div>
  );
}
