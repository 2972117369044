import React, { useState } from "react";
import ShortInput from "../../../../../../Basic/Input/ShortInput";
import LongInput from "../../../../../../Basic/Input/LongInput";
import PrimaryButton from "../../../../../../Basic/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../../Basic/Buttons/SecondaryButton";

export default function EditNamePage({ onNext, onBack, currName, currDesc }) {
  const [name, setName] = useState(currName);
  const [errName, setErrName] = useState(null);
  const [desc, setDesc] = useState(currDesc);

  const onSubmit = () => {
    if (name.trim().length == 0 || name.length > 40)
      return setErrName("Please enter a service name. (max 40)");
    onNext(name, desc);
  };

  return (
    <div className="p-8 space-y-8">
      <div>
        <div className="text-2xl font-semibold">Edit a weekly service</div>
        <div className="mt-2 text-sm text-slate-500">
          Describe the deliverables you will provide
        </div>
      </div>
      <div className="p-4 px-6 rounded-xl bg-yellow-400/30">
        <div>
          A service should describe the deliverables you can submit on a weekly
          basis.
        </div>
      </div>

      <ShortInput
        value={name}
        label={"Title"}
        onChange={setName}
        placeholder="ex: Tiktok Videos"
        error={errName}
      />
      <LongInput
        value={desc}
        maxLength={250}
        label="Extra details (optional)"
        onChange={setDesc}
        placeholder="Ex: 2 revisions per video"
      />

      <div className="flex justify-end space-x-4">
        <SecondaryButton text={"Back"} onPress={onBack} />
        <PrimaryButton text={"Next"} onPress={onSubmit} />
      </div>
    </div>
  );
}
