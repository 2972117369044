import { deleteMatch } from "@thatsclutch/shared/services/MatchingService";
import dayjs from "dayjs";
import React, { useState } from "react";
import ProfileImage from "../../../../../../../Basic/ProfileImage";
import AddMatchModal from "../../../../../CreatorList/AddMatchModal";
import BasicModal from "../../../../../../../Dialogs/BasicModal";
import RequestCollab from "./RequestCollab";

export default function MatchItem({ match, brief, onUpdate }) {
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);

  const handleRemove = async () => {
    const result = await deleteMatch(match);
    onUpdate();
  };

  return (
    <div className="p-6 space-y-4 rounded-lg bg-slate-100">
      <div className="flex justify-between">
        {/* Left */}
        <div className="space-y-4">
          <div className="px-4 py-2 text-white lowercase bg-black rounded-full first-letter:capitalize w-min">
            {match.status}
          </div>
          <div className="flex items-center py-2 space-x-4">
            {/* <ProfileImage profileImage={match.creator.image} size={"md"} /> */}
            <div className="text-2xl font-medium capitalize">
              {match?.creator?.firstName + " " + match?.creator?.lastName}
            </div>
          </div>
        </div>
        {/* Right */}
        <div className="flex flex-col items-end space-y-4">
          {/* CREATE DATE */}
          <div className="space-y-1">
            <div className="font-medium ">Matched On</div>
            <div>{dayjs(match.createdAt).format("MMM DD, YYYY")}</div>
          </div>
          {/* ASSET INFO */}
          <div className="space-y-1 text-end">
            <div className="font-medium">
              {match.deliverableAmount || 9} assets
            </div>
            <div>${match.price || 300} each</div>
          </div>
        </div>
      </div>
      <div className="p-4 space-y-2 rounded-md bg-slate-200">
        <div className="text-xs font-medium text-slate-500">Your note</div>
        <div className="text-base">{match.adminNote || "None"}</div>
      </div>
      {match.rejectNote && (
        <div className="p-4 space-y-2 rounded-md bg-slate-200">
          <div className="text-xs font-medium text-slate-500">
            Client reject note
          </div>
          <div className="text-base">{match.rejectNote}</div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <button
          onClick={() => handleRemove()}
          className="underline cursor-pointer hover:text-red-500/100 text-red-500/50"
        >
          Delete match
        </button>
        <button
          onClick={() => setShowCollabModal(true)}
          className="underline cursor-pointer hover:text-red-500/100 text-red-500/50"
        >
          Request collab
        </button>
        <button
          onClick={() => setShowMatchModal(true)}
          className="font-medium underline cursor-pointer"
        >
          Edit match
        </button>
      </div>

      <BasicModal isVisible={showCollabModal} onCancel={()=>setShowCollabModal(false)}>
        <RequestCollab
          match={match}
          client={match.client}
          brief={brief}
          onRequest={() => {}}
          onClose={() => setShowCollabModal(false)}
        />
      </BasicModal>

      <AddMatchModal
        open={showMatchModal}
        onClose={() => setShowMatchModal(false)}
        onConfirm={() => {
          onUpdate();
        }}
        creator={match.creator}
        brief={brief}
        currMatch={match}
      />
    </div>
  );
}
