import React, { useEffect, useState } from "react";
import {
  ClockIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  getCollabByID,
  updateCollab,
} from "@thatsclutch/shared/services/CollabService";
import AcceptModal from "./AcceptModal";
import StartModal from "./StartModal";
import OptionMenu from "./OptionMenu";
import Month from "./Month";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getBriefByID } from "@thatsclutch/shared/services/BriefService";
import MessageStack from "./MessagesStack";
import SubmitCollabPopup from "../../Popups/SubmitCollabPopup";
import SubmitModal from "./SubmitModal";
dayjs.extend(utc);

const Detail = ({ title, value }) => (
  <div className="flex justify-center flex-1">
    <div className="flex flex-col items-center space-y-2">
      <div className="text-sm text-center text-slate-400">{title}</div>
      <div className="font-medium text-center">{value}</div>
    </div>
  </div>
);

const Info = ({
  title,
  value,
  children = null,
}: {
  title: string;
  value?: string;
  children?: Element | any | null | undefined;
}) => (
  <div className="flex-1 space-y-3">
    <div className="text-base font-medium ">{title}</div>
    {children ? (
      children
    ) : (
      <div className="text-sm whitespace-pre-wrap max-w-max text-slate-700">
        {value}
      </div>
    )}
  </div>
);

export default function Collab({ onBack, creator, collab: selectedCollab }) {
  const [collab, setCollab] = useState(selectedCollab);
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [collapsedScope, setCollapsedScope] = useState(true);
  const [brief, setBrief] = useState<any>(null);

  const [isActivated, setIsActivated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isLastMonth, setIsLastMonth] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCollab();
  }, []);

  const getCollab = async () => {
    setLoading(true);
    const result = await getCollabByID(selectedCollab.id);
    console.log(result);
    if (result && result.briefID) {
      const briefResult = await getBriefByID(result.briefID);
      setBrief(briefResult);
    }
    setCollab(result);
    // if (result?.status !== "REQUESTED") createWeeklyView(result);
    // if (result?.status !== "REQUESTED") createMonthlyView(result);
    setLoading(false);
  };

  function beforeEndDate(endDate, indexDate) {
    if (endDate) return indexDate.isBefore(dayjs(endDate));
    else return true;
  }

  const createMonthlyView = (collab) => {
    const todayDate = dayjs();
    const submissions = collab.submissions.items;
    const startDate = dayjs.utc(collab.startDate);

    let indexDate = startDate.clone();
    let pastMonths: any = [];
    while (
      (indexDate.isSame(todayDate, "day") || indexDate.isBefore(todayDate)) &&
      beforeEndDate(collab.endDate, indexDate)
    ) {
      let monthSubmissions = submissions.filter((v) => {
        if (v.lateDate)
          return (
            dayjs.utc(v.lateDate).isAfter(indexDate.startOf("day")) &&
            dayjs.utc(v.lateDate).isBefore(indexDate.add(1, "month"))
          );
        else
          return (
            dayjs.utc(v.createdAt).isAfter(indexDate.startOf("day")) &&
            dayjs.utc(v.createdAt).isBefore(indexDate.add(1, "month"))
          );
      });

      pastMonths.push({
        startDate: indexDate,
        endDate: indexDate.add(1, "month"),
        submissions: monthSubmissions,
        isCurrent:
          todayDate.isAfter(indexDate) &&
          todayDate.isBefore(indexDate.add(1, "month")),
      });

      indexDate = indexDate.add(1, "month");
    }

    setMonths(pastMonths.reverse());
  };

  function getDaysToStartDate() {
    const days = dayjs(collab?.startDate).diff(dayjs(), "days");
    return days > 0 ? "in " + days + " days" : "today";
  }

  function getDaysToStart() {
    return dayjs(collab?.startDate).diff(dayjs(), "days");
  }

  //    TEST
  async function updateCollabStatus(status) {
    const result = await updateCollab(collab, { status: status });
    console.log(result);
    setLoading(true);
    getCollab();
  }

  function getLink(link) {
    if (!link) return "No link provided";
    if (!link.includes("http")) return "https://" + link.trim();
    return link.trim();
  }

  return loading ? (
    <div className="relative h-screen overflow-y-auto">
      <div
        onClick={() => onBack()}
        className="absolute z-10 flex items-center p-4 pr-6 m-4 space-x-2 rounded-full cursor-pointer hover:bg-white/30"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>

        <div>Back</div>
      </div>
      {/* GRADIENT */}

      <div className="h-64 bg-gradient-to-r from-slate-100 to-slate-400 rounded-b-2xl"></div>
      <div className="space-y-8 -translate-y-40">
        {/* Widget */}
        <div className="mx-6 bg-white shadow-2xl md:w-3/4 md:mx-auto shadow-slate-200/50 rounded-2xl">
          <div className="h-48 p-6 space-y-4 text-center text-slate-500">
            <div className="flex items-center justify-between">
              <div className="flex pt-2 space-x-6">
                <div className="flex items-center space-x-4">
                  <div className="space-y-1">
                    <div className="text-3xl font-medium capitalize rounded-lg text-slate-50 bg-slate-50">
                      Loading profile...
                    </div>
                    <div className="text-xs rounded-lg text-slate-50 bg-slate-50">
                      they/them
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="relative h-screen overflow-y-auto bg-slate-50">
      <div
        onClick={() => onBack()}
        className="absolute z-30 flex items-center p-4 m-4 space-x-2 rounded-full cursor-pointer hover:bg-white/30"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>

        <div>Back</div>
      </div>
      {/* GRADIENT */}

      <div className="h-64 bg-gradient-to-r to-[#e1eff8] from-[#e1f3ef] rounded-b-2xl"></div>
      <div className="space-y-8 -translate-y-40">
        {/* Widget */}
        {false && (
          <div className="mx-6 space-y-4 md:w-3/4 md:mx-auto">
            <div className="flex flex-wrap items-center gap-4 p-4 shadow-2xl bg-slate-50 shadow-slate-200/50 rounded-2xl">
              <button
                className="flex-1 p-4 text-black rounded-md hover:bg-slate-200 bg-slate-100"
                onClick={() => updateCollabStatus("REQUESTED")}
              >
                Requested
              </button>
              <button
                className="flex-1 p-4 text-black rounded-md hover:bg-slate-200 bg-slate-100"
                onClick={() => updateCollabStatus("ACCEPTED")}
              >
                Accepted
              </button>
              <button
                className="flex-1 p-4 text-black rounded-md hover:bg-slate-200 bg-slate-100"
                onClick={() => updateCollabStatus("ACTIVE")}
              >
                Active
              </button>
              <button
                className="flex-1 p-4 text-black rounded-md hover:bg-slate-200 bg-slate-100"
                onClick={() => updateCollabStatus("PAUSED")}
              >
                Paused
              </button>
              <button
                className="flex-1 p-4 text-black rounded-md hover:bg-slate-200 bg-slate-100"
                onClick={() => updateCollabStatus("COMPLETED")}
              >
                Completed
              </button>
            </div>
          </div>
        )}
        {isLastMonth && (
          <div className="mx-6 space-y-4 md:w-3/4 md:mx-auto">
            <div className="flex items-center p-4 space-x-4 bg-[#FFFAD9] shadow-2xl shadow-slate-200/50 rounded-2xl">
              <ClockIcon className="w-6 h-6 text-black " />
              <div className="text-black">
                Collab is ending, submit any outstanding deliverables!
              </div>
            </div>
          </div>
        )}
        {getDaysToStart() >= 0 && collab.status === "ACCEPTED" && (
          <div className="mx-6 space-y-4 md:w-3/4 md:mx-auto">
            <div className="flex items-center p-4 pl-6 space-x-4 bg-white shadow-2xl shadow-slate-200/50 rounded-2xl">
              <ClockIcon className="w-6 h-6 text-black " />
              <div className="flex-1 space-y-1">
                <div className="text-black">
                  Collab is starting{" "}
                  <span className="font-bold">{getDaysToStartDate()}</span>, get
                  ready!
                </div>
                <div className="text-xs text-gray-400">
                  Client payment will be processed on the start date and the
                  collab will be activated
                </div>
              </div>
              <button
                className="pr-4 underline"
                onClick={() => {
                  setLoading(true);
                  getCollab();
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        )}
        <div className="mx-6 bg-white shadow-2xl md:w-3/4 md:mx-auto shadow-slate-200/50 rounded-2xl">
          <div className="p-6 space-y-4">
            {/* Top */}
            <div className="items-center justify-between space-y-6 md:space-y-0 md:flex">
              <div className="flex items-center space-x-6">
                <div className="space-y-2">
                  {collab?.status !== "REQUESTED" && (
                    <div className="px-2 py-1 text-xs text-white bg-blue-400 rounded-md w-min">
                      {collab?.status === "PAUSED" ? "ENDING" : collab.status}
                    </div>
                  )}
                  {/* <div className="text-lg text-gray-600 first-letter:capitalize">
                    {collab?.client.brand}
                  </div> */}
                  <div className="text-2xl font-medium">
                    {collab?.name}
                    <span className="text-lg font-normal text-gray-600 first-letter:capitalize">
                      {" with " + collab?.client.brand}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  className="p-3 rounded-full cursor-pointer hover:bg-gray-50"
                  onClick={() => setShowPopup(true)}
                >
                  <QuestionMarkCircleIcon className="w-6 h-6 text-slate-600" />
                </button>
                {collab?.status === "REQUESTED" ? (
                  <div>
                    <AcceptModal
                      collab={collab}
                      creator={creator}
                      onSuccess={async () => {
                        setIsActivated(true);
                        await getCollab();
                      }}
                      clientName={collab?.client.brand}
                    >
                      <div className="px-8 py-3 text-sm font-light text-center text-black capitalize transition-opacity bg-green-400 rounded-full cursor-pointer hover:opacity-50">
                        Accept Collab
                      </div>
                    </AcceptModal>
                  </div>
                ) : collab?.status === "PAUSED" ? (
                  <div
                    onClick={() => {}}
                    className="px-8 py-3 text-sm font-light text-center capitalize rounded-full text-slate-600 bg-slate-100"
                  >
                    Ending soon
                  </div>
                ) : collab?.status === "ACTIVE" ? (
                  <SubmitModal
                    onComplete={async () => {
                      await getCollab();
                    }}
                    collabID={collab?.id}
                  >
                    <div
                      onClick={() => {}}
                      className="w-full px-6 py-3 text-base text-center text-black capitalize transition-opacity bg-green-400 cursor-pointer whitespace-nowrap rounded-xl hover:opacity-50"
                    >
                      Submit Asset
                    </div>
                  </SubmitModal>
                ) : null}
                <StartModal
                  open={isActivated}
                  setOpen={setIsActivated}
                  onSubmit={() => {
                    setIsActivated(false);
                    setShowPopup(true);
                  }}
                />
                <SubmitCollabPopup
                  onClose={() => setShowPopup(false)}
                  open={showPopup}
                />
                <div>
                  <OptionMenu collab={collab} onUpdate={getCollab} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-6 bg-white shadow-2xl md:w-3/4 md:mx-auto shadow-slate-200/50 rounded-2xl">
          <div className="flex p-6 space-x-4 justify-evenly">
            <Detail
              title="Price"
              value={"$" + Math.round(collab?.price) / 100}
            />
            <Detail title="# of assets" value={collab?.deliverableCount} />

            {collab?.startDate && (
              <Detail
                title="Start date"
                value={dayjs.utc(collab?.startDate).format("DD MMMM")}
              />
            )}
            {collab?.endDate && (
              <Detail
                title="End date"
                value={dayjs.utc(collab?.endDate).format("DD MMMM")}
              />
            )}
          </div>
          <div className="px-8 pt-8 pb-2 space-y-4 leading-normal border-t border-slate-100">
            <div
              className={`whitespace-pre-line  text-slate-600  ${collapsedScope &&
                "line-clamp-2"}`}
            >
              {collab?.requirements}
            </div>
            {!collapsedScope && brief?.socialLink && (
              <div className="overflow-hidden text-ellipsis">
                <div className="">Link</div>
                <a
                  href={getLink(brief?.socialLink)}
                  target="_blank"
                  className="pt-4 text-sm underline whitespace-nowrap text-slate-600"
                >
                  {brief?.socialLink}
                </a>
              </div>
            )}
            {!collapsedScope && brief?.clientNote && (
              <div className="overflow-hidden text-ellipsis">
                <div className="">Client notes</div>
                <div className="leading-normal text-slate-600">
                  {brief?.clientNote}
                </div>
              </div>
            )}
          </div>

          <button
            onClick={() => setCollapsedScope(!collapsedScope)}
            className="w-full pt-4 pb-4 text-sm whitespace-pre-line transition-colors hover:underline text-slate-600 hover:bg-slate-50"
          >
            Show {collapsedScope ? "more" : "less"}
          </button>
        </div>

        <div className="mx-6 space-y-4 md:w-3/4 md:mx-auto">
          <MessageStack
            submissions={collab.submissions.items}
            collab={collab}
            refresh={() => getCollab()}
          />
        </div>
        <div className="mx-6 space-y-4 md:w-3/4 md:mx-auto">
          <div className="text-2xl font-medium">About the collab</div>
          <div className="flex flex-col space-y-4 xl:space-y-0 xl:flex-row xl:space-x-4">
            {(collab?.status === "REQUESTED" ||
              collab?.status === "ACCEPTED") && (
              <div className="flex-1 p-6 space-y-6 bg-white shadow-2xl shadow-slate-200/50 rounded-2xl">
                <Info title="Details" value={collab?.requirements} />
                {brief?.clientNote && (
                  <Info title="Notes" value={brief?.clientNote} />
                )}
                {brief?.socialLink && (
                  <Info title="Links" value={brief?.socialLink} />
                )}
              </div>
            )}
            <div className="flex-1 p-6 bg-white shadow-2xl shadow-slate-200/50 rounded-2xl ">
              <div className="space-y-6">
                {collab?.client.firstName && (
                  <Info
                    title="Point of Contact"
                    children={
                      <div className="flex items-center ">
                        <div className="flex items-center flex-1 pr-4 space-x-4">
                          <div>
                            <div className="capitalize">
                              {collab?.client.firstName +
                                " " +
                                collab?.client.lastName}
                            </div>
                            {collab?.client.role && (
                              <div className="text-xs text-slate-600">
                                {collab?.client.role}
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              collab?.client.email || "no email provided"
                            );
                            setTimeout(() => {
                              setCopied(false);
                            }, 2000);
                          }}
                          className="relative p-2 rounded-full cursor-pointer hover:bg-slate-100 group text-slate-800 bg-slate-50"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 "
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                            />
                          </svg>
                          <div
                            className={`absolute -top-full -left-1/2 px-3 py-2 text-xs text-black opacity-0 transition-all translate-y-0 translate-x-1  rounded-lg ${copied &&
                              "opacity-100 -translate-y-2"} bg-slate-100`}
                          >
                            Copied!
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
                <Info
                  title={collab?.client.brand}
                  value={collab?.client.bio || "No bio yet"}
                />
                {collab?.client.businessLinks && (
                  <Info
                    title="Links"
                    value={collab?.client.businessLinks?.map((link, i) => (
                      <a
                        key={i}
                        className="underline cursor-pointer"
                        href={link?.includes("http") ? link : "https://" + link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {link?.includes("http") ? link : "https://" + link}
                      </a>
                    ))}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
