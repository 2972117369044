import React, { useEffect, useRef, useState } from "react";
import UploadVideo from "./UploadVideo";
import {
  deleteProfileVideo,
  getProfileVideo,
} from "@thatsclutch/shared/services/UserService";
import { FiLoader, FiPause, FiPlay } from "react-icons/fi";
import {
  getCurrCreator,
  useCreatorDispatch,
  useCreatorState,
} from "../../../../../contexts/CreatorContext";
import ThemeSelect from "./ThemeSelect";

export default function IntroVideo({ refresh }) {
  const [showVideoUpload, setShowVideoUpload] = useState(false);
  const [videoUrl, setVideoUrl] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const vidRef = useRef(null);
  const vidRefBlur = useRef(null);
  const { creator } = useCreatorState();
  const dispatch = useCreatorDispatch();
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    loadIntroVideo();
  }, [creator?.updatedAt]);

  const loadIntroVideo = async () => {
    const src = await getProfileVideo(creator);
    setVideoUrl(src);
    vidRef.current && vidRef.current.load();
  };

  const refreshCreator = async () => {
    await refresh();
  };

  const THEME_MAP = {
    "#F5F2ED": "bg-white md:bg-[#F5F2ED]",
    "#E1EEF5": "bg-white md:bg-[#E1EEF5]",
    "#E6F5E8": "bg-white md:bg-[#E6F5E8]",
    "#F0F2F5": "bg-white md:bg-[#F0F2F5]",
    "#F3EDF7": "bg-white md:bg-[#F3EDF7]",
  };

  return (
    <div
      className={`relative h-[75vh] ${
        THEME_MAP[creator?.themeColor || "#F5F2ED"]
      } rounded-xl`}
    >
      <div className="hidden md:absolute left-4 top-4">
        <ThemeSelect />
      </div>
      <div className="flex flex-col w-full h-full py-8 mx-auto space-y-8 md:w-3/5 ">
        {/* Header */}
        <div className="flex items-center justify-between space-x-4">
          <div className="text-xl font-medium md:text-4xl">My Intro Video</div>
          <button
            onClick={() => setShowVideoUpload(true)}
            className="px-8 py-3 text-sm font-thin text-white bg-black border-2 border-black rounded-full hover:bg-transparent hover:text-black"
          >
            Upload
          </button>
        </div>
        {/* Video Wrapper */}
        {videoUrl ? (
          <div className="relative h-full overflow-hidden rounded-lg b">
            <video
              controls={false}
              onClick={(e) => {
                const video = vidRef.current;
                const videoBlur = vidRefBlur.current;
                if (video.paused) {
                  video.play();
                  videoBlur.play();
                  setIsPlaying(true);
                  setWaiting(false);
                } else {
                  video.pause();
                  videoBlur.pause();
                  videoBlur.currentTime = video.currentTime;
                  setIsPlaying(false);
                  setWaiting(false);
                }
              }}
              loop
              ref={vidRef}
              className={`absolute top-0 left-0 object-contain w-full h-full mx-auto rounded-lg cursor-pointer`}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {!isPlaying && (
              <div className="absolute p-4 translate-x-1/2 -translate-y-1/2 bg-black rounded-full pointer-events-none top-1/2 right-1/2 ">
                <FiPlay className="text-white" />
              </div>
            )}
            {waiting && (
              <div className="absolute p-4 translate-x-1/2 -translate-y-1/2 bg-black rounded-full pointer-events-none top-1/2 right-1/2 ">
                <FiLoader className="text-white animate-spin" />
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center flex-1 w-full p-4 space-y-4 rounded-lg md:p-8 bg-gray-50 md:bg-white">
            <div className="text-xl font-medium md:w-2/3 ">
              ✨ Upload a 1 min intro video ✨
            </div>
            <div className="text-sm font-thin md:w-2/3 ">
              Please create a short video to get matched with brands. This is
              your chance to show off your personality and creativity.
            </div>
          </div>
        )}
      </div>
      <UploadVideo
        open={showVideoUpload}
        onClose={() => {
          setShowVideoUpload(false);
        }}
        videoUrl={videoUrl}
        onSubmit={() => {
          setShowVideoUpload(false);
          setVideoUrl(null);
          refreshCreator();
        }}
        creator={creator}
      />
    </div>
  );
}
