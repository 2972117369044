import { AmpAPI } from "@thatsclutch/shared";
import dayjs from "dayjs";
import React, { useState } from "react";

export default function Message({ submission, sender }) {
  const formatLink = (links: string) => {
    // check if links is array or stirng
    const unformattedLink = Array.isArray(links)
      ? links.length > 0
        ? links[0]
        : "Invalid link"
      : links;
    if (!unformattedLink) return "Invalid link";
    if (!unformattedLink.includes("http"))
      return "https://" + unformattedLink.trim();
    return unformattedLink.trim();
  };

  return (
    <div className="p-4 py-4 space-y-4 overflow-hidden bg-white shadow-2xl md:p-6 shadow-slate-200/50 rounded-2xl">
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {submission.status === "IN_REVIEW" && (
              <div className="w-2 h-2 bg-green-400 rounded-full"></div>
            )}
            <div
              className={`text-slate-600 font-medium ${sender === "You" &&
                "text-slate-500 font-normal"} capitalize text-slate-700`}
            >
              {sender}
            </div>
          </div>
          <div className="text-sm text-slate-400">
            {dayjs(submission.createdAt).format("h:mm a - MMM D, YYYY")}
          </div>
        </div>
        <div className="leading-normal whitespace-pre-line text-slate-600">
          {submission.note}
        </div>
		{submission.revisionNote && (
          <div className="leading-normal whitespace-pre-line text-rose-800/70">
            Revision note: {submission.revisionNote}
          </div>
        )}
      </div>
      {submission.links && submission.links.length > 0 && submission.links[0] && (
        <div className="flex flex-col items-center p-2 space-y-4 bg-white border rounded-lg md:space-y-0 md:space-x-4 md:flex-row md:px-2 md:p-2 border-slate-200">
          <a
            href={formatLink(submission.links)}
            className="flex-1 w-full overflow-clip"
          >
            <div className="text-center underline md:text-start md:pl-4 text-slate-600">
              {submission.links}
            </div>
          </a>
          {/* divider */}
          {submission.status === "IN_REVIEW" && (
            <div className="w-full p-2 px-6 text-center transition-colors bg-blue-100 rounded-md md:w-min whitespace-nowrap text-blue-800/70">
              In Review
            </div>
          )}
          {submission.status === "REVISION" && (
            <div className="w-full p-2 px-6 text-center transition-colors rounded-md bg-rose-100 md:w-min whitespace-nowrap text-rose-800/70">
              Revision Requested
            </div>
          )}
          {submission.status === "COMPLETED" && (
            <div className="w-full p-2 px-6 text-center transition-colors bg-green-100 rounded-md md:w-min whitespace-nowrap text-green-800/70">
              Asset approved
            </div>
          )}
        </div>
      )}
    </div>
  );
}
