import { getCreator, getCurrentCreator } from "@thatsclutch/shared/services/CreatorService";
import React from "react";

const CreatorStateContext = React.createContext(undefined);
const CreatorDispatchContext = React.createContext(undefined);

const initialState = {
  creator: null,
  loading: true,
};

async function getCreatorByID(dispatch, creatorID) {
  try {
    const result = await getCreator(creatorID);
    dispatch({ type: "set", payload: result });
  } catch (error) {
    console.log(error);
  }
}

async function getCurrCreator(dispatch) {
  try {
    const result = await getCurrentCreator();
    dispatch({ type: "set", payload: result });
  } catch (error) {
    console.log(error);
  }
}

async function setCreator(dispatch, creator) {
  try {
    dispatch({ type: "set", payload: creator });
  } catch (error) {
    console.log(error);
  }
}

function userReducer(state, action) {
  switch (action.type) {
    case "set": {
      return { ...state, creator: action.payload, loading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CreatorStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  return (
    <CreatorStateContext.Provider value={state}>
      <CreatorDispatchContext.Provider value={dispatch}>
        {children}
      </CreatorDispatchContext.Provider>
    </CreatorStateContext.Provider>
  );
}

function useCreatorState() {
  const context = React.useContext(CreatorStateContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationState must be used within a Creator StateProvider"
    );
  }
  return context;
}

function useCreatorDispatch() {
  const context = React.useContext(CreatorDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationDispatch must be used within a CreatorStateProvider"
    );
  }
  return context;
}

export {
  CreatorStateProvider,
  useCreatorState,
  useCreatorDispatch,
  setCreator,
  getCreatorByID,
  getCurrCreator
};
