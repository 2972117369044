import React, { useEffect, useState } from "react";
import Header from "./Header";
import Navigation from "./Navigation";
import useAuth from "../../Auth/useAuth";
import {
  getCreatorByID,
  getCurrCreator,
  useCreatorDispatch,
  useCreatorState,
} from "../../../contexts/CreatorContext";
import Collabs from "./Collabs";
import Collab from "./Collabs/Collab";
import Profile from "./Profile";
import Home from "./Home";

export default function Dashboard({ creatorID }) {
  const [currUser] = useAuth();
  const dispatch = useCreatorDispatch();
  const { creator, loading } = useCreatorState();
  const [showPage, setShowPage] = useState("Home");
  const [selectedCollab, setSelectedCollab] = useState(null);

  useEffect(() => {
    currUser && loadCreator();
  }, [currUser]);

  const loadCreator = async () => {
    console.log("Load creator");
    // Check if admin
    if (currUser?.userGroups?.includes("admins") && creatorID) {
      await getCreatorByID(dispatch, creatorID);
    } else {
      await getCurrCreator(dispatch);
    }
  };

  const handleSwitch = (page) => {
    setSelectedCollab(null);
    setShowPage(page);
  };

  return (
    <div className="flex w-screen bg-white">
      {/* Navigation */}
      <div className="hidden md:block">
        <Navigation navSelect={handleSwitch} selected={showPage} />
      </div>
      {/* Main */}
      {creator ? (
        <div className="flex flex-col flex-1 h-screen overflow-y-auto">
          <Header navSelect={handleSwitch} />

          {selectedCollab ? (
            <Collab
              onBack={() => setSelectedCollab(null)}
              collab={selectedCollab}
              creator={creator}
            />
          ) : showPage === "Collabs" ? (
            <Collabs
              creatorID={creator?.id}
              creator={creator}
              onSelectCollab={setSelectedCollab}
            />
          ) : showPage === "Profile" ? (
            <Profile creator={creator} loadCreator={loadCreator} />
          ) : (
            <>
              <Home
                creator={creator}
                loading={loading}
                onSelectCollab={setSelectedCollab}
                navSelect={handleSwitch}
              />
            </>
            //   <div className="w-full max-w-5xl mx-auto overflow-y-scroll">
            //     {/* hide todo if creator is active */}
            //     {creator && creator?.status !== "ACTIVE" && (
            //       <Todo refreshCreator={loadCreator} />
            //     )}
            //     <ActiveCollabs
            //       creatorID={creator?.id}
            //       creator={creator}
            //       onSelectCollab={setSelectedCollab}
            //     />
            //     <div className="flex flex-col md:flex-row">
            //       {/* <Services creatorID={creatorID} /> */}
            //       <Blogs />
            //     </div>
            //   </div>
          )}
        </div>
      ) : (
        <div className="w-full p-8 text-center">Loading...</div>
      )}
    </div>
  );
}
